import { createSlice } from "@reduxjs/toolkit";

export const intialStateData = {
  templateFlow: {
    templateFlowValue: []
  },
  statusTransition: {
    statusTransitionValue: [],
    loader: false,
    templateId: ""
  },
  participants: {
    participantsValue: [],
    participantCreated:null
  },
  documentType: {
    documentTypeValue: [],
    documentTypeFormFill: [],

  },
  genericGroup: {
    genericGroupValue: [],

  },
  userType: {
    userTypeValue: [],

  },

  localParticipantUpdate:null,
  localConditionUpdate: null,

  condition: {
    conditionValue: null,
  },
  statusTemplate: {
    statusTemplateValue: null,
  },
  patchStatusTemplate: {
    patchStatusTemplateValue: null,
  },
  StatusTemplateWithNodes: {
    StatusTemplateWithNodesValue: null,
  },
  
  
};
export const WorkFlowSlice = createSlice({
  name: "workFlow",
  initialState: intialStateData,
  reducers: {
    //template redux action
    getTemplateFlowAction: (state, action) => {
      // state.templateFlow = {
      //   ...state.templateFlow,
      // };
    },

    successTemplateFlow: (state, action) => {

      state.templateFlow = {
        ...state.templateFlow,
        templateFlowValue: action.payload,
      };
    },

    //template redux action
    getStatusTransitionAction: (state, action) => {
      console.log("actionPayload000200202", action.payload)
      state.statusTransition = {
        ...state.statusTransition,
        templateId: action.payload,
      };
    },

    successTransitionAction: (state, action) => {

      state.statusTransition = {
        ...state.statusTransition,
        statusTransitionValue: action.payload,
      };
    },

    postStatusTransitionAction: (state, action) => {
      console.log("loader1111")
      state.statusTransition = {
        ...state.statusTransition,
        // loader: true,
      };
    },

    putStatusTransitionAction: (state, action) => {
      console.log("loader1111")
      state.statusTransition = {
        ...state.statusTransition,
        // loader: true,
      };
    },

    successPostTransitionAction: (state, action) => {
      state.statusTransition = {
        ...state.statusTransition,
        loader: false,
      };
    },

    getParticipantsAction: (state, action) => {
      console.log("actionPayload", action.payload)
    },

    successGetParticipantsAction: (state, action) => {
      state.participants = {
        ...state.participants,
        participantsValue: action.payload,
      };
    },

    getDocumentTypeAction: (state, action) => {
      console.log("actionPayload", action.payload)
    },

    successGetDocumentTypeAction: (state, action) => {
      state.documentType = {
        ...state.documentType,
        documentTypeValue: action.payload,
      };
    },

    getDocumentTypeFormFillAction: (state, action) => {
      console.log("actionPayload", action.payload)
    },

    successGetDocumentTypeFormFillAction: (state, action) => {
      state.documentType = {
        ...state.documentType,
        documentTypeFormFill: action.payload,
      };
    },

    getGenericGroupAction: (state, action) => {
      console.log("actionPayload", action.payload)
    },

    successGetGenericGroupAction: (state, action) => {
      state.genericGroup = {
        ...state.genericGroup,
        genericGroupValue: action.payload,
      };
    },
    getUserTypeAction: (state, action) => {
      console.log("actionPayload", action.payload)
    },
    successGetUserTypeAction: (state, action) => {
      state.userType = {
        ...state.userType,
        userTypeValue: action.payload,
      };
    },

    postParticipantsAction: (state, action) => {
      console.log("loader1111")
    },
    successPostParticipantsAction: (state, action) => {
      state.participants = {
        ...state.participants,
        participantCreated: action.payload,
      };
    },
    postConditionAction: (state, action) => {
      console.log("loader1111")
    },
    getConditionAction: (state, action) => {
      console.log("loader1111")
    },
    successGetConditionAction: (state, action) => {
      state.condition = {
        ...state.condition,
        conditionValue: action.payload,
      };
    },

    getStatusTemplateAction: (state, action) => {
      console.log("loader1111")
    },
    postStatusTemplateAction: (state, action) => {
      console.log("loader1111")
    },
    putStatusTemplateAction: (state, action) => {
      console.log("loader1111")
    },
    successGetStatusTemplateAction: (state, action) => {
      state.statusTemplate = {
        ...state.statusTemplate,
        statusTemplateValue: action.payload,
      };
    },

    successPostConditionAction: (state, action) => {
      // state.participants = {
      //   ...state.participants,
      //   participantCreated: action.payload,
      // };
    },
    localPaticipantUpdateAction: (state, action) => {
      state.localParticipantUpdate = action.payload
    },
    localConditionUpdateAction: (state, action) => {
      state.localConditionUpdate = action.payload
    },
    patchStatusTemplateAction: (state, action) => {
      console.log("patch01")
    },
    successPatchStatusTemplateAction: (state, action) => {
      state.patchStatusTemplate = {
        ...state.patchStatusTemplate,
        patchStatusTemplateValue: action.payload,
      };
    },

    getStatusTemplateWithNodesAction: (state, action) => {
      console.log("loader1111")
    },
    successGetStatusTemplateWithNodesAction: (state, action) => {
      console.log("action010101010",action.payload)
      state.StatusTemplateWithNodes = {
        ...state.StatusTemplateWithNodes,
        StatusTemplateWithNodesValue: action.payload,
      };
    },
    putParticipantsAction: (state, action) => {
      console.log("loader1111")
    },
  },
});

export const {
  getTemplateFlowAction,
  successTemplateFlow,
  getStatusTransitionAction,
  successTransitionAction,
  postStatusTransitionAction,
  successPostTransitionAction,
  putStatusTransitionAction,
  getParticipantsAction,
  successGetParticipantsAction,
  getDocumentTypeAction,
  successGetDocumentTypeAction,
  getDocumentTypeFormFillAction,
  successGetDocumentTypeFormFillAction,
  getGenericGroupAction,
  successGetGenericGroupAction,
  getUserTypeAction,
  successGetUserTypeAction,
  postParticipantsAction,
  successPostParticipantsAction,
  postConditionAction,
  successPostConditionAction,
  localPaticipantUpdateAction,
  localConditionUpdateAction,
  getConditionAction,
  successGetConditionAction,
  getStatusTemplateAction,
  successGetStatusTemplateAction,
  postStatusTemplateAction,
  putStatusTemplateAction,
  patchStatusTemplateAction,
  successPatchStatusTemplateAction,
  getStatusTemplateWithNodesAction,
  successGetStatusTemplateWithNodesAction,
  putParticipantsAction
} = WorkFlowSlice.actions;
