import React, { FC, useState } from "react";
import RenderItem from "./RenderItem";
import { FormLayoutComponentsType } from "../../../../types/FormTemplateTypes";
import { Button } from "@mui/material";

const previewWindowStyle = {
  backgroundColor: "white",
  height: "85vh",
  overflowY: "scroll",
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: "9px",
  boxShadow: "0 9px 90px #efefef",
  marginLeft: "auto",
  marginRight: "auto",
};

const buttonStyle = {
  margin: "1rem 0 0 1rem",
  fontSize: "1.15rem",
};

interface StepperFormPreviewProps {
  formLayoutComponents: FormLayoutComponentsType[];
  screenType: string;
}

const StepperFormPreview: FC<StepperFormPreviewProps> = (props: any) => {
  const { saveForm, closePreviewDrawer, templateName } = props;
  const [componentIndex, setComponentIndex] = useState(0);
  const { formLayoutComponents, screenType } = props;

  const component = formLayoutComponents[componentIndex];

  const nextPrevIndex = (val: number) => {
    setComponentIndex((prev) => prev + val);
  };

  const isMobile = screenType === "mobile";

  return (
    <>
      {formLayoutComponents.length > 0 ? (
        <>
          <div className="m-t-5 p-2">
            <div
              style={{
                ...(previewWindowStyle as any),
                width: "100%",
                height: "88vh",
              }}
              className="p-20"
            >
              <div className="main-form">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  style={{ minWidth: "100%" }}
                >
                  <div className="text-center border-bottom p-b-10">
                    <h4 style={{ textAlign: "left" }}>{templateName}</h4>
                  </div>

                  <h5 style={{ margin: "1rem 0 0 1rem" }}>
                    {component?.container?.name}
                  </h5>
                  <div className="w-100 d-flex flex-wrap">
                    {component.children.map((child, ind) =>
                      child?.children ? (
                        <>
                          <RenderItem key={ind} item={child} />
                          {/* {child?.children.map((arrayChild) => (
                          <div key={arrayChild.id} className="my-4">
                            <h5>
                              {arrayChild.labelName +
                                (arrayChild.required ? " *" : "")}
                            </h5>
                            {arrayChild.description !== "" ? (
                              <>
                                <div className="mt-1">
                                  <p>{arrayChild.description}</p>
                                </div>
                              </>
                            ) : null}
                            
                          </div>
                        ))} */}
                        </>
                      ) : (
                        child?.visible && (
                          <div
                            key={child.id}
                            className="my-3"
                            style={{
                              width:
                                child?.gridValue === 4
                                  ? "26%"
                                  : child?.gridValue === 6
                                  ? "42%"
                                  : "100%",
                              margin: "0 1rem",
                            }}
                          >
                            <RenderItem key={child.id} item={child} />
                          </div>
                        )
                      )
                    )}
                  </div>

                  {componentIndex !== 0 && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        nextPrevIndex(-1);
                      }}
                      className="text-capitalize"
                      style={buttonStyle}
                    >
                      Previous
                    </Button>
                  )}
                  {componentIndex < formLayoutComponents.length - 1 && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        nextPrevIndex(1);
                      }}
                      className="text-capitalize"
                      style={buttonStyle}
                    >
                      Next
                    </Button>
                  )}
                  {componentIndex + 1 === formLayoutComponents.length && (
                    <Button
                      variant="contained"
                      className="text-capitalize"
                      style={buttonStyle}
                      onClick={() => {
                        saveForm();
                        closePreviewDrawer();
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="m-t-30">
            <p>You need to add Containers and Controls to see output here.</p>
          </div>
        </>
      )}
    </>
  );
};

export default StepperFormPreview;
