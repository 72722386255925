import React, { useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditRuleComponent from "./EditRuleComponent";
import { RuleIcon } from "../../../assets/icons";
import RemoveIcon from "@mui/icons-material/Remove";
import { FormBuilderContext } from "../FormBuilder";

const ViewRuleCard = (props: any) => {
  const {
    item,
    index,
    expanded,
    setExpanded,
    handleChange,
    selectedItem,
    setParticularRule,
    activeInactive,
    isRuleActive,
    setFormName,
  } = props;

  const value: any = useContext(FormBuilderContext);
  const { ruleDropdownValue } = value;

  const display =
    isRuleActive === undefined
      ? "block"
      : isRuleActive && !item?.deleted
      ? "block"
      : !isRuleActive && item?.deleted
      ? "block"
      : "none";

  let particularRuleJson: any;
  // Extracting names by ids
  if (item?.actionType && item?.sourceType) {
    particularRuleJson = ruleDropdownValue?.find(
      (e: any) =>
        e?.action === item?.actionType && e?.source === item?.sourceType
    );
  } else {
    particularRuleJson = ruleDropdownValue?.find(
      (e: any) => e?.action === item?.actionType
    );
  }

  return (
    <Accordion
      expanded={expanded === index}
      onChange={() => {
        handleChange(index, item, "");
        setFormName(null);
        setParticularRule(null);
        setTimeout(() => {
          setParticularRule(item);
        }, 0);
      }}
      style={{
        boxShadow: "none",
        background: "transparent",
        display: display,
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === index ? (
            <RemoveIcon
              style={{
                color: "#4f4f4f",
                fontSize: "1.8rem",
                margin: "0.75rem 0 0 0",
              }}
            />
          ) : (
            <AddOutlinedIcon
              style={{
                color: "#4f4f4f",
                fontSize: "1.8rem",
                margin: "-1rem 0 0 -2rem",
              }}
            />
          )
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        style={{ width: "100%", minWidth: 0 }}
      >
        <div
          style={{ margin: "0 0 1rem 0", borderBottom: "1px dotted grey" }}
          className="w-100 d-flex align-items-center justify-content-between"
        >
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <RuleIcon size={30} color={""} />
              <div style={{ margin: "0 0 0 1rem" }}>
                <p style={{ margin: 0, padding: 0, fontSize: "12px" }}>
                  Rule {index + 1}
                </p>
                {particularRuleJson && particularRuleJson?.name?.length > 11 ? (
                  <Tooltip
                    title={particularRuleJson?.name}
                    placement="top-start"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#FFF",
                          color: "#4f4f4f",
                          boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                          fontSize: "12px",
                        },
                      },
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {particularRuleJson?.name?.slice(0, 10) + "..."}
                    </p>
                  </Tooltip>
                ) : (
                  particularRuleJson?.name && (
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#000",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {particularRuleJson?.name}
                    </p>
                  )
                )}
              </div>
            </div>

            <p
              style={{
                margin: "0.25rem 2.25rem 0 1rem",
                color: !item?.deleted ? "#3CB371" : "",
              }}
              onClick={(e) => {
                setParticularRule(item);
                console.log(`initial status`, item);
                handleChange(index, item, "status");
                activeInactive(e, !item?.deleted);
              }}
            >
              {!item?.deleted ? "Active" : "InActive"}
            </p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <EditRuleComponent
          type="view"
          {...props}
          expanded={expanded}
          setExpanded={setExpanded}
          selectedItem={selectedItem}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ViewRuleCard;
