// import { configureStore } from "@reduxjs/toolkit";
// import entitiesReducer from "./entities";
// import uireducers from "./uireducers";

// export const store = configureStore({
//   reducer: {
//     entities: entitiesReducer,
//     uielements: uireducers,
//   },
// });

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;


// import { configureStore } from "@reduxjs/toolkit";
// import createSagaMiddleware from "redux-saga";
// import entitiesReducer from "./entities";
// import uireducers from "./uireducers";
// import { rootSagas } from "../middlewareSaga/Saga";

// // Create the saga middleware
// const sagaMiddleware = createSagaMiddleware();

// // Configure the store
// export const store = configureStore({
//   reducer: {
//     entities: entitiesReducer,
//     uielements: uireducers,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(sagaMiddleware),
// });

// // Run the root saga
// sagaMiddleware.run(rootSagas);

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;


import { configureStore } from "@reduxjs/toolkit";
import { createRootReducer } from "./Reducer";
import createSagaMiddleware from "redux-saga";
import { rootSagas } from "../middlewareSaga/Saga";
const sagaMiddleware = createSagaMiddleware();

export function createStore() {
  return configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
  });
}

export const store = createStore();
sagaMiddleware.run(rootSagas)