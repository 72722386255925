import { styled, TextareaAutosize } from "@mui/material";

export const selectedRuleObj = {
  name: "",
  source: "",
  action: "",
  processingType: "",
  applicableTypes: [],
  validatable: true,
  conditionsRequired: false,
  destinationFields: {
    numberOfOutputs: 0,
    fields: [
      {
        name: "",
        ordinal: 0,
        mandatory: false,
      },
    ],
  },
  sourceFields: {
    numberOfOutputs: 0,
    fields: [
      {
        name: "",
        ordinal: 0,
        mandatory: false,
      },
    ],
  },
};

export const incDec: number = 0.0001;

export const roundOff = (
  a: number,
  type: string = "add",
  b: number = incDec
) => {
  if (type === "sub") {
    return Math.round((a - b) * 10000) / 10000;
  } else {
    return Math.round((a + b) * 10000) / 10000;
  }
};

function removeSpecialCharacters(str: string) {
  return str.replace(/[^a-zA-Z0-9_ ]/g, "");
}

export const variableName = async (text: any) => {
  if (text?.length > 0) {
    const updatedText = text.charAt(0).toLowerCase() + text.slice(1);

    let res = updatedText.replace(/\s+/g, "");

    if (res.slice(0, res.length - 1).length > 7) {
      res =
        res.slice(0, res.length - 1).slice(0, 7) +
        Math.floor(Math.random() * 90 + 10);
    } else {
      res = res.slice(0, res.length - 1) + Math.floor(Math.random() * 90 + 10);
    }

    res = "_" + removeSpecialCharacters(res) + "_";
    return res;
  } else {
    return "_" + "_";
  }
};

export const defaultTxtObj = {
  text: "",
  type: "text",
};

export const defaultBoxObj = {
  text: "",
  type: "box",
};

export const initialDisplayState = {
  action: "",
  group: [
    {
      condition: [
        { when: [defaultTxtObj], operator: "", value: [defaultTxtObj] },
      ],
    },
  ],
  destination: [],
};

export const defaultValRange = {
  val: null,
  range: null,
};

export const defaultPath = "/";

export const searchableArr = ["EXT_DROP_DOWN", "EXT_VALUE"];

export const searchableEdvLocation = [
  { label: "Dropdown with location", value: 7 },
  { label: "Dropdown without location", value: 4 },
];

export const deleteDocData = [
  "DELETE_DOCUMENT",
  "COPY_TO_DOCUMENT_STORAGE_ID",
  "MAKE_DOCUMENT_UPLOAD_MANDATORY",
  "MAKE_DOCUMENT_UPLOAD_MANDATORY",
  "UNDELETE_DOCUMENT",
];

export const standardArray = [
  "TEXT",
  "TEXTAREA",
  "NUMBER",
  "TIME",
  "DATE",
  "STATIC_CHECK_BOX",
  "EXTERNAL_DROP_DOWN_VALUE",
  "FILE",
  "IMAGE",
  "EXECUTE_BUTTON",
  "OTP",
];

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

export const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  margin: 1rem 0 0 0;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  /* firefox */
  &:focus-visible {
    outline: 0;
  }
`
);

export const operatorData = [
  { label: "Equal", value: "eq" },
  { label: "Not Equal", value: "notEq" },
  { label: "In", value: "in" },
  { label: "Not In", value: "notIn" },
  { label: "Contains", value: "contains" },
  { label: "Not Contains", value: "notContains" },
  { label: "Greater Than", value: "gt" },
  { label: "Less Than", value: "lt" },
  { label: "Greater Than Equal To", value: "ge" },
  { label: "Less Than Equal To", value: "le" },
  { label: "Is Null", value: "isNull" },
  { label: "Is Not Null", value: "isNotNull" },
];

export const actionData = [
  {
    name: "makeMandatory",
    type: "action",
    label: "Make a field madantory",
    shorthand: "mm",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make mandatory",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "makeNonMandatory",
    type: "action",
    label: "Make a field non madantory",
    shorthand: "mnm",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make mandatory",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "sessionBasedMakeMandatory",
    type: "action",
    label: "Session Based make a field madantory",
    shorthand: "sbmm",
    parameters: [
      // condition box
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },

      // Insert Box inside Condition Box ( only one box )
      {
        type: "dynamicValue",
        label: "Value to copy",
        mandatory: true,
        unlimited: false,
      },

      // dropdown ( Destination Field )
      {
        type: "formFillMetadata",
        label: "Fields to make mandatory",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "sessionBasedMakeNonMandatory",
    type: "action",
    label: "Session Based make a field non madantory",
    shorthand: "sbmnm",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "dynamicValue",
        label: "Value to copy",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make mandatory",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "makeVisible",
    type: "action",
    label: "Make a field visible",
    shorthand: "mvi",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make visible",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "makeInvisible",
    type: "action",
    label: "Make a field invisible",
    shorthand: "minvi",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make invisible",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "sessionBasedMakeVisible",
    type: "action",
    label: "Session Based make a field visible",
    shorthand: "sbmvi",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "dynamicValue",
        label: "Value to copy",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make mandatory",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "sessionBasedMakeInvisible",
    type: "action",
    label: "Session Based make a field invisible",
    shorthand: "sbmnm",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "dynamicValue",
        label: "Value to copy",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make mandatory",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "enable",
    type: "action",
    label: "Make a field enable",
    shorthand: "en",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make enable",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "disable",
    type: "action",
    label: "Make a field disable",
    shorthand: "dis",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make disable",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "sessionBasedEnable",
    type: "action",
    label: "Session Based make a field enable",
    shorthand: "sben",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "dynamicValue",
        label: "Value to copy",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make enable",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "autoSaveDestinationFormFields",
    type: "action",
    label: "Save a destination",
    shorthand: "asdff",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to save",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "sessionBasedDisable",
    type: "action",
    label: "Session Based make a field disable",
    shorthand: "sbdis",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "dynamicValue",
        label: "Value to copy",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make disable",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "executeRuleById",
    type: "action",
    label: "Executes all rules of id",
    shorthand: "erbyid",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Fields to make disable",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "clearField",
    type: "action",
    label: "Clear all fields of the id",
    shorthand: "cf",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "clear all fields",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "addError",
    type: "action",
    label: "Add Error to the fields",
    shorthand: "adderr",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "adderr to the fields",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "removeError",
    type: "action",
    label: "Remove Error to the fields",
    shorthand: "remerr",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "remerr to the fields",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "copyToFillData",
    label: "Copy to fields",
    shorthand: "ctfd",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "dynamicValue",
        label: "Value to copy",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Destination fields",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "refreshFormFillDropDown",
    label: "Refresh fields from dropdown",
    shorthand: "rffdd",
    parameters: [
      {
        type: "condition",
        label: "Condition",
        mandatory: true,
        unlimited: false,
      },
      {
        type: "formFillMetadata",
        label: "Destination fields",
        mandatory: true,
        unlimited: true,
      },
    ],
  },
  {
    name: "valueOf",
    label: "Value of a field",
    type: "dataAccess",
    shorthand: "vo",
    parameters: [
      {
        type: "formFillMetadata",
        label: "Source field",
        mandatory: true,
        unlimited: false,
      },
    ],
  },
  {
    name: "validationStatusOf",
    label: "Validation status of a field",
    type: "dataAccess",
    shorthand: "vso",
    parameters: [
      {
        type: "formFillMetadata",
        label: "Source field",
        mandatory: true,
        unlimited: false,
      },
    ],
  },
  {
    name: "participantOrder",
    label: "Participant Order of a field",
    type: "dataAccess",
    shorthand: "po",
  },
  {
    name: "getElementById",
    label: "element of a field",
    type: "dataAccess",
    shorthand: "elbyid",
    parameters: [
      {
        type: "formFillMetadata",
        label: "Source field",
        mandatory: true,
        unlimited: false,
      },
    ],
  },
];
