import { Box, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { RuleIcon } from "../../../../assets/icons";
import { FormBuilderContext } from "../../FormBuilder";

const Card = ({ index = 1, particularRuleJson = { name: "Test Scene" } }) => {
  const value: any = useContext(FormBuilderContext);
  const { onSmartSuggestion } = value;
  return (
    <Box sx={{ width: "100%", marginTop: "1rem" }}>
      <div
        style={{ margin: "0 0 1rem 0", borderBottom: "1px dotted grey" }}
        className="w-100 d-flex align-items-center justify-content-between"
      >
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <RuleIcon size={30} color={""} />
            <div style={{ margin: "0 0 0 1rem" }}>
              <p style={{ margin: 0, padding: 0, fontSize: "12px" }}>
                Rule {index + 1}
              </p>
              {particularRuleJson && particularRuleJson?.name?.length > 20 ? (
                <Tooltip
                  title={particularRuleJson?.name}
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#FFF",
                        color: "#4f4f4f",
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                        fontSize: "12px",
                      },
                    },
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "#000",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {particularRuleJson?.name?.slice(0, 19) + "..."}
                  </p>
                </Tooltip>
              ) : (
                particularRuleJson?.name && (
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "#000",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {particularRuleJson?.name}
                  </p>
                )
              )}
            </div>
          </div>

          <p
            style={{
              margin: "0.25rem 2.25rem 0 1rem",
              color: "#318be3",
              cursor: "pointer",
            }}
            onClick={() => onSmartSuggestion(particularRuleJson)}
          >
            Add
          </p>
        </div>
      </div>
    </Box>
  );
};

export default Card;
