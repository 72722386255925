import React from 'react'
import "./CongratulationPopup.scss"
import { Box, Typography,Stack } from '@mui/material'
// import upload from "../../../assets/images/active-documents.svg"
// import flag from "../../../assets/images/flag.svg"
import { useSelector} from "react-redux";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import BigDone from "../../../../assets/icons/FormFillIcons/BigDone"
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[400],
    borderRadius: 1,
  },
}));

// Custom Step Icon Styles
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "transparent",
  zIndex: 1,
  color: "#fff",
  width: 54,
  height: 54,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "transparent",
    color: "#fff",
  }),
  ...(ownerState.completed && {
  
    color: "#1d7c5f",
  }),
}));

// Custom Step Icon Component
const CustomStepIcon = ({
  active,
  completed,
  icon,
  firstDocumentAvailable,
}) => {
  const icons = firstDocumentAvailable
    ? {
        1: <BigDone />,
        2: <BigDone />,
        3: <BigDone />,
      }
    : {
        1: <BigDone />,
        2: <BigDone />,
        3: <BigDone />,
      };

  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {icons[icon]}
    </CustomStepIconRoot>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired,
  firstDocumentAvailable: PropTypes.bool.isRequired,
};


const CongratulationPopup = () => {
  const documents = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents || []
  );

  const firstDocumentName = documents?.[0]?.name || "Loading...";
  const firstDocumentAvailable = firstDocumentName !== "Loading...";
  const steps = [
    { id: 1, label: firstDocumentName },
    { id: 2, label: "Document List" },
    { id: 3, label: "Submission" },
  ];

  // Adjust steps if there's only one document
  const adjustedSteps =
    documents.length === 1
      ? [
          { id: 1, label: firstDocumentName },
          { id: 2, label: "Submission" },
        ]
      : steps;
  console.log(documents, "documents");
  const activeStep = adjustedSteps.length-1;
  return (
    <Box className="CongratulationClass">
      <Box className="textCongra">
        <Typography variant="fontThirty">
        Congratulations!
        </Typography>
      </Box>
      <Box className="textCompleted" mt={2}>
        <Typography variant="sidebarText">
        You have successfully completed all the required task and submitted!

        </Typography>
      </Box>
      <Box className="horizontalStepperClass">
      <Stack spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep} // Set active step dynamically
            connector={<CustomConnector />}
          >
            {adjustedSteps.map((step) => (
              <Step key={step.id}>
                <StepLabel
                  style={{
                    color: step === activeStep ? "#1d7c5f" : "#898d90",
                    fontWeight: step === activeStep ? "bold" : "normal",
                 
                  }}
                  StepIconComponent={(props) => (
                    <CustomStepIcon
                      {...props}
                      icon={step.id}
                      firstDocumentAvailable={firstDocumentAvailable}
                      style={{ width: '44px', height: '44px' }} 
                    />
                  )}
                >
            {step.label}  
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
    </Box>
    <Box className="textCompleted" mt={5}>
        <Typography variant="returnText">
        You will be returned to home page in few second’s!
        </Typography>
      </Box>
    </Box>
  )
}

export default CongratulationPopup