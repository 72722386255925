import { createSlice } from "@reduxjs/toolkit";

export const intialStateData = {
  loader: {
    loadingState: false,
  },
  alert: {
    severity: "success",
    open:false,
    message:""
  },
  
};
export const GlobalSlice = createSlice({
  name: "global",
  initialState: intialStateData,
  reducers: {
    loaderAction: (state, action) => {
        console.log("loaderPayload",action.payload)
      state.loader = {
        ...state.loader,
        loadingState: action.payload
      };
    },
    alertAction: (state, action) => {
      state.alert = {
        ...state.alert,
        severity: action.payload.severity,
        open:action.payload.severity,
        message:action.payload.message,
      };
    },

  },
});

export const {
    loaderAction,
    alertAction

} = GlobalSlice.actions;
