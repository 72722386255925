import {
  getTemplateFlowAction,
  successTemplateFlow,
  getStatusTransitionAction,
  successTransitionAction,
  postStatusTransitionAction,
  successPostTransitionAction,
  putStatusTransitionAction,
  getParticipantsAction,
  successGetParticipantsAction,
  getDocumentTypeAction,
  successGetDocumentTypeAction,
  successGetDocumentTypeFormFillAction,
  getDocumentTypeFormFillAction,
  successGetGenericGroupAction,
  getGenericGroupAction,
  successGetUserTypeAction,
  getUserTypeAction,
  postParticipantsAction,
  successPostParticipantsAction,
  postConditionAction,
  successPostConditionAction,
  getConditionAction,
  successGetConditionAction,
  successGetStatusTemplateAction,
  getStatusTemplateAction,
  postStatusTemplateAction,
  putStatusTemplateAction,
  patchStatusTemplateAction,
  getStatusTemplateWithNodesAction,
  successGetStatusTemplateWithNodesAction,
  putParticipantsAction,
} from "../redux/slices/WorkFlowSlices";
import { call, all, put, takeLatest, debounce } from "redux-saga/effects";
import * as Api from "../services/Api";
import { alertAction, loaderAction } from "../redux/slices/GlobalSlices";

//saga function to call template api
function* fetchTemplate(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getTemplates, payloadData);
    yield put(successTemplateFlow(response));
  } catch (e) {
    console.log("catch0101", JSON.parse(e?.response.data));
    const errorData = JSON.parse(e?.response.data);
    console.log("typecheck", errorData.customError.errorMessage);
    yield put(
      alertAction({
        open: true,
        severity: "error",
        message: errorData.customError.errorMessage,
      })
    );
  }
}

//saga function to call status transition api
function* getStatusTransition(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getStatusTransitions, payloadData);
    yield put(successTransitionAction(response));
    // yield put(getParticipantsAction(payloadData));
    yield put(getDocumentTypeAction(payloadData));

    // getDocumentTypeAction
  } catch (e) {
    console.log("catch0001", e.response.data);
    const message = JSON.parse(e.response.data);
    console.log("message", message);
    yield put(
      alertAction({ open: true, severity: "error", message: message.error })
    );
  }
}
let counnt = 0;

function* postStatusTransition(action) {
  try {
    let payloadData = action.payload;
    const { id, data } = payloadData;
    console.log("payloadData000011", payloadData);
    console.log("data000001", data);
    const response = yield call(Api.postStatusTransitions, payloadData);
    counnt = counnt + 1;
    console.log("counnt", counnt);
    console.log("resultlENGHT", payloadData.result.length);

    if (payloadData.result.length === counnt) {
      console.log("RefreshHIt", payloadData.result.length, counnt);
      yield put(getStatusTemplateWithNodesAction(id));
      yield put(getStatusTransitionAction(id));
      yield put(loaderAction(false));
      yield put(
        alertAction({
          open: true,
          severity: "success",
          message: "Flow is successfully created",
        })
      );
      counnt = 0;
    } else if (payloadData.postActions.length === counnt) {
      yield put(getStatusTemplateWithNodesAction(id));
      yield put(getStatusTransitionAction(id));
      yield put(loaderAction(false));
      yield put(
        alertAction({
          open: true,
          severity: "success",
          message: "Flow is successfully updated",
        })
      );
      counnt = 0;
    }
    yield put(successPostTransitionAction());
    console.log("response111", response);
  } catch (e) {
    console.log("catchaaa");
    // yield put(successPostTransitionAction());
  }
}

let putCount = 0;
function* putStatusTransition(action) {
  try {
    let payloadData = action.payload;
    console.log("payloadData21123343434", payloadData);
    const response = yield call(Api.putStatusTransitions, payloadData);
    console.log(
      "payloadData.deleteRemaining.length",
      payloadData.deleteRemaining.length
    );
    console.log("payloadData.postData.length", payloadData.postData.length);
    console.log("payloadData.data", payloadData.data);

    if (
      payloadData.deleteRemaining.length === 0 &&
      payloadData.postData.length === 0
    ) {
      putCount = putCount + 1;
      console.log("putCount11", putCount);

      if (payloadData.data.length === putCount) {
        console.log("putCount2278", putCount);

        yield put(getStatusTemplateWithNodesAction(payloadData.id));
        yield put(getStatusTransitionAction(payloadData.id));
        yield put(loaderAction(false));
        yield put(
          alertAction({
            open: true,
            severity: "success",
            message: "Flow is successfully Updated",
          })
        );
        putCount = 0;
      }
    } else if (
      payloadData.deleteRemaining.length === 0 &&
      payloadData.postData.length > 0
    ) {
    } else if (payloadData.deleteRemaining.length > 0) {
      putCount = putCount + 1;
      console.log("putCount145", putCount);

      if (
        payloadData.putActions.length + payloadData.deleteRemaining.length ===
        putCount
      ) {
        console.log("putCount212", putCount);

        yield put(getStatusTemplateWithNodesAction(payloadData.id));
        yield put(getStatusTransitionAction(payloadData.id));
        yield put(loaderAction(false));
        yield put(
          alertAction({
            open: true,
            severity: "success",
            message: "Flow is successfully Updated",
          })
        );
        putCount = 0;
      }
    }
  } catch (e) {
    console.log("catch0001Test01", e.message);
    // yield put(loaderAction(false));
    // yield put(alertAction({ open: true, severity: "error", message: "Error" }));
    // yield put(successPostTransitionAction());
    // Check for specific error type and message
    if (
      !(
        e instanceof TypeError &&
        e.message.includes(
          "Cannot read properties of undefined (reading 'length')"
        )
      )
    ) {
      yield put(
        alertAction({ open: true, severity: "error", message: e.message })
      );
    yield put(loaderAction(false));

    }
  }
}

function* getparticipants(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getparticipants, payloadData);
    yield put(successGetParticipantsAction(response));
  } catch (e) {
    console.log("catch");
    yield put(loaderAction(false));
    yield put(alertAction({ open: true, severity: "error", message: "Error" }));
  }
}

function* getDocumentType(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getDocumentType, payloadData);
    yield put(successGetDocumentTypeAction(response));
    console.log("responseananana", response.documentTypes);
    const filteredDocuments = response?.documentTypes?.filter(
      (doc) => doc.containsSignMetadata === true
    );
    console.log("filteredDocuments", filteredDocuments[0]?.id);
    yield put(getDocumentTypeFormFillAction(filteredDocuments[0]?.id));
  } catch (e) {
    console.log("catch");
  }
}

function* getDocumentTypeFormFill(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getDocumentTypeFormFill, payloadData);
    yield put(successGetDocumentTypeFormFillAction(response));
  } catch (e) {
    console.log("catch");
  }
}

function* getGenericGroup(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getGenericGroup, payloadData);
    yield put(successGetGenericGroupAction(response));
  } catch (e) {
    console.log("catch");
  }
}

function* getUserType(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getUserType, payloadData);
    yield put(successGetUserTypeAction(response));
  } catch (e) {
    console.log("catch");
  }
}

function* postParticipants(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postParticipant, payloadData);
    yield put(successPostParticipantsAction(response));
    yield put(getParticipantsAction(payloadData.id));

    // console.log("response111", response)
  } catch (e) {
    console.log("catch");
  }
}

function* postCondition(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postCondition, payloadData);
    yield put(successPostConditionAction(response));
    // console.log("response111", response)
  } catch (e) {
    console.log("catch");
  }
}

function* getCondition(action) {
  try {
    let payloadData = action.payload;
    console.log("payloadDataCond1212", payloadData);
    const response = yield call(Api.getCondition, payloadData);
    yield put(successGetConditionAction(response));
    // console.log("response111", response)
  } catch (e) {
    console.log("catch");
  }
}

function* getStatusTemplates(action) {
  try {
    let payloadData = action.payload;
    console.log("payloadDataCond1212", payloadData);
    const response = yield call(Api.getStatusTemplates, payloadData);
    yield put(successGetStatusTemplateAction(response));
    // console.log("response111", response)
  } catch (e) {
    console.log("catch");
  }
}

function* postStatusTemplates(action) {
  try {
    let payloadData = action.payload;
    console.log("payloadDataCond1212", payloadData);
    const response = yield call(Api.postStatusTemplates, payloadData);
    // yield put(successGetStatusTemplateAction(response));
    // console.log("response111", response)
    yield put(getStatusTemplateAction(payloadData.templateId));
  } catch (e) {
    console.log("catch");
  }
}

function* putStatusTemplates(action) {
  try {
    let payloadData = action.payload;
    console.log("payloadDataCond1212", payloadData);
    const response = yield call(Api.putStatusTemplates, payloadData);
    yield put(getStatusTemplateAction(payloadData.templateId));
    // yield put(successGetStatusTemplateAction(response));
    // console.log("response111", response)
  } catch (e) {
    console.log("catch");
  }
}

function* putParticipants(action) {
  try {
    let payloadData = action.payload;
    console.log("Participants1212", payloadData);
    const response = yield call(Api.putParticipants, payloadData);
    yield put(getParticipantsAction(payloadData.tempId));

    // yield put(getStatusTemplateAction(payloadData.templateId));
  } catch (e) {
    console.log("catch");
  }
}

function* patchStatusTemplate(action) {
  try {
    let payloadData = action.payload;
    console.log("payloadDataCond1212", payloadData);
    const response = yield call(Api.patchStatusTemplate, payloadData);
    // yield put(successGetStatusTemplateAction(response));
  } catch (e) {
    console.log("catch");
  }
}
function* getStatusTemplateWithNodes(action) {
  try {
    let payloadData = action.payload;
    console.log("payloadDataCond1212", payloadData);
    const response = yield call(Api.getStatusTemplatesWithNodes, payloadData);
    console.log("AnkitTestResponse", response);
    yield put(successGetStatusTemplateWithNodesAction(response));
  } catch (e) {
    console.log("catch");
  }
}

function* WorkFlowSaga() {
  yield all([
    takeLatest(getTemplateFlowAction.type, fetchTemplate),
    debounce(500, getStatusTransitionAction.type, getStatusTransition),
    takeLatest(postStatusTransitionAction.type, postStatusTransition),
    debounce(500, putStatusTransitionAction.type, putStatusTransition),
    takeLatest(getParticipantsAction.type, getparticipants),
    takeLatest(getDocumentTypeAction.type, getDocumentType),
    takeLatest(getDocumentTypeFormFillAction.type, getDocumentTypeFormFill),
    takeLatest(getGenericGroupAction.type, getGenericGroup),
    debounce(500, getUserTypeAction.type, getUserType),
    takeLatest(postParticipantsAction.type, postParticipants),
    takeLatest(postConditionAction.type, postCondition),
    takeLatest(getConditionAction.type, getCondition),
    takeLatest(getStatusTemplateAction.type, getStatusTemplates),
    takeLatest(postStatusTemplateAction.type, postStatusTemplates),
    takeLatest(putStatusTemplateAction.type, putStatusTemplates),
    takeLatest(patchStatusTemplateAction.type, patchStatusTemplate),
    takeLatest(
      getStatusTemplateWithNodesAction.type,
      getStatusTemplateWithNodes
    ),
    takeLatest(putParticipantsAction.type, putParticipants),
  ]);
}

export default WorkFlowSaga;
