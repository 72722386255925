// ApiCallTracker.ts

// Global variable to store the last API call time
let lastApiCallTime: Date | null = null;

// Function to set the last API call time
export const setLastApiCallTime = (time: Date) => {
  lastApiCallTime = time;
};

// Function to get the last API call time
export const getLastApiCallTime = () => lastApiCallTime;
