import React from "react";
import { common } from "./interface";

export const CheckIcon = (props:common) => {
  const { size, color } = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 30 30"
  >
    <g data-name="Group 4169">
      <g data-name="Group 4079">
        <path
          data-name="Path 1726"
          d="m-911.47 986.688-5.9 5.9-3.059-3.059a1.063 1.063 0 0 0-1.489 0 1.062 1.062 0 0 0 0 1.489l3.817 3.817a1.064 1.064 0 0 0 .758.3 1.111 1.111 0 0 0 .758-.3l6.633-6.633a1.062 1.062 0 0 0 0-1.489 1.07 1.07 0 0 0-1.518-.025z"
          transform="translate(930.224 -975.387)"
           style={{ fill: color }}
        />
        <path
          data-name="Path 1727"
          d="M-927.1 953.5h-13.41a5.957 5.957 0 0 0-5.895 6.01v13.672a5.957 5.957 0 0 0 5.895 6.01h13.41a5.957 5.957 0 0 0 5.895-6.01V959.51a5.974 5.974 0 0 0-5.895-6.01zm4.222 19.709a4.3 4.3 0 0 1-4.249 4.332h-13.41a4.3 4.3 0 0 1-4.249-4.332v-13.672a4.3 4.3 0 0 1 4.249-4.332h13.41a4.3 4.3 0 0 1 4.249 4.332z"
          transform="translate(948.401 -951.5)"
           style={{ fill: color }}
        />
      </g>
      <path data-name="Rectangle 875"  style={{ fill: "none" }} d="M0 0h30v30H0z" />
    </g>
  </svg>
  )
}
