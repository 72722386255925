import { combineReducers } from "redux";
import { ManchSlice } from "./slices/ManchSlices";
import { GlobalSlice } from "./slices/GlobalSlices";
import { WorkFlowSlice } from "./slices/WorkFlowSlices";
import entitiesReducer from "./entities";
import uireducers from "./uireducers";
export function createRootReducer() {
    return combineReducers({
      GlobalSlice: GlobalSlice.reducer,
      WorkFlowSlice: WorkFlowSlice.reducer,
      ManchSlice: ManchSlice.reducer,
     entities: entitiesReducer,
    uielements: uireducers,
    });
  }
  