import * as React from "react"
const FilesMobile = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} {...props}>
    <g data-name="Group 740" transform="translate(0 .492)">
      <circle
        cx={45}
        cy={45}
        r={45}
        data-name="Ellipse 18"
        style={{
          fill: "#e8f0fe",
        }}
        transform="translate(0 -.492)"
      />
      <g data-name="Group 3413">
        <path
          d="M0 0h50v50H0z"
          data-name="Rectangle 292"
          style={{
            fill: "none",
          }}
          transform="translate(20 19.508)"
        />
        <path
          d="M190.158 137.788v-24.841h-4.968a.994.994 0 0 1-.994-.993v-4.968h-18.878v30.8zm-3.974-29.4 2.569 2.569h-2.569zm4.968 31.384h-26.828a.993.993 0 0 1-.994-.993v-32.79a.994.994 0 0 1 .994-.994h20.866a.994.994 0 0 1 .7.291l5.962 5.962a.994.994 0 0 1 .291.7v26.828a.994.994 0 0 1-.994.993z"
          data-name="Path 690"
          style={{
            fillRule: "evenodd",
            fill: "#3f5fff",
          }}
          transform="translate(-134.625 -75.893)"
        />
        <path
          d="M227.151 81.441h-3.88a.994.994 0 1 0 0 1.987h4.874a.994.994 0 0 0 .994-.993V56.6a1 1 0 0 0-.291-.7l-8.948-8.945a1 1 0 0 0-.7-.291h-19.87a.994.994 0 0 0-.994.993v3.975a.994.994 0 1 0 1.987 0v-2.981h18.467l8.361 8.36z"
          data-name="Path 691"
          style={{
            fillRule: "evenodd",
            fill: "#3f5fff",
          }}
          transform="translate(-166.65 -22.527)"
        />
        <path
          d="M182 414.4h16.378v2.184H182z"
          data-name="Path 296"
          style={{
            fill: "#3f5fff",
          }}
          transform="translate(-147.315 -359.135)"
        />
        <path
          d="M203.369 110.468v-9.991l4.886 4.2 1.419-1.665-6.688-5.732a1.079 1.079 0 0 0-1.419 0l-6.688 5.732 1.419 1.665 4.886-4.2v9.963h2.184z"
          data-name="Path 297"
          style={{
            fill: "#3f5fff",
          }}
          transform="translate(-159.567 -57.222)"
        />
      </g>
    </g>
  </svg>
)
export default FilesMobile