import * as React from "react"
const VerifiedGreen = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 4517">
      <path
        d="M15 0A15 15 0 1 1 0 15 15 15 0 0 1 15 0z"
        data-name="Path 1944"
        style={{
          fill: "#089a6c",
        }}
      />
      <path
        d="m215.771 168-8.752 8.222-4.97-4.67-1.39 1.305 6.359 5.974 10.142-9.526z"
        data-name="Path 286"
        style={{
          fill: "#fff",
          fillRule: "evenodd",
        }}
        transform="translate(-193.389 -158.303)"
      />
    </g>
  </svg>
)
export default VerifiedGreen
