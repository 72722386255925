import * as API from "../utility/ApiHelper";
import CryptoJS from "crypto-js";

const encryptData = () => {

  const secret = process.env.REACT_APP_SECRET_KEY
  console.log(secret, "secret");
  const encryptedData = CryptoJS.AES.encrypt("ENCRYPTED", secret); // Assuming secret data encryption
  return encryptedData.toString(); // Convert to string
};
export const fetchUserApiResults = (id) => {
  console.log(id,"ciiid")
  return API.get(
    `/app/v2/companies/${id}/templates?size=1000&sort=id%2Cdesc&fillEnabled=true`
  );
};
export const fetchUserDetail = () => {
  return API.get("/app/user/who-am-i");
};
export const fetchTemplate = (companyId,id) => {
  return API.get(
    `/app/companies/${companyId}/ui-customizations?templateId=${id}&section=create_ob&userType=first_party`
  );
};
export const fetchCompanyFeature = (companyId,id) => {
  return API.get(
    `/app/companies/${companyId}/company-product-features?templateId=${id}&parentFeatureName=ESIGN_METHOD`
  );
};
export const postFill = (data) => {
  return API.post(`/app/document/fill`, data);
};
export const fetchAllDocuments = (no) => {
  return API.get(
    `/app/transactions/${no}/documents?partyType=FIRST_PARTY,SECOND_PARTY`
  );
};
export const putFillTxn = (transactionId, dataOfPut) => {
  console.log(transactionId, "transactionId");
  console.log(dataOfPut, "data1");

  return API.put(`/app/document/fill/${transactionId}`, dataOfPut);
};
export const fetchTemplateInfo = (id) => {
  return API.get(`/app/document/fill/${id}`);
};
export const fetchmetaData = (transactionId, documentTypeId) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 if needed
    },
  };
  return API.get(
    `/app/document/fill/${transactionId}/${documentTypeId}/metadata`,
    null,
    apiConfig
  );
};
export const fillmetaData = (
  transactionId,
  documentTypeId,
  dataValue,
  metaDataId,
  valueHash
) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 with encrypted data
    },
  };
  
console.log(CryptoJS.AES.decrypt(dataValue, "dev.manchtech.com").toString(CryptoJS.enc.Utf8),"decrypt data value")
  return API.post(
    `/app/document/fill/${transactionId}/${documentTypeId}/filldata`,
    [{ dataValue, metaDataId, valueHash }],
    apiConfig
  );
};
export const uploadFileData = (transactionId, documentTypeId, dataValue) => {
  const apiConfig = {
    headers: {
      // "X-Auth2": "U2FsdGVkX19nY8HmrSkk0IcXvdqE9MN5RVl4vGCOzOk=",
      "Content-Type": "multipart/form-data", // Set multipart form data header
    },
  };

  return API.post(
    `/app/document/fill/${transactionId}/${documentTypeId}/upload`,
    dataValue, // Send FormData directly
    apiConfig
  );
};
export const documentsUpload = (transactionId, formData) => {
  const apiConfig = {
    headers: {
      // Include auth header if required
      "Content-Type": "multipart/form-data", // Correct header for file upload
    },
  };

  // Send the form data and transactionId
  return API.post(
    `/app/transactions/${transactionId}/documents`,
    formData, // Send form data
    apiConfig
  )
};
export const fetchUploadedFiles = (transactionId, documentId) => {
  return API.get(
    `/app/transactions/${transactionId}/documents/${documentId}/preview`,
    { responseType: "arraybuffer" }
  );
};
export const getStatusTransition = (transactionId) => {
  return API.get(
    `/app/transactions/${transactionId}/status-transitions?future=true`
  );
};
export const patchTransitStatus = (transactionId) => {
  return API.get(`/app/transactions/${transactionId}/transit-status`);
};

export const trasitStatus = (transactionId,currentStatus, nextStatus, id) => {
  const data = {
    status: nextStatus,          // 'status' should be the next status
    currentStatus: currentStatus, 
    statusTransitionId: id       // 'statusTransitionId' from the response
  };
  return API.patch(
    `/app/transactions/${transactionId}/transit-status`,
   data
  );
};

export const send = (transactionId,currentStatus ) => {
const data={
  currentStatus:currentStatus
}

  // Send the form data and transactionId
  return API.post(
    `/app/document/fill/${transactionId}/send`,
    data
   
  );
};
export const deleteDoc = (transactionId,docTypeId) => {
  return API.deleteResource(`/app/transactions/${transactionId}/documents?doc_type_id=${docTypeId}`);
};
export const fetchUploadedFilesThumbnail = (transactionId, documentId) => {
  return API.get(
    `/app/transactions/${transactionId}/documents/${documentId}/thumbnail`,
    { responseType: "arraybuffer" }
  );
};

export const getDropDown = (tld, dtld, ddType, fType, pv, criteria, ce, da, dl) => {
  const params = new URLSearchParams({
    tld,
    dtld,
    ddType,
    fType,
    pv,
    criteria,
    ce,
    da,
    dl,
  }).toString();

  return API.get(`/app/document/fill/drop-down-values?${params}`);
};
export const send_otp = ( data,
  trxcId,
  docTypeId,) => {
  return API.post(
    `/app/document/fill/transaction/${trxcId}/docType/${docTypeId}/send-form-otp`,
    data, // Send FormData directly
    
  );
};
export const verify_otp = ( data,
  trxcId,
  docTypeId,) => {
  return API.post(
    `/app/document/fill/transaction/${trxcId}/docType/${docTypeId}/validate-otp`,
    data, // Send FormData directly
    
  );
};
export const getTemplateData = (templateId) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 if needed
    },
  };
  return API.get(
    `/app/v2/templates/${templateId}`,
    null,
    apiConfig
  );
};
export const getTheValuesOfTemplate = (trxnId) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 if needed
    },
  };
  return API.get(
    `/app/hr/getOfferDetails?offerId=${trxnId}`,
    null,
    apiConfig
  );
};
export const getTheMetaDataUploadedImage = (trxnId,formFillId) => {
  return API.get(
    `app/transactions/${trxnId}/form-fill-data/${formFillId}/object-content`,
  
  );
};
export const extendedFormGroupValue = (formFillId) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 if needed
    },
  };
  return API.get(
    `app/v1/form-fill-data/${formFillId}/extended-form-groups?page=0&size=1000`,
    null,
    apiConfig
  );
};
export const postExtendedFormGroupValue = (formFillId) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 if needed
    },
  };
  return API.post(
    `app/v1/form-fill-data/${formFillId}/extended-form-groups`,
    null,
    apiConfig
  );
};
export const deleteExtendedUserGroup = (headerId,rowId) => {
  return API.deleteResource(`/app/v1/form-fill-data/${headerId}/extended-form-groups/${rowId}`);
};
export const putExtended = (headerId, extendedFillDataId, dataOfPut) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 if needed
    },
  };
  return API.put(
    `/app/v1/form-fill-data/${headerId}/extended-form-fill-data/${extendedFillDataId}`,
    dataOfPut,
    apiConfig,
    {
      headers: {
        "Content-Type": "application/json", // Ensure JSON format
        Accept: "application/json",
      },
    }
  );
};
export const uploadFileDataExtend = (headerId, extendedFillDataId, dataValue) => {
  const apiConfig = {
    headers: {
      // "X-Auth2": "U2FsdGVkX19nY8HmrSkk0IcXvdqE9MN5RVl4vGCOzOk=",
      "Content-Type": "multipart/form-data", // Set multipart form data header
    },
  };

  return API.post(
    `/app/v1/form-fill-data/${headerId}/extended-form-fill-data/${extendedFillDataId}/upload`,
    dataValue, // Send FormData directly
    apiConfig
  );
};