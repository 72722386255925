import React from "react";
import { common } from "./interface";

export const RadioIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4166">
        <g data-name="Group 4086" transform="translate(2.554 2.554)">
          <path
            data-name="Path 1739"
            d="M21.247 8.8a12.447 12.447 0 1 0 12.446 12.447A12.468 12.468 0 0 0 21.247 8.8zm0 23.685a11.238 11.238 0 1 1 11.238-11.238 11.256 11.256 0 0 1-11.238 11.238z"
            transform="translate(-8.8 -8.8)"
            style={{ fill: color }}
          />
          <circle
            data-name="Ellipse 102"
            cx="6.799"
            cy="6.799"
            r="6.799"
            transform="translate(5.648 5.648)"
            style={{ fill: color }}
          />
        </g>
        <path
          data-name="Rectangle 879"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
