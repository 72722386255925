import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const Loading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="background.default"
    >
      <CircularProgress color="primary" />
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 600,
          color: " #318be3",
          margin: "1rem 0 0 0",
        }}
      >
        Loading…
      </Typography>
    </Box>
  );
};

export default Loading;
