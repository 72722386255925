import React from "react";
import { common } from "./interface";

export const NotesIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30.157"
    >
      <g data-name="Group 4130">
        <path
          data-name="Rectangle 877"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
        <path
          data-name="Path 1783"
          d="M48.9 28.1a1.088 1.088 0 0 0-.019-.191 1.085 1.085 0 0 0-.054-.179c-.007-.018-.015-.034-.023-.051a1.083 1.083 0 0 0-.068-.127l-.029-.047a1.082 1.082 0 0 0-.124-.151l-3.79-3.79L41 19.772a1.087 1.087 0 0 0-.147-.121l-.049-.031a1.085 1.085 0 0 0-.124-.066l-.053-.024a1.091 1.091 0 0 0-.173-.053h-.014a1.091 1.091 0 0 0-.186-.018H26.983a1.093 1.093 0 0 0-1.093 1.093v26.964a1.093 1.093 0 0 0 1.093 1.093h20.826a1.093 1.093 0 0 0 1.091-1.093V28.1zm-7.577-4.92 3.849 3.849h-3.85zM28.075 46.424V21.637h11.062v6.487a1.093 1.093 0 0 0 1.093 1.093h6.487v17.207H28.075z"
          transform="translate(-22.892 -18.452)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  );
};
