import * as React from "react"
const ImgUpload = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 3730">
      <path
        d="M0 0h30v30H0z"
        data-name="Rectangle 661"
        style={{
          fill: "none",
        }}
      />
      <g data-name="Group 3530">
        <path
          d="M5.182 6.835v24.352h27.595V6.835zm12.1 17.084-5.9-5.529-.074-.069-4.526 3.63V8.435h24.393v14.129L24.3 15.481l-.092-.093zm13.9.937v4.731H6.783V24l4.448-3.565 6.228 5.825 6.859-8.46z"
          data-name="Path 894"
          style={{
            fill: "#6a6a6a",
          }}
          transform="translate(-3.49 -3.882)"
        />
        <path
          d="M17.359 11.165A3.718 3.718 0 1 0 20 17.513a3.649 3.649 0 0 0 1.1-2.626 3.729 3.729 0 0 0-3.738-3.723zm.326 1.636a2.075 2.075 0 0 1 1.183.6 2.137 2.137 0 0 1 .627 1.508 2.134 2.134 0 0 1-2.155 2.113 2.137 2.137 0 0 1-1.509-.631 2.143 2.143 0 0 1 .018-3.01 2.159 2.159 0 0 1 1.514-.613l.224-.006v.027z"
          data-name="Path 895"
          style={{
            fill: "#6a6a6a",
          }}
          transform="translate(-4.072 -4.207)"
        />
      </g>
    </g>
  </svg>
)
export default ImgUpload

