import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Submit from "../../../../assets/icons/FormFillIcons/Submit";
import Form from "../../../../assets/icons/FormFillIcons/Form";
import ActiveForm from "../../../../assets/icons/FormFillIcons/ActiveForm";
import Flag from "../../../../assets/icons/FormFillIcons/Flag";
import { Box, Typography } from "@mui/material";
import "./HorizontalStepper.scss";

// Custom Connector Styles
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[400],
    borderRadius: 1,
  },
}));

// Custom Step Icon Styles
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "transparent",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "transparent",
    color: "#fff",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  }),
}));

// Custom Step Icon Component
const CustomStepIcon = ({
  active,
  completed,
  icon,
  firstDocumentAvailable,
}) => {
  const icons = firstDocumentAvailable
    ? {
        1: <ActiveForm />,
        2: <Submit />,
        3: <Flag />,
      }
    : {
        1: <Form />,
        2: <Submit />,
        3: <Flag />,
      };

  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {icons[icon]}
    </CustomStepIconRoot>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired,
  firstDocumentAvailable: PropTypes.bool.isRequired,
};

const HorizontalStepper = () => {
  // Fetching data from Redux
  const documents = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents || []
  );

  const firstDocumentName = documents?.[0]?.name || "Loading...";
  const firstDocumentAvailable = firstDocumentName !== "Loading...";
  const steps = [
    { id: 1, label: firstDocumentName },
    { id: 2, label: "Document List" },
    { id: 3, label: "Submission" },
  ];

  // Adjust steps if there's only one document
  const adjustedSteps =
    documents.length === 1
      ? [
          { id: 1, label: firstDocumentName },
          { id: 2, label: "Submission" },
        ]
      : steps;
  console.log(documents, "documents");
  const marginStyle = {
    marginLeft: documents?.some((doc) => doc.formFillEnabled === false)
      ? "-57px"
      : "-150px",
  };
  const activeStep = 0;
  return (
    <Box className="horizontalStepperClass">
      <Box className="oneMoreClass" style={marginStyle}>
        <Stack spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={0} // Set active step dynamically
            connector={<CustomConnector />}
          >
            {adjustedSteps.map((step) => (
              <Step key={step.id}>
                <StepLabel
                  style={{
                    color: step === activeStep ? "#393e46" : "#898d90",
                    fontWeight: step === activeStep ? "bold" : "normal",
                 
                  }}
                  StepIconComponent={(props) => (
                    <CustomStepIcon
                      {...props}
                      icon={step.id}
                      firstDocumentAvailable={firstDocumentAvailable}
                    />
                  )}
                >
            {step.label}  
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Box>
    </Box>
  );
};

export default HorizontalStepper;
