import * as React from "react"
const Back = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} {...props}>
    <g data-name="Group 3422">
      <g data-name="Group 3421">
        <path
          d="M0 0h34v34H0z"
          data-name="Rectangle 304"
          style={{
            fill: "none",
          }}
        />
      </g>
      <path
        d="m132.382 104.533-1.495-1.495-7.763 7.763h-.131v.131l-.918.918.918.918v.131h.131l7.763 7.763 1.495-1.495-6.268-6.268h16.024v-2.1h-16.024z"
        data-name="Path 837"
        style={{
          fill: "#4f4f4f",
        }}
        transform="translate(-115.075 -95.038)"
      />
    </g>
  </svg>
)
export default Back
