import React from "react";
import { common } from "./interface";

export const NumberIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30.557"
    >
      <g data-name="Group 4170">
        <path
          data-name="Rectangle 890"
          style={{ fill: "none", opacity: ".3" }}
          d="M0 0h30v30H0z"
        />
        <g data-name="Group 4123">
          <path
            data-name="Path 1764"
            d="M14.759 4.11a.985.985 0 0 0-.985-.985H9.36a.985.985 0 0 0-.985.985v4.414a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.97.99v2.439h-2.443V5.1z"
            style={{ fill: color, fillRule: "evenodd" }}
            transform="translate(-6.375 -2.125)"
          />
          <path
            data-name="Path 1765"
            d="M14.759 28.614a.985.985 0 0 0-.985-.985H9.36a.985.985 0 0 0-.985.985v4.413a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.971.985v2.442h-2.442V29.6z"
            transform="translate(-6.375 -18.903)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1766"
            d="M14.759 53.11a.985.985 0 0 0-.985-.985H9.36a.985.985 0 0 0-.985.985v4.414a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.971.985v2.443h-2.442V54.1z"
            transform="translate(-6.375 -35.676)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1767"
            d="M77.759 4.11a.985.985 0 0 0-.985-.985H72.36a.985.985 0 0 0-.985.985v4.414a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.97.99v2.439h-2.444V5.1z"
            transform="translate(-49.512 -2.125)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1768"
            d="M77.759 28.614a.985.985 0 0 0-.985-.985H72.36a.985.985 0 0 0-.985.985v4.413a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.971.985v2.442h-2.443V29.6z"
            transform="translate(-49.512 -18.903)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1769"
            d="M77.759 53.11a.985.985 0 0 0-.985-.985H72.36a.985.985 0 0 0-.985.985v4.414a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.971.985v2.443h-2.443V54.1z"
            transform="translate(-49.512 -35.676)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1770"
            d="M46.259 4.11a.985.985 0 0 0-.985-.985H40.86a.985.985 0 0 0-.985.985v4.414a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.97.99v2.439h-2.443V5.1z"
            transform="translate(-27.944 -2.125)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1771"
            d="M46.259 28.614a.985.985 0 0 0-.985-.985H40.86a.985.985 0 0 0-.985.985v4.413a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.971.985v2.442h-2.442V29.6z"
            transform="translate(-27.944 -18.903)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1772"
            d="M46.259 53.11a.985.985 0 0 0-.985-.985H40.86a.985.985 0 0 0-.985.985v4.414a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.971.985v2.443h-2.442V54.1z"
            transform="translate(-27.944 -35.676)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
          <path
            data-name="Path 1773"
            d="M46.259 77.61a.985.985 0 0 0-.985-.985H40.86a.985.985 0 0 0-.985.985v4.414a.985.985 0 0 0 .985.985h4.414a.985.985 0 0 0 .985-.985zm-1.971.985v2.443h-2.442V78.6z"
            transform="translate(-27.944 -52.452)"
            style={{ fill: color, fillRule: "evenodd" }}
          />
        </g>
      </g>
    </svg>
  );
};
