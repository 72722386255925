import axios from "axios";
import RequestConfig from "../config/RequestConfig";

// const X_AUTH = process.env.REACT_APP_AUTH;
const X_AUTH = window.sessionStorage.getItem("token");
// Set default Axios headers
axios.defaults.headers.common["X-Authorization"] = X_AUTH;

// You can also set the base URL globally if desired
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const mainPath = window.location.pathname;
let domainUrl;

// if (mainPath?.includes("onboardingRequest")) {
//   domainUrl = process.env.REACT_APP_SERVER_URL;
// } else {
//   const domainUrl = process.env.REACT_APP_MAIN_URL;
//   axios.defaults.baseURL = domainUrl;
// }

domainUrl = process.env.REACT_APP_MAIN_URL;
axios.defaults.baseURL = domainUrl;

axios.defaults.baseURL = domainUrl;

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["X-Authorization"] = token;
    }
    return config;
  },
  (error) => {
    // Handle request error
    console.error("Request Interceptor - Error:", error);
    return Promise.reject(error);
  }
);

async function getRequestConfig(apiConfig) {
  let config = Object.assign({}, RequestConfig);
  if (apiConfig) {
    config = Object.assign({}, RequestConfig, apiConfig);
  }
  return config;
}

export const get = async (url, params, apiConfig) => {
  const config = await getRequestConfig(apiConfig);

  config.params = params;

  const request = await axios.get(url, config);

  const requestParsed = JSON.parse(request.data);
  return requestParsed;
};

export const post = async (url, data, apiConfig) => {
  const config = await getRequestConfig(apiConfig);
  let postData = {};
  if (
    apiConfig &&
    apiConfig.headers &&
    apiConfig.headers["Content-Type"] &&
    apiConfig.headers["Content-Type"] !== "application/json"
  ) {
    postData = data;
    axios.defaults.headers.post["Content-Type"] =
      apiConfig.headers["Content-Type"];
  } else {
    postData = JSON.stringify(data);
    axios.defaults.headers.post["Content-Type"] = "application/json";
  }

  try {
    const response = await axios.post(url, postData, config);

    // Automatically parse the JSON response if necessary
    if (typeof response.data === "string") {
      try {
        response.data = JSON.parse(response.data);
      } catch (e) {
        console.warn("Response data is not valid JSON:", response.data);
      }
    }

    return response;
  } catch (error) {
    console.error("Post request failed:", error);
    throw error;
  }
};

export const put = async (url, data, config) => {
  try {
    const response = await axios.put(url, data, config);
    return response.data; // Automatically parse and return the response data
  } catch (error) {
    console.error("Error in PUT request:", error);
    throw error; // Optionally re-throw the error to handle it in the calling function
  }
};

export const patch = async (url, data) => {
  const config = await getRequestConfig();
  config.headers["Content-Type"] = "application/json";
  const request = axios.patch(url, JSON.stringify(data), config);
  return request;
};

export const deleteResource = async (url) => {
  const config = await getRequestConfig();
  const request = axios.delete(url, config);
  return request;
};
