import React, { useEffect, useState, lazy, Suspense } from "react";
import BackdropCircularProgressComponent from "./components/BackdropCircularProgressComponent";
import ModalStrip from "./components/ModalStrip";
import { useNavigate } from "react-router-dom";
import Templates from "./pages/FormFillPages/Templates/Templates";
import Transaction from "./pages/FormFillPages/Transaction/Transaction";
import FormLayout from "./pages/FormFillPages/FormLayout/FormLayout";
import UploadDocuments from "./pages/FormFillPages/UploadDocuments/UploadDocuments";
import FilledTransaction from "./pages/FormFillPages/FilledTransaction/FilledTransaction";
import { Routes, Route, useLocation } from "react-router-dom";
import { AxiosGet } from "./api";
import { getLastApiCallTime } from "./api/components/ApiCallTime";
import { DialogBox } from "./components/Modal";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyDetails } from "./common";
import { alertAction } from "./redux/slices/GlobalSlices";
import NotFoundPage from "./pages/Error404";
import Loading from "./pages/Loading";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { defaultPath } from "./components/FormBuilder/hooks/Constants";
import InactivityTracker from "./InactivityTracker";

const FormBuilderPage = lazy(
  () => import("./pages/FormBuilder/FormBuilderPage")
);
const WorkFlowBuilder = lazy(
  () => import("./pages/WorkFlowBuilder/WorkFlowBuilder")
);
const MainLayout = lazy(() => import("./MainLayout"));
const TransactionList = lazy(() => import("./pages/Transcation"));
const ProcessNavigator = lazy(() => import("./pages/ProcessNavigator"));

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const alert = useSelector((e: any) => e.GlobalSlice.alert);
  const [timeSinceLastCall, setTimeSinceLastCall] = useState<number | null>(
    null
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isScreenInactive, setIsScreenInactive] = useState<boolean>(false);

  const myParam = useLocation().search;
  const token = new URLSearchParams(myParam).get("token");
  const mainUrl = new URLSearchParams(myParam).get("redirectUrl");
  const navigateToDomain = () => {
    const domainUrl: any = process.env.REACT_APP_MAIN_URL;
    window.location.href = domainUrl;
  };

  const mainPath = window.location.pathname;
  let pathFormBuilder = defaultPath,
    pathWorkFlow = defaultPath,
    onBoardingPathMain = defaultPath,
    processPath = defaultPath,
    path = defaultPath;

  // extract all the info from URL

  // Form-Builder
  if (mainPath?.includes("form-builder")) {
    const pathLoc = window.location.pathname.slice(10);

    const templateLast = pathLoc.indexOf(defaultPath);
    const templateId = pathLoc.slice(0, templateLast);

    const documentFirst = pathLoc.indexOf("d");
    const doc = pathLoc.slice(documentFirst + 9);
    const docLast = doc.indexOf(defaultPath);
    const documentId = doc.slice(0, docLast);

    window.sessionStorage.setItem("templateId", templateId);
    window.sessionStorage.setItem("docId", documentId);

    pathFormBuilder = `template/${templateId}/document/${documentId}/form-builder`;
    path = pathFormBuilder;
    // Work flow builder
  } else if (mainPath?.includes("workflowbuilder")) {
    const pathLoc = window.location.pathname.slice(10);
    const templateLast = pathLoc.indexOf(defaultPath);
    const workFlowTemplateId = pathLoc.slice(0, templateLast);
    window.sessionStorage.setItem("workFlowTemplateId", workFlowTemplateId);
    pathWorkFlow = `template/${workFlowTemplateId}/workflowbuilder`;
    path = pathWorkFlow;
    // On boarding Request
  } else if (mainPath?.includes("onboardingRequest")) {
    if (mainPath?.includes("edit")) {
      const pathLoc = window.location.pathname.slice(28);
      const templateLast = pathLoc.indexOf(defaultPath);
      const onBoardTemplateId = pathLoc.slice(0, templateLast);

      const transFirst = pathLoc.indexOf("t");
      const doc = pathLoc.slice(transFirst + 12);
      const docLast = doc.indexOf(defaultPath);
      const onBoardTransactionId = doc.slice(0, docLast);

      path = `onboardingRequest/template/${onBoardTemplateId}/transaction/${onBoardTransactionId}/edit`;
    }
     else {
      onBoardingPathMain = `/onboardingRequest`;
      path = onBoardingPathMain;
    }
  }
  else if(mainPath?.includes("processCreator")){
    processPath = `/processCreator`;
    path = processPath;
  }
  // End Extract

  useEffect(() => {
    if (token !== null) {
      window.sessionStorage.setItem("token", token);
    }
  }, [token]);

  useEffect(() => {
    if (mainUrl !== null) {
      window.sessionStorage.setItem("mainUrl", mainUrl);
    }
  }, [mainUrl]);

  useEffect(() => {
    const controller = new AbortController();
    const pathChangeFun = async () => {
      await getCompanyDetails();
      navigate(path);
    };

    if (path !== defaultPath) {
      if (!path?.includes("onboardingRequest")) {
        pathChangeFun();
      }
    }
    return () => controller.abort();
  }, [path]);

  // Effect to calculate time since the last API call
  useEffect(() => {
    const interval = setInterval(() => {
      const lastApiCallTime = getLastApiCallTime();
      if (lastApiCallTime) {
        const now = new Date();
        const secondsElapsed = Math.floor(
          (now.getTime() - lastApiCallTime.getTime()) / 1000
        );
        setTimeSinceLastCall(secondsElapsed);
      } else {
        // No API call made yet
        setTimeSinceLastCall(null);
      }
    }, 300000); // it runs for every 5 mins.

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [getLastApiCallTime()?.toLocaleString()]);

  // check if tokken expires or not
  useEffect(() => {
    // check after 10 mins (600 secs) of last api call
    if (timeSinceLastCall && timeSinceLastCall > 600) {
      //const endPoint = "/user/check-session/first-party?extendSession=true";
      const endPoint = "/user/who-am-i";
      const durationOfLastApi = async () => {
        // Is there any screen activity ::
        if (!isScreenInactive) {
          await AxiosGet({ endPoint }); // If there any screen activity we re-new the api tokken
        } else {
          // just check for tokken expiry
          const toCheck = true;
          try {
            await AxiosGet({ endPoint, toCheck });
            setModalOpen(false);
          } catch (err) {
            setModalOpen(true);
          }
        }
      };

      durationOfLastApi();
    }
  }, [timeSinceLastCall]);

  const handleClose = (
    _?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(alertAction({ open: false, severity: "" }));
  };

  return (
    <>
      <InactivityTracker setIsScreenInactive={setIsScreenInactive} />
      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ marginTop: "60px" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          variant="filled"
          sx={{
            width: "900px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& .MuiAlert-icon": {
              fontSize: "24px",
              alignSelf: "center",
            },
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <BackdropCircularProgressComponent />
      <ModalStrip />
      <DialogBox
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        deleteFun={navigateToDomain}
        type="alert"
      />
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <TransactionList /> */}
          <Route path="/" element={<NotFoundPage />}>
            <Route index element={<div>.....</div>} />
          </Route>
          {/* OnBoarding */}
          <Route path={onBoardingPathMain} element={<MainLayout />}>
            <Route index element={<Templates />} />
            <Route
              path="template/:templateId/transaction"
              element={<Transaction />}
            />
            <Route
              path="template/:templateId/transaction/:transactionId"
              element={<FormLayout />}
            />
            <Route
              path="template/:templateId/transaction/:transactionId/uploadDocuments"
              element={<UploadDocuments />}
            />
            <Route
              path="onboardingRequest/template/:templateId/transaction/:transactionId/edit"
              element={<FilledTransaction />}
            />
          </Route>
          {/* Form Builder */}
          <Route path={pathFormBuilder} element={<FormBuilderPage />} />
          {/* Work Flow */}
          <Route
            path={pathWorkFlow}
            element={
              <DndProvider backend={HTML5Backend}>
                <WorkFlowBuilder />
              </DndProvider>
            }
          />
          {/* Transaction */}
          <Route path={"/transaction"} element={<TransactionList />} />
          {/* Process Navigator */}
          <Route path={processPath} element={<ProcessNavigator />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
