import * as React from "react"
const Preview = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <path
      d="M0 0h30v30H0z"
      data-name="Rectangle 511"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M252.11 177.89a4.745 4.745 0 0 0-2.986 1.011 3.024 3.024 0 0 0 0 4.879 4.917 4.917 0 0 0 5.972 0 3.021 3.021 0 0 0 0-4.877 4.753 4.753 0 0 0-2.986-1.013zm0 6.013a3.526 3.526 0 0 1-2.219-.751 2.247 2.247 0 0 1 0-3.625 3.654 3.654 0 0 1 4.437 0 2.245 2.245 0 0 1 0 3.624 3.529 3.529 0 0 1-2.217.752z"
      data-name="Path 800"
      style={{
        fill: "#4f4f4f",
        stroke: "#4f4f4f",
        strokeWidth: ".8px",
      }}
      transform="translate(-236.648 -166.007)"
    />
    <path
      d="M126.341 117.2a.259.259 0 0 0 0-.051v-.023a.155.155 0 0 1 0-.042c-.94-3.511-4.664-6.054-8.905-6.054s-7.963 2.543-8.88 6.051a.158.158 0 0 1 0 .042v.023a.256.256 0 0 0 0 .051v.09a.258.258 0 0 0 0 .052v.023a.159.159 0 0 1 0 .042c.917 3.514 4.641 6.057 8.88 6.057s7.965-2.543 8.88-6.051a.156.156 0 0 1 0-.042v-.026a.256.256 0 0 0 0-.051v-.045c0-.016.026-.029.026-.045zm-8.905 5.294c-3.775 0-7.081-2.2-7.921-5.249.84-3.048 4.145-5.249 7.921-5.249s7.081 2.2 7.921 5.249c-.841 3.049-4.146 5.255-7.921 5.255z"
      data-name="Path 801"
      style={{
        fill: "#4f4f4f",
        stroke: "#4f4f4f",
        strokeWidth: ".8px",
      }}
      transform="translate(-101.975 -101.914)"
    />
  </svg>
)
export default Preview