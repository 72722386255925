import React,{useState,useEffect} from "react";
import { Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import "./TemplateName.scss";
import useWindowSize from "../../Global/Inner/useWindowSize";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";


const TemplateName = ({
  activeSection
}) => {
  const [width] = useWindowSize();
  const isMobile = width <= 500;
  const [panelData, setPanelData] = useState([]);
    const metaData = useSelector(
      (state) => state?.ManchSlice?.metaDataInfo?.metaDataInfoValue
    );
  const selectedTemplate = useSelector(
    (state) => state?.ManchSlice?.selectedTemplateName?.Name
  );
  const info = useSelector(
    (state) => state?.ManchSlice?.templateInfo?.templateInfoValue
  );

   useEffect(() => {
      const Data = metaData.filter((item) => item.type === "PANEL");
      setPanelData(Data);
    }, [metaData]);
   // Get the last segment of the URL path
   const lastSegment = window.location.pathname.split("/").pop();

   // If the last segment is "uploadDocuments", return null
   if (lastSegment === "uploadDocuments" && isMobile) {
     return null;
   }
  return (
    <>
    {isMobile ? (
  <Box
  sx={{
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    // Hide scrollbar for IE, Edge, and Firefox
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // Hide scrollbar for Chrome, Safari, and Opera
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }}
  className="StepperForSection"
>
  <Stepper
    activeStep={activeSection}
    alternativeLabel
    sx={{ minWidth: "max(100%, 300px)" }}
  >
    {panelData?.map((element, index) => (
      <Step key={index} sx={{ flexShrink: 0, minWidth: "33.33%" }}>
        <StepLabel sx={{
            //maxWidth: "100px", // Adjust the max width as needed
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            px: 1, // padding on left and right
          }}>{element.tagName}</StepLabel>
      </Step>
    ))}
  </Stepper>
</Box>
    ) : (
    <Box className="templateName">

      <Box className="menuItem">
        <Box>
          <Typography variant="formHeader" sx={{textTransform:"capitalize"}}> {selectedTemplate}  </Typography>
        </Box>
        <Box>
          <Button variant="templateWhite" sx={{ mr: "20px" }}>
            {" "}
            Preview
          </Button>
          <Button variant="templateBlue"> Close</Button>
        </Box>
      </Box>
      <Box className="infoSection">

       <Box>
        <Box sx={{pb:"10px"}}>
        <Typography variant="templateTitle" > Title</Typography>
        </Box>
        <Box>
        <Typography variant="templateInfo"> {info?.title}</Typography>
        </Box>
       </Box>
       <Box>
        <Box sx={{pb:"10px"}}>
        <Typography variant="templateTitle" > Message</Typography>
        </Box>
        <Box>
        <Typography variant="templateInfo"> {info?.message}</Typography>
        </Box>
       </Box>
       <Box>
        <Box sx={{pb:"10px"}}>
        <Typography variant="templateTitle" > Name</Typography>
        </Box>
        <Box>
        <Typography variant="templateInfo"> {info?.secondParty?.name}</Typography>
        </Box>
       </Box>
       <Box>
        <Box sx={{pb:"10px"}}>
        <Typography variant="templateTitle" > Mobile No.</Typography>
        </Box>
        <Box>
        <Typography variant="templateInfo"> {info?.secondParty?.phone}</Typography>
        </Box>
       </Box>
       <Box>
        <Box sx={{pb:"10px"}}>
        <Typography variant="templateTitle" > Email</Typography>
        </Box>
        <Box>
        <Typography variant="templateInfo"> {info?.secondParty?.email}</Typography>
        </Box>
       </Box>
       <Box>
        <Box sx={{pb:"10px"}}>
        <Typography variant="templateTitle" > Organisation</Typography>
        </Box>
        <Box>
        <Typography variant="templateInfo">{info?.secondParty?.organization}</Typography>
        </Box>
       </Box>
       <Box>
        <Box sx={{pb:"10px"}}>
        <Typography variant="templateTitle" > Auth mode type</Typography>
        </Box>
        <Box>
        <Typography variant="templateInfo">{info?.secondParty?.authModeType ?? "Not Available"}</Typography>
        </Box>
       </Box>
      </Box>
    </Box>
    )}
    </>
  );
};

export default TemplateName;

