import React from "react";
import { common } from "./interface";

export const MultiDropdown = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      data-name="Group 4130"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30.001 30"
    >
      <path
        data-name="Rectangle 877"
        transform="translate(.001)"
        style={{ fill: "none" }}
        d="M0 0h30v30H0z"
      />
      <g data-name="Group 4118">
        <path
          data-name="Path 1755"
          d="M28.126 0H.907A.907.907 0 0 0 0 .907v7.259a.907.907 0 0 0 .907.907h27.219a.944.944 0 0 0 .907-.907V.907A.944.944 0 0 0 28.126 0zM.907 8.166V.907H19.96v7.259zm27.219-.016-.018.018h-7.24V.907h7.24l.018.018z"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1756"
          d="M26.517 18H12.454a.454.454 0 0 0 0 .907h14.063a.454.454 0 1 0 0-.907z"
          transform="translate(-9.277 -13.917)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1757"
          d="m101.847 16.218-1.495 1.492-1.495-1.492a.454.454 0 0 0-.64.64l1.815 1.815a.454.454 0 0 0 .64 0l1.815-1.815a.454.454 0 0 0-.64-.64z"
          transform="translate(-75.855 -12.455)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1758"
          d="M28.126 44H.907a.907.907 0 0 0-.907.907v7.258a.907.907 0 0 0 .907.907h27.219a.944.944 0 0 0 .907-.907v-7.258a.944.944 0 0 0-.907-.907zM.907 52.166v-7.259H19.96v7.258zm27.219-.016-.018.018h-7.24v-7.261h7.24l.018.018z"
          transform="translate(0 -34.02)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1759"
          d="M26.517 62H12.454a.454.454 0 1 0 0 .907h14.063a.454.454 0 0 0 0-.907z"
          transform="translate(-9.277 -47.937)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1760"
          d="m101.847 60.218-1.495 1.492-1.495-1.492a.454.454 0 0 0-.64.64l1.815 1.815a.454.454 0 0 0 .64 0l1.815-1.815a.454.454 0 0 0-.64-.64z"
          transform="translate(-75.855 -46.475)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1761"
          d="M28.126 88H.907a.907.907 0 0 0-.907.907v7.258a.907.907 0 0 0 .907.907h27.219a.944.944 0 0 0 .907-.907v-7.258a.944.944 0 0 0-.907-.907zM.907 96.166v-7.259H19.96v7.258zm27.219-.016-.018.018h-7.24v-7.261h7.24l.018.018z"
          transform="translate(0 -68.04)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1762"
          d="M26.517 106H12.454a.454.454 0 1 0 0 .907h14.063a.454.454 0 0 0 0-.907z"
          transform="translate(-9.277 -81.957)"
          style={{ fill: color }}
        />
        <path
          data-name="Path 1763"
          d="m101.847 104.218-1.495 1.492-1.495-1.492a.454.454 0 0 0-.64.64l1.815 1.815a.454.454 0 0 0 .64 0l1.815-1.815a.454.454 0 0 0-.64-.64z"
          transform="translate(-75.855 -80.495)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  );
};
