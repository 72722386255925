import React from "react";
import { common } from "./interface";

export const ButtonIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4167">
        <path
          data-name="Rectangle 877"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
        <g data-name="Group 4124">
          <g data-name="Group 4126">
            <g data-name="Group 4124">
              <path
                data-name="Path 1774"
                d="M28.666 15.6H6.068A3.567 3.567 0 0 0 2.5 19.168v6.51a3.567 3.567 0 0 0 3.568 3.568h14.711l.031-1.346H6.068a2.231 2.231 0 0 1-2.222-2.222v-6.541a2.231 2.231 0 0 1 2.222-2.222h22.6a2.231 2.231 0 0 1 2.222 2.222v6.51a2.231 2.231 0 0 1-2.222 2.222h-.751l1.878 1.158a3.556 3.556 0 0 0 2.41-3.38v-6.51a3.513 3.513 0 0 0-3.539-3.537z"
                transform="translate(-2.5 -11.6)"
                style={{ fill: color }}
              />
            </g>
            <g data-name="Group 4125">
              <path
                data-name="Path 1775"
                d="m72.4 54.089-6.7-4.069a.483.483 0 0 0-.751.407l-.149 7.825a.494.494 0 0 0 .845.376l1.6-1.5 1.878 3.38a.485.485 0 0 0 .689.188l1.972-1.1a.475.475 0 0 0 .188-.657l-1.878-3.38 2.128-.532a.52.52 0 0 0 .178-.938z"
                transform="translate(-45.298 -35.191)"
                style={{ stroke: color, fill: "none" }}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
