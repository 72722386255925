export const FormControlNames = {
  STEPCONTAINER: "step-container",
  INPUTTEXTFIELD: "TEXT",
  LABEL: "LABEL",
  INPUTNUMBERFIELD: "NUMBER",
  INPUTMULTILINE: "TEXTAREA",
  CHECKBOX: "STATIC_CHECK_BOX",
  RADIOGROUP: "EXTERNAL_DROP_DOWN_RADIOBUTTON",
  SELECTDROPDOWN: "EXTERNAL_DROP_DOWN_VALUE",
  MULTIPLESELECTDROPDOWN: "MULTISELECT_EXTERNAL_DROPDOWN",
  DATEFIELD: "DATE",
  TIMEFIELD: "TIME",
  FILEUPLOAD: "FILE",
  IMAGEUPLOAD: "IMAGE",
  TOGGLE: "toggle",
  CHECKLIST: "checklist",
  BUTTON: "EXECUTE_BUTTON",
  ARRAY: "ARRAY",
  OTP: "OTP",
};

export const FormTextDataTypes = {
  TEXT: "text",
  NUMBER: "number",
  PASSWORD: "password",
};

export const FormItemTypes = {
  CONTROL: "control",
  CONTAINER: "PANEL",
  ADVOBJ: "ADV_OBJ",
};

export const FormPublishStatus = {
  DRAFT: "draft",
  PUBLISHED: "published",
};

export const FormContainerList = [
  {
    id: "",
    type: FormItemTypes.CONTAINER,
    displayText: "Workflow Step",
    itemType: FormItemTypes.CONTAINER,
    icon: "fa fa-building",
    name: "Panel",
    //type: "",
    mandatory: false,
    editable: false,
    visible: true,
    formOrder: 0,
    deleted: false,
  },
];
