import * as React from "react"
const MobileAddIcon = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    <g
      data-name="Ellipse 59"
      style={{
        fill: "#fff",
        stroke: "#3f5fff",
      }}
    >
      <circle
        cx={25}
        cy={25}
        r={25}
        stroke="none"
        style={{
          stroke: "none",
        }}
      />
      <circle
        cx={25}
        cy={25}
        r={24.5}
        style={{
          fill: "none",
        }}
      />
    </g>
    <path
      d="M172.69 124.308a1.063 1.063 0 0 1-1.045-1.27l.753-3.764a1.069 1.069 0 0 1 .292-.545l12.789-12.789a3.183 3.183 0 0 1 2.26-.936 3.192 3.192 0 0 1 2.249 5.452L177.2 123.244a1.1 1.1 0 0 1-.543.292l-3.762.753a1.053 1.053 0 0 1-.211.021zm3.762-1.817zm-2.03-2.486-.377 1.883 1.881-.379 12.562-12.56a1.055 1.055 0 0 0 .313-.751 1.072 1.072 0 0 0-1.062-1.068 1.052 1.052 0 0 0-.753.313z"
      data-name="Path 359"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-157.767 -91.14)"
    />
    <path
      d="M420.175 174.383a1.063 1.063 0 0 1-.753-1.815l3.762-3.764-2.258-2.258a1.064 1.064 0 0 1 1.5-1.5l3.009 3.009a1.065 1.065 0 0 1 0 1.5l-4.514 4.516a1.056 1.056 0 0 1-.751.313z"
      data-name="Path 360"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-390.205 -147.235)"
    />
    <path
      d="M182 414.4h21.688v2.317H182z"
      data-name="Path 361"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-168.142 -379.964)"
    />
  </svg>
)
export default MobileAddIcon
