import React, { Dispatch, FC, useEffect, useState, useContext } from "react";
import {
  Button,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
  Grid,
  Autocomplete,
  MenuItem,
  FormControl,
  InputBase,
} from "@mui/material";
import { textboxStyle, subHeading } from "./EditPropertiesComponent";
import {
  dirtObjType,
  FormLayoutComponentChildrenType,
  FormLayoutComponentContainerType,
  RuleDataType,
  selectedDropdownRule,
} from "../../../types/FormTemplateTypes";
import CheckIcon from "@mui/icons-material/Check";
import Expression from "./expression/Expression";
import {
  conditionDropdown,
  conditionValueTypeDropdown,
  criteriaArr,
  attributeStaticArr,
} from "../../../assets/rules";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { FormBuilderContext } from "../FormBuilder";
import { AxiosGet } from "../../../api";
import { ffdIdArr } from "../../../assets/rules/index";

interface EditRulesComponentProps {
  selectedControl:
    | undefined
    | FormLayoutComponentChildrenType
    | FormLayoutComponentContainerType;
  setIsAddRule: Dispatch<boolean>;
  isOtherRule: boolean;
  setIsOtherRule: Dispatch<boolean>;
  isPropertyRule: boolean;
  type: string;
  firstName: string;
  lastName: string;
  setChange: Dispatch<number>;
  expanded: number;
  setExpanded: Dispatch<number>;
  change: number;
  selectedRule: any;
  setSelectedRule: Dispatch<any>;
  ruleFieldDropdown: any;
  setRuleData: Dispatch<any>;
  onRuleSubmit: any;
  particularRule: any;
  setParticularRule: any;
  ruleData: any;
  dirtyRecord: any;
  setDirtyRecord: any;
  selectedItem: any;
  paramData: any;
  setParamData: any;
  documentDropDownData: any;
  formNames: any;
  setFormName: any;
}

const names = [
  "Humaira Sims",
  "Santiago Solis",
  "Dawid Floyd",
  "Mateo Barlow",
  "Samia Navarro",
  "Kaden Fields",
  "Genevieve Watkins",
  "Mariah Hickman",
  "Rocco Richardson",
  "Harris Glenn",
];

const EditRuleComponent: FC<EditRulesComponentProps> = (props) => {
  const {
    selectedControl,
    selectedRule,
    setSelectedRule,
    ruleFieldDropdown,
    isOtherRule,
    setIsOtherRule,
    change,
    setChange,
    selectedItem,
    setRuleData,
    onRuleSubmit,
    paramData,
    setParamData,
    particularRule,
    setParticularRule,
    ruleData,
    documentDropDownData,
    formNames,
    setFormName,
    dirtyRecord,
    setDirtyRecord,
  } = props;

  const value: any = useContext(FormBuilderContext);
  const { ruleDropdownValue, dropdownTypeArr } = value;

  const [isExpression, setIsExpression] = useState<boolean>(false);

  const [fromDropdown, setFromDropdown] = useState(false);
  const [dropDownTypeApiOrField, setDropDownTypeApiOrField] = useState({
    fromApi: false,
    isMultiple: false,
  });
  const [selectedVal, setSelectedVal] = useState<any>([]);

  const [stringToDropdown, setStringToDropdown] = useState<any>({});

  const convertStringToDropDownJSONform = async (val: any) => {
    let updatedValue = JSON.parse(JSON.stringify(val));

    const dropDownFun = async (obj: any) => {
      let updatedVal = JSON.parse(JSON.stringify(obj));
      const arr: any = [];

      let resArray = await AxiosGet({
        endPoint: obj?.remoteEnum,
      });

      for (let i = 0; i < resArray?.data?.length; i++) {
        arr.push({
          // const: resArray?.data[i]?.id,
          const: resArray?.data?.[i],
          title: resArray?.data?.[i],
        });
      }

      updatedVal = {
        oneOf: arr,
        //type: typeof resArray?.data[0]?.id,
        type: typeof resArray?.data?.[0],
        title: obj?.title,
      };
      return updatedVal;
    };

    const recursionFun = async (obj: any) => {
      for (let [key, value] of Object.entries(obj) as any) {
        if (
          typeof value !== null &&
          typeof value === "object" &&
          Object.keys(value)?.length
        ) {
          if (value?.remoteEnum && Object.keys(value?.remoteEnum)?.length) {
            let updatedValue = JSON.parse(JSON.stringify(value)),
              startIndex,
              endIndex,
              type: any;

            for (let i = 0; i < updatedValue?.remoteEnum?.length; i++) {
              if (updatedValue?.remoteEnum[i] === "{") {
                startIndex = i;
                i++;
              }
              if (updatedValue?.remoteEnum[i] === "}") {
                endIndex = i;
                i++;
              }

              if (startIndex && endIndex) {
                type = updatedValue?.remoteEnum?.slice(
                  startIndex + 2,
                  endIndex
                );

                const res = ffdIdArr?.find((e: any) => e?.comingKey === type);

                if (res) {
                  updatedValue.remoteEnum =
                    updatedValue.remoteEnum.slice(0, startIndex) +
                    window.sessionStorage.getItem(res?.toBeReplacedKey) +
                    updatedValue.remoteEnum.slice(
                      endIndex + 2,
                      updatedValue?.remoteEnum?.length
                    );
                }

                startIndex = undefined;
                endIndex = undefined;
                type = undefined;
              }
            }

            obj[key] = await dropDownFun(updatedValue);
          }
          await recursionFun(value);
        }
      }
      return obj;
    };

    const len = val?.params ? Object.keys(val?.params)?.length : 0;

    if (len) {
      const params = await recursionFun(val?.params);

      if (!params?.jsonSchema?.properties?.value?.oneOf?.length) {
        updatedValue.params = undefined;
      } else {
        updatedValue.params = params;
      }
    }

    setSelectedRule(updatedValue);
    setStringToDropdown(updatedValue);
  };

  const setState = async (val: any) => {
    let isMultiple = false;

    if (
      val?.sourceFields?.fields?.[0]?.fieldType === "DOCUMENT_TYPE" ||
      val?.destinationFields?.fields?.[0]?.fieldType === "DOCUMENT_TYPE"
    ) {
      const sourceLen = val?.sourceFields?.fields?.length ?? 0;
      const destinationLen = val?.destinationFields?.fields?.length ?? 0;

      isMultiple =
        documentDropDownData?.length > sourceLen + destinationLen
          ? true
          : false;

      setDropDownTypeApiOrField({
        isMultiple: isMultiple,
        fromApi: true,
      });

      // setDocumentDropDownData(filteredData);
    } else {
      setDropDownTypeApiOrField({
        isMultiple: isMultiple,
        fromApi: false,
      });
    }
  };

  const handleRuleChange = async (event: any, val: any) => {
    setFromDropdown(true);
    // Rule type : DOCUMENT_TYPE
    let isMultiple = false;
    if (
      val?.sourceFields?.fields?.[0]?.fieldType === "DOCUMENT_TYPE" ||
      val?.destinationFields?.fields?.[0]?.fieldType === "DOCUMENT_TYPE"
    ) {
      const sourceLen = val?.sourceFields?.fields?.length ?? 0;
      const destinationLen = val?.destinationFields?.fields?.length ?? 0;
      isMultiple =
        documentDropDownData?.length > sourceLen + destinationLen
          ? true
          : false;
      setDropDownTypeApiOrField({
        isMultiple: isMultiple,
        fromApi: true,
      });
    } else {
      setDropDownTypeApiOrField({
        isMultiple: isMultiple,
        fromApi: false,
      });
    }
    // End

    convertStringToDropDownJSONform(val);
  };

  const handleSourceDestinationFieldChange = (
    event: any,
    val: any,
    item: any,
    type: string
  ) => {
    const previous = particularRule;
    let sourceDestination =
      type === "source"
        ? "sourceName"
        : type === "destination"
        ? "destinationName"
        : "";
    let id =
      type === "source"
        ? "sourceIds"
        : type === "destination"
        ? "destinationIds"
        : "";

    // Remove dirty record
    if (item?.mandatory) {
      let dirtRecordUpdate =
        dirtyRecord && JSON.parse(JSON.stringify(dirtyRecord));

      // Panel --> children --> rule --> source/destination

      // Panel Id
      const panelIndex = dirtRecordUpdate?.findIndex(
        (e: any) => e?.panelId === selectedControl?.containerId
      );

      // Children Id
      let childrenIndex = -1;
      if (panelIndex > -1) {
        childrenIndex = dirtRecordUpdate[panelIndex]?.children?.findIndex(
          (e: any) => e?.childrenId === selectedControl?.id
        );
      }

      //rule id
      let ruleId = -1;
      if (childrenIndex > -1) {
        ruleId = dirtRecordUpdate[panelIndex]?.children[
          childrenIndex
        ]?.rule?.findIndex((e: any) => e?.id === particularRule?.id);
      }

      // Source or destination Id
      let sourceDestinationId = -1;
      if (ruleId > -1) {
        if (val === null) {
          dirtRecordUpdate[panelIndex]?.children[childrenIndex]?.rule[ruleId][
            id
          ]?.push(item);
          setDirtyRecord(dirtRecordUpdate);
        } else {
          sourceDestinationId = dirtRecordUpdate[panelIndex]?.children[
            childrenIndex
          ]?.rule[ruleId][id]?.findIndex(
            (e: any) =>
              e?.mandatory === item?.mandatory &&
              e?.name === item?.name &&
              e?.ordinal === item?.ordinal
          );
        }
      }

      if (val !== null && sourceDestinationId > -1) {
        dirtRecordUpdate[panelIndex]?.children[childrenIndex]?.rule[ruleId][
          id
        ]?.splice(sourceDestinationId, 1);
        setDirtyRecord(dirtRecordUpdate);
      }
    }
    // End

    //Names
    previous[sourceDestination][item?.ordinal - 1] = val ? val : null;

    //Ids
    let arrId: string[] = [];
    // Multi select
    if (Array.isArray(val)) {
      for (let i = 0; i < val?.length; i++) {
        arrId.push(val?.[i]?.id);
      }

      previous[id][item.ordinal - 1] = [...arrId];
      //Multi end

      // No source field is there in json
    } else if (item === null && type === "source") {
      previous[id][0] = val?.id;
    } else {
      previous[id][item.ordinal - 1] = val?.id ? val?.id : "-1";
    }

    if (typeof previous?.id === "number") {
      previous.isRuleEdited = true;
    }

    setRuleData((val: any) => {
      let prevRuleVal = val;
      const index = prevRuleVal?.findIndex((e: any) => e?.id === previous?.id);
      if (index > -1) {
        prevRuleVal[index] = previous;

        // disabled dropdown for document delete
        if (dropDownTypeApiOrField?.isMultiple) {
          const arrIds = [];
          for (let i = 0; i < prevRuleVal[index]?.sourceIds?.length; i++) {
            if (typeof prevRuleVal[index]?.sourceIds[i] === "object") {
              arrIds.push(...prevRuleVal[index]?.sourceIds[i]);
            } else {
              arrId.push(prevRuleVal[index]?.sourceIds[i]);
            }
          }
          for (let i = 0; i < prevRuleVal[index]?.destinationIds?.length; i++) {
            if (typeof prevRuleVal[index]?.destinationIds[i] === "object") {
              arrIds.push(...prevRuleVal[index]?.destinationIds[i]);
            } else {
              arrId.push(prevRuleVal[index]?.destinationIds[i]);
            }
          }
          setSelectedVal(arrId);
        }
        // end
      }
      return prevRuleVal;
    });

    setChange(change + 1);
  };

  const handleFieldChange = (event: any, val: any, type: string) => {
    let currentRule = particularRule;

    if (type === "conditionValueType") {
      currentRule = {
        ...currentRule,
        conditionValueType: val,
        isRuleEdited: typeof currentRule?.id === "number" ? true : false,
      };
    } else if (type === "condition") {
      currentRule = {
        ...currentRule,
        condition: val,
        //conditionValueType: "TEXT",
        isRuleEdited: typeof currentRule?.id === "number" ? true : false,
      };
    } else if (type === "input") {
      currentRule = {
        ...currentRule,
        //conditionValueType: "TEXT",
        conditionalValues: [event.target.value],
        isRuleEdited: typeof currentRule?.id === "number" ? true : false,
      };
    } else if (type === "button") {
      currentRule = {
        ...currentRule,
        button: event.target.value,
        isRuleEdited: typeof currentRule?.id === "number" ? true : false,
      };
    }

    setRuleData((val: any) => {
      let prevRuleVal = val;
      const index = prevRuleVal?.findIndex(
        (e: any) => e?.id === currentRule?.id
      );
      if (index > -1) {
        prevRuleVal[index] = currentRule;
        setParticularRule(currentRule);
      }
      return prevRuleVal;
    });

    setChange(change + 1);
  };

  const paramFieldChange = () => {
    const index = ruleData?.findIndex((e: any) => e?.id === particularRule?.id);

    if (index > -1) {
      const updatedRule = ruleData.slice();

      updatedRule[index] = {
        ...updatedRule[index],
        //param: stringParam,
        param: paramData,
        isRuleEdited: true,
        isDropdown:
          particularRule?.actionType === "EXT_DROP_DOWN" &&
          particularRule?.sourceType === "FORM_FILL_DROP_DOWN"
            ? true
            : false,
      };

      setRuleData(updatedRule);
    }
  };

  const handleAttributeAndCriteriaChange = (
    _: any,
    val: any,
    indexAttribute: number,
    type: string
  ) => {
    if (type === "criteria") {
      const index = ruleData?.findIndex(
        (e: any) => e?.id === particularRule?.id
      );

      if (index > -1) {
        const updatedRule = ruleData.slice();

        if (particularRule?.param?.conditionList[0].ddType) {
          updatedRule[index].param.conditionList[0].ddType = [val];
        } else {
          updatedRule[index] = {
            ...updatedRule[index],
            param: {
              conditionList: [{ ddType: [val] }, { criterias: [] }, { da: [] }],
            },
          };
        }

        setParticularRule(updatedRule[index]);
        setRuleData(updatedRule);
      }
    } else if (type === "single") {
      let updatedEdv =
        particularRule?.param?.[0]?.conditionList[0].criterias.slice();

      if (!updatedEdv?.length) {
        updatedEdv = JSON.parse(JSON.stringify(criteriaArr));
      }
      updatedEdv[indexAttribute] = {
        ["a" + (indexAttribute + 1)]: val?.id,
        name: val,
      };

      const index = ruleData?.findIndex(
        (e: any) => e?.id === particularRule?.id
      );

      if (index > -1) {
        const updatedRule = ruleData.slice();

        if (particularRule?.param?.conditionList[1].criterias) {
          updatedRule[index].param.conditionList[1].criterias = updatedEdv;
        } else {
          updatedRule[index] = {
            ...updatedRule[index],
            param: {
              conditionList: [
                { ddType: [] },
                { criterias: updatedEdv },
                { da: [] },
              ],
            },
          };
        }

        setParticularRule(updatedRule[index]);
        setRuleData(updatedRule);
      }
    } else if (type === "multiple") {
      const index = ruleData?.findIndex(
        (e: any) => e?.id === particularRule?.id
      );

      if (index > -1) {
        const updatedRule = ruleData.slice();

        if (particularRule?.param?.conditionList?.[2]?.da) {
          updatedRule[index].param.conditionList[2].da = val;
        } else {
          updatedRule[index] = {
            ...updatedRule[index],
            param: {
              conditionList: [{ ddType: [] }, { criterias: [] }, { da: val }],
            },
          };
        }

        setParticularRule(updatedRule[index]);
        setRuleData(updatedRule);
      }
    }
  };

  const specialRule = async () => {
    if (selectedRule?.name === "FFD Dropdown (Client)") {
      convertStringToDropDownJSONform(selectedRule);
    }
    // else if (selectedRule?.name === "EDV Dropdown (Client)") {
    //   if (!dropdownTypeArr?.length) {
    //     const res = await AxiosGet({
    //       endPoint: `v2/company/${window.sessionStorage.getItem(
    //         "companyId"
    //       )}/external-data-metadata?page=0&size=30&sort=id%2Cdesc&genericData=false&searchParam=`,
    //     });

    //     setDropdownTypeArr(res?.data);
    //   }
    // }
  };

  useEffect(() => {
    setFromDropdown(false);
    if (selectedItem) {
      let rule;
      if (selectedItem?.actionType && selectedItem?.sourceType) {
        rule = ruleDropdownValue?.find(
          (e: any) =>
            e?.action === selectedItem?.actionType &&
            e?.source === selectedItem?.sourceType
        );
      } else {
        rule = ruleDropdownValue?.find(
          (e: any) => e?.action === selectedItem?.actionType
        );
      }

      setState(rule);

      setSelectedRule(rule);

      // if (!selectedItem?.fromStatus) {
      //   if (selectedItem?.param) {
      //     const paramObj =
      //       typeof selectedItem?.param === "string"
      //         ? selectedItem?.param
      //         : JSON.parse(selectedItem?.param);
      //     setParamData(paramObj);
      //   } else {
      //     setParamData(null);
      //   }
      // }

      let paramObj;
      if (selectedItem?.param && particularRule) {
        if (
          particularRule?.actionType === "EXT_DROP_DOWN" &&
          particularRule?.sourceType === "FORM_FILL_DROP_DOWN"
        ) {
          if (typeof selectedItem?.param === "string") {
            paramObj = { value: selectedItem?.param };
          } else {
            paramObj = selectedItem?.param;
          }
        } else {
          paramObj =
            typeof selectedItem?.param === "string" &&
            selectedItem?.param?.includes("{")
              ? JSON.parse(selectedItem?.param)
              : selectedItem?.param;
        }

        setParamData(paramObj);
      } else {
        setParamData(null);
      }
    }
  }, [selectedItem, particularRule]);

  useEffect(() => {
    if (selectedRule !== null && selectedRule) {
      if (fromDropdown) {
        onRuleSubmit("dropdownRuleSelected");
      }
    }
  }, [selectedRule, fromDropdown]);

  useEffect(() => {
    if (paramData && Object.keys?.(paramData)?.length) {
      setTimeout(() => {
        paramFieldChange();
      }, 1000);
    }
  }, [paramData]);

  useEffect(() => {
    if (particularRule) {
      setFormName(particularRule);
    }
  }, [particularRule]);

  useEffect(() => {
    specialRule();
  }, [selectedRule?.params]);

  return (
    <div className="main-form">
      <form onSubmit={onRuleSubmit} style={{ minWidth: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id=""
              fullWidth
              options={ruleDropdownValue || []}
              getOptionLabel={(option) => option?.name}
              value={selectedRule}
              onChange={handleRuleChange}
              popupIcon={<KeyboardArrowDownIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Rule"
                  variant="standard"
                  label="Rule Name"
                />
              )}
            />
          </Grid>

          {selectedRule?.sourceFields?.fields?.length ? (
            <div className="w-100" style={{ marginLeft: "16px" }}>
              <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                Source Field
                {+selectedRule?.sourceFields?.fields?.length > 1 && "s"}
              </h6>
              <Grid container spacing={2}>
                {
                  // dropDownTypeApiOrField?.fromApi
                  //   ? selectedRule?.sourceFields?.fields?.map(
                  //       (item: any, index: number) => (
                  //         <Grid
                  //           item
                  //           xs={
                  //             selectedRule?.sourceFields?.fields?.length - 1 ===
                  //               index && dropDownTypeApiOrField?.isMultiple
                  //               ? 12
                  //               : 6
                  //           }
                  //         >
                  //           <Autocomplete
                  //             value={particularRule?.sourceName?.[index]}
                  //             id=""
                  //             fullWidth={
                  //               selectedRule?.sourceFields?.fields?.length - 1 ===
                  //                 index && dropDownTypeApiOrField?.isMultiple
                  //                 ? true
                  //                 : false
                  //             }
                  //             multiple={
                  //               selectedRule?.sourceFields?.fields?.length - 1 ===
                  //                 index && dropDownTypeApiOrField?.isMultiple
                  //                 ? true
                  //                 : false
                  //             }
                  //             options={documentDropDownData || []}
                  //             getOptionLabel={(option) => option?.documentType}
                  //             onChange={(event, val) => {
                  //               handleSourceDestinationFieldChange(
                  //                 event,
                  //                 val,
                  //                 item,
                  //                 "source"
                  //               );
                  //             }}
                  //             popupIcon={<KeyboardArrowDownIcon />}
                  //             getOptionDisabled={(option) =>
                  //               selectedVal?.includes(option?.id)
                  //             }
                  //             renderInput={(params) => (
                  //               <TextField
                  //                 {...params}
                  //                 placeholder={`Select ${item?.name}`}
                  //                 variant="standard"
                  //                 label={item?.name}
                  //                 required={item?.mandatory ? true : false}
                  //                 InputLabelProps={{
                  //                   shrink: true,
                  //                 }}
                  //               />
                  //             )}
                  //           />
                  //         </Grid>
                  //       )
                  //     )
                  //   :

                  formNames !== null &&
                    selectedRule?.sourceFields?.fields?.map(
                      (item: any, index: number) => (
                        <Grid item xs={item?.unlimited ? 12 : 6}>
                          <Autocomplete
                            multiple={item?.unlimited ? true : false}
                            value={
                              item?.unlimited
                                ? formNames?.sourceName?.[index]
                                  ? formNames?.sourceName?.[index]
                                  : []
                                : formNames?.sourceName?.[index]
                                ? formNames?.sourceName?.[index]
                                : null
                            }
                            id={item?.ordinal}
                            fullWidth
                            options={ruleFieldDropdown ?? []}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event, val) => {
                              handleSourceDestinationFieldChange(
                                event,
                                val,
                                item,
                                "source"
                              );
                            }}
                            popupIcon={<KeyboardArrowDownIcon />}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={`Select ${item?.name}`}
                                variant="standard"
                                label={item?.name}
                                required={item?.mandatory ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  item?.mandatory
                                    ? item?.unlimited
                                      ? formNames?.sourceName?.[index]
                                        ? false
                                        : true
                                      : formNames?.sourceName?.[index]
                                      ? false
                                      : true
                                    : false
                                }
                                helperText={`${
                                  item?.mandatory
                                    ? item?.unlimited
                                      ? formNames?.sourceName?.[index]
                                        ? ""
                                        : "*Select " + item?.name
                                      : formNames?.sourceName?.[index]
                                      ? ""
                                      : "*Select " + item?.name
                                    : ""
                                }`}
                              />
                            )}
                          />
                        </Grid>
                      )
                    )
                }
              </Grid>
            </div>
          ) : (
            selectedRule && (
              <div className="w-100" style={{ marginLeft: "16px" }}>
                <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                  Source Field
                </h6>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      value={selectedControl?.name}
                      disabled
                      fullWidth
                      options={[]}
                      getOptionLabel={(option) => option}
                      popupIcon={<KeyboardArrowDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={"selected source field"}
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            )
          )}

          {formNames !== null &&
            selectedRule?.destinationFields?.fields?.length && (
              <div className="w-100" style={{ marginLeft: "16px" }}>
                <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                  Destination Field
                  {+selectedRule?.destinationFields?.fields?.length > 1 && "s"}
                </h6>
                <Grid container spacing={2}>
                  {
                    // dropDownTypeApiOrField?.fromApi
                    //   ? selectedRule?.destinationFields?.fields?.map(
                    //       (item: any, index: number) => (
                    //         <Grid
                    //           item
                    //           xs={
                    //             selectedRule?.destinationFields?.fields?.length -
                    //               1 ===
                    //               index && dropDownTypeApiOrField?.isMultiple
                    //               ? 12
                    //               : 6
                    //           }
                    //         >
                    //           <Autocomplete
                    //             value={particularRule?.destinationName?.[index]}
                    //             id=""
                    //             fullWidth={
                    //               selectedRule?.destinationFields?.fields?.length -
                    //                 1 ===
                    //                 index && dropDownTypeApiOrField?.isMultiple
                    //                 ? true
                    //                 : false
                    //             }
                    //             multiple={
                    //               selectedRule?.destinationFields?.fields?.length -
                    //                 1 ===
                    //                 index && dropDownTypeApiOrField?.isMultiple
                    //                 ? true
                    //                 : false
                    //             }
                    //             options={documentDropDownData}
                    //             getOptionLabel={(option) => option?.documentType}
                    //             onChange={(event, val) => {
                    //               handleSourceDestinationFieldChange(
                    //                 event,
                    //                 val,
                    //                 item,
                    //                 "destination"
                    //               );
                    //             }}
                    //             popupIcon={<KeyboardArrowDownIcon />}
                    //             getOptionDisabled={(option) =>
                    //               selectedVal?.includes(option?.id)
                    //             }
                    //             renderInput={(params) => (
                    //               <TextField
                    //                 {...params}
                    //                 placeholder={`Select ${item?.name}`}
                    //                 variant="standard"
                    //                 label={item?.name}
                    //                 required={item?.mandatory ? true : false}
                    //                 InputLabelProps={{
                    //                   shrink: true,
                    //                 }}
                    //               />
                    //             )}
                    //           />
                    //         </Grid>
                    //       )
                    //     )
                    //   :

                    selectedRule?.destinationFields?.fields?.map(
                      (item: any, index: number) => (
                        <Grid item xs={item?.unlimited ? 12 : 6}>
                          <Autocomplete
                            value={
                              item?.unlimited
                                ? formNames?.destinationName?.[index]
                                  ? formNames?.destinationName?.[index]
                                  : []
                                : formNames?.destinationName?.[index]
                                ? formNames?.destinationName?.[index]
                                : null
                            }
                            id=""
                            fullWidth
                            multiple={item?.unlimited ? true : false}
                            options={ruleFieldDropdown}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event, val) => {
                              handleSourceDestinationFieldChange(
                                event,
                                val,
                                item,
                                "destination"
                              );
                            }}
                            popupIcon={<KeyboardArrowDownIcon />}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={`Select ${item?.name}`}
                                variant="standard"
                                label={item?.name}
                                required={item?.mandatory ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  item?.mandatory
                                    ? item?.unlimited
                                      ? formNames?.destinationName?.[index]
                                        ? false
                                        : true
                                      : formNames?.destinationName?.[index]
                                      ? false
                                      : true
                                    : false
                                }
                                helperText={`${
                                  item?.mandatory
                                    ? item?.unlimited
                                      ? formNames?.destinationName?.[index]
                                        ? ""
                                        : "*Select " + item?.name
                                      : formNames?.destinationName?.[index]
                                      ? ""
                                      : "*Select " + item?.name
                                    : ""
                                }`}
                              />
                            )}
                          />
                        </Grid>
                      )
                    )
                  }
                </Grid>
              </div>
            )}

          {selectedRule?.conditionsRequired && (
            <div className="w-100" style={{ marginLeft: "16px" }}>
              <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                Condition
              </h6>
              <Grid container spacing={2}>
                <>
                  <Grid item xs={6}>
                    <Autocomplete
                      id=""
                      fullWidth
                      options={conditionDropdown || []}
                      getOptionLabel={(option) => option}
                      value={particularRule?.condition}
                      onChange={(event, val) =>
                        handleFieldChange(event, val, "condition")
                      }
                      popupIcon={<KeyboardArrowDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Condition"
                          variant="standard"
                          label="Conditions"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id=""
                      fullWidth
                      options={conditionValueTypeDropdown || []}
                      getOptionLabel={(option) => option}
                      value={particularRule?.conditionValueType}
                      onChange={(event, val) =>
                        handleFieldChange(event, val, "conditionValueType")
                      }
                      popupIcon={<KeyboardArrowDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Condition Value Type"
                          variant="standard"
                          label="Condition Value Type"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={24}>
                    <TextField
                      placeholder="Enter Conditional Values"
                      variant="standard"
                      label="Conditional Values"
                      value={particularRule?.conditionalValues}
                      onChange={(e) => handleFieldChange(e, "", "input")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      fullWidth
                    />
                  </Grid>
                </>
              </Grid>
            </div>
          )}

          {selectedRule?.button && (
            <div className="w-100" style={{ marginLeft: "16px" }}>
              <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                Button Field
              </h6>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    placeholder="Enter Button Name"
                    variant="standard"
                    label="Button Name"
                    value={particularRule?.button}
                    onChange={(e) => handleFieldChange(e, "", "button")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {/* <Grid item xs={6}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                multiple
                options={names}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                //onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Destination"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <MenuItem
                    {...props}
                    key={option}
                    value={option}
                    sx={{ justifyContent: "space-between" }}
                  >
                    {option}
                    {selected ? <CheckIcon color="info" /> : null}
                  </MenuItem>
                )}
              />
            </Grid>
          </Grid> */}

          {
            // selectedRule && <Grid item xs={12}>
            //   <Autocomplete
            //     fullWidth
            //     multiple
            //     options={names}
            //     getOptionLabel={(option) => option}
            //     disableCloseOnSelect
            //     //onChange={handleChange}
            //     renderInput={(params) => (
            //       <TextField
            //         {...params}
            //         variant="standard"
            //         label="First Name"
            //       />
            //     )}
            //     renderOption={(props, option, { selected }) => (
            //       <MenuItem
            //         {...props}
            //         key={option}
            //         value={option}
            //         sx={{ justifyContent: "space-between" }}
            //       >
            //         {option}
            //         {selected ? <CheckIcon color="info" /> : null}
            //       </MenuItem>
            //     )}
            //   />
            // </Grid>
          }

          {selectedRule?.name === "FFD Dropdown (Client)" ? (
            stringToDropdown?.params?.jsonSchema &&
            Object.keys(stringToDropdown?.params?.jsonSchema).length > 0 ? (
              <div
                className="w-100"
                style={{ padding: "0 16px", margin: 0, overflowY: "auto" }}
              >
                <h6 style={{ ...subHeading, margin: "2.5rem 0 4px 0" }}>
                  Param Fields
                </h6>

                <JsonForms
                  schema={
                    stringToDropdown?.params?.jsonSchema
                      ? stringToDropdown?.params?.jsonSchema
                      : undefined
                  }
                  uischema={
                    stringToDropdown?.params?.uiSchema &&
                    Object.keys(stringToDropdown?.params?.uiSchema)?.length
                      ? stringToDropdown?.params?.uiSchema
                      : undefined
                  }
                  data={paramData}
                  renderers={materialRenderers}
                  cells={materialCells}
                  onChange={({ data }) => {
                    setParamData(data);
                  }}
                />
              </div>
            ) : (
              <></>
            )
          ) : selectedRule?.params?.jsonSchema &&
            Object.keys(selectedRule?.params?.jsonSchema).length > 0 ? (
            <div
              className="w-100"
              style={{ padding: "0 16px", margin: 0, overflowY: "auto" }}
            >
              <h6 style={{ ...subHeading, margin: "2.5rem 0 4px 0" }}>
                Param Fields
              </h6>

              <JsonForms
                schema={
                  selectedRule?.params?.jsonSchema
                    ? selectedRule?.params?.jsonSchema
                    : undefined
                }
                uischema={
                  selectedRule?.params?.uiSchema &&
                  Object.keys(selectedRule?.params?.uiSchema)?.length
                    ? selectedRule?.params?.uiSchema
                    : undefined
                }
                data={paramData}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={({ data }) => {
                  setParamData(data);
                }}
              />
            </div>
          ) : (
            <></>
          )}

          {(selectedRule?.name === "EDV Dropdown (Client)" ||
            selectedRule?.params?.paramType === "edv_criteria") && (
            <div className="w-100" style={{ marginLeft: "16px" }}>
              <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                Dropdown Types
              </h6>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    id=""
                    fullWidth
                    options={dropdownTypeArr ?? []}
                    getOptionLabel={(option: any) => option?.name}
                    value={
                      particularRule?.param?.conditionList?.[0]?.ddType?.[0]
                    }
                    onChange={(event, val) =>
                      handleAttributeAndCriteriaChange(
                        event,
                        val,
                        -1,
                        "criteria"
                      )
                    }
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Dropdown Types"
                        variant="standard"
                        label="Dropdown Types"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {(selectedRule?.name === "EDV Dropdown (Client)" ||
            selectedRule?.params?.paramType === "edv_criteria") && (
            <div className="w-100" style={{ marginLeft: "16px" }}>
              <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                Criterias
              </h6>
              <Grid container spacing={2}>
                {criteriaArr?.map((e: any, index: number) => (
                  <Grid item xs={6}>
                    <Autocomplete
                      id=""
                      fullWidth
                      options={ruleFieldDropdown ?? []}
                      getOptionLabel={(option: any) => option?.name}
                      value={
                        particularRule?.param?.conditionList?.[1]?.criterias?.[
                          index
                        ]?.name
                      }
                      onChange={(event, value) =>
                        handleAttributeAndCriteriaChange(
                          event,
                          value,
                          index,
                          "single"
                        )
                      }
                      popupIcon={<KeyboardArrowDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Criteria Type"
                          variant="standard"
                          label={"Criteria " + (index + 1)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )}

          {(selectedRule?.name === "EDV Dropdown (Client)" ||
            selectedRule?.params?.paramType === "edv_criteria") && (
            <div className="w-100" style={{ marginLeft: "16px" }}>
              <h6 style={{ ...subHeading, margin: "2.5rem 0 0.25rem 0" }}>
                Display attributes
              </h6>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    id=""
                    fullWidth
                    multiple
                    options={attributeStaticArr || []}
                    getOptionLabel={(option: any) => option?.name}
                    value={
                      particularRule?.param?.conditionList?.[2]?.da
                        ? particularRule?.param?.conditionList?.[2]?.da
                        : []
                    }
                    onChange={(event, val) =>
                      handleAttributeAndCriteriaChange(
                        event,
                        val,
                        -1,
                        "multiple"
                      )
                    }
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Attribute Type"
                        variant="standard"
                        label="Display attribute"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {/* <Grid
            item
            xs={12}
            className="d-flex align-items-center justify-content-space-between my-2"
          >
            <h6 style={subHeading} className="mt-1">
              Expression
            </h6>
            <Button
              color="primary"
              disableElevation
              variant="contained"
              style={{ ...rest, height: "2rem", backgroundColor: "#318BE3" }}
              className="ml-4"
              onClick={() => setIsExpression(true)}
            >
              Add
            </Button>
          </Grid>
          {isExpression && <Expression setIsExpression={setIsExpression} />} */}
        </Grid>

        {/* {selectedRule?.processingType === "SERVER" && (
          <div className="mt-4">
            <h6 style={subHeading}>Do you want to execute any other rule</h6>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                //value="female"
                control={
                  <Radio
                    checked={isOtherRule ? true : false}
                    onChange={() => setIsOtherRule(true)}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                //value="male"
                control={
                  <Radio
                    checked={!isOtherRule ? true : false}
                    onChange={() => setIsOtherRule(false)}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </div>
        )} */}

        {isOtherRule && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                multiple
                options={names}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                popupIcon={<KeyboardArrowDownIcon />}
                //onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select Other Rules"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <MenuItem
                    {...props}
                    key={option}
                    value={option}
                    sx={{ justifyContent: "space-between" }}
                  >
                    {option}
                    {selected ? <CheckIcon color="info" /> : null}
                  </MenuItem>
                )}
              />
            </Grid>
          </Grid>
        )}

        {/* {selectedRule?.processingType === "SERVER" && (
          <div className="mt-4">
            <h6 style={{ ...subHeading, color: "rgb(49, 123, 240)" }}>
              Advance Setting
            </h6>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  id=""
                  options={[]}
                  popupIcon={<KeyboardArrowDownIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Execute On Fill"
                      variant="standard"
                    />
                  )}
                  style={textboxStyle}
                  //onChange={onRuleTypeChange}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  id=""
                  options={[]}
                  popupIcon={<KeyboardArrowDownIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Execute On eSign"
                      variant="standard"
                    />
                  )}
                  style={textboxStyle}
                  //onChange={onRuleTypeChange}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  id=""
                  options={[]}
                  popupIcon={<KeyboardArrowDownIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Execute On Read"
                      variant="standard"
                    />
                  )}
                  style={textboxStyle}
                  //onChange={onRuleTypeChange}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  id=""
                  options={[]}
                  popupIcon={<KeyboardArrowDownIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Execute Post eSign"
                      variant="standard"
                    />
                  )}
                  style={textboxStyle}
                  //onChange={onRuleTypeChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="text"
                  fullWidth={true}
                  //placeholder={item.placeholder}
                  label={"Delimiter"}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </div>
        )} */}
      </form>
    </div>
  );
};

export default EditRuleComponent;
