import React from "react";
import { Box, Typography } from "@mui/material";

import "./Templates.scss";
const Templates = () => {
  return (
    <Box className="centered-box">
      <Typography className="theText">
        Please select the template to create a request
      </Typography>
      {/* Add more content here */}
    </Box>
  );
};

export default Templates;
