import axios from "axios";

const domainUrl = process.env.REACT_APP_MAIN_URL;

const BASE_URL = domainUrl + "app/";
const token = window.sessionStorage.getItem("token");

export const commonAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "X-Authorization": token,
  },
});

commonAxios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["X-Authorization"] = token;
    }
    return config;
  },
  (error) => {
    throw error;
  }
);
