import React, {
  FunctionComponent,
  Dispatch,
  useContext,
  useState,
} from "react";
import ControlDragComponent from "./subcomponents/ControlDragComponent";
import {
  FormLayoutComponentChildrenType,
  FormLayoutComponentContainerType,
  FormLayoutComponentsType,
} from "../../types/FormTemplateTypes";
import { disable, enable, FormBuilderContext } from "./FormBuilder";
import { Box, Skeleton } from "@mui/material";

type DispatchObject = {
  value: boolean;
  arrayId?: string;
};

interface LeftSidebarProps {
  handleItemAdded: (
    item: FormLayoutComponentChildrenType | FormLayoutComponentContainerType,
    containerId?: string
  ) => void;
  formLayoutComponents: FormLayoutComponentsType[];
  sideBarLoading: boolean;
  isOverMainContainer?: DispatchObject;
  setIsCalled?: Dispatch<boolean>;
}

const SkeletonDisplay = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        margin: "0 0 2rem 0",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={"42.5%"}
        height={60}
        style={{
          borderRadius: "6px",
          margin: "0 0 0 1rem",
        }}
      />
      <Skeleton
        variant="rectangular"
        width={"42.5%"}
        height={60}
        style={{
          borderRadius: "6px",
          margin: "0 1rem 0 0",
        }}
      />
    </Box>
  );
};

const LeftSidebar: FunctionComponent<LeftSidebarProps> = (props) => {
  const { isOverMainContainer, sideBarLoading } = props;

  const value: any = useContext(FormBuilderContext);

  const { leftBarFields } = value;

  const [genericOrObject, setIsGenericOrObject] = useState<boolean>(true);

  return (
    <>
      {sideBarLoading ? (
        <>
          <SkeletonDisplay />
          <SkeletonDisplay />
          <SkeletonDisplay />
          <SkeletonDisplay />
          <SkeletonDisplay />
        </>
      ) : (
        <div className="row">
          <div
            className="w-100 mb-3 pt-3 d-flex justify-content-around align-items-center"
            style={{
              backgroundColor: "#e8f0fd",
              height: "5rem",
            }}
          >
            <p
              style={genericOrObject ? enable : disable}
              onClick={() => setIsGenericOrObject(true)}
            >
              Standard Fields
            </p>
            <p
              style={
                genericOrObject
                  ? { ...disable, marginRight: "2rem" }
                  : { ...enable, marginRight: "2rem" }
              }
              onClick={() => setIsGenericOrObject(false)}
            >
              Objects
            </p>
          </div>
          <div
            className="row"
            style={{
              padding: "0 0 0 2rem",
              minHeight: "100%",
              maxHeight: "78vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            {genericOrObject &&
              leftBarFields
                ?.filter(
                  (e: any) =>
                    (e?.group === "STD" && e?.meta?.properties?.display) ||
                    (e?.group === "ADV" && e?.meta?.properties?.display)
                )
                ?.sort((a: any, b: any) => {
                  const labelA = a.meta.properties.label.toUpperCase();
                  const labelB = b.meta.properties.label.toUpperCase();
                  return labelA.localeCompare(labelB);
                })
                ?.map((item: any) => {
                  return (
                    <div className="col-6" key={item?.id}>
                      <ControlDragComponent
                        key={item?.id}
                        item={item?.meta?.properties}
                        handleItemAdded={props.handleItemAdded}
                        formLayoutComponents={props.formLayoutComponents}
                        isOverMainContainer={isOverMainContainer}
                      />
                    </div>
                  );
                })}

            {!genericOrObject &&
              leftBarFields
                .filter((e: any) => e?.group === "OBJ")
                ?.sort((a: any, b: any) => {
                  const labelA = a.meta.properties.label.toUpperCase();
                  const labelB = b.meta.properties.label.toUpperCase();
                  return labelA.localeCompare(labelB);
                })
                ?.map((item: any) => {
                  return (
                    <div className="col-6" key={item?.id}>
                      <ControlDragComponent
                        key={item?.id}
                        item={item?.meta?.properties}
                        fields={item?.meta?.fields}
                        handleItemAdded={props.handleItemAdded}
                        formLayoutComponents={props.formLayoutComponents}
                        isOverMainContainer={isOverMainContainer}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      )}
    </>
  );
};

export default LeftSidebar;
