import { createTheme } from "@mui/material/styles";

const mainPath = window.location.pathname;

const formBuilderOrFormFillStyle = mainPath?.includes("form-builder")
  ? true
  : false;

const muiFormFillTextField = {
  variants: [
    {
      props: { variant: "standard" },
      style: {
        backgroundColor: "transparent",
        "& .MuiInputBase-root": {
          backgroundColor: "transparent",
        },
        "& input": {
          backgroundColor: "transparent",
        },
        "& input:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px transparent inset",
          WebkitTextFillColor: "inherit",
          transition: "background-color 5000s ease-in-out 0s",
        },
        width: "90%",
        marginTop: "30px",

        "& .MuiInputBase-root": {
          textTransform: "capitalize !important",
          fontWeight: 600,
          color: "#4f4f4f",
          fontSize: "14px",
        },
        "& .MuiInputLabel-root": {
          textTransform: "capitalize",
          fontWeight: 500,
          color: "#6a6a6a",
          fontSize: "14px",
        },
//         "& .MuiInput-input":{
// paddingBottom:"10px",
//         },
        // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        //   borderColor: 'yellow !important', // Change this line
        // }
        "@media (max-width: 500px)": {
          width: "99%",
        },
      },
    },
  ],
};

const muiFormBuilderTextField = {
  defaultProps: {
    variant: "standard", // Set the default variant to 'standard' for all TextFields
  },
};

export const appTheme = createTheme({
  palette: {
    primary: {
      main: formBuilderOrFormFillStyle ? "rgb(49, 139, 227)" : "#3f5fff",
    },
    secondary: {
      main: "#0F0F0F",
    },
    tertiary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
    textTransform: "none",
    color: "#4f4f4f",
    sidebarText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      textAlign: "left",
      color: "#393e46",
    },
    errorPageText: {
      color: "#ff6347",
      fontFamily: "Poppins",
      fontSize: "80px",
      fontWeight: 600,
    },
    returnText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      textAlign: "left",
      color: "#212429",
    },
    fontThirty: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 500,
      textAlign: "left",
      color: "#4f4f4f",
    },
    formHeader: {
      fontFamily: "Montserrat",
      fontSize: "26px",
      color: "#000",
      fontWeight: "600",
    },
    formSecondary: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      color: "#6a6a6a",
      fontWeight: "600",
    },
    formLabel: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      color: "#4f4f4f",
      fontWeight: "600",
    },
    helperText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      textAlign: "left",
      color: "#4f4f4f",
    },
    templateTitle: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "left",
      color: "#4f4f4f",
    },
    templateInfo: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 600,
      textAlign: "left",
      color: "#4f4f4f",
    },
    sectionTitle: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      textAlign: "left",
      color: "#4f4f4f",
    },
    imgPlaceholder: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "left",
      color: "#4f4f4f",
    },
    regularTwentyFour: {
      fontSize: "1.3vw",
      lineHeight: "30px",
      fontWeight: 500,
      color: "#ffffff",
      "@media (max-width: 768px)": {
        fontSize: "2.222vw",
      },
      "@media (max-width: 500px)": {
        fontSize: "4.222vw",
        lineHeight: "20px",
      },
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      variants: [
        {
          props: { variant: "mobiledark" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            backgroundColor: "#3f5fff",
            fontSize: "14px",
            color: "#ffffff",
            width:"326px",
            padding: "15px 0px 15px 0px",
            fontWeight: "600",
            "&:hover": {
              textDecoration: "none",
              backgroundColor: "blue",
            },
          },
        },
        {
          props: { variant: "mobileDisableButton" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            backgroundColor: "#f2f4f5",
            fontSize: "14px",
            color: "#ffffff",
            width:"326px",
            padding: "15px 0px 15px 0px",
            fontWeight: "600",
            "&:hover": {
              textDecoration: "none",
              backgroundColor: "blue",
            },
          },
        },
        {
          props: { variant: "dark" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            backgroundColor: "#3f5fff",
            fontSize: "16px",
            color: "#ffffff",
            padding: "18px 48px 17px 48px",
            fontWeight: "600",
            "&:hover": {
              textDecoration: "none",
              backgroundColor: "blue",
            },
          },
        },
        {
          props: { variant: "templateWhite" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            backgroundColor: "#fff",
            fontSize: "16px",
            color: " #4f4f4f",
            padding: "12.9px 49px 12.1px 49px",
            fontWeight: "600",
            border: "1px solid #4f4f4f",

            "&:hover": {
              textDecoration: "none",
              backgroundColor: "none",
            },
          },
        },
        {
          props: { variant: "templateBlue" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            backgroundColor: "#3f5fff",
            fontSize: "16px",
            color: "#fff ",
            padding: "12.9px 60px 12.1px 60px",
            fontWeight: "600",
          },
        },
        {
          props: { variant: "disableButton" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            backgroundColor: "#f2f4f5",
            fontSize: "16px",
            color: "grey",
            padding: "12.9px 60px 12.1px 60px",
            fontWeight: "600",

            "&:hover": {
              textDecoration: "none",
              backgroundColor: "blue",
            },
          },
        },
        {
          props: { variant: "sectionSubmit" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "16px",
            padding: "18px 140px 17px 140px",
            backgroundColor: "blue",
            fontWeight: "600",
            color: "#fff ",
          },
        },
        {
          props: { variant: "sectionSubmitDisable" },
          style: {
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "16px",
            padding: "18px 140px 17px 140px",
            backgroundColor: "#f2f4f5",
            fontWeight: "600",
            color: "grey",
          },
        },
      ],
    },
    MuiTextField: formBuilderOrFormFillStyle
      ? muiFormBuilderTextField
      : muiFormFillTextField,
    MuiSelect: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
          width: "100%",
            textTransform: "none",
            "& .MuiFormControl-root": {
              marginTop: "30px",
            },
            "& .MuiInputBase-input": {
              textTransform: "capitalize",
              fontWeight: 600,
              color: "#4f4f4f",
              fontSize: "14px",
            },
            "@media (max-width: 500px)": {
              width: "86.958vw",
            },
          },
        },
      ],
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          // Custom styles for the DatePicker root
          "& .MuiInputBase-root": {
            backgroundColor: "lightblue",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          },
          "& .MuiInputBase-input": {
            color: "green",
            fontWeight: "bold",
          },
          "& .MuiPickersCalendarHeader-root": {
            backgroundColor: "lightgreen",
          },
          "& .MuiPickersDay-root": {
            color: "blue",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: "#337cef", // Custom success color
        },
        filledError: {
          backgroundColor: "#d94660", // Custom error color
        },
      },
    },
  },
});
