import * as React from "react"
const CircleOutline = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 709">
      <g data-name="Group 704">
        <g
          data-name="Ellipse 12"
          style={{
            stroke: "#dbdbdb",
            strokeWidth: "1.4px",
            fill: "none",
          }}
        >
          <circle
            cx={15}
            cy={15}
            r={15}
            stroke="none"
            style={{
              stroke: "none",
            }}
          />
          <circle
            cx={15}
            cy={15}
            r={14.3}
            style={{
              fill: "none",
            }}
          />
        </g>
      </g>
    </g>
  </svg>
)
export default CircleOutline