import * as React from "react"
const ActiveDocuments = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} {...props}>
    <g data-name="Group 4275">
      <g
        data-name="Rectangle 284"
        style={{
          fill: "#fff",
          stroke: "#3f5fff",
          strokeWidth: "1.6px",
        }}
      >
        <rect
          width={44}
          height={44}
          stroke="none"
          rx={22}
          style={{
            stroke: "none",
          }}
        />
        <rect
          width={42.4}
          height={42.4}
          x={0.8}
          y={0.8}
          rx={21.2}
          style={{
            fill: "none",
          }}
        />
      </g>
      <g data-name="Group 3413">
        <path
          d="M0 0h30v30H0z"
          data-name="Rectangle 292"
          style={{
            fill: "none",
          }}
          transform="translate(7 7)"
        />
        <path
          d="M179.267 124.477V109.72h-2.951a.59.59 0 0 1-.59-.59v-2.951h-11.215v18.3zm-2.361-17.464 1.526 1.526h-1.526zm2.951 18.644H163.92a.59.59 0 0 1-.59-.59v-19.479a.59.59 0 0 1 .59-.59h12.4a.59.59 0 0 1 .417.173l3.542 3.542a.59.59 0 0 1 .173.417v15.937a.59.59 0 0 1-.59.59z"
          data-name="Path 690"
          style={{
            fillRule: "evenodd",
            fill: "#3f5fff",
          }}
          transform="translate(-151.16 -92.31)"
        />
        <path
          d="M215.454 67.324h-2.305a.59.59 0 1 0 0 1.18h2.9a.59.59 0 0 0 .59-.59V52.566a.591.591 0 0 0-.173-.417l-5.312-5.312a.591.591 0 0 0-.418-.173h-11.81a.59.59 0 0 0-.59.59v2.361a.59.59 0 1 0 1.181 0v-1.771h10.97l4.967 4.967z"
          data-name="Path 691"
          style={{
            fillRule: "evenodd",
            fill: "#3f5fff",
          }}
          transform="translate(-184.395 -36.928)"
        />
        <path
          d="M182 414.4h9.729v1.3H182z"
          data-name="Path 296"
          style={{
            fill: "#3f5fff",
          }}
          transform="translate(-166.277 -386.172)"
        />
        <path
          d="M199.923 105.007v-5.935l2.9 2.5.843-.989-3.966-3.408a.641.641 0 0 0-.843 0l-3.973 3.405.843.989 2.9-2.5v5.919h1.3z"
          data-name="Path 297"
          style={{
            fill: "#3f5fff",
          }}
          transform="translate(-178.784 -77.979)"
        />
      </g>
    </g>
  </svg>
)
export default ActiveDocuments