import React from "react";
import { common } from "./interface";

export const ArrayIcon = (props:common) => {
  const { size, color } = props;
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 30 30"
  >
    <g data-name="Group 4130">
      <path
        data-name="Rectangle 877"
        style={{ fill: "none" }}
        d="M0 0h30v30H0z"
      />
      <g data-name="Group 4172">
        <g data-name="Group 4176">
          <g data-name="Group 4173">
            <g data-name="Group 4172">
              <path
                data-name="Path 1784"
                d="M99.115 95.1H75.124a.736.736 0 0 0-.724.724v16.639a.736.736 0 0 0 .724.724h23.991a.736.736 0 0 0 .724-.724V95.824a.725.725 0 0 0-1.449 0v16.639l.724-.724h-23.99l.724.724V95.824l-.724.724h23.991a.725.725 0 0 0 0-1.448z"
                transform="translate(-72.222 -91.1)"
                style={{ fill: color }}
              />
            </g>
          </g>
          <g data-name="Group 4175">
            <g data-name="Group 4174">
              <path
                data-name="Path 1785"
                d="M72.626 387.249h24.389a.725.725 0 0 0 0-1.449H72.626a.725.725 0 0 0 0 1.449z"
                transform="translate(-69.926 -360.743)"
                style={{ fill: color }}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>)
}
