import * as React from "react"
import { SVGProps } from "react"
const BigDone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54}
    height={54}
    data-name="Document fill active"
    {...props}
  >
    <g data-name="Group 807">
      <rect
        width={54}
        height={54}
        data-name="Rectangle 284"
        rx={27}
        style={{
          fill: "#3f5fff",
        }}
      />
      <path
        d="m225.013 168-14.1 14.106-8.009-8.011-2.24 2.24 10.249 10.249 16.345-16.342z"
        data-name="Path 841"
        style={{
          fill: "#fff",
          fillRule: "evenodd",
        }}
        transform="translate(-186.66 -150)"
      />
    </g>
  </svg>
)
export default BigDone