import React, { useEffect, useState } from "react";
import "./Transaction.scss";
import {
  Box,
  Typography,
  TextField,
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { CloseOutlined } from "@mui/icons-material";
import Submit from "../../../assets/icons/FormFillIcons/Submit";
import Form from "../../../assets/icons/FormFillIcons/Form";
import ActiveForm from "../../../assets/icons/FormFillIcons/ActiveForm";
import Flag from "../../../assets/icons/FormFillIcons/Flag";
import {
  getAllDocument,
  getcompanyFeature,
  getTemplate,
  getTemplateInfo,
  postFillAction,
  putFillTxnAction,
  storeDocumentIdWithoutTMPT,
  storeDocumentTypeId,
  storeSelectedIdWithTMPT,
  storeSelectedName,
} from "../../../redux/slices/ManchSlices";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import useWindowSize from "../../../components/FormFill/Global/Inner/useWindowSize";
// Vertical Connector (new)
const VerticalConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.vertical}`]: {
    marginLeft: 25,
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 3,
    minHeight: 30,
    backgroundColor: theme.palette.grey[400],
  },
}));
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "transparent",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "transparent",
    color: "#fff",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  }),
}));
const CustomStepIcon = ({
  active,
  completed,
  icon,
  firstDocumentAvailable,
}) => {
  const icons = firstDocumentAvailable
    ? {
        1: <ActiveForm />,
        2: <Submit />,
        3: <Flag />,
      }
    : {
        1: <Form />,
        2: <Submit />,
        3: <Flag />,
      };

  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {icons[icon]}
    </CustomStepIconRoot>
  );
};
const Transaction = () => {
  const [active,setActive]=useState(false)
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const [age, setAge] = React.useState("");
  const [otpType, setOtpType] = useState("");
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      message: "",
      name: "",
      mobileNo: "",
      email: "",
      organization: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().when([], {
        is: () => !!uiCustomizations[0]?.displayInUi, // Ensure boolean value
        then: () => Yup.string().required("Title is required"), // Always return schema
        otherwise: () => Yup.string(), // Default schema
      }),
      message: Yup.string().when([], {
        is: () => !!uiCustomizations[1]?.displayInUi,
        then: () => Yup.string().required("Message is required"),
        otherwise: () => Yup.string(),
      }),
      name: Yup.string().when([], {
        is: () => !!uiCustomizations[3]?.displayInUi,
        then: () => Yup.string().required("Name is required"),
        otherwise: () => Yup.string(),
      }),
      mobileNo: Yup.string().when([], {
        is: () => !!uiCustomizations[4]?.displayInUi,
        then: () =>
          Yup.string()
            .matches(/^[0-9]{10}$/, "Invalid mobile number")
            .required("Mobile number is required"),
        otherwise: () => Yup.string(),
      }),
      email: Yup.string().when([], {
        is: () => !!uiCustomizations[5]?.displayInUi,
        then: () =>
          Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        otherwise: () => Yup.string(),
      }),
      organization: Yup.string(), // No conditions applied
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log("Submitted values: ", values);
      handleProceed();
    },
  });

  const navigate = useNavigate();
  const sidebarOpen = useSelector((state) => state?.ManchSlice?.sidebarOpen);
  const handleChange = (event) => {
    setOtpType(event.target.value);
  };

  const handleChangeTemplate = (event) => {
    console.log(event, "eventevent");
    setAge(event?.target?.value);
  };

  // Encryption function
  const encryptData = (data) => {
    const secret = "dev.manchtech.com"; // Secret key
    const encryptedData = CryptoJS.AES.encrypt(data, secret);
    return encryptedData.toString(); // Convert to string
  };

  const companyFeature = useSelector(
    (e) => e?.ManchSlice?.companyFeature?.companyFeatureValue
  );
  const fillTxnSuccess = useSelector(
    (state) => state?.ManchSlice?.fillByTxnurl?.fillTxnSuccess
  );
  const allDocumentsSuccess = useSelector(
    (state) => state.ManchSlice?.allDocument?.allDocumentValue?.templateId
  );
  const uiCustomizations = useSelector(
    (e) => e?.ManchSlice?.template?.templateValue?.uiCustomizations
  );
  const TEMPID = useSelector(
    (e) => e?.ManchSlice?.selectedTemplateIdWithTMPT?.TempId
  );
  const TemplateIdWithoutTMPT = useSelector(
    (e) => e?.ManchSlice?.selectedTemplateIdWithoutTMPT?.TempId
  );
  const selectedTemplate = useSelector(
    (e) => e?.ManchSlice?.selectedTemplateName?.Name
  );

  const txnURL = useSelector(
    (e) => e?.ManchSlice?.fill?.fillValue?.data?.txnURL
  );
  const firstPartyEmail = useSelector(
    (e) => e?.ManchSlice?.user?.userValue?.email
  );
  const stateValue = useSelector(
    (e) => e.ManchSlice.userForm.userFormValue.content
  );
  const companyId = useSelector(
    (state) => state?.ManchSlice?.user?.userValue?.companyId
  );
  const documents = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents || []
  );
  const firstDocumentName = documents?.[0]?.name || "Loading...";
  const firstDocumentAvailable = firstDocumentName !== "Loading...";
  const steps = [
    { id: 1, label: firstDocumentName },
    { id: 2, label: "Document List" },
    { id: 3, label: "Submission" },
  ];
  const adjustedSteps =
    documents.length === 1
      ? [
          { id: 1, label: firstDocumentName },
          { id: 2, label: "Submission" },
        ]
      : steps;
  // Validation function
  const activeStep = 0;
  const handleListItemClick = (id, name, key, dockTypeId) => {
    console.log(id, name, key, dockTypeId, "123");
    dispatch(storeDocumentIdWithoutTMPT(id));
    dispatch(storeSelectedIdWithTMPT(key));
    dispatch(storeSelectedName(name));
    dispatch(storeDocumentTypeId(dockTypeId));
    dispatch(getTemplate({ companyId, id }));
    dispatch(getcompanyFeature({ companyId, id }));
    console.log("id01", id);
    // debugger
  };
  console.log(firstPartyEmail, "firstPartyemail");
  const handleProceed = () => {
    const authModeConfig = uiCustomizations.find(
      (item) => item.internalName === "auth_mode_type" && !item.displayInUi
    );
    const msgConfig = uiCustomizations.find(
      (item) => item.internalName === "message" && !item.displayInUi
    );
    const authModeType = authModeConfig ? authModeConfig.defaultValue : otpType;
    const messageType = msgConfig
      ? msgConfig.defaultValue
      : formik.values.message;
    const data = {
      authModeType,
      expiresIn: 366,
      lockEsignMethodSelection: false,
      messageType,
      templateKey: TEMPID,
      title: formik.values.title,
    };
    console.log(data, "datatata");
    dispatch(postFillAction(data));

  setActive(!active)

  };
  useEffect(() => {
    if (txnURL) {
      const transactionId = txnURL.split("/").pop(); // "47520"
      dispatch(getAllDocument(transactionId));
      console.log(firstPartyEmail, "firstPartyEmail");
      console.log(formik.values.name, "name");
      console.log(formik.values.mobileNo, "mobileNo");
      console.log(formik.values.email, "form.email");
      console.log(formik.values.organization, "form.organization");

      // Encrypting firstParty and secondParty data
      const encryptedFirstPartyEmail = encryptData(firstPartyEmail);
      const encryptedSecondPartyName = encryptData(formik.values.name);
      const encryptedSecondPartyMobile = encryptData(formik.values.mobileNo);
      const encryptedSecondPartyEmail = encryptData(formik.values.email);
      const encryptedSecondPartyOrganization = encryptData(
        formik.values.organization
      );

      // const dataOfPut = {
      //   firstParty: {
      //     email: encryptedFirstPartyEmail,
      //   },
      //   secondParty: {
      //     name: encryptedSecondPartyName,
      //     mobile: encryptedSecondPartyMobile,
      //     email: encryptedSecondPartyEmail,
      //     organization: encryptedSecondPartyOrganization,
      //   },
      //   title: form.title,
      //   message: form.message,
      //   lockEsignMethodSelection: false,
      //   authModeType: otpType,
      //   // dataHash: null,
      // };
      const dataOfPut = {
        firstParty: {
          email: firstPartyEmail,
        },
        secondParty: {
          name: formik.values.name ? formik.values.name : "name",
          mobile: formik.values.mobileNo ? formik.values.mobileNo : "mobile",
          email: formik.values.email ? formik.values.email : "email",
          organization: formik.values.organization,
        },
        title: formik.values.title ? formik.values.title : "title",
        message: formik.values.message
          ? formik.values.message
          : "Please Review It",
        lockEsignMethodSelection: false,
        authModeType: otpType,
        dataHash: null,
      };
      console.log("data of put transaction", dataOfPut);
      console.log("transactionId", transactionId);

      dispatch(putFillTxnAction({ transactionId, dataOfPut }));
    }
  }, [txnURL]);
  useEffect(() => {
    // Set the default OTP type when companyFeature is populated
    if (companyFeature.length > 0 && !otpType) {
      setOtpType(companyFeature[0]?.featureName);
    }
  }, [companyFeature]);
  console.log(active,"activeactive")
  useEffect(() => {
    console.log(fillTxnSuccess, allDocumentsSuccess,isMobile, "zxcvbgtrew");
    console.log(
      fillTxnSuccess === true && allDocumentsSuccess !== undefined,
      "zxcvbgtrew"
    );
    console.log(
      fillTxnSuccess === true && allDocumentsSuccess !== undefined && isMobile,
      "zxcvbgtrew"
    );

    if (fillTxnSuccess === true && allDocumentsSuccess !== undefined &&
      isMobile) {
      const transactionId = txnURL.split("/").pop();
      console.log(transactionId, "ext");
      dispatch(getTemplateInfo(transactionId));
      setOpen(true);
     
        // navigate(
        //   `/onboardingRequest/template/${TemplateIdWithoutTMPT}/transaction/${transactionId}`
        // ); // Use absolute path
  
    } else if (
      fillTxnSuccess === true &&
      allDocumentsSuccess !== undefined && !isMobile
    ) {
      const transactionId = txnURL.split("/").pop();
      console.log(transactionId, "ext");
      dispatch(getTemplateInfo(transactionId));
      setOpen(true);
     
        navigate(
          `/onboardingRequest/template/${TemplateIdWithoutTMPT}/transaction/${transactionId}`
        ); // Use absolute path
  

    }
  }, [fillTxnSuccess, allDocumentsSuccess, navigate,active]);

  useEffect(() => {
    if (selectedTemplate) {
      setAge(selectedTemplate);
    }
  }, [selectedTemplate]);
  const displayItems = stateValue && stateValue.length > 0 ? stateValue : [];
  const isMobile = width <= 500;
const startBtnHandler=()=>{
  console.log("startbtnhandler")
  const transactionId = txnURL.split("/").pop();
  navigate(
    `/onboardingRequest/template/${TemplateIdWithoutTMPT}/transaction/${transactionId}`
  );
  setOpen(false)
}
const topStyle = 
  documents?.some((doc) => doc.formFillEnabled === false)
    ? "45px"
    : "90px"
;
  return (
    <div
      className="formClass"
      style={{
        paddingLeft: sidebarOpen ? "80px" : "0px",
        height: "100%",
        backgroundColor: "#f4f8fb",
        overflowY: "hidden",
      }}
    >
      <Box className="background">
        <form onSubmit={formik.handleSubmit}>
          <Box className="insideForm">
            <Box className="content">
              <Box className="menuItem">
                {isMobile ? (
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Signature Template
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        variant="standard"
                        onChange={handleChangeTemplate}
                      >
                        {" "}
                        {displayItems?.map((item) => (
                          <MenuItem
                            value={item.name}
                            key={item.id}
                            onClick={() =>
                              handleListItemClick(
                                item.id,
                                item.name,
                                item.key,
                                item.topDocumentTypeId
                              )
                            }
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <Typography
                    variant="formHeader"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {" "}
                    {selectedTemplate}
                  </Typography>
                )}
              </Box>
              <Box className="titleAndMessage">
                <Box>
                  {" "}
                  {uiCustomizations && uiCustomizations[0]?.displayInUi ? (
                    <TextField
                      id="title"
                      name="title"
                      label={uiCustomizations[0]?.displayName
                        ?.toLowerCase()
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                      variant="standard"
                      required={true}
                      error={formik.touched.title && !!formik.errors.title}
                      helperText={formik.touched.title && formik.errors.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      sx={{ width: "31.25vw" }}
                      InputLabelProps={{
                        sx: {
                          "& .MuiFormLabel-asterisk": {
                            color: "red",
                          },
                        },
                      }}
                    />
                  ) : null}
                </Box>
                {uiCustomizations && uiCustomizations[1]?.displayInUi ? (
                  <TextField
                    id="message"
                    name="message"
                    label={uiCustomizations[1]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    variant="standard"
                    required={true}
                    error={formik.touched.message && !!formik.errors.message}
                    helperText={formik.touched.message && formik.errors.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    sx={{ width: "31.25vw" }}
                    InputLabelProps={{
                      sx: {
                        "& .MuiFormLabel-asterisk": {
                          color: "red",
                        },
                      },
                    }}
                  />
                ) : null}
              </Box>
              <Box mt={5}>
                {uiCustomizations && uiCustomizations[2]?.displayInUi ? (
                  <Typography variant="formSecondary">
                    {uiCustomizations[2]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </Typography>
                ) : null}
              </Box>
              <Box className="titleAndMessage">
                {uiCustomizations && uiCustomizations[3]?.displayInUi ? (
                  <TextField
                    id="name"
                    name="name"
                    label={uiCustomizations[3]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    variant="standard"
                    required={true}
                    error={formik.touched.name && !!formik.errors.name}
                    helperText={formik.touched.name && formik.errors.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    sx={{ width: "31.25vw" }}
                    InputLabelProps={{
                      sx: {
                        "& .MuiFormLabel-asterisk": {
                          color: "red",
                        },
                      },
                    }}
                  />
                ) : null}
                {uiCustomizations && uiCustomizations[4]?.displayInUi ? (
                  <TextField
                    id="standard-basic"
                    name="mobileNo"
                    label={uiCustomizations[4]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    variant="standard"
                    required={true}
                    error={formik.touched.mobileNo && !!formik.errors.mobileNo}
                    helperText={
                      formik.touched.mobileNo && formik.errors.mobileNo
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobileNo}
                    sx={{ width: "31.25vw" }}
                    InputLabelProps={{
                      sx: {
                        "& .MuiFormLabel-asterisk": {
                          color: "red",
                        },
                      },
                    }}
                  />
                ) : null}
              </Box>
              <Box className="titleAndMessage">
                {uiCustomizations && uiCustomizations[5]?.displayInUi ? (
                  <TextField
                    id="standard-basic"
                    name="email"
                    label={uiCustomizations[5]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    variant="standard"
                    required={true}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    sx={{ width: "31.25vw" }}
                    InputLabelProps={{
                      sx: {
                        "& .MuiFormLabel-asterisk": {
                          color: "red",
                        },
                      },
                    }}
                  />
                ) : null}
                {uiCustomizations && uiCustomizations[6]?.displayInUi ? (
                  <TextField
                    id="organization"
                    name="organization"
                    label={uiCustomizations[6]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    variant="standard"
                    required={false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.organization}
                    sx={{ width: "31.25vw" }}
                  />
                ) : null}
              </Box>
              {uiCustomizations && uiCustomizations[7]?.displayInUi ? (
                <FormControl
                  variant="standard"
                  sx={{ mt: "30px", minWidth: 120,width: "31.25vw" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    {uiCustomizations[7]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={otpType}
                    onChange={handleChange}
                    label="Otp"
                    required={true}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {companyFeature.map((element) => {
                      return (
                        <MenuItem value={element.featureName} key={element.id}>
                          {element.displayName
                            ?.toLowerCase()
                            .replace(/\b\w/g, (c) => c.toUpperCase())}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* {errors.authModeType && <Typography color="error">{errors.authModeType}</Typography>} */}
                </FormControl>
              ) : null}
              <Box className="btnBox">
                <Button variant={isMobile?"mobiledark":"dark"} type="submit">
                  Proceed
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: 241, // Adjust height as needed
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "100%", p: 2 }}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<VerticalConnector />}
            >
              {adjustedSteps.map((step, index) => (
                <Step key={step.id}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <CustomStepIcon
                        {...props}
                        icon={step.id}
                        firstDocumentAvailable={firstDocumentAvailable}
                      />
                    )}
                  >
                    <Typography
                      sx={{
                        color: index === activeStep ? "#393e46" : "#898d90",
                        fontWeight: index === activeStep ? "bold" : "normal",
                      }}
                    >
                      {step.label}
                    </Typography>
                    <Box>
                      {index === 0 && (
                        <Button
                          // onClick={() => setOpen(false)}
                          onClick={startBtnHandler}
                          sx={{
                            position: "absolute",
                            right: "-4px",
                            top: topStyle,
                            textTransform: "none",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                          >
                          Start
                        </Button>
                      )}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default Transaction;
