import { Box, Typography } from "@mui/material";
import Card from "./Card";
import React, { useContext } from "react";
import { FormBuilderContext } from "../../FormBuilder";

const index = () => {
  const value: any = useContext(FormBuilderContext);
  const { smartSuggestion } = value;
  return (
    <Box sx={{ width: "100%", padding: "1rem" }}>
      <Typography
        variant="body1"
        sx={{
          fontSize: "16px",
          color: "#4f4f4f",
          fontWeight: 500,
          margin: "1rem 0 0 0",
        }}
      >
        Smart Suggestions
      </Typography>
      {smartSuggestion?.map((e: any, index: number) => (
        <Card particularRuleJson={e} index={index} />
      ))}
    </Box>
  );
};

export default index;
