import * as React from "react"
const Done = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg"
   width={30} height={30} {...props}>
    <g data-name="Group 4237" transform="translate(-.229 -.229)">
      <circle
        cx={15}
        cy={15}
        r={15}
        data-name="Ellipse 12"
        style={{
          fill: "#3f5fff",
        }}
        transform="translate(.229 .229)"
      />
      <path
        d="m216.689 168-9.284 9.285-5.272-5.273-1.474 1.474 6.746 6.746 10.759-10.756z"
        data-name="Path 286"
        style={{
          fill: "#fff",
          fillRule: "evenodd",
        }}
        transform="translate(-193.891 -158.693)"
      />
    </g>
  </svg>
)
export default Done
