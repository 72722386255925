import React, { FunctionComponent } from "react";
import logo from "../assets/logo/logo.png";

interface NavbarProps {
  window?: () => Window;
}

const Navbar: FunctionComponent<NavbarProps> = () => {
  const domainUrl = process.env.REACT_APP_MAIN_URL;
  const mainUrl = window.sessionStorage.getItem("mainUrl");
  const diy1Url = domainUrl ? domainUrl + mainUrl : "";

  const reDirectToMainSite = () => {
    window.location.href = diy1Url;
  };

  return (
    <header
      id="header"
      className="header-sticky sticky-active"
      data-fullwidth="true"
    >
      <img
        src={logo}
        alt=""
        style={{
          width: "10rem",
          height: "3rem",
          margin: "0 0 0 3rem",
          cursor: "pointer",
        }}
        onClick={reDirectToMainSite}
      />
    </header>
  );
};

export default Navbar;
