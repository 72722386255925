import { commonAxios } from "./CommonAxios";
import { GET } from "./ApiType";
import { setLastApiCallTime } from "./ApiCallTime";

type AxiosGetProps = {
  endPoint: string;
  toCheck?: boolean;
  isImage?: boolean;
};

export const AxiosGet = async (props: AxiosGetProps) => {
  const { endPoint, toCheck, isImage } = props;
  try {
    const res = await commonAxios({
      method: GET,
      url: endPoint,
      responseType: isImage ? "blob" : "json",
    });
    // Update the last API call time
    if (!toCheck) {
      setLastApiCallTime(new Date());
    }
    return res;
  } catch (err) {
    throw err;
  }
};
