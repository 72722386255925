import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import { Box, Typography, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PdfIcon from "../../../../assets/icons/FormFillIcons/Pdf"; // Assuming this is the correct path
import "./PdfViewer.scss"; // Import your styles
import useWindowSize from "../Inner/useWindowSize";
import { useSwipeable } from "react-swipeable";
const PdfViewer = ({ pdfUrl }) => {
  const [width] = useWindowSize();
  const isMobile = width <= 500;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const goToPrevPage = () => setPageNumber(prev => (prev > 1 ? prev - 1 : 1));
  const goToNextPage = () => setPageNumber(prev => (prev < numPages ? prev + 1 : numPages));
  const goToPage = (page) => setPageNumber(page);
  const handlers = useSwipeable({
    onSwipedLeft: goToNextPage,
    onSwipedRight: goToPrevPage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  return (
    <>{isMobile? 
      <Box {...handlers} className="mobilePdfContainer">
      <Box className="pdfWrapper">
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={340} // Adjust size as needed
          />
        </Document>
      </Box>

      {/* Navigation Controls */}
      <Box className="mobileNav">
        <IconButton onClick={goToPrevPage} disabled={pageNumber === 1}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="body2">
          Page {pageNumber} of {numPages}
        </Typography>
        <IconButton onClick={goToNextPage} disabled={pageNumber === numPages}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
      :
    <Box className="pdfViewerClass">
      <Box className="pdfContainer">
        {/* Sidebar for page numbers */}
        <Box className="pagesSidebar">
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <div
                key={index}
                className={`thumbnail ${index + 1 === pageNumber ? "active" : ""}`}
                onClick={() => goToPage(index + 1)}
              >
                {/* Render a small preview of each page */}
                <Page
                  pageNumber={index + 1}
                  width={231} 
                  renderAnnotationLayer={false}
                />
              </div>
            ))}
          </Document>
        </Box>

        {/* Main PDF Viewer */}
        <Box className="pdfContent">
          <Box className="pdfContentHeader">
            <Box className="first">
              <PdfIcon />
              <Typography variant="body1">Document Name</Typography>
            </Box>
            <Box className="second">
              <ChevronLeftIcon onClick={goToPrevPage} style={{ cursor: "pointer" }} />
              <Typography variant="body2">Page {pageNumber} of {numPages}</Typography>
              <ChevronRightIcon onClick={goToNextPage} style={{ cursor: "pointer" }} />
            </Box>
            <Box className="third">
              <Typography variant="body2">Select document</Typography>
              <KeyboardArrowDownIcon />
            </Box>
          </Box>

          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
          </Document>

        
        </Box>
      </Box>
    </Box>}
    </>
  );
};

export default PdfViewer;
