import React, { useContext } from "react";
import { DeleteIcon } from "../../assets/icons";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import { Box, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormBuilderContext } from "../FormBuilder/FormBuilder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const DisplayComponent = (props: any) => {
  const {
    component,
    isSelected,
    handleDeleteControl,
    item,
    special,
    isArray = false,
    isArrayItem = false,
  } = props;

  const value: any = useContext(FormBuilderContext);

  const { dirtyRecord } = value;

  let record;
  for (let i = 0; i < dirtyRecord?.length; i++) {
    record = dirtyRecord[i]?.children?.find(
      (e: any) => e?.childrenId === item?.id
    )?.rule;
    if (record) {
      break;
    }
  }

  return (
    <div
      style={{
        borderBottom: isArrayItem
          ? "none"
          : `1px solid ${
              isSelected ? "#318BE3" : !special && "rgba(0,0,0,0.25)"
            }`,

        width: "100%",
      }}
      className="w-100 d-flex align-items-center justify-content-between"
    >
      <div
        style={{
          width: "100%",
          margin: isSelected ? "0 1rem 0 0" : "0",
          display: "flex",
          alignItems: "center",
          padding: isArray ? "0 0 1rem 0" : "initial",
        }}
      >
        {/* {isHover && <div
          style={{ cursor: "grab", margin: "0 8px 8px 0" }}
          className="d-flex"
        >
          <i className="fa fa-ellipsis-v" color="#4f4f4f"></i>
          <i className="fa fa-ellipsis-v" color="#4f4f4f"></i>
        </div>} */}
        {(record?.some((e: any) => e?.sourceIds?.length > 0) ||
          record?.some((e: any) => e?.destinationIds?.length > 0)) && (
          <Tooltip
            title={`Some Mandatory fields of Rules are missing`}
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#FFF",
                  color: "red",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                  fontSize: "12px",
                  border: "1px solid red",
                },
              },
            }}
          >
            <InfoOutlinedIcon
              sx={{
                width: "1.25rem",
                margin: "0 0.5rem 0.65rem 0",
                cursor: "pointer",
                color: "red",
              }}
            />
          </Tooltip>
        )}
        <div className="w-100">{component}</div>
      </div>
      {isSelected && !isArrayItem && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div onClick={handleDeleteControl} style={{ margin: "0 0.5rem 0 0" }}>
            <DeleteIcon />
          </div>

          <ArrowForwardIosIcon
            sx={{
              color: "rgb(79, 79, 79)",
              fontSize: "1rem",
              margin: "0 -0.5rem 0 0",
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default DisplayComponent;
