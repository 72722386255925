import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { CircularProgress, Box } from "@mui/material";

interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {}

const ListBox = forwardRef(function ListBoxBase(
  props: any,
  ref: ForwardedRef<HTMLUListElement>
) {
  const { children, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(
    ref,
    () => innerRef.current
  );

  return (
    <ul {...rest} ref={innerRef} role="list-box">
      {children}
      {props?.loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" size={20} />
        </Box>
      )}
    </ul>
  );
});

export default ListBox;

type NullableUlElement = HTMLUListElement | null;
