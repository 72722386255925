import { AxiosGet } from "../api";

export const getCompanyDetails = async () => {
  const endPoint = "/user/who-am-i";
  try {
    const response = await AxiosGet({ endPoint });
    const data = response?.data?.companyId;
    window.sessionStorage.setItem("companyId", data);
  } catch (err) {}
  AxiosGet({ endPoint });
};
