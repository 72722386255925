import React, { Dispatch, FC, useContext, useEffect, useState } from "react";
import {
  FormLayoutComponentChildrenType,
  FormLayoutComponentContainerType,
  ValidationDataType,
} from "../../../types/FormTemplateTypes";
import {
  Button,
  FormControl,
  FormControlLabel,
  Autocomplete,
  TextField,
  Radio,
  RadioGroup,
  MenuItem,
  Skeleton,
  List,
  CircularProgress,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import {
  FormControlNames,
  FormItemTypes,
} from "../../../utils/formBuilderUtils";
import { FormLayoutComponentsType } from "../../../types/FormTemplateTypes";
import _ from "lodash";
import CheckIcon from "@mui/icons-material/Check";
import { AxiosGet, AxiosPatch, AxiosPost } from "../../../api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Padding } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import ListBox from "./ListBox";
import { isEditable } from "@testing-library/user-event/dist/utils";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { styled } from "@mui/material/styles";
import { FormBuilderContext } from "../FormBuilder";
import {
  yesNo,
  fontStyle,
  fontSizeArr,
  groupType,
  startFrom,
} from "../../../assets/properties";

export const textboxStyle = {
  minWidth: "100%",
  maxWidth: "100%",
  marginTop: 10,
};

export const fontSize = { fontSize: "16px" };

export const subHeading = {
  fontSize: "16px",
  lineHeight: 1.25,
  color: "#4f4f4f",
};

interface EditPropertiesComponentProps {
  selectedControl?:
    | undefined
    | FormLayoutComponentChildrenType
    | FormLayoutComponentContainerType;
  selectControl?: (
    layout:
      | FormLayoutComponentChildrenType
      | FormLayoutComponentContainerType
      | undefined
  ) => void;
  editControlProperties: (
    updatedItem: FormLayoutComponentChildrenType,
    type: string
  ) => void;
  editContainerProperties: (
    updatedItem: FormLayoutComponentContainerType
  ) => void;
  formLayoutComponents: FormLayoutComponentsType[];
  moveControlFromSide: (
    selectedControl: FormLayoutComponentChildrenType,
    moveControlObj: FormLayoutComponentChildrenType
  ) => void;
  gridValue: number | undefined;
  isCalled: boolean;
  validationData: ValidationDataType[];
  isValidationEdited: boolean;
  fromValidationSearch: boolean;
  setFromValidationSearch: Dispatch<boolean>;
  setPage: Dispatch<number>;
  validationInput: string | undefined | null;
  setValidationInput: Dispatch<string | undefined>;
  selectedValidation: any;
  setSelectedValidation: any;
  selectedApiValidation: any;
  setSelectedApiValidation: any;
  setValidationData: any;
  getSelectedTemplateValidationData: any;
  addValidation: any;
  setAddValidation: any;
  validationFormData: any;
  setValidationFormData: any;
  validationType: any;
  setValidationType: any;
  prevValue: any;
  fetchOptions: any;
  inputRef: any;
}

interface alertType {
  show: boolean;
  message: string;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const EditPropertiesComponent: FC<EditPropertiesComponentProps> = (
  props: any
) => {
  const {
    selectedControl,
    editControlProperties,
    editContainerProperties,
    gridValue,
    isCalled,
    validationData,
    setValidationData,
    selectedValidation,
    setSelectedValidation,
    isValidationEdited,
    selectedApiValidation,
    setSelectedApiValidation,
    getSelectedTemplateValidationData,
    addValidation,
    setAddValidation,
    validationFormData,
    setValidationFormData,
    validationType,
    setValidationType,
    fromValidationSearch,
    setFromValidationSearch,
    validationInput,
    setValidationInput,
    setPage,
    prevValue,
    fetchOptions,
    inputRef,
  } = props;
  const [updatedItem, setUpdatedItem] = useState<any>({});

  const childUpdatedItem = updatedItem as FormLayoutComponentChildrenType;
  const containerUpdatedItem = updatedItem as FormLayoutComponentContainerType;

  const values: any = useContext(FormBuilderContext);

  const { leftBarFields } = values;

  const [fieldProperties, setFieldPropperties] = useState({
    isMandatory: false,
    isEditable: false,
    isVisible: true,
  });

  const [type, setType] = useState<undefined | string>(undefined);
  const [alertShow, setAlertShow] = useState<alertType>({
    show: false,
    message: "",
  });

  useEffect(() => {
    if (selectedControl) {
      setType(undefined);
      if ((selectedControl as FormLayoutComponentChildrenType).items) {
        setUpdatedItem({
          ...selectedControl,
          items: JSON.parse(
            JSON.stringify(
              (selectedControl as FormLayoutComponentChildrenType).items
            )
          ),
        });
      } else {
        setUpdatedItem({ ...selectedControl });
      }
    }
  }, [selectedControl]);

  useEffect(() => {
    setFieldPropperties({
      isMandatory:
        childUpdatedItem?.mandatory !== undefined
          ? childUpdatedItem?.mandatory
          : false,
      isVisible:
        childUpdatedItem?.visible !== undefined
          ? childUpdatedItem?.visible
          : true,
      isEditable:
        childUpdatedItem?.editable !== undefined
          ? childUpdatedItem?.editable
          : false,
    });
  }, [childUpdatedItem]);

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { name, value } = e.target;
    setUpdatedItem((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (event: any, val: string) => {
    setFromValidationSearch(false);
    if (event?.type === "change") {
      setValidationInput(val);
      setFromValidationSearch(true);
    }
  };

  const handleMandatory = (type: string) => {
    setType("container");
    if (type === "request") {
      setFieldPropperties((prev) => ({
        ...prev,
        isMandatory: true,
        isEditable: false,
      }));
    } else if (type === "other") {
      setFieldPropperties((prev) => ({
        ...prev,
        isMandatory: true,
        isEditable: true,
      }));
    } else if (type === "not") {
      setFieldPropperties((prev) => ({
        ...prev,
        isMandatory: false,
        isEditable: false,
      }));
    }
  };

  const handleVisible = (type: boolean) => {
    setType("container");
    setFieldPropperties((prev) => ({ ...prev, isVisible: type }));
  };

  const onFormSubmit = () => {
    const finalData = {
      ...updatedItem,
      mandatory: fieldProperties.isMandatory,
      visible: fieldProperties.isVisible,
      editable: fieldProperties.isEditable,
      isUpdated: true,
    };
    editControlProperties(
      finalData as FormLayoutComponentChildrenType,
      "change"
    );
  };

  const onContainerFormSubmit = () => {
    editContainerProperties(updatedItem as FormLayoutComponentContainerType);
  };

  const handleValidationChange = (_: any, value: any) => {
    if (value === null) {
      setSelectedApiValidation(null);
    }
    setSelectedValidation(value);
    setFromValidationSearch(false);
  };

  const onChangeValidationData = (e: any) => {
    setValidationFormData((pre: any) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  const validationSubmit = async (event: any) => {
    const endPoint = "v2/form-validation";

    if (
      !Object.keys(validationFormData)?.length ||
      validationFormData?.name === "" ||
      !Object.keys(validationFormData).includes("name")
    ) {
      setAlertShow({
        show: true,
        message: "name field is mandatory",
      });
    } else if (
      (validationFormData?.regex === "" ||
        !Object.keys(validationFormData).includes("regex")) &&
      (validationFormData?.maxLength === "" ||
        !Object.keys(validationFormData).includes("maxLength")) &&
      (validationFormData?.minLength === "" ||
        !Object.keys(validationFormData).includes("minLength"))
    ) {
      setAlertShow({
        show: true,
        message: "fill either one field regex,max length or min length",
      });
    } else {
      setAlertShow((prev) => ({
        ...prev,
        show: false,
      }));

      try {
        const res = await AxiosPost({
          endPoint,
          payload: validationFormData,
        });

        //Validation Logic
        const endPointValidation: string = "v2/form-fill-metadata-validation";
        const payloadValidation = {
          formFillMetadataValidationId: selectedControl?.id,
          formValidationId: res?.data?.id,
        };

        if (isValidationEdited) {
          const selectedTemplateValidationEndPoint = `/v2/form-fill-metadatas/${selectedControl?.id}/validations`;

          const response = await AxiosGet({
            endPoint: selectedTemplateValidationEndPoint,
          });
          const validation = response?.data?.formValidations[0] ?? null;

          const end = `${endPointValidation}/${validation?.formFillMetadataValidationId}`;
          await AxiosPatch({ endPoint: end, payload: payloadValidation });
        } else {
          await AxiosPost({
            endPoint: endPointValidation,
            payload: payloadValidation,
          });
        }
        isCalled;

        getSelectedTemplateValidationData({ templateId: selectedControl?.id });
        setPage(0);
        setAddValidation(false);
        fetchOptions("scroll");
      } catch (err) {}
    }
  };

  const handleScroll = (event: any) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom) {
      fetchOptions("scroll");
    }
  };

  const fieldType = (_: any, val: any, name: string) => {
    isCalled;
    let res: any;
    if (name === "type") {
      res = val?.name;
    } else if (name === "pdfFill") {
      res = val === "Yes" ? true : false;
    } else {
      res = val;
    }
    setUpdatedItem((prevState: any) => ({
      ...prevState,
      [name]: res,
    }));
  };

  useEffect(() => {
    if (updatedItem?.id && selectedControl && type) {
      if (type === "panel") {
        onContainerFormSubmit();
      } else if (type === "container") {
        onFormSubmit();
      }
    }
  }, [updatedItem, fieldProperties, type]);

  useEffect(() => {
    if (selectedValidation || selectedValidation === null) {
      const finalData = {
        ...updatedItem,
        mandatory: fieldProperties.isMandatory,
        visible: fieldProperties.isVisible,
        isUpdated: true,
        formFillValidation: selectedValidation,
        isValidationUpdate: selectedApiValidation !== null ? true : false,
      };
      editControlProperties(
        finalData as FormLayoutComponentChildrenType,
        "change"
      );
    }
  }, [selectedValidation]);

  useEffect(() => {
    if (validationInput !== undefined && fromValidationSearch) {
      const timeId = setTimeout(() => {
        fetchOptions("input");
      }, 1200);

      return () => clearTimeout(timeId);
    }
    if (!fromValidationSearch) {
      setValidationData(prevValue);
    }
  }, [validationInput, fromValidationSearch]);

  useEffect(() => {
    if (selectedApiValidation) {
      setValidationType("select");
    }
  }, [selectedApiValidation]);

  return (
    <>
      {selectedControl ? (
        <>
          {containerUpdatedItem.type === FormItemTypes.CONTAINER ? (
            <div className="main-form" style={{ margin: "0 16px" }}>
              <TextField
                label="Container Heading"
                name="name"
                value={containerUpdatedItem.name}
                onChange={(e) => {
                  handleChange(e), setType("panel");
                }}
                style={{ ...textboxStyle }}
                className="mb-3"
                variant="standard"
                InputLabelProps={{
                  sx: {
                    fontSize: "16px",
                  },
                  shrink: containerUpdatedItem.name ? true : false,
                }}
                inputRef={inputRef}
              />
            </div>
          ) : (
            <div
              className="main-form m-0"
              style={{ minWidth: "100%", padding: "0 16px" }}
            >
              <TextField
                label="Field Label Name"
                name="name"
                value={childUpdatedItem.name}
                onChange={(e) => {
                  handleChange(e), setType("container");
                }}
                style={textboxStyle}
                className="mb-3"
                variant="standard"
                InputLabelProps={{
                  sx: {
                    fontSize: "16px",
                  },
                  shrink: childUpdatedItem.name ? true : false,
                }}
                inputRef={inputRef}
              />

              {(childUpdatedItem.type === FormControlNames.INPUTTEXTFIELD ||
                childUpdatedItem.type === FormControlNames.INPUTMULTILINE ||
                childUpdatedItem.type ===
                  FormControlNames.INPUTNUMBERFIELD) && (
                //   ||
                // childUpdatedItem.type === FormControlNames.CHECKBOX ||
                // childUpdatedItem.type === FormControlNames.BUTTON

                <TextField
                  label={
                    childUpdatedItem.type === FormControlNames.BUTTON
                      ? "Button Name"
                      : "Field Placeholder"
                  }
                  name="placeholder"
                  value={childUpdatedItem?.placeholder}
                  onChange={(e) => {
                    handleChange(e), setType("container");
                  }}
                  style={textboxStyle}
                  className="mb-3"
                  variant="standard"
                  InputLabelProps={{
                    sx: {
                      fontSize: "16px",
                    },
                  }}
                />
              )}

              <Autocomplete
                value={
                  leftBarFields
                    ?.filter((e: any) => e?.group === "STD")
                    ?.find((e: any) => e?.name === childUpdatedItem?.type) ||
                  null
                }
                sx={{ margin: "10px 0" }}
                fullWidth
                options={leftBarFields?.filter((e: any) => e?.group === "STD")}
                getOptionLabel={(option) => option?.meta?.properties?.label}
                onChange={(e, val) => {
                  fieldType(e, val, "type"), setType("container");
                }}
                popupIcon={<KeyboardArrowDownIcon />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Select" + childUpdatedItem?.type}
                    variant="standard"
                    label="Field Type"
                    required={true}
                    name="type"
                  />
                )}
              />

              {selectedControl?.type !== "LABEL" && (
                <>
                  <div className="m-t-30">
                    <Box sx={{ display: "flex" }}>
                      <h6 style={subHeading}>Mandatory Settings</h6>
                      <CustomWidthTooltip
                        title={
                          <div>
                            <span style={{ fontWeight: 600 }}>
                              Requester Mandatory :
                            </span>{" "}
                            mandatory - true | editable - false
                            <br />
                            <span style={{ fontWeight: 600 }}>
                              Other Mandatory :
                            </span>{" "}
                            mandatory - true | editable - true <br />
                            <span style={{ fontWeight: 600 }}>
                              Not Mandatory :
                            </span>{" "}
                            mandatory - false | editable - false
                          </div>
                        }
                        placement="top-start"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#4f4f4f",
                              color: "#FFF",
                              boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                              fontSize: "14px",
                              padding: "1rem",
                            },
                          },
                        }}
                      >
                        <HelpOutlineOutlinedIcon
                          sx={{
                            width: "1rem",
                            margin: "0 0 0 0.25rem",
                            cursor: "pointer",
                          }}
                        />
                      </CustomWidthTooltip>
                    </Box>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        name="required"
                        control={
                          <Radio
                            checked={
                              fieldProperties?.isMandatory &&
                              !fieldProperties?.isEditable
                            }
                            onChange={() => handleMandatory("request")}
                          />
                        }
                        label="Requester Mandatory"
                      />
                      <FormControlLabel
                        name="required"
                        control={
                          <Radio
                            checked={
                              fieldProperties?.isMandatory &&
                              fieldProperties?.isEditable
                            }
                            onChange={() => handleMandatory("other")}
                          />
                        }
                        label="Other Mandatory"
                      />
                      <FormControlLabel
                        name="required"
                        control={
                          <Radio
                            checked={
                              !fieldProperties?.isMandatory &&
                              !fieldProperties?.isEditable
                            }
                            onChange={() => handleMandatory("not")}
                          />
                        }
                        label="Not Mandatory"
                      />
                    </RadioGroup>
                  </div>
                  <div className="m-t-10">
                    <h6 style={subHeading}>Display in Form</h6>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={
                          <Radio
                            checked={fieldProperties?.isVisible}
                            onChange={() => handleVisible(true)}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="male"
                        control={
                          <Radio
                            checked={!fieldProperties?.isVisible}
                            onChange={() => handleVisible(false)}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                  {/* {childUpdatedItem.controlName ===
                  FormControlNames.RADIOGROUP ||
                childUpdatedItem.controlName ===
                  FormControlNames.SELECTDROPDOWN ||
                childUpdatedItem.controlName ===
                  FormControlNames.SEARCHABLEDROPDOWN ||
                childUpdatedItem.controlName ===
                  FormControlNames.MULTIPLESELECTDROPDOWN ||
                childUpdatedItem.controlName ===
                  FormControlNames.CHECKLIST ? (
                  <>
                    <h6 style={subHeading} className="mb-3">
                      List Items
                    </h6>
                    <ManageItemsListComponent
                      addItemInList={addItemInList}
                      editIteminList={editIteminList}
                      deleteItemFromList={deleteItemFromList}
                      items={childUpdatedItem.items}
                    />
                  </>
                ) : null} */}

                  <TextField
                    label="Grid Value"
                    name="gridValue"
                    value={isCalled ? childUpdatedItem.gridValue : gridValue}
                    //value={childUpdatedItem.gridValue ?? gridValue}
                    style={{ ...textboxStyle, marginTop: 0 }}
                    className="mb-3"
                    type="number"
                    disabled
                    variant="standard"
                    InputLabelProps={{
                      sx: {
                        fontSize: "16px",
                      },
                    }}
                  />

                  <div className="m-t-5">
                    <h6 style={subHeading}>Validation</h6>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="f"
                        control={
                          <Radio
                            checked={validationType === "select"}
                            onChange={() => setValidationType("select")}
                          />
                        }
                        label="Select Validation"
                      />
                      <FormControlLabel
                        value="m"
                        control={
                          <Radio
                            checked={validationType === "create"}
                            onChange={() => setValidationType("create")}
                          />
                        }
                        label="Create Validation"
                      />
                    </RadioGroup>

                    {(validationType === "select" ||
                      validationType === "create") && (
                      <>
                        <FormControl style={{ minWidth: "100%" }}>
                          {validationType === "select" && (
                            <>
                              <Autocomplete
                                fullWidth
                                popupIcon={<KeyboardArrowDownIcon />}
                                options={validationData || []}
                                value={
                                  selectedValidation ?? selectedApiValidation
                                }
                                getOptionLabel={(option) => option?.name}
                                //inputValue={validationInput}
                                onInputChange={handleInputChange}
                                onChange={(event, val) =>
                                  handleValidationChange(event, val)
                                }
                                onBlur={() => setValidationData(prevValue)}
                                componentsProps={{
                                  popper: {
                                    placement: "top",
                                    modifiers: [
                                      {
                                        name: "flip",
                                        options: {
                                          fallbackPlacements: ["left"],
                                        },
                                      },
                                    ],
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Validation"
                                    variant="standard"
                                  />
                                )}
                                ListboxComponent={ListBox}
                                ListboxProps={{
                                  onScroll: handleScroll,
                                }}
                                // {(props) => (
                                //   <List {...props} onScroll={handleScroll} ref={listRef}>
                                //     {props.children}
                                //     {loading && (
                                //       <Box
                                //         sx={{
                                //           display: "flex",
                                //           justifyContent: "center",
                                //         }}
                                //       >
                                //         <CircularProgress color="inherit" size={20} />
                                //       </Box>
                                //     )}
                                //   </List>
                                // )}
                                filterOptions={(options, state) => options}
                              />

                              {selectedValidation && (
                                <div className="mt-3">
                                  <h6 style={subHeading}>Regex</h6>
                                  <div className="m-t-10">
                                    {selectedValidation?.regex}
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          {validationType === "create" && (
                            <>
                              {alertShow?.show && (
                                <Alert
                                  variant="outlined"
                                  severity="warning"
                                  onClose={() => {
                                    setAlertShow((prev) => ({
                                      ...prev,
                                      show: false,
                                    }));
                                  }}
                                  icon={false}
                                  sx={{ margin: "0 0 4px 0" }}
                                >
                                  <span
                                    style={{
                                      padding: 0,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {alertShow?.message}
                                  </span>
                                </Alert>
                              )}
                              <Box sx={{ margin: "-0.5rem 0 0 0" }}>
                                <TextField
                                  label="Name"
                                  name="name"
                                  style={{
                                    ...textboxStyle,
                                    // margin: "-0.15rem 0 0 0",
                                  }}
                                  className="mb-3"
                                  variant="standard"
                                  InputLabelProps={{
                                    sx: {
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={validationFormData?.name}
                                  onChange={onChangeValidationData}
                                  required={true}
                                />
                                <TextField
                                  label="Regex"
                                  name="regex"
                                  style={{ ...textboxStyle }}
                                  className="mb-3"
                                  variant="standard"
                                  InputLabelProps={{
                                    sx: {
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={onChangeValidationData}
                                  value={validationFormData?.regex}
                                />
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Min Length"
                                      name="minLength"
                                      style={{ ...textboxStyle }}
                                      className="mb-3"
                                      variant="standard"
                                      InputLabelProps={{
                                        sx: {
                                          fontSize: "14px",
                                        },
                                      }}
                                      onChange={onChangeValidationData}
                                      type="number"
                                      value={validationFormData?.minLength}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Max Length"
                                      name="maxLength"
                                      style={{ ...textboxStyle }}
                                      className="mb-3"
                                      variant="standard"
                                      InputLabelProps={{
                                        sx: {
                                          fontSize: "14px",
                                        },
                                      }}
                                      onChange={onChangeValidationData}
                                      type="number"
                                      value={validationFormData?.maxLength}
                                    />
                                  </Grid>
                                </Grid>
                                <Box sx={{ display: "flex" }}>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    className="text-capitalize mt-3"
                                    style={{
                                      ...fontSize,
                                      width: "12rem",
                                      height: "2.5rem",
                                      fontSize: "14px",
                                    }}
                                    //type="submit"
                                    onClick={validationSubmit}
                                  >
                                    Create Validation
                                  </Button>
                                </Box>
                              </Box>
                            </>
                          )}
                        </FormControl>
                      </>
                    )}
                  </div>

                  <div style={{ margin: "1.75rem 0 0 0" }}>
                    <h6 style={subHeading}>PDF Properties</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Autocomplete
                          value={childUpdatedItem?.pdfFill ? "Yes" : "No"}
                          fullWidth
                          options={yesNo}
                          getOptionLabel={(option) => option}
                          onChange={(e, val) => {
                            fieldType(e, val, "pdfFill"), setType("container");
                          }}
                          popupIcon={<KeyboardArrowDownIcon />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={"Select" + "Display in PDF"}
                              variant="standard"
                              label="Display in PDF"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Autocomplete
                          value={childUpdatedItem?.fontStyle}
                          fullWidth
                          options={fontStyle}
                          getOptionLabel={(option) => option}
                          onChange={(e, val) => {
                            fieldType(e, val, "fontStyle"),
                              setType("container");
                          }}
                          popupIcon={<KeyboardArrowDownIcon />}
                          disabled={childUpdatedItem?.pdfFill ? false : true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={"Select" + "Font Style"}
                              variant="standard"
                              label="Font Style"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Autocomplete
                          value={childUpdatedItem?.fontSize}
                          fullWidth
                          options={fontSizeArr}
                          getOptionLabel={(option: any) => option}
                          onChange={(e, val) => {
                            fieldType(e, val, "fontSize"), setType("container");
                          }}
                          popupIcon={<KeyboardArrowDownIcon />}
                          disabled={childUpdatedItem?.pdfFill ? false : true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={"Select" + "Font Size"}
                              variant="standard"
                              label="Font Size"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Display On Page"
                          name="page"
                          value={childUpdatedItem?.page}
                          type="number"
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          disabled={childUpdatedItem?.pdfFill ? false : true}
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter Display On Page"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Upper X-Coordinate"
                          name="upperLeftX"
                          value={childUpdatedItem?.upperLeftX}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter Upper X-Coordinate"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Upper Y-Coordinate"
                          name="upperLeftY"
                          value={childUpdatedItem?.upperLeftY}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter Upper Y-Coordinate"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Lower X-Coordinate"
                          name="lowerRightX"
                          value={childUpdatedItem?.lowerRightX}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter Lower X-Coordinate"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Lower Y-Coordinate"
                          name="lowerRightY"
                          value={childUpdatedItem?.lowerRightY}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter Lower Y-Coordinate"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Character Spacing"
                          name="characterSpace"
                          value={childUpdatedItem?.characterSpace}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter Character Spacing"
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="m-t-10">
                    <h6 style={subHeading}>Group Details</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Group Name"
                          name="groupName"
                          value={childUpdatedItem?.groupName}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter Group Name"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          value={childUpdatedItem?.groupType}
                          fullWidth
                          options={groupType}
                          getOptionLabel={(option) => option}
                          onChange={(e, val) => {
                            fieldType(e, val, "groupType"),
                              setType("container");
                          }}
                          popupIcon={<KeyboardArrowDownIcon />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={"Select" + "Group Type"}
                              variant="standard"
                              label="Group Type"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="m-t-10">
                    <h6 style={subHeading}>Other Details</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Autocomplete
                          value={childUpdatedItem?.startFrom}
                          fullWidth
                          options={startFrom}
                          getOptionLabel={(option) => option}
                          onChange={(e, val) => {
                            fieldType(e, val, "startFrom"),
                              setType("container");
                          }}
                          popupIcon={<KeyboardArrowDownIcon />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={"Select" + "Start From"}
                              variant="standard"
                              label="Start From"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="HTML Content"
                          name="htmlContent"
                          value={childUpdatedItem?.htmlContent}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            width: "50%",
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                            },
                            shrink: true,
                          }}
                          placeholder="Enter HTML Content"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="CSS Style"
                          name="cssStyle"
                          value={childUpdatedItem?.cssStyle}
                          onChange={(e) => {
                            handleChange(e), setType("container");
                          }}
                          style={{
                            ...textboxStyle,
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                          className="mb-3"
                          variant="standard"
                          InputLabelProps={{
                            sx: {
                              fontSize: "16px",
                              marginTop: 0,
                              paddingTop: 0,
                            },
                            shrink: true,
                          }}
                          placeholder="Enter CSS Style"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditPropertiesComponent;
