import React from "react";
import { common } from "./interface";

export const ImageIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4168">
        <g data-name="Group 4084">
          <g data-name="Group 4083">
            <path
              data-name="Path 1734"
              d="M72.682 63.4a.335.335 0 0 1-.485 0l-1.108-1.108v3.463a.346.346 0 0 1-.693 0v-3.461L69.289 63.4a.374.374 0 0 1-.242.1.314.314 0 0 1-.242-.1.335.335 0 0 1 0-.485l1.7-1.7c.035-.035.069-.069.1-.069a.379.379 0 0 1 .277 0c.035 0 .069.035.1.069l1.7 1.7a.335.335 0 0 1 0 .485z"
              transform="translate(-47.234 -42.517)"
              style={{ fill: color, stroke: color, strokeWidth: ".6px" }}
            />
            <path
              data-name="Path 1735"
              d="M26.816 29.9a2.209 2.209 0 0 1-2.216-2.218 2.2 2.2 0 1 1 2.216 2.218zm0-3.705a1.531 1.531 0 0 0-1.524 1.489 1.561 1.561 0 0 0 1.524 1.524 1.508 1.508 0 0 0 1.489-1.524 1.478 1.478 0 0 0-1.489-1.491z"
              transform="translate(-18.405 -19.228)"
              style={{ fill: color, stroke: color, strokeWidth: ".6px" }}
            />
            <path
              data-name="Path 1736"
              d="M34.808 30.8V19.239a1.042 1.042 0 0 0-1.039-1.039h-19.53a1.042 1.042 0 0 0-1.039 1.039v15.617a1.042 1.042 0 0 0 1.039 1.039h15.479a4.767 4.767 0 1 0 5.09-5.09zM13.893 19.239a.347.347 0 0 1 .346-.346h19.53a.347.347 0 0 1 .346.346v10.146l-5.055-4.433a.363.363 0 0 0-.45 0l-6.095 5.679-2.8-2.77a.363.363 0 0 0-.45-.035l-5.372 3.914zm.346 15.961a.347.347 0 0 1-.346-.346v-2.283l5.541-4.017 2.84 2.8a.335.335 0 0 0 .485 0l6.095-5.679 5.263 4.606v.519a4.758 4.758 0 0 0-4.4 4.4zm20.223 4.432a4.086 4.086 0 1 1 4.086-4.086 4.116 4.116 0 0 1-4.086 4.089z"
              transform="translate(-10.953 -14.456)"
              style={{ fill: color, stroke: color, strokeWidth: ".6px" }}
            />
          </g>
        </g>
        <path
          data-name="Rectangle 877"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
