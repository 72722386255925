import React, { FC } from "react";
import {
  FormLayoutComponentChildrenType,
  FormLayoutCoponentChildrenItemsType,
} from "../../../../types/FormTemplateTypes";
import { FormControlNames } from "../../../../utils/formBuilderUtils";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import {
  FileUploadIcon,
  MultiFileUploadIcon,
  ImageIcon,
} from "../../../../assets/icons";

const dateFormat = "yyyy, MMM dd";

interface RenderItemProps {
  item: FormLayoutComponentChildrenType;
}

const RenderItem: FC<RenderItemProps> = (props) => {
  const { item } = props;

  switch (item?.container?.type ?? item.type) {
    case FormControlNames.INPUTTEXTFIELD:
      return (
        <TextField
          type={item.dataType}
          fullWidth={true}
          //placeholder={item.placeholder}
          label={
            item.name?.length > 9
              ? item.name?.slice(0, 9) + (item.mandatory ? " *" : "") + "..."
              : item.name + (item.mandatory ? " *" : "")
          }
          variant="standard"
        />
      );

    case FormControlNames.INPUTMULTILINE:
      return (
        <TextField
          type={item.dataType}
          fullWidth={true}
          //placeholder={item.placeholder}
          label={
            item.name?.length > 9
              ? item.name?.slice(0, 9) + (item.mandatory ? " *" : "") + "..."
              : item.name + (item.mandatory ? " *" : "")
          }
          variant="standard"
        />
      );

    case FormControlNames.INPUTNUMBERFIELD:
      return (
        <div className="d-flex justify-content-between align-items-center">
          <TextField
            type={item.dataType}
            fullWidth={true}
            //placeholder={item.placeholder}
            label={
              item.name?.length > 9
                ? item.name?.slice(0, 9) + (item.mandatory ? " *" : "") + "..."
                : item.name + (item.mandatory ? " *" : "")
            }
            variant="standard"
          />
        </div>
      );
    case FormControlNames.CHECKBOX:
      return (
        <div style={{ borderBottom: "1px solid rgba(0,0,0,0.4)" }}>
          <FormControlLabel
            label={
              item.name?.length > 9
                ? item.name?.slice(0, 9) + (item.mandatory ? " *" : "") + "..."
                : item.name + (item.mandatory ? " *" : "")
            }
            control={<Checkbox style={{ margin: "0 0 -0.1rem 0" }} />}
            style={{ margin: "0.75rem 0 -0.25rem 0" }}
          />
        </div>
      );

    case FormControlNames.RADIOGROUP:
      return (
        <FormControl>
          <RadioGroup name={item.controlName + item.id} row>
            {item.items?.map((i) => (
              <FormControlLabel
                value={i.value}
                key={i.value}
                control={<Radio />}
                label={
                  item.name?.length > 9
                    ? item.name?.slice(0, 9) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      );

    case FormControlNames.SELECTDROPDOWN:
      return (
        <FormControl style={{ minWidth: "100%" }}>
          <Autocomplete
            fullWidth
            options={[]}
            getOptionLabel={(option) => option}
            disableCloseOnSelect
            //onChange={handleChange}
            popupIcon={<KeyboardArrowDownIcon />}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={
                  item.name?.length > 9
                    ? item.name?.slice(0, 9) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")
                }
              />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem
                {...props}
                key={option}
                value={option}
                sx={{ justifyContent: "space-between" }}
              >
                {option}
                {selected ? <CheckIcon color="info" /> : null}
              </MenuItem>
            )}
          />
        </FormControl>
      );

    case FormControlNames.MULTIPLESELECTDROPDOWN:
      const valueArr: string[] = [];
      item?.items?.map((each: FormLayoutCoponentChildrenItemsType) => {
        valueArr.push(each.value);
      });
      return (
        <div className="d-flex justify-content-between align-items-center">
          <FormControl style={{ width: "100%" }}>
            <Autocomplete
              fullWidth
              multiple
              options={[]}
              getOptionLabel={(option) => option}
              disableCloseOnSelect
              //onChange={handleChange}
              popupIcon={<KeyboardArrowDownIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={
                    item.name?.length > 9
                      ? item.name?.slice(0, 9) +
                        (item.mandatory ? " *" : "") +
                        "..."
                      : item.name + (item.mandatory ? " *" : "")
                  }
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem
                  {...props}
                  key={option}
                  value={option}
                  sx={{ justifyContent: "space-between" }}
                >
                  {option}
                  {selected ? <CheckIcon color="info" /> : null}
                </MenuItem>
              )}
            />
          </FormControl>
        </div>
      );

    case FormControlNames.BUTTON:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ borderBottom: "1px solid rgba(0,0,0,0.4)" }}
        >
          <Button variant="outlined" style={{ margin: "0.35rem 0 0.45rem 0" }}>
            {item.name?.length > 9
              ? item.name?.slice(0, 9) + (item.mandatory ? " *" : "") + "..."
              : item.name + (item.mandatory ? " *" : "")}
          </Button>
        </div>
      );

    case FormControlNames.DATEFIELD:
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            slotProps={{ textField: { fullWidth: true, variant: "standard" } }}
            label={
              item.name?.length > 7
                ? item.name?.slice(0, 7) + (item.mandatory ? " *" : "") + "..."
                : item.name + (item.mandatory ? " *" : "")
            }
            slots={{
              openPickerIcon: EventNoteOutlinedIcon,
            }}
          />
        </LocalizationProvider>
      );

    case FormControlNames.TIMEFIELD:
      return (
        <div style={{ height: "2rem" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              slotProps={{
                textField: { fullWidth: true, variant: "standard" },
              }}
              label={
                item.name?.length > 7
                  ? item.name?.slice(0, 7) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")
              }
            />
          </LocalizationProvider>
        </div>
      );

    case FormControlNames.FILEUPLOAD:
      return (
        <div
          style={{
            borderBottom: "1px solid rgba(0,0,0,0.25)",
            width: "100%",
          }}
          className="d-flex align-items-center justify-content-between pb-2"
        >
          <div
            style={{
              width: "100%",
              margin: "0",
              padding: "0",
            }}
          >
            <input
              style={{ display: "none" }}
              id={item.controlName + item.id}
              type="file"
            />
            <label
              className="d-flex justify-content-between align-items-center w-100"
              htmlFor={item.controlName + item.id}
              style={{ margin: "1rem 0 0 0" }}
            >
              <p style={{ margin: 0 }}>
                {item.name?.length > 7
                  ? item.name?.slice(0, 7) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")}
              </p>
              <FileUploadIcon size="20" color="#4f4f4f" />
            </label>
          </div>
        </div>
      );

    case FormControlNames.IMAGEUPLOAD:
      return (
        <div
          style={{
            borderBottom: "1px solid rgba(0,0,0,0.25)",
            width: "100%",
          }}
          className="d-flex align-items-center justify-content-between pb-2"
        >
          <div
            style={{
              width: "100%",
              margin: "0",
              padding: "0",
            }}
          >
            <input
              style={{ display: "none" }}
              id={item.controlName + item.id}
              type="file"
            />
            <label
              className="d-flex justify-content-between align-items-center w-100"
              htmlFor={item.controlName + item.id}
              style={{ margin: "1rem 0 0 0" }}
            >
              <p style={{ margin: 0 }}>
                {item.name?.length > 7
                  ? item.name?.slice(0, 7) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")}
              </p>
              <ImageIcon size="20" color="#4f4f4f" />
            </label>
          </div>
        </div>
      );

    case FormControlNames.CHECKLIST:
      return (
        <>
          <FormGroup>
            {item.items?.map((i, ind) => (
              <FormControlLabel
                key={i.value}
                control={<Checkbox />}
                label={
                  item.name?.length > 9
                    ? item.name?.slice(0, 9) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")
                }
                style={{ marginLeft: "0px" }}
              />
            ))}
          </FormGroup>
        </>
      );

    case FormControlNames.OTP:
      return (
        <TextField
          type={item.dataType}
          fullWidth={true}
          //placeholder={item.placeholder}
          label={
            item.name?.length > 9
              ? item.name?.slice(0, 9) + (item.mandatory ? " *" : "") + "..."
              : item.name + (item.mandatory ? " *" : "")
          }
          variant="standard"
        />
      );

    case FormControlNames.ARRAY:
      return (
        <div className={"w-100 position-relative my-3"}>
          <h5 className="ml-2">
            {item?.container?.labelName +
              (item?.container?.required ? " *" : "")}
          </h5>
          <div
            className="row align-items-stretch mx-1"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.025)",
              justifyContent: !item?.children?.length ? "center" : "flex-start",
              borderRadius: "9px",
            }}
          >
            {!item?.children?.length ? (
              <p className="pt-3">Array items are empty</p>
            ) : (
              <>
                {item?.children?.map(
                  (item: FormLayoutComponentChildrenType) => (
                    <div
                      className="p-20 mx-2"
                      style={{
                        width: item?.gridValue === 6 ? "45%" : "100%",
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>{item.labelName + (item.required ? " *" : "")}</h5>
                      </div>
                      {/* {item.description !== "" && (
                      <>
                        <div className="mt-2">
                          <p>{item.description}</p>
                        </div>
                      </>
                    )} */}

                      <RenderItem key={item.id} item={item} />
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      );
  }
  return <></>;
};

export default RenderItem;
