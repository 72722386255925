import React, { useEffect, useState,  } from "react";
import "./FormLayout.scss";
import { Box } from "@mui/material";
import TemplateName from "../../../components/FormFill/Reuseable/TemplateName/TemplateName";
import CustomizedSteppers from "../../../components/FormFill/Global/HorizontalStepper/HorizontalStepper";
import Sections from "../../../components/FormFill/Global/Sections/Sections";
import SectionsFormView from "../../../components/FormFill/Reuseable/SectionsFormView/SectionsFormView";
import { useSelector, useDispatch } from "react-redux";
import { getMetaDataAction } from "../../../redux/slices/ManchSlices";
import { useNavigate } from "react-router-dom";
const FormLayout = () => {
  
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(0);
  const navigate = useNavigate();
  const [hasErrors, setHasErrors] = useState(false);
  const [formStatus, setFormStatus] = useState("notFilled");
  // const TEMPID = useSelector((e) => e?.ManchSlice?.selectedTemplateIdWithTMPT?.TempId);
  const sidebarOpen = useSelector((state) => state?.ManchSlice?.sidebarOpen);
   const selectedTemplate = useSelector(
      (e) => e?.ManchSlice?.selectedTemplateName?.Name
    );
  const TemplateIdWithoutTMPT = useSelector(
    (e) => e?.ManchSlice?.selectedTemplateIdWithoutTMPT?.TempId
  );
  const txnURL = useSelector(
    (e) => e?.ManchSlice?.fill?.fillValue?.data?.txnURL
  );
  const trasactionId = useSelector(
    (e) => e?.ManchSlice?.selectedtransactionId?.transactionId
  );
  const selectDocuments = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents
  );
  const handleButtonClick = (message) => {
    if (message === "clicked") {
      if (txnURL !== undefined) {
        const extractedValue = txnURL.split("/").pop();
        navigate(
          `/onboardingRequest/template/${TemplateIdWithoutTMPT}/transaction/${extractedValue}/uploadDocuments`
        );
      } else {
        navigate(
          `/onboardingRequest/template/${TemplateIdWithoutTMPT}/transaction/${trasactionId}/uploadDocuments`
        );
      }
    }
  };
  const trxcId = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.transactionId
  );
  const docTypeId = useSelector(
    (state) =>
      state?.ManchSlice?.allDocument?.allDocumentValue?.documents?.[0]
        ?.documentTypeId
  );
  console.log(docTypeId, "doctypeid");
  const handleSectionChange = (index) => {
    setActiveSection(index);
  };
  useEffect(() => {
    if (trxcId && docTypeId) {
      dispatch(getMetaDataAction({ trxcId, docTypeId }));
    }
  }, [trxcId, docTypeId]);
const widthOfBackground=selectedTemplate === "Account KYC Process" ? "17%" : "100%"
  return (
    <Box className="storeSectionDetail">
      <Box className="layout">
        <Box  className="backgroundSection"
 >
          <Box className="block-a">
            {" "}
            <TemplateName  activeSection={activeSection}/>
          </Box>

          <Box className="main-content">
            <Box className="left-col">
              <Box className="block-b">
                {" "}
                <CustomizedSteppers />
              </Box>
              <Box className="block-d">
                <SectionsFormView
                  activeSection={activeSection}
                  onSectionChange={handleSectionChange}
                  onErrorStateChange={setHasErrors} // Pass the error state callback
                  updateFormStatus={setFormStatus} // Pass updateFormStatus
                  onButtonClick={handleButtonClick}
                />
              </Box>
            </Box>
            <Box className="right-col">
              <Box className="block-c">
                <Sections
                  onSectionChange={handleSectionChange}
                  activeSection={activeSection}
                  onButtonClick={handleButtonClick}
                  hasErrors={hasErrors}
                  formStatus={formStatus} // Pass error state to Sections
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FormLayout;
