import * as React from "react"
const Pdf = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    data-name="Group 3311"
    {...props}
  >
    <path
      d="M0 0h30v30H0z"
      data-name="Rectangle 582"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M11.895 0H0v20.762h17.011V5.114zm.393 2.207 2.537 2.535h-2.537zm3.431 17.266H1.292V1.289H11v4.742h4.722z"
      data-name="Union 19"
      style={{
        fill: "#4f4f4f",
      }}
      transform="translate(6 5)"
    />
  </svg>
)
export default Pdf