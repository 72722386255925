import * as React from "react"
const Upload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    data-name="Group 740"
    {...props}
  >
    <circle
      cx={30}
      cy={30}
      r={30}
      data-name="Ellipse 18"
      style={{
        fill: "#f5f8fa",
      }}
    />
    <path
      d="M182 414.4h21.872v2.916H182z"
      data-name="Path 296"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-163 -375.74)"
    />
    <path
      d="M206.217 114.98v-13.342l6.525 5.614 1.9-2.224-8.931-7.655a1.441 1.441 0 0 0-1.9 0l-8.931 7.655 1.9 2.224 6.525-5.614v13.306h2.916z"
      data-name="Path 297"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-175.042 -79.017)"
    />
  </svg>
)
export default Upload