import React from "react";
import { reDirectToMainSite } from "../components/Navbar";
import { Typography } from "@mui/material";

const NotFoundPage: React.FC = () => {
  return (
    <div style={styles.container}>
      <Typography
        component="h1"
        sx={{
          marginBottom: 0,
        }}
        variant="errorPageText"
      >
        404
      </Typography>

      <p style={styles.message}>
        Oops! The page you are looking for doesn't exist.
      </p>
      <p onClick={reDirectToMainSite} style={styles.link}>
        Go back to Homepage
      </p>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4",
    textAlign: "center",
    padding: "20vh",
  },
  message: {
    fontSize: "24px",
    color: "#6a6a6a",
  },
  link: {
    fontSize: "1.2rem",
    color: "#007BFF",
    textDecoration: "none",
    cursor: "pointer",
  },
};

export default NotFoundPage;
