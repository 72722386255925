import React from "react";
import { common } from "./interface";

export const MultiFileUploadIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4165">
        <path
          data-name="Rectangle 878"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
        <g data-name="Group 4106">
          <path
            data-name="Path 1744"
            d="M27.85 6.242 22.71 1.28A1.019 1.019 0 0 0 22 1H11.51A2.478 2.478 0 0 0 9 3.423v1.438H7.5A2.467 2.467 0 0 0 5 7.284v20.273a2.468 2.468 0 0 0 2.5 2.4h14a2.467 2.467 0 0 0 2.5-2.42V26.1h1.63a2.477 2.477 0 0 0 2.51-2.423V6.927a1 1 0 0 0-.29-.685zM23 4.292l1.73 1.67H23zm-1 23.245a.474.474 0 0 1-.143.348.509.509 0 0 1-.357.145h-14a.509.509 0 0 1-.357-.145.474.474 0 0 1-.143-.348V7.265a.492.492 0 0 1 .5-.473H9v16.884a2.477 2.477 0 0 0 2.51 2.424H22zm3.63-3.369H11.51a.5.5 0 0 1-.51-.492V3.4a.5.5 0 0 1 .51-.473H21v4a.983.983 0 0 0 1 .965h4.14v15.784a.5.5 0 0 1-.51.492z"
            transform="translate(-2)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1745"
            d="M19.21 10.29a1 1 0 0 0-1.42 0l-3.5 3.5a1 1 0 1 0 1.42 1.42l1.79-1.8v6.34a1 1 0 0 0 2 0v-6.34l1.79 1.8a1 1 0 1 0 1.42-1.42z"
            transform="translate(-2 -.486)"
            style={{ fill: color }}
          />
        </g>
      </g>
    </svg>
  );
};
