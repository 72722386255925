import * as React from "react"
import { AnyComponent } from "styled-components/dist/types"
const AddIcon = (props:AnyComponent) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 4086">
      <path
        d="M3.186 23.561v-2.6h5.967v-5.682H11.8v5.681h5.647v2.6H11.8v6.34H9.153v-6.339z"
        data-name="Path 1723"
        style={{
          fill: "#4f4f4f",
        }}
        transform="translate(4.814 -7.279)"
      />
      <path
        d="M0 0h30v30H0z"
        data-name="Rectangle 875"
        style={{
          fill: "none",
        }}
      />
    </g>
  </svg>
)
export default AddIcon