import React from "react";

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 30 30"
      style={{margin:"0 0 0.25rem 0"}}
    >
      <path data-name="Path 941" d="M0 0h30v30H0z" style={{ fill: "none" }} />
      <path
      style={{ fill: "#4f4f4f" }}
        data-name="Path 845"
        d="M8.668 24.918h12.437l.925-14.226h1.743V8.869h-4.756V6h-8.261v2.867H6v1.823h1.732zm10.73-1.823h-9.024L9.567 10.69h10.637zm-6.82-15.267h4.617v1.044h-4.616z"
      />
    </svg>
  );
};
