import React from "react";
import { common } from "./interface";

export const DropdownIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4163">
        <g data-name="Group 4078">
          <path
            data-name="Path 1724"
            d="M29.742 10H14.936A4.941 4.941 0 0 0 10 14.936v14.806a4.941 4.941 0 0 0 4.936 4.936h14.806a4.941 4.941 0 0 0 4.936-4.936V14.936A4.941 4.941 0 0 0 29.742 10zm4.319 19.742a4.323 4.323 0 0 1-4.319 4.319H14.936a4.323 4.323 0 0 1-4.319-4.319V14.936a4.323 4.323 0 0 1 4.319-4.319h14.806a4.323 4.323 0 0 1 4.319 4.319z"
            transform="translate(-6.512 -7.404)"
            style={{ fill: color, stroke: color }}
          />
          <path
            data-name="Path 1725"
            d="m38.567 40.434-5.5 6.291-5.5-6.291a.211.211 0 0 0-.364.061.5.5 0 0 0 .042.526l5.666 6.479a.2.2 0 0 0 .322 0l5.657-6.475a.5.5 0 0 0 .042-.526.21.21 0 0 0-.365-.065z"
            transform="translate(-17.245 -29.03)"
            style={{ fill: color, stroke: color }}
          />
        </g>
        <path
          data-name="Rectangle 874"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
