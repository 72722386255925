import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Modal,
  List,
  Paper,
  Drawer,
  Stack,
} from "@mui/material";
import { pdfjs } from "react-pdf";
import Backbtn from "../../../../assets/icons/FormFillIcons/Back";
import Upload from "../../../../assets/icons/FormFillIcons/Upload";
import FileUpload from "../../../../assets/icons/FormFillIcons/FileUpload";
import "./DocumentList.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import preview from "../../../../assets/icons/FormFillIcons/Preview";
import Add from "../../../../assets/icons/FormFillIcons/AddIcon";
import DeleteIcon from "../../../../assets/icons/FormFillIcons/Deleteicon";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../Global/Inner/useWindowSize";
import CloseIcon from '@mui/icons-material/Close';
import {
  deleteDocumentAction,
  getStatusTrasition,
  getThumbnailAction,
  postDocumentsInfo,
  successThumbnailAction,
  successUploadedInfo,
} from "../../../../redux/slices/ManchSlices";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PdfViewer from "../../Global/PdfViewer/PdfViewer";
import CongratulationPopup from "../../Global/congratulationPopup/CongratulationPopup";
import { useNavigate } from "react-router-dom";
import FilesMobile from "../../../../assets/icons/FormFillIcons/FilesMobile";
import Eyemobile from "../../../../assets/icons/FormFillIcons/Eyemobile";
import DeleteIconMobile from "../../../../assets/icons/FormFillIcons/DeleteIconMobile";
import MobileAddIcon from "../../../../assets/icons/FormFillIcons/MobileAddIcon";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import FolderIcon from "@mui/icons-material/Folder";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "npne",
  boxShadow: 24,
  p: 4,
};
const mobileStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height:"100%",
  width: "100%",
  bgcolor: "#f2f4f5;",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const DocumentList = () => {
  const cameraInputRef = useRef(null);
  const galleryInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [width] = useWindowSize();
  const isMobile = width <= 500;
  // const X_AUTH = process.env.REACT_APP_AUTH;
  let domainUrl = process.env.REACT_APP_MAIN_URL;
  const X_AUTH = window.sessionStorage.getItem("token");
  const navigate = useNavigate();
  const hasEffectRun = useRef(false);
  const [congratulationModal, setCongratulationModal] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dispatch = useDispatch();
  const [showPdf, setShowPdf] = useState(false);
  const [open, setOpen] = useState(false); // Modal open state
  const [mobileModal, setMobileModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Menu anchor
  const [selectedDocIndex, setSelectedDocIndex] = useState(null); // Keep track of selected document
  const [files, setFiles] = useState([]);
  const [modalData, setModalData] = useState();
  const trxcId = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.transactionId
  );
  const successFullyPostFiles = useSelector(
    (state) => state?.ManchSlice?.documentsInfo?.loading
  );

  const successStatusTransition = useSelector(
    (state) =>
      state?.ManchSlice?.statusTransitionInfo?.statusTransitionInfoValue
        ?.statusTransitions
  );
  const handleModalOpen = (element) => {
    if (isMobile){
      setMobileModal(true)
      setFiles([]);
      setModalData(element);
    }
    else{
    setOpen(true); // Open the modal
    setAnchorEl(null); // Close the menu
    setFiles([]);
    setModalData(element);
    console.log(element, "element info");
    }
  };
  const handleCloseCongratulation = () => setCongratulationModal(true);

  const handleClose = () => setOpen(false); // Close modal function

  const handleMoreVertClick = (event, index) => {
    console.log(event, index, "more icon");
    setAnchorEl(event.currentTarget); // Open menu on MoreVert click
    setSelectedDocIndex(index); // Set the selected document index
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close menu
    setSelectedDocIndex(null); // Reset selected document index
  };

  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [thumbnailsData, setThumbnailsData] = useState(filteredDocuments || []);
  const selectDocuments = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents
  );
  console.log(selectDocuments, "select");
  const thumbnails = useSelector(
    (state) => state?.ManchSlice?.thumbnailInfo?.thumbnailInfoValue
  );
  useEffect(() => {
    if (Array.isArray(selectDocuments) && selectDocuments?.length > 0) {
      const filtered = selectDocuments.filter(
        (doc) => doc.formFillEnabled === false
      );
      setFilteredDocuments(filtered);
      console.log(filtered, "Filtered Documents");
    }
  }, [selectDocuments]);
  const handleFileUpload = (e) => {
    let selectedFiles = Array.from(e.target.files || e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  // Remove a file from the list
  const handleRemoveFile = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };
  const triggerInput = (inputRef) => {
    if (inputRef.current) inputRef.current.click();
  };
  // Handle file drop event
  const handleDrop = (e) => {
    e.preventDefault();
    handleFileUpload(e);
  };

  useEffect(() => {
    // Only proceed if there are filteredDocuments to process
    if (
      !hasEffectRun.current &&
      filteredDocuments &&
      filteredDocuments.length > 0
    ) {
      console.log("how many times this useEffect run");
      const newArray = filteredDocuments.map((item) => ({
        documentTypeId: item.documentTypeId,
        limit: item.maxNumberOfFilesToCollate,
        name: item.name,
        addedFiles: 0, // Add new key with default value
      }));
      console.log(newArray, "newArray");
      console.log(uploadedFiles, "uploadedFiles");

      // Check if the new array is different from the current uploadedFiles state to avoid re-renders
      const areArraysEqual =
        JSON.stringify(uploadedFiles) === JSON.stringify(newArray);
      console.log(areArraysEqual, "areArraysEqual");

      if (!areArraysEqual) {
        console.log(areArraysEqual, "areArraysEqual2");

        setUploadedFiles(newArray); // Only set state if newArray is different
      }
      hasEffectRun.current = true; // Mark the effect as executed
    }
  }, [filteredDocuments]);
  const uploadHandler = (element) => {
    if (files && files.length > 0) {
      const formData = new FormData();

      // Append files to the FormData object
      files.forEach((file, index) => {
        // The key 'objects' is used as per the form data in your image
        formData.append("objects", file);
      });
      const documentTypeId = element?.documentTypeId;
      // If you also need to append other fields like `documentTypeId`
      formData.append("documentTypeId", documentTypeId);

      dispatch(
        postDocumentsInfo({
          formData,
          trxcId,
        })
      );
      if (successFullyPostFiles === false) {
        // After successful dispatch, update the uploadedFiles array
        // Ensure documentTypeId is of the correct type
        const documentTypeIdNum = Number(documentTypeId); // Convert to number just in case

        // Map over the uploadedFiles array and update the one matching the documentTypeId
        const updatedUploadedFiles = uploadedFiles.map((file) => {
          console.log(file, "before addition");
          if (file.documentTypeId === documentTypeIdNum) {
            console.log("Matched documentTypeId:", documentTypeIdNum);

            // Update addedFiles count for the matching file
            return {
              ...file,
              addedFiles: file.addedFiles + files.length, // Increment existing count
            };
          }
          console.log(file, "after addition");
          return file; // Return unchanged file if documentTypeId doesn't match
        });

        // Update the state with the modified array
        setUploadedFiles(updatedUploadedFiles);
        if(isMobile){
          setMobileModal(false);
        }
        else
        setOpen(false); // Close the modal after successful upload
      }
    } else {
      console.error("No files available for upload");
      return;
    }
  };
  const handlePreview = (element) => {
    setAnchorEl(null);
    const documentId = element.documentId;
    fetchDocument(trxcId, documentId);
    // dispatch(getUploadedInfo({trxcId,documentId}))
  };
  const uploadedDocumentInfo = useSelector(
    (state) => state.ManchSlice.uploadedDocumentInfo
  );
  const { uploadedDocumentInfoValue: pdfUrl, loading } = uploadedDocumentInfo;
  console.log(pdfUrl, "pdfurl");
  console.log(loading, "loading");

  const fetchDocument = async (trxcId, documentId) => {
    try {
      const response = await axios.get(
        `${domainUrl}/app/transactions/${trxcId}/documents/${documentId}/preview`,
        {
          headers: {
            "X-Authorization": process.env.REACT_APP_AUTH, // Replace with your actual token
          },
          responseType: "arraybuffer", // To receive binary data as arrayBuffer
        }
      );

      // If the request is successful
      const arrayBuffer = response.data; // Axios directly gives us the arrayBuffer
      const blob = new Blob([arrayBuffer], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      console.log(url, "new url");
      dispatch(successUploadedInfo(url));

      setShowPdf(true);
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    }
  };
  const handlePreviewThumbNail = async (trxcId, documentId, documentTypeId) => {
    console.log(trxcId, documentId, documentTypeId, "thumb");
    try {
      const response = await axios.get(
        `${domainUrl}/app/transactions/${trxcId}/documents/${documentId}/thumbnail`,
        {
          headers: {
            "X-Authorization": process.env.REACT_APP_AUTH, // Replace with your actual token
          },
          responseType: "arraybuffer", // To receive binary data as arrayBuffer
        }
      );

      // Convert array buffer to base64
      const base64String = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      // Construct the image data URL
      const imageData = `data:image/jpeg;base64,${base64String}`;

      // Dispatch action with the image data and document type ID
      dispatch(successThumbnailAction({ documentTypeId, imageData }));
    } catch (error) {
      console.error("Error fetching the thumbnail:", error);
    }
  };

  const closePdfViewer = () => {
    setShowPdf(false);
  };

  console.log(successFullyPostFiles, "success");

  // Add a separate useEffect to monitor uploadedFiles if you want to log it after state updates
  useEffect(() => {
    console.log(uploadedFiles, "uploadedFiles"); // This ensures you see the updated uploadedFiles
  }, [uploadedFiles]);
  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  useEffect(() => {
    if (successStatusTransition) {
      const timer = setTimeout(() => {
        navigate(`/onboardingRequest?token=${X_AUTH}`, { replace: true });
        window.location.reload();
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [successStatusTransition, navigate]);
  const deleteHandler = (element) => {
    setAnchorEl(null);
    const docTypeId = element.documentTypeId;
    console.log(element, "delete");
    dispatch(deleteDocumentAction({ trxcId, docTypeId }));
  };
  useEffect(() => {
    if (thumbnailsData && filteredDocuments) {
      console.log("hero");
      function findDifferences(oldArray, newArray) {
        console.log("hero1", oldArray, newArray);
        newArray.forEach((newObj) => {
          const oldObj = oldArray.find(
            (item) => item.documentId === newObj.documentId
          );

          if (oldObj) {
            // Check if there are any differences
            let hasDifference = false;

            for (let key in newObj) {
              if (JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])) {
                hasDifference = true;
                break;
              }
            }

            // Log the whole object from newArray if differences are found
            if (hasDifference) {
              console.log("Updated object with differences:", newObj);
            }
          } else if (newObj.storageId && newObj.uploaded === true) {
            console.log("one1");
            const documentId = newObj.documentId;
            const documentTypeId = newObj.documentTypeId;
            handlePreviewThumbNail(trxcId, documentId, documentTypeId);
          }
        });
      }

      // Call findDifferences with the arrays to compare
      findDifferences(thumbnailsData, filteredDocuments);
    }
  }, [filteredDocuments]);
  console.log(filteredDocuments, "filteredDocuments");
  console.log(thumbnails, "thumb");
  // Increment the activeStep state when "Continue" is clicked

  const activeDocument = filteredDocuments[activeStep];
  const activeMatchingThumbnail =
    thumbnails &&
    thumbnails?.find(
      (thumb) => thumb?.documentTypeId === activeDocument?.documentTypeId
    );
  const handleContinue = () => {
    if (activeStep < filteredDocuments.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const handleSubmit = () => {
    // Check if any document meets the criteria
    const hasMandatoryAndUploaded = filteredDocuments.some(
      (doc) => doc.mandatory === true && doc.uploaded === true
    );

    // Dispatch if the condition is met
    if (hasMandatoryAndUploaded) {
      dispatch(getStatusTrasition(trxcId));
    }
  };
  function MyComponent() {
    let content;

    if (showPdf) {
      content = (
        <Box>
          <PdfViewer pdfUrl={pdfUrl} />
        </Box>
      );
    } else if (activeDocument?.uploaded) {
      if (activeMatchingThumbnail) {
        content = (
          <img
            src={activeMatchingThumbnail.imageData}
            alt="Thumbnail"
            width={155}
            height={204}
          />
        );
      } else {
        content = <p>No Thumbnail Available</p>;
      }
    } else {
      content = (
        <Box  role="button" onClick={() => handleModalOpen(activeDocument)}>
          <FilesMobile />
          <Box className="nameForDocks">
            <Typography>{activeDocument?.name}</Typography>
          </Box>
        </Box>
      );
    }
    return content;
  }
  return (
    <>
      {isMobile ? (
        <Box className="documentListClassForMobile">
          {/* Stepper: displays all steps with the active one highlighted */}
          <Box className="stepsForDocks">
            <Stepper activeStep={activeStep} alternativeLabel>
              {filteredDocuments.map((element, index) => (
                <Step key={index}>
                  <StepLabel> {element.mandatory
                            ? element.name + " *"
                            : element.name}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Render only the current active document */}
          <Box>
        { !mobileModal &&  <Box className="singleFile">
              <Box className={showPdf ? "" : "singleFileWhiteBg"}>
                <MyComponent />

                {activeDocument?.uploaded && !showPdf && (
                  <Box className="buttonsPreviewDelete"
                  style={{
                    width:
                      uploadedFiles.find(
                        (item) => item.documentTypeId === activeDocument?.documentTypeId
                      )?.addedFiles <
                      activeDocument?.maxNumberOfFilesToCollate
                        ? "55%"
                        : "36%",
                  }}
                  >
                    {(() => {
                      // Find the file in uploadedFiles that matches the documentTypeId
                      const file = uploadedFiles.find(
                        (item) =>
                          item.documentTypeId === activeDocument?.documentTypeId
                      );
                      console.log(file, "file add");
                      // Check if file is found and addedFiles is less than maxNumberOfFilesToCollate
                      if (
                        file &&
                        file.addedFiles <
                          activeDocument?.maxNumberOfFilesToCollate
                      ) {
                        console.log(file, "file 001");
                        return (
                          <IconButton
                            className="previewIcon"
                            onClick={() => {
                              handleModalOpen(activeDocument); // Open the modal on Add click
                            }}
                          >
                            <MobileAddIcon />
                          </IconButton>
                        );
                      }
                      return null; // If the condition is not met, render nothing
                    })()}
                    <IconButton
                      onClick={() => handlePreview(activeDocument)}
                      className="previewIcon"
                    >
                      <Eyemobile />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteHandler(activeDocument)}
                      className="previewIcon"
                    >
                      <DeleteIconMobile />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>}
            {
              mobileModal && <Box className="singleFile">
                <Box className="singleFileWhiteBg">
               {files.length > 0 && (
           <List sx={{ padding: 0 }}>
             {files.map((file, index) => {
               const fileURL = URL.createObjectURL(file); // Generate preview URL
   
               return (
                 <Paper
                   key={index}
                   elevation={2}
                   sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                     padding: "8px",
                     marginBottom: "8px",
                     borderRadius: "4px",
                     height: "87px", // Fixed height as per your screenshot
                     width:"88vw"
                   }}
                 >
                   {/* Show image preview ONLY if isMobile is true */}
                   {isMobile && (
                     <Box
                       component="img"
                       src={fileURL}
                       alt={file.name}
                       sx={{
                         width: "60px",
                         height: "60px",
                         objectFit: "cover",
                         borderRadius: "4px",
                         marginRight: "10px",
                       }}
                     />
                   )}
   
                   {/* File Info */}
                   <Box sx={{ flexGrow: 1 }}>
                     <Typography fontWeight="bold" fontSize="14px">
                       {file.name}
                     </Typography>
                     <Typography variant="body2" color="textSecondary">
                       {file.size ? (file.size / 1024 / 1024).toFixed(1) + " MB" : "Queued"}
                     </Typography>
                   </Box>
   
                   {/* Delete Button */}
                   <IconButton onClick={() => handleRemoveFile(file)}>
                     <CloseIcon />
                   </IconButton>
                 </Paper>
               );
             })}
           </List>
         )
         }
             { files.length <= modalData?.maxNumberOfFilesToCollate - 1 &&     <Stack spacing={2} sx={{ mb: 3 }}>
              <Button
                variant="outlined"
                startIcon={<CameraAltIcon />}
                onClick={() => triggerInput(cameraInputRef)}
              >
                Take Photo
              </Button>
              <Button
                variant="outlined"
                startIcon={<PhotoLibraryIcon />}
                onClick={() => triggerInput(galleryInputRef)}
              >
                Choose from Gallery
              </Button>
              <Button
                variant="outlined"
                startIcon={<FolderIcon />}
                onClick={() => triggerInput(fileInputRef)}
              >
                Choose File
              </Button>

              {/* Hidden Inputs */}
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleFileUpload}
                ref={cameraInputRef}
                hidden
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                ref={galleryInputRef}
                hidden
              />
              <input
                type="file"
                multiple
                accept="image/png, image/jpeg, application/pdf"
                onChange={handleFileUpload}
                ref={fileInputRef}  
                hidden
              />
            </Stack>}
            <Typography>
            File limit - {modalData?.maxNumberOfFilesToCollate}
          </Typography>
 <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button variant="outlined" onClick={()=>setMobileModal(false)} sx={{ mr: 2 }}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => uploadHandler(modalData)}
            >
              Upload
            </Button>
          </Box>
              </Box>
              </Box>
            }
       { !mobileModal &&    <Box className={showPdf ? "btnContinueNew" : "btnContinue"}>
              <Button
                variant={isMobile?"mobiledark":"dark"}
                disabled={!filteredDocuments.some(
                  (doc) => doc.mandatory === true && doc.uploaded === true)}
                onClick={
                  showPdf
                    ? closePdfViewer
                    : activeStep >= filteredDocuments.length - 1
                    ? handleSubmit
                    : handleContinue
                }
              >
                {showPdf
                  ? "Go back"
                  : activeStep >= filteredDocuments.length - 1
                  ? "Submit"
                  : "Continue"}
              </Button>
            </Box>}
          </Box>
        </Box>
      ) : (
        <Box className="documentListClass">
          <Box className="btnAndHeader">
            {showPdf ? (
              <Button onClick={closePdfViewer}>
                <Backbtn />
              </Button>
            ) : (
              <Button onClick={() => navigate(-1)}>
                <Backbtn />
              </Button>
            )}
            <FileUpload style={{ marginRight: "13.3px" }} />
            <Typography variant="formLabel">Upload documents</Typography>
          </Box>
          {showPdf ? (
            <PdfViewer pdfUrl={pdfUrl} />
          ) : (
            <Box className="section">
              {filteredDocuments.map((element, index) => {
                const matchingThumbnail =
                  thumbnails &&
                  thumbnails?.find(
                    (thumb) => thumb.documentTypeId === element.documentTypeId
                  );
                console.log(filteredDocuments, "filterdocuments");
                console.log(thumbnails, "thumbnails");
                console.log(matchingThumbnail, "matching");
                return (
                  <Box className="documentsClass" key={element.documentTypeId}>
                    <Box className="document">
                      {element.uploaded ? (
                        matchingThumbnail ? (
                          // Show the thumbnail if it exists
                          <img
                            src={matchingThumbnail.imageData}
                            alt="Thumbnail"
                            width={155}
                            height={204}
                          />
                        ) : (
                          // Show a fallback if thumbnail does not exist
                          <p>No Thumbnail Available</p>
                        )
                      ) : (
                        // Show upload icon if the document is not uploaded
                        <Upload />
                      )}
                    </Box>
                    <Box className="documentName">
                      <Box>
                        <Typography variant="templateInfo">
                          {element.mandatory
                            ? element.name + " *"
                            : element.name}
                        </Typography>
                      </Box>
                      <Box>
                        <MoreVertIcon
                          sx={{ cursor: "pointer" }}
                          onClick={(event) => handleMoreVertClick(event, index)}
                        />
                      </Box>
                    </Box>

                    {/* Menu */}

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedDocIndex === index}
                      onClose={handleMenuClose}
                      PaperProps={{
                        style: {
                          borderRadius: 8,
                          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)",
                        },
                      }}
                    >
                      {element?.uploaded ? (
                        <MenuItem onClick={() => handlePreview(element)}>
                          <Box>
                            <img src={preview} alt="..." />
                          </Box>
                          <Box>
                            <Typography>Preview</Typography>
                          </Box>
                        </MenuItem>
                      ) : null}
                      {element?.uploaded ? (
                        <MenuItem onClick={() => deleteHandler(element)}>
                          <Box>
                            <DeleteIcon />
                          </Box>
                          <Box>
                            <Typography>Delete</Typography>
                          </Box>
                        </MenuItem>
                      ) : null}
                      {/* Add MenuItem based on logic */}
                      {(() => {
                        // Find the file in uploadedFiles that matches the documentTypeId
                        const file = uploadedFiles.find(
                          (item) =>
                            item.documentTypeId === element.documentTypeId
                        );
                        console.log(file, "file add");
                        // Check if file is found and addedFiles is less than maxNumberOfFilesToCollate
                        if (
                          file &&
                          file.addedFiles < element.maxNumberOfFilesToCollate
                        ) {
                          console.log(file, "file 001");
                          return (
                            <MenuItem
                              onClick={() => {
                                handleModalOpen(element); // Open the modal on Add click
                              }}
                            >
                              <Box>
                                <Add />
                              </Box>
                              <Box>
                                <Typography>Add</Typography>
                              </Box>
                            </MenuItem>
                          );
                        } else {
                          return null; // If the condition is not met, render nothing
                        }
                      })()}
                    </Menu>
                  </Box>
                );
              })}
            </Box>
          )}
          {/* Modal */}
        </Box>
      )}

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" className="modalForDocumentList">
        <Box sx={isMobile ? mobileStyle : style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Upload Your Documents
          </Typography>

          {/* Mobile-Specific Upload Options */}
          {isMobile && files.length <= modalData?.maxNumberOfFilesToCollate - 1 &&  (
            <Stack spacing={2} sx={{ mb: 3 }}>
              <Button
                variant="outlined"
                startIcon={<CameraAltIcon />}
                onClick={() => triggerInput(cameraInputRef)}
              >
                Take Photo
              </Button>
              <Button
                variant="outlined"
                startIcon={<PhotoLibraryIcon />}
                onClick={() => triggerInput(galleryInputRef)}
              >
                Choose from Gallery
              </Button>
              <Button
                variant="outlined"
                startIcon={<FolderIcon />}
                onClick={() => triggerInput(fileInputRef)}
              >
                Choose File
              </Button>

              {/* Hidden Inputs */}
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleFileUpload}
                ref={cameraInputRef}
                hidden
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                ref={galleryInputRef}
                hidden
              />
              <input
                type="file"
                multiple
                accept="image/png, image/jpeg, application/pdf"
                onChange={handleFileUpload}
                ref={fileInputRef}
                hidden
              />
            </Stack>
          )}

          {/* Web Upload Area */}
          {!isMobile &&
            files.length <= modalData?.maxNumberOfFilesToCollate - 1 && (
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: "8px",
                  p: 3,
                  textAlign: "center",
                  mb: 3,
                  cursor: "pointer",
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <Typography variant="body2" sx={{ color: "#666" }}>
                  Browse, Drag and Drop File or{" "}
                  <label
                    htmlFor="file-upload"
                    style={{ color: "#007bff", cursor: "pointer" }}
                  >
                    Choose File
                  </label>
                </Typography>
                <Typography variant="caption">
                  Supported Formats: ‘png’, ‘jpg’, ‘jpeg’, ‘pdf’
                </Typography>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  accept="image/png, image/jpeg, application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </Box>
            )}

          <Typography>
            File limit - {modalData?.maxNumberOfFilesToCollate}
          </Typography>

          {/* File List Display */}
          {files.length > 0 && (
        <List sx={{ padding: 0 }}>
          {files.map((file, index) => {
            const fileURL = URL.createObjectURL(file); // Generate preview URL

            return (
              <Paper
                key={index}
                elevation={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "8px",
                  marginBottom: "8px",
                  borderRadius: "4px",
                  height: "87px", // Fixed height as per your screenshot
                }}
              >
                {/* Show image preview ONLY if isMobile is true */}
                {isMobile && (
                  <Box
                    component="img"
                    src={fileURL}
                    alt={file.name}
                    sx={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                      borderRadius: "4px",
                      marginRight: "10px",
                    }}
                  />
                )}

                {/* File Info */}
                <Box sx={{ flexGrow: 1 }}>
                  <Typography fontWeight="bold" fontSize="14px">
                    {file.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {file.size ? (file.size / 1024 / 1024).toFixed(1) + " MB" : "Queued"}
                  </Typography>
                </Box>

                {/* Delete Button */}
                <IconButton onClick={() => handleRemoveFile(file)}>
                  <DeleteIcon sx={{ color: "#ff0000" }} />
                </IconButton>
              </Paper>
            );
          })}
        </List>
      )}

          {/* Action Buttons */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => uploadHandler(modalData)}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Modal>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={successStatusTransition && congratulationModal}
          onClose={handleCloseCongratulation}
        >
          <Box sx={{ height: 425, p: 4 }} role="presentation">
            <CongratulationPopup />
          </Box>
        </Drawer>
      ) : (
        <Modal
          open={successStatusTransition && congratulationModal}
          onClose={handleCloseCongratulation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CongratulationPopup />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default DocumentList;
