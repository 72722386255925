import React, { FunctionComponent } from "react";
import { useDrag } from "react-dnd";
import {
  FormLayoutComponentChildrenType,
  FormLayoutComponentContainerType,
  FormLayoutComponentsType,
} from "../../../types/FormTemplateTypes";
import { iconArr } from "./iconArray/IconArray";

type DispatchObject = {
  value: boolean;
  arrayId?: string;
};

export interface ControlDragComponentProps {
  handleItemAdded: (
    item: any,
    containerId?: string,
    isOverMainContainer?: Boolean,
    arrayId?: String
  ) => void;
  item: FormLayoutComponentChildrenType | FormLayoutComponentContainerType;
  formLayoutComponents?: FormLayoutComponentsType[];
  isOverMainContainer?: DispatchObject;
  fields?: any;
}

const ControlDragComponent: FunctionComponent<ControlDragComponentProps> = (
  props
) => {
  const { item, handleItemAdded, isOverMainContainer, fields } = props;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: item.itemType,
      item: item,
      end: (
        item:
          | FormLayoutComponentChildrenType
          | FormLayoutComponentContainerType,
        monitor: any
      ) => {
        const dropResult: FormLayoutComponentContainerType =
          monitor.getDropResult();
        if (item && dropResult) {
          if (item.itemType === "container") {
            handleItemAdded(item);
          } else if (item.itemType === "control") {
            handleItemAdded(
              item,
              dropResult.id,
              isOverMainContainer?.value,
              isOverMainContainer?.arrayId,
              fields
            );
          }
        }
        //setIsCalled?.(false);
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [props.formLayoutComponents, isOverMainContainer?.value]
  ); // Need to add this dependency for dragging elements.
  const opacity = isDragging ? 0.4 : 1;

  const index = iconArr?.findIndex((e: any) => e?.type === item?.type);

  return (
    <div
      ref={drag}
      style={{
        opacity,
        cursor: "move",
        backgroundColor: "#e8f0fe",
        padding: "16px 8px",
        borderRadius: "8px",
      }}
      className="d-flex align-items-center justify-content-center w-100 my-2 mb-4"
    >
      <div style={{ marginRight: "10px" }}>{iconArr[index]?.ele}</div>
      <p style={{ margin: "0", color: "#212121", fontSize: "12px" }}>
        {item?.label}
      </p>
    </div>
  );
};

export default ControlDragComponent;
