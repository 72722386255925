import { useEffect, useState, useRef } from "react";
import {
  TemplateType,
  FormLayoutComponentsType,
  FormLayoutComponentChildrenType,
  FormLayoutComponentContainerType,
  RuleDataType,
  selectedDropdownRule,
  forTemplateNameDataType,
  ValidationDataType,
  dirtObjType,
  useFormBuilderProps,
  getTypeId,
  getSelectedTypeValidationId,
} from "../../../types/FormTemplateTypes";
import {
  FormItemTypes,
  FormPublishStatus,
  FormTextDataTypes,
} from "../../../utils/formBuilderUtils";
import { generateID } from "../../../utils/common";
import { useAppDispatch } from "../../../redux/hooks";
import moment from "moment";
import { saveTemplate } from "../../../redux/entities/formBuilderEntity";
import useModalStrip from "../../../global-hooks/useModalStrip";
import { AxiosGet, AxiosPost, AxiosPatch, AxiosDelete } from "../../../api";
import { standardArray } from "../../../assets/standardArray";
import { attributeStaticArr } from "../../../assets/rules";

export const selectedRuleObj = {
  name: "",
  source: "",
  action: "",
  processingType: "",
  applicableTypes: [],
  validatable: true,
  conditionsRequired: false,
  destinationFields: {
    numberOfOutputs: 0,
    fields: [
      {
        name: "",
        ordinal: 0,
        mandatory: false,
      },
    ],
  },
  sourceFields: {
    numberOfOutputs: 0,
    fields: [
      {
        name: "",
        ordinal: 0,
        mandatory: false,
      },
    ],
  },
};

// function getRandomFloat(min: any, max: any) {
//   const num = Math.random() * (max - min) + min;
//   const updateVal = parseFloat(num.toFixed(4));
//   return updateVal;
// }

const incDec: number = 0.0001;

const roundOff = (a: number, type: string = "add", b: number = incDec) => {
  if (type === "sub") {
    return Math.round((a - b) * 10000) / 10000;
  } else {
    return Math.round((a + b) * 10000) / 10000;
  }
};

const variableName = (text: any) => {
  if (text?.length > 0) {
    const updatedText = text.charAt(0).toLowerCase() + text.slice(1);
    return "_" + updatedText.replace(/\s+/g, "") + "_";
  } else {
    return "_" + "_";
  }
};

const useFormBuilder = (props: useFormBuilderProps) => {
  // Dispatch redux function
  const dispatch = useAppDispatch();

  // Notification
  const { showModalStrip } = useModalStrip();

  // Name field focus
  const inputRef: any = useRef();
  const [selectedTemplate, setSelectedTemplate] = useState<null | TemplateType>(
    props.template
  );

  // Master Data
  const [formLayoutComponents, setFormLayoutComponents] = useState<
    FormLayoutComponentsType[]
  >(props.template.formLayoutComponents);
  // Control Item Selected State
  const [selectedControl, setSelectedControl] = useState<
    | undefined
    | FormLayoutComponentContainerType
    | FormLayoutComponentChildrenType
  >(undefined);

  // Validation Master Data State
  const [validationData, setValidationData] = useState<ValidationDataType[]>(
    []
  ); // All Validation Data State
  // Validation Raw Master Data State
  const [validationRawData, setValidationRawData] = useState<
    ValidationDataType[]
  >([]);
  const [selectedValidation, setSelectedValidation] = useState(undefined); // Selected particular validation
  const [selectedApiValidation, setSelectedApiValidation] = useState(null); // Particular valiaditon from api
  const [isValidationEdited, setIsValidationEdited] = useState(false); // edited State of validation
  const [validationObjArr, setValidationObjArr] = useState<any[]>([]); // arr for advanced obj validation
  //End

  // Rule State
  const [ruleDropdownValue, setRuleDropdownValue] = useState<
    selectedDropdownRule[]
  >([]); // Rule drop down data coming from API
  const [ruleObjectList, setRuleObjectList] = useState<any>([]);
  const [isEdited, setIsEdited] = useState(false); //  edited State of Rule
  const [ruleData, setRuleData] = useState<RuleDataType[]>([]); // Master Rule Data
  const [isRuleLoading, setIsRuleLoading] = useState<boolean>(false);
  const [ruleFieldDropdown, setRuleFieldDropdown] = useState<
    | undefined
    | FormLayoutComponentContainerType[]
    | FormLayoutComponentChildrenType[]
  >([]); // Source and Destination Fields Dropdown
  const [selectedRule, setSelectedRule] = useState<selectedDropdownRule | null>(
    selectedRuleObj
  ); // Particular/Selected Rule
  const [particularRule, setParticularRule] = useState<
    RuleDataType | undefined
  >(undefined); // Particular/Selected Rule for some logic
  const [isRuleActive, setIsRuleActive] = useState<boolean | undefined>(true); // Filter for Rules: active, Inactive, Both
  const [isAddRule, setIsAddRule] = useState<boolean>(false); // For addition of new Rule, Form open
  const [isOtherRule, setIsOtherRule] = useState<boolean>(false);
  const [ruleStatus, setRuleStatus] = useState<boolean | undefined>(false);
  const [dropdownTypeArr, setDropdownTypeArr] = useState<any>([]); // edv
  //End

  // Template Name State (Left Top)
  const [allTemplateData, setAllTemplateData] = useState<
    forTemplateNameDataType[]
  >([]); // All Data
  const [templateName, setTemplateName] = useState<string | undefined>(""); // Particular Display template Name
  //End

  const [change, setChange] = useState<number>(1); // To
  const [expanded, setExpanded] = useState<number>(-1);
  const [selectedItem, setSelectedItem] = useState(null);

  const [positionChangePanel, setPositionChangePanel] = useState<string[]>([]);
  const [paramData, setParamData] = useState(null);
  const [saveFormLoading, setIsSaveFormLoading] = useState<boolean>(false);
  const [companyDetails, setCompanyDetails] = useState("");
  const [expandedPanel, setExpandedPanel] = useState<
    undefined | string[] | number[]
  >([]);
  const [documentDropDownData, setDocumentDropDownData] = useState([]);

  const [mainArrayUpdate, setMainArrUpdate] = useState<number>(0);

  // State of Validation
  const [addValidation, setAddValidation] = useState<boolean>(false); // To create new validation
  const [validationFormData, setValidationFormData] = useState({
    name: "",
    regex: "",
    minLength: "",
    maxLenght: "",
  }); // State of new validation

  const [validationType, setValidationType] = useState<string>("");
  const [fromValidationSearch, setFromValidationSearch] =
    useState<boolean>(false); // To search from scroll or input field
  const [validationInput, setValidationInput] = useState<string | undefined>(
    undefined
  ); // Input search from api for validation
  const [page, setPage] = useState<number>(0); // page number for validation dropdown
  const [isMoreRecord, setIsMoreRecord] = useState<boolean>(true); // To know if need to call more api or not
  const [prevValue, setPrevValue] = useState<ValidationDataType[]>([]); // validation dropdown previous fetched value
  // End Validation State

  const [containerType, setContainerType] = useState<
    number | string | undefined
  >(-1); // to maintain rule fields dropdown, (same panel fields come first)

  // State of Grid Vlaue
  const [gridValue, setGridValue] = useState<number | undefined>(6); // Grid Value for Fields
  const [isCalled, setIsCalled] = useState<boolean>(false);
  // End

  const [isPropertyRule, setIsPropertyRule] = useState<boolean>(true); // Maintain state we are in Property or State Form
  const [editCaseDropdown, setEditCaseDropdown] = useState<boolean>(false); // Boolean State to manage edit case of shuffle rule drop down
  const [panelDropdown, setPanelDropdown] = useState([]); // Panel dropdown Top
  const [formNames, setFormName] = useState([]); // Get Rid of mui error --> change state
  // This state to track if any mandatory fields are missing in all records
  const [dirtyRecord, setDirtyRecord] = useState<dirtObjType[]>([]);

  // Left Side Bar
  const [leftBarFields, setLeftBarFields] = useState([]);
  const [advancedType, setAdvanceType] = useState<any[]>([]);
  const [sideBarLoading, setSideBarLpoading] = useState<boolean>(false);
  // End

  // Standard Fields are added when advanced object dragged
  const [currentObj, setCurrentObj] = useState<any>([]);

  //Post Rule Arr
  const [postRule, setPostRule] = useState<any[]>([]);

  // Handles a Container or a Component added on the form builder
  const handleItemAdded = (
    item: FormLayoutComponentChildrenType | FormLayoutComponentContainerType,
    containerId?: string,
    isOverMainContainer?: Boolean,
    arrayId?: string,
    fields?: any
  ) => {
    setIsEdited(true);

    // Common Method which returns order accordingly [ if array children contains record --> cal acc to last item of array children OR cal acc to container ]
    // Parameters : particularRecordOfMainArray, length of children of particular main Array
    const orderFunction = (mainContainer: any, mainChildLen: any) => {
      const arrChildLen =
        mainContainer?.children?.[mainChildLen]?.children?.length;
      let prevCase: any;

      // If child items are present in child array [ take form order of that last child ]
      if (arrChildLen) {
        prevCase =
          mainContainer.children[mainChildLen].children[arrChildLen - 1]
            ?.formOrder;
        // If no child item is there, use form order of array container
      } else {
        prevCase = mainContainer.children[mainChildLen].container?.formOrder;
      }

      return prevCase;
    };

    // Last Index of container
    let len = formLayoutComponents?.length - 1;

    // Form Order for Container
    if (item.itemType === FormItemTypes.CONTAINER) {
      const newState = formLayoutComponents.slice();
      let order;
      if (!formLayoutComponents.length) {
        order = 1;
      } else {
        // Checking array children length
        const lastLen = formLayoutComponents?.[len]?.children?.length;

        if (lastLen) {
          if (formLayoutComponents?.[len].children?.[lastLen - 1]?.formOrder) {
            order =
              formLayoutComponents?.[len].children?.[lastLen - 1]?.formOrder +
              1;
          } else {
            order = orderFunction(formLayoutComponents?.[len], lastLen - 1) + 1;
          }
        } else {
          order = formLayoutComponents?.[len].container.formOrder + 1;
        }
      }

      const updatedComponent: any = {
        ...(item as FormLayoutComponentContainerType),
        id: generateID(),
        formOrder: order,
      };

      setTimeout(() => {
        setIsPropertyRule(true); // Property Section
        setExpandedPanel([updatedComponent?.id]); // New Created Panel expand
        selectControl(updatedComponent); // New Created Panel Focus
      }, 0);

      newState.push({
        container: updatedComponent,
        children: [],
      });

      setFormLayoutComponents(newState);
      setMainArrUpdate(() => mainArrayUpdate + 1);

      // Form Order for children
    } else if (item.itemType === FormItemTypes.CONTROL) {
      let newState = formLayoutComponents.slice();
      let formContainerId = newState.findIndex(
        (f) => f.container.id === containerId
      );
      let formContainer = { ...newState[formContainerId] };

      // Last Index of children
      let childLen = formLayoutComponents?.[len]?.children?.length - 1;

      let order;

      // Order Logic Starts

      // For Array
      if (!isOverMainContainer) {
        // Clone of master data
        newState = formLayoutComponents.slice();

        // Index of Master Container
        formContainerId = newState.findIndex(
          (f) => f.container.id === containerId
        );

        // Last Index of Master Container
        const lastMastContainerIndex = newState?.length - 1;

        // Particular Master Container
        formContainer = { ...newState[formContainerId] };
        // Particular Master Children
        let newChildren = formContainer.children.slice();

        // Last Index of Master Children Arr
        const lastMastChildrenIndex = newChildren?.length - 1;

        // Index of Array Container
        const index: any = newChildren?.findIndex(
          (e) => e?.container?.id === arrayId
        );

        // 1. Main Container
        // 2. Main Children arr
        // 3. Arr Container
        // 4. Arr Children Arr

        // formContainer?.container;
        // formContainer?.children;
        // newChildren[index]?.container;
        // newChildren[index]?.children;

        // Case 1 : child Array === 0 && main Child Array === last Index && main Container === lastIndex
        if (
          !newChildren[index]?.children?.length &&
          index === lastMastChildrenIndex &&
          formContainerId === lastMastContainerIndex
        ) {
          order =
            newChildren[index]?.container?.formOrder &&
            newChildren[index]?.container?.formOrder + 1;
          // Case 2 : child Array !== 0 && main Child Array === last Index && main Container === lastIndex
        } else if (
          newChildren[index]?.children?.length &&
          index === lastMastChildrenIndex &&
          formContainerId === lastMastContainerIndex
        ) {
          const lastIndex = newChildren[index]?.children?.length - 1;
          order = newChildren[index]?.children?.[lastIndex]?.formOrder + 1;
          // Case 3 : child Array === 0 && main Container === lastIndex && main Child Array !== last Index
        } else if (
          !newChildren[index]?.children?.length &&
          index === lastMastChildrenIndex &&
          formContainerId !== lastMastContainerIndex
        ) {
          if (newChildren[index]?.container?.formOrder) {
            // order = getRandomFloat(
            //   newChildren[index]?.container?.formOrder,
            //   newState[formContainerId + 1]?.container?.formOrder
            // );
            order = roundOff(newChildren[index]?.container?.formOrder);
          }
          // Case 4 : child Array === 0 && main Container !== lastIndex
        } else if (
          !newChildren[index]?.children?.length &&
          index !== lastMastChildrenIndex
        ) {
          // If next record is Standard Field
          if (newChildren[index + 1]?.formOrder) {
            // order = getRandomFloat(
            //   newChildren[index]?.container?.formOrder,
            //   newChildren[index + 1]?.formOrder
            // );
            order =
              newChildren[index]?.container?.formOrder &&
              roundOff(newChildren[index]?.container?.formOrder);
            // If next record is Array Field
          } else {
            // order = getRandomFloat(
            //   newChildren[index]?.container?.formOrder,
            //   newChildren[index + 1]?.container?.formOrder
            // );
            order =
              newChildren[index]?.container?.formOrder &&
              roundOff(newChildren[index]?.container?.formOrder);
          }
        }
        // Case 5 : child Array !== 0 && main Children === lastIndex && main Container === lastIndex
        else if (
          newChildren[index]?.children?.length &&
          index === lastMastChildrenIndex &&
          formContainerId !== lastMastContainerIndex
        ) {
          const lastArrInd = newChildren[index]?.children?.length - 1;
          // order = getRandomFloat(
          //   newChildren[index]?.children?.[lastArrInd]?.formOrder,
          //   newState[formContainerId + 1]?.container?.formOrder
          // );
          order = roundOff(
            newChildren[index]?.children?.[lastArrInd]?.formOrder
          );

          // Case 6 : child Array !== 0 && main Children === lastIndex && main Container === lastIndex
        } else if (
          newChildren[index]?.children?.length &&
          index !== lastMastChildrenIndex
        ) {
          const lastArrInd = newChildren[index]?.children?.length - 1;

          order = roundOff(
            newChildren[index]?.children?.[lastArrInd]?.formOrder
          );

          // // If next record is Standard Field
          // if (newState[formContainerId]?.children?.[index + 1]?.formOrder) {
          //   // order = getRandomFloat(
          //   //   newChildren[index]?.children?.[lastArrInd]?.formOrder,
          //   //   newState[formContainerId]?.children?.[index + 1]?.formOrder
          //   // );
          //   order =
          //     newChildren[index]?.children?.[lastArrInd]?.formOrder + incDec;

          //   // If next record is Array Field
          // } else {
          //   // order = getRandomFloat(
          //   //   newChildren[index]?.children?.[lastArrInd]?.formOrder,
          //   //   newState[formContainerId]?.children?.[index + 1]?.container
          //   //     ?.formOrder
          //   // );
          //   order =
          //     newChildren[index]?.children?.[lastArrInd]?.formOrder + incDec;
          // }
        }
        // For non array elements
      } else {
        // First child Record added
        if (newState.length === 1 && !newState[0].children.length) {
          order = newState[0]?.container?.formOrder + 1;
          // Item added in newly formed children array.
        } else if (
          !formContainer.children.length &&
          newState?.length - 1 === formContainerId
        ) {
          order = formContainer.container.formOrder + 1;
          // Item added in last children array [item already present in children array]
        } else if (
          formContainer.children.length &&
          newState?.length - 1 === formContainerId
        ) {
          // Normal Case [ if prev case is Standard Field ]
          if (formContainer.children[childLen].formOrder) {
            order = formContainer.children[childLen].formOrder + 1;
          } else {
            // // Check if child items are present in child array
            // const arrChildLen =
            //   formContainer?.children?.[childLen]?.children?.length;
            // let prevCase: any;

            // // If child items are present in child array [ take form order of that last child ]
            // if (arrChildLen) {
            //   prevCase =
            //     formContainer.children[childLen].children[arrChildLen - 1]
            //       ?.formOrder;
            //   // If no child item is there, use form order of array container
            // } else {
            //   prevCase = formContainer.children[childLen].container?.formOrder;
            // }

            orderFunction(formContainer, childLen);

            order = orderFunction(formContainer, childLen) + 1;
          }
          // Item added in mid starts
        } else {
          // First child Item [ no array case possible ]
          if (!formContainer.children.length) {
            // order = getRandomFloat(
            //   newState[formContainerId]?.container?.formOrder,
            //   newState[formContainerId + 1]?.container?.formOrder
            // );

            order = roundOff(newState[formContainerId]?.container?.formOrder);

            // Child Item already present
          } else {
            const childLastIndex =
              newState[formContainerId]?.children?.length - 1;

            // Normal Case [ prev state is Standard Field ]
            if (
              newState[formContainerId]?.children?.[childLastIndex]?.formOrder
            ) {
              // order = getRandomFloat(
              //   newState[formContainerId]?.children?.[childLastIndex]
              //     ?.formOrder,
              //   newState[formContainerId + 1]?.container?.formOrder
              // );

              order = roundOff(
                newState[formContainerId]?.children?.[childLastIndex]?.formOrder
              );

              // If prev state is array
            } else {
              // Check if child items are present in child array
              // const arrChildLen =
              //   newState[formContainerId]?.children?.[childLastIndex]?.children
              //     ?.length;
              // let prevCase: any;

              // // If child items are present in child array [ take form order of that last child ]
              // if (arrChildLen) {
              //   prevCase =
              //     newState[formContainerId]?.children?.[childLastIndex]
              //       ?.children[arrChildLen - 1]?.formOrder;
              //   // If no child item is there, use form order of array container
              // } else {
              //   prevCase =
              //     newState[formContainerId]?.children?.[childLastIndex]
              //       ?.container?.formOrder;
              // }

              //orderFunction(newState[formContainerId],childLastIndex)

              // order = getRandomFloat(
              //   orderFunction(newState[formContainerId], childLastIndex),
              //   newState[formContainerId + 1]?.container?.formOrder
              // );

              order = roundOff(
                orderFunction(newState[formContainerId], childLastIndex)
              );
            }
          }
        }
        // Item added in mid ends
      }

      // Order Logic Ends

      // common function
      const addStandardFields = (obj: any) => {
        const newState = formLayoutComponents.slice();
        const childItem = item as FormLayoutComponentChildrenType;
        if (childItem.items) {
          obj.items = JSON.parse(JSON.stringify(childItem.items));
        }

        const newChildren = formContainer.children.slice(); // childern array of one of main container
        if (!isOverMainContainer) {
          newChildren
            ?.filter((e) => e?.container)
            ?.find((f) => f?.container?.id === arrayId)
            ?.children?.push(obj as FormLayoutComponentChildrenType);
        } else {
          newChildren.push(obj as FormLayoutComponentChildrenType);
          setCurrentObj((prev: any) => [...prev, obj]);
        }

        formContainer.children = newChildren;
        newState[formContainerId] = formContainer;
        setFormLayoutComponents(newState);

        if (obj?.rules) {
          const ruleArr: any = [];
          if (obj?.rules?.length > 0) {
            for (let j = 0; j < obj?.rules?.length; j++) {
              if (Object.keys(obj?.rules[j])?.length) {
                ruleArr.push({
                  ...obj?.rules[j],
                  appliedId: obj?.id,
                  postTriggerRuleIds: obj?.rules[j]?.postTriggerRuleIds
                    ? obj?.rules[j]?.postTriggerRuleIds
                    : undefined,
                });
              }
            }
            setRuleObjectList((prev: any) => [...prev, ...ruleArr]);
          }
        }
      };

      // Advanced Type Logic starts
      if (item?.type === "ADV") {
        const advancedField = advancedType?.find(
          (e: any) => e?.meta?.properties?.label === item?.label
        );
        const obj: any = {
          ...(item as FormLayoutComponentChildrenType),
          id: generateID(),
          containerId: containerId,
          formOrder: order,
          required: false,
          gridValue: item?.gridColumn,
          mandatory: false,
          visible: true,
          deleted: false,
          editable: false,
          name: advancedField?.meta?.properties?.label,
          placeholder: `Placeholder for ${item?.label}`,
          type: advancedField?.baseType,
        };

        if (advancedField?.meta?.properties?.validation?.length > 0) {
          const singleValidationData = validationRawData?.find(
            (e: any) => e?.name === advancedField?.meta?.properties?.validation
          );

          // validation logic
          const validationObj = {
            id: obj.id,
            formFillValidation: singleValidationData,
            containerId: containerId,
          };

          const containerIndex = formLayoutComponents.findIndex(
            (e: any) => e?.container?.id === validationObj?.containerId
          );

          if (containerIndex > -1) {
            const newFormData = JSON.parse(
              JSON.stringify(formLayoutComponents)
            );

            if (!isOverMainContainer) {
              newFormData[containerIndex]?.children
                ?.filter((e: any) => e?.container)
                ?.find((f: any) => f?.container?.id === arrayId)
                ?.children?.push({
                  ...obj,
                  formFillValidation: validationObj?.formFillValidation,
                });
            } else {
              newFormData[containerIndex]?.children?.push({
                ...obj,
                formFillValidation: validationObj?.formFillValidation,
              });
            }

            setFormLayoutComponents(newFormData);
            selectControl({
              ...obj,
              formFillValidation: validationObj?.formFillValidation,
            });
          }
        }
      }
      // Advanced Type ends
      // Advanced Object Logic starts
      else if (fields?.length) {
        let orderNoObjectField = order;
        let obj: any,
          arr: any = [];

        // Label for Object
        obj = {
          ...(item as FormLayoutComponentChildrenType),
          id: generateID(),
          containerId: containerId,
          formOrder: orderNoObjectField,
          required: false,
          gridValue: item?.gridColumn,
          mandatory: false,
          visible: true,
          deleted: false,
          editable: false,
          name: item?.label,
          type: "LABEL",
        };

        addStandardFields(obj);

        for (let i = 0; i < fields?.length; i++) {
          orderNoObjectField = roundOff(orderNoObjectField);
          obj = {
            ...(item as FormLayoutComponentChildrenType),
            id: generateID(),
            containerId: containerId,
            formOrder: orderNoObjectField,
            required: false,
            gridValue: fields?.[i]?.properties?.gridColumn,
            mandatory: false,
            visible: true,
            deleted: false,
            editable: false,
            name: fields?.[i]?.properties?.label,
            placeholder: `Placeholder for ${fields?.[i]?.properties?.label}`,
            type: fields?.[i]?.formType,
          };

          if (standardArray.includes(obj?.type)) {
            addStandardFields({ ...obj, rules: fields[i]?.rules });
          } else {
            const advancedField = advancedType?.find(
              (e: any) => e?.name === fields[i]?.formType
            );
            orderNoObjectField = roundOff(orderNoObjectField);
            obj = {
              ...(item as FormLayoutComponentChildrenType),
              id: generateID(),
              containerId: containerId,
              formOrder: orderNoObjectField,
              required: false,
              gridValue: fields?.[i]?.properties?.gridColumn,
              mandatory: false,
              visible: true,
              deleted: false,
              editable: false,
              name: advancedField?.meta?.properties?.label,
              placeholder: `Placeholder for ${fields?.[i]?.properties?.label}`,
              type: advancedField?.baseType,
            };

            addStandardFields({ ...obj, rules: fields[i]?.rules });
            if (advancedField?.meta?.properties?.validation?.length > 0) {
              const singleValidationData = validationRawData?.find(
                (e: any) =>
                  e?.name === advancedField?.meta?.properties?.validation
              );

              arr.push({
                id: obj.id,
                formFillValidation: singleValidationData,
                containerId: containerId,
              });

              setValidationObjArr(arr);
            }

            // const ruleArr = [];
            // if (fields?.[i]?.rules?.length > 0) {
            //   for (let j = 0; j < fields?.[i]?.rules?.length; j++) {
            //     if (Object.keys(fields?.[i]?.rules[j])?.length) {
            //       ruleArr.push({
            //         ...fields?.[i]?.rules[j],
            //         appliedId: obj?.id,
            //       });
            //     }
            //   }
            //   setRuleObjectList(ruleArr);
            // }
          }
        }

        setTimeout(() => {
          setIsPropertyRule(true); // Property Section
          setExpandedPanel([formContainer?.container?.id]); // New Created Panel expand
          selectControl(formContainer?.container); // New Created Panel Focus
        }, 0);

        setMainArrUpdate(() => mainArrayUpdate + 1);
        return;
      }
      // Advanced Object Logic ends

      // Standard fields Logic
      else {
        let obj: any;

        obj = {
          ...(item as FormLayoutComponentChildrenType),
          id: generateID(),
          containerId: containerId,
          formOrder: order,
          required: false,
          gridValue: item?.gridColumn,
          mandatory: false,
          visible: true,
          deleted: false,
          editable: false,
          name: item?.label,
          placeholder: " ",
        };

        setIsPropertyRule(true); // Property Section
        selectControl(obj); // New Created Field Focus

        if (item?.type !== "ARRAY") {
          const childItem = item as FormLayoutComponentChildrenType;
          if (childItem.items) {
            obj.items = JSON.parse(JSON.stringify(childItem.items));
          }
        } else {
          obj = {
            container: {
              ...obj,
            },
            children: [],
          };
        }

        const newChildren = formContainer.children.slice(); // childern array of one of main container
        if (!isOverMainContainer) {
          newChildren
            ?.filter((e) => e?.container)
            ?.find((f) => f?.container?.id === arrayId)
            ?.children?.push(obj as FormLayoutComponentChildrenType);
        } else {
          newChildren.push(obj as FormLayoutComponentChildrenType);
        }

        formContainer.children = newChildren;
        newState[formContainerId] = formContainer;
        setFormLayoutComponents(newState);
        setMainArrUpdate(() => mainArrayUpdate + 1);
      }
      // Standard fields ends
    }
  };

  // Common Delete Function
  const commonDeletRecord = async (id: any) => {
    const endPoint = `v2/document-types/${window.sessionStorage.getItem(
      "docId"
    )}/form-fill-metadatas`;
    let end = `${endPoint}/${id}`;
    try {
      await AxiosDelete({
        endPoint: end,
        //payload: payload,
      });
    } catch (err) {}
  };

  // Delete a container from the layout
  // Previously Sending Payload also but now only id. [ thats why code is written for payload ]
  const deleteContainer = async (containerId: string | number) => {
    const index = formLayoutComponents.findIndex(
      (e) => e?.container?.id === containerId
    );

    const updatedState: any = formLayoutComponents.slice();
    updatedState[index].container.deleted = true;
    setIsSaveFormLoading(true);

    if (index > -1 && typeof containerId === "number") {
      // Children of Pannel
      for (let i = 0; i < updatedState[index]?.children?.length; i++) {
        if (
          typeof updatedState[index]?.children[i]?.container?.id === "number"
        ) {
          updatedState[index].children[i].container.deleted = true;

          await commonDeletRecord(
            updatedState[index]?.children[i]?.container?.id
          );
          if (updatedState[index]?.children[i]?.children?.length) {
            for (
              let j = 0;
              j < updatedState[index]?.children[i]?.children?.length;
              j++
            ) {
              if (
                typeof updatedState[index]?.children[i]?.children[j]?.id ===
                "number"
              ) {
                updatedState[index].children[i].children[j].deleted = true;
                await commonDeletRecord(
                  updatedState[index]?.children[i]?.children[j]?.id
                );
              }
            }
          }
        } else if (typeof updatedState[index]?.children[i]?.id === "number") {
          updatedState[index].children[i].deleted = true;
          await commonDeletRecord(formLayoutComponents[index]?.children[i]?.id);
        }
      }

      // Main Pannel
      await commonDeletRecord(containerId);
    }

    // Delete dirty record (if exists)
    const updatedDirtyRecord = JSON.parse(JSON.stringify(dirtyRecord));
    const id = dirtyRecord?.findIndex((e: any) => e?.panelId === containerId);
    if (id > -1) {
      updatedDirtyRecord?.splice(id, 1);
    }
    setDirtyRecord(updatedDirtyRecord);
    // End

    showModalStrip("success", "Records are deleted.", 5000);
    setIsSaveFormLoading(false);

    //setFormLayoutComponents(updatedState);
    //setMainArrUpdate(() => mainArrayUpdate + 1);

    const newState = formLayoutComponents.filter(
      (comp) => comp.container.id !== containerId
    );
    setFormLayoutComponents(newState);
    setMainArrUpdate(() => mainArrayUpdate + 1);

    // setSelectedControl((prev) =>
    //   prev &&
    //   (prev.id === containerId ||
    //     (prev as FormLayoutComponentChildrenType).containerId === containerId)
    //     ? undefined
    //     : prev
    // );
  };

  // Delete a control from the layout
  // New type just pass id to delete
  const deleteControl = async (controlId: string, containerId: string) => {
    setIsSaveFormLoading(true);
    const newState = formLayoutComponents.slice();
    const formContainer: any = newState.filter(
      (comp) => comp.container.id === containerId
    )[0];

    // Api delete also along with local
    if (typeof controlId === "number") {
      const index = formContainer?.children?.findIndex(
        (e: any) => e?.id === controlId
      );

      // Whole array is requested to delete
      if (index === -1) {
        const index = formContainer?.children?.findIndex(
          (e: any) => e?.container?.id === controlId
        );

        if (index > -1) {
          for (
            let i = 0;
            i < formContainer?.children[index]?.children?.length;
            i++
          ) {
            if (
              typeof formContainer?.children[index]?.children[i]?.id ===
              "number"
            ) {
              await commonDeletRecord(
                formContainer?.children[index]?.children[i]?.id
              );
            }
          }

          formContainer?.children?.splice(index, 1);
        }
      }
      // Standard Field requested to delete
      else {
        formContainer?.children?.splice(index, 1);
      }

      // Standard Field is requested to delete or // Container of array
      await commonDeletRecord(controlId);
    }
    // Only local Delete
    else {
      const index = formContainer?.children?.findIndex(
        (e: any) => e?.id === controlId
      );

      // Whole array is requested to delete
      if (index === -1) {
        const index = formContainer?.children?.findIndex(
          (e: any) => e?.container?.id === controlId
        );

        if (index > -1) {
          formContainer?.children?.splice(index, 1);
        }
      }
      // Standard Field requested to delete
      else {
        formContainer?.children?.splice(index, 1);
      }
    }

    // Delete dirty record (if exists)
    const updatedDirtyRecord = JSON.parse(JSON.stringify(dirtyRecord));
    for (let i = 0; i < dirtyRecord?.length; i++) {
      const id: number = dirtyRecord[i]?.children?.findIndex(
        (e: any) => e?.childrenId === controlId
      );
      if (id > -1) {
        updatedDirtyRecord[i]?.children?.splice(id, 1);
      }
    }
    setDirtyRecord(updatedDirtyRecord);
    // End

    showModalStrip("success", "Records are deleted.", 5000);
    setIsSaveFormLoading(false);

    setFormLayoutComponents(newState);
    setMainArrUpdate(() => mainArrayUpdate + 1);
    // setSelectedControl((prev) =>
    //   prev && prev.id === controlId ? undefined : prev
    // );
  };

  // Selet a control on click
  const selectControl = (
    item:
      | FormLayoutComponentChildrenType
      | FormLayoutComponentContainerType
      | undefined
  ) => {
    setSelectedControl(item);
  };

  // Edit properties of the control from Sidebar
  const editControlProperties = (
    item: FormLayoutComponentChildrenType,
    type: string
  ) => {
    setIsEdited(true);
    const newState = formLayoutComponents.slice();

    const formContainerId = newState.findIndex(
      (comp) => comp.container.id === item.containerId
    );

    let formContainer = { ...newState[formContainerId] };

    if (item?.type === "ARRAY") {
      formContainer.children?.forEach((cont, ind) => {
        if (cont?.container?.id === item.id) {
          const newChildren: any = formContainer.children.slice();
          newChildren[ind].container = { ...item, isUpdated: true };
          //formContainer.children = { ...newChildren, isUpdated: true };
          return;
        }
      });
    } else {
      const arrayContainer = formContainer.children?.filter(
        (e) => e?.container
      );
      let resObj;

      const findArray = (arr: any, id: string) => {
        for (let i = 0; i < arr?.children.length; i++) {
          if (arr.children[i].id === id) {
            return arr;
          }
        }
      };

      for (let i = 0; i < arrayContainer?.length; i++) {
        resObj = findArray(arrayContainer[i], item.id);
        if (resObj) {
          break;
        }
      }

      if (resObj) {
        const newChildren = formContainer.children.slice();
        const index = newChildren?.findIndex(
          (e) => e?.container?.id === resObj?.container?.id
        );
        const finalIndex = newChildren[index]?.children?.findIndex(
          (e: { id: string }) => e?.id === item.id
        );

        if (finalIndex > -1) {
          newChildren[index].children[finalIndex] = {
            ...item,
            isUpdated: true,
          };
          formContainer.children = newChildren;
        }
      } else {
        // console.log(`formContainer 222`, formContainer);
        formContainer?.children?.forEach((cont, ind) => {
          if (cont.id === item.id) {
            const newChildren = formContainer.children.slice();
            newChildren[ind] = { ...item, isUpdated: true };
            formContainer.children = newChildren;
            return;
          }
        });
      }
    }

    //,isUpdate: true
    newState[formContainerId] = formContainer;
    setFormLayoutComponents(newState);

    if (type !== "noDropdownUpdate") {
      setMainArrUpdate(() => mainArrayUpdate + 1);
    }
  };

  // Edit properties of the container
  const editContainerProperties = (item: FormLayoutComponentContainerType) => {
    setIsEdited(true);
    const newState = formLayoutComponents.slice();
    const formContainerId = newState.findIndex(
      (comp) => comp.container.id === item.id
    );
    const formContainer = { ...newState[formContainerId] };
    formContainer.container = {
      ...formContainer.container,
      name: item.name,
      isUpdated: true,
      //subHeading: item.subHeading,
    };
    newState[formContainerId] = formContainer;
    setFormLayoutComponents(newState);
    setMainArrUpdate(() => mainArrayUpdate + 1);
  };

  // Move a control from the sidebar based on the values on Drop Down
  const moveControlFromSide = (
    item: FormLayoutComponentChildrenType,
    { containerId, position }: FormLayoutComponentChildrenType
  ) => {
    let componentsCopy: FormLayoutComponentsType[] = JSON.parse(
      JSON.stringify(formLayoutComponents)
    );

    const currentItemContainer = componentsCopy.filter((con, ind) => {
      return con.container.id === item.containerId;
    })[0];

    const moveItemToContainer = componentsCopy.filter((con, ind) => {
      return con.container.id === containerId;
    })[0];

    const itemIndex = currentItemContainer.children.findIndex(
      (con) => con.id === item.id
    );

    const deletedItem = currentItemContainer.children.splice(itemIndex, 1);
    deletedItem[0].containerId = containerId;

    if (position !== undefined) {
      moveItemToContainer.children.splice(position, 0, deletedItem[0]);
    } else {
      if (item.containerId !== containerId) {
        if (position) {
          moveItemToContainer.children.splice(position, 0, deletedItem[0]);
        }
      } else {
        moveItemToContainer.children.splice(itemIndex, 0, deletedItem[0]);
      }
    }
    setSelectedControl(deletedItem[0]);
    setFormLayoutComponents(componentsCopy);
    setMainArrUpdate(() => mainArrayUpdate + 1);
  };

  // Move control when dragged to a different position
  const moveControl = (
    item: FormLayoutComponentChildrenType,
    dragIndex: number,
    hoverIndex: number,
    containerId: string
  ) => {
    if (item === undefined) {
      return;
    }

    if (
      dragIndex !== undefined &&
      !positionChangePanel?.includes(containerId)
    ) {
      setPositionChangePanel((val) => {
        const updatedVal = [...val, containerId];
        return updatedVal;
      });
    }

    let componentsCopy: FormLayoutComponentsType[] = JSON.parse(
      JSON.stringify(formLayoutComponents)
    );

    if (dragIndex !== undefined && item.id) {
      if (item.containerId === containerId) {
        const formContainer = componentsCopy.filter((con, ind) => {
          return con.container.id === containerId;
        })[0];
        const deletedItem = formContainer.children.splice(
          formContainer.children.findIndex((con) => con.id === item.id),
          1
        );
        // Just to be sure that there is a item deleted
        if (deletedItem.length === 0) {
          return;
        }
        formContainer.children.splice(hoverIndex, 0, deletedItem[0]);
      }
      setFormLayoutComponents(componentsCopy);
      setMainArrUpdate(() => mainArrayUpdate + 1);
    }
  };

  const checkIfControlsInContainer = () => {
    for (let i = 0; i < formLayoutComponents.length; i++) {
      let componentChildren = formLayoutComponents[i].children;
      if (componentChildren.length === 0) {
        showModalStrip(
          "danger",
          "You need to have controls inside containers before updating.",
          5000
        );
        return false;
      }
    }
    return true;
  };

  const publishForm = () => {
    if (formLayoutComponents.length === 0) {
      showModalStrip("danger", "Form cannot be empty", 5000);
      return;
    }

    if (!checkIfControlsInContainer()) {
      return;
    }

    let currentTemplate: TemplateType = JSON.parse(
      JSON.stringify(selectedTemplate)
    );

    // Check if there is a change in the previous published version
    if (
      currentTemplate.publishHistory.length > 0 &&
      JSON.stringify(currentTemplate.publishHistory[0].formLayoutComponents) ===
        JSON.stringify(formLayoutComponents)
    ) {
      showModalStrip(
        "info",
        "No Change in current & previous published version.",
        5000
      );
      return;
    }

    let updatedAt = moment().unix() * 1000;

    if (currentTemplate.lastPublishedAt !== 0) {
      // Add current layout components to publish history
      currentTemplate.publishHistory.splice(0, 0, {
        lastPublishedAt: currentTemplate.lastPublishedAt,
        formLayoutComponents: currentTemplate.formLayoutComponents,
      });
    }
    currentTemplate.formLayoutComponents = formLayoutComponents;
    currentTemplate.publishStatus = FormPublishStatus.PUBLISHED;
    currentTemplate.lastPublishedAt = updatedAt;
    currentTemplate.updatedAt = updatedAt;

    dispatch(saveTemplate(currentTemplate))
      .unwrap()
      .then((newTemplate) => {
        // Adding this so that the current template in the state is updated.
        setSelectedTemplate(newTemplate);
        showModalStrip("success", "Changes in Form Published.", 5000);
      });
  };

  // To Recieve Records after saving or any error occured
  const defaultState = (type: string, message: string = " success ") => {
    if (type === "success") {
      showModalStrip("success", message, 5000);
    } else if (type === "error") {
      showModalStrip("danger", "Something went wronng", 5000);
    }
    setFormLayoutComponents([]);
    selectControl(undefined);
    setRuleData([]);
    setIsEdited(false);
    setParticularRule(undefined);
    getTemplateData({ docId: window.sessionStorage.getItem("docId") });
    setExpanded(-1);
    setSelectedItem(null);
  };

  // Master Save Button
  const saveForm = async () => {
    const endPoint = `v2/document-types/${window.sessionStorage.getItem(
      "docId"
    )}/form-fill-metadatas`;

    // Check dirty record
    for (let i = 0; i < dirtyRecord?.length; i++) {
      for (let j = 0; j < dirtyRecord[i]?.children?.length; j++) {
        for (let k = 0; k < dirtyRecord[i]?.children[j]?.rule?.length; k++) {
          if (
            dirtyRecord[i]?.children[j]?.rule[k]?.sourceIds?.length > 0 ||
            dirtyRecord[i]?.children[j]?.rule[k]?.destinationIds?.length > 0
          ) {
            showModalStrip(
              "danger",
              "Please fill mandatory fields before saving.",
              5000
            );
            return;
          }
        }
      }
    }

    //Form Order Logic
    const stateFormData = formLayoutComponents.slice();
    for (let i = 0; i < stateFormData.length; i++) {
      if (positionChangePanel.includes(stateFormData[i]?.container?.id)) {
        let order = stateFormData[i]?.container?.formOrder;
        for (let j = 0; j < stateFormData[i]?.children?.length; j++) {
          order++;
          stateFormData[i].children[j].formOrder = order;
          stateFormData[i].children[j].isUpdated = true;
        }
      }
    }

    setFormLayoutComponents(stateFormData);
    setMainArrUpdate(() => mainArrayUpdate + 1);

    if (!isEdited) {
      showModalStrip(
        "info",
        "You need to add or Update fields before saving.",
        5000
      );
      return;
    }

    setIsSaveFormLoading(true);
    const ids = [];
    for (let i = 0; i < formLayoutComponents.length; i++) {
      //Panel (Container) Logic
      if (formLayoutComponents[i]?.container) {
        let { controlName, displayText, icon, id, itemType, ...payload } =
          formLayoutComponents[i]?.container;
        if (typeof formLayoutComponents[i]?.container?.id === "string") {
          try {
            let res = await AxiosPost({ endPoint, payload });
            ids.push({
              prevId: formLayoutComponents[i]?.container?.id,
              id: res?.data?.id,
            });
            if (
              selectedControl?.formOrder ===
              formLayoutComponents[i]?.container?.formOrder
            ) {
              selectControl(formLayoutComponents[i]?.container);
            }
          } catch (err) {
            defaultState("error");
          }
        } else if (formLayoutComponents[i]?.container?.isUpdated) {
          let end = `${endPoint}/${formLayoutComponents[i]?.container?.id}`;
          try {
            await AxiosPatch({ endPoint: end, payload });
            if (
              selectedControl?.formOrder ===
              formLayoutComponents[i]?.container?.formOrder
            ) {
              selectControl(formLayoutComponents[i]?.container);
            }
          } catch (err) {
            defaultState("error");
          }
        }
      }
      // Panel Logic ends

      //Children (Control) Logic
      if (formLayoutComponents[i]?.children) {
        for (let j = 0; j < formLayoutComponents[i]?.children?.length; j++) {
          // For Array Type
          if (formLayoutComponents[i]?.children?.[j]?.container?.id) {
            let payload: any =
              formLayoutComponents[i]?.children?.[j]?.container;
            payload.type = "ARRAY_HDR";
            let {
              controlName,
              displayText,
              icon,
              id,
              itemType,
              containerId,
              ...updatedPayload
            } = payload;

            // For Container [ array header ]
            if (
              typeof formLayoutComponents[i]?.children?.[j]?.container?.id ===
              "string"
            ) {
              try {
                let res = await AxiosPost({
                  endPoint,
                  payload: {
                    ...updatedPayload,
                    formOrder: roundOff(
                      formLayoutComponents[i]?.children?.[j]?.children[0]
                        ?.formOrder,
                      "sub"
                    ),
                    variableName: variableName(
                      formLayoutComponents[i]?.children?.[j]?.container?.name
                    ),
                  },
                });
                ids.push({
                  prevId: payload?.id,
                  id: res?.data?.id,
                });
              } catch (err) {}
            }

            for (
              let k = 0;
              k < formLayoutComponents[i]?.children?.[j]?.children?.length;
              k++
            ) {
              let {
                controlName,
                displayText,
                icon,
                id,
                itemType,
                containerId,
                ...payload
              } = formLayoutComponents[i]?.children?.[j]?.children[k];
              if (
                typeof formLayoutComponents[i]?.children[j]?.children[k]?.id ===
                "string"
              ) {
                if (payload?.type === "FILE" || payload?.type === "IMAGE") {
                  payload.placeholder = "";
                }

                const id = ids?.find(
                  (e: any) =>
                    e?.prevId ===
                    formLayoutComponents[i]?.children?.[j]?.container?.id
                )?.id;
                if (id) {
                  payload.headerMetadataId = id;
                }

                payload.variableName = variableName(
                  formLayoutComponents[i]?.children[j]?.children[k]?.name
                );

                try {
                  let res = await AxiosPost({
                    endPoint,
                    payload,
                  });
                  ids.push({
                    prevId:
                      formLayoutComponents[i]?.children[j]?.children[k]?.id,
                    id: res?.data?.id,
                  });

                  // if (
                  //   selectedControl?.formOrder ===
                  //   formLayoutComponents[i]?.children[j]?.formOrder
                  // ) {
                  //   selectControl(formLayoutComponents[i]?.children[j]);
                  // }

                  //Validation Logic
                  const endPointValidation: string =
                    "v2/form-fill-metadata-validation";
                  const payloadValidation = {
                    formFillMetadataValidationId: res?.data?.id,
                    formValidationId:
                      formLayoutComponents[i]?.children[j]?.children[k]
                        ?.formFillValidation?.formValidationId,
                  };
                  if (
                    !formLayoutComponents[i]?.children[j]?.children[k]
                      ?.isValidationUpdate &&
                    payloadValidation?.formValidationId
                  ) {
                    await AxiosPost({
                      endPoint: endPointValidation,
                      payload: payloadValidation,
                    });
                  }
                } catch (err) {
                  defaultState("error");
                }
              } else if (
                formLayoutComponents[i]?.children[j]?.children[k]?.isUpdated
              ) {
                let end = `${endPoint}/${formLayoutComponents[i]?.children[j]?.children[k]?.id}`;
                payload.variableName = variableName(
                  formLayoutComponents[i]?.children[j]?.children[k]?.name
                );
                try {
                  await AxiosPatch({ endPoint: end, payload });
                } catch (err) {
                  defaultState("error");
                }
                // if (
                //   selectedControl?.formOrder ===
                //   formLayoutComponents[i]?.children[j]?.formOrder
                // ) {
                //   selectControl(formLayoutComponents[i]?.children[j]);
                // }

                //Validation Logic
                const endPointValidation: string =
                  "v2/form-fill-metadata-validation";
                const payloadValidation = {
                  formFillMetadataValidationId:
                    formLayoutComponents[i]?.children[j]?.children[k]?.id,
                  formValidationId:
                    formLayoutComponents[i]?.children[j]?.children[k]
                      ?.formFillValidation?.formValidationId,
                };
                if (
                  formLayoutComponents[i]?.children[j]?.children[k]
                    ?.isValidationUpdate === true
                ) {
                  try {
                    const selectedTemplateValidationEndPoint = `/v2/form-fill-metadatas/${formLayoutComponents[i]?.children[j]?.children[k]?.id}/validations`;

                    const response = await AxiosGet({
                      endPoint: selectedTemplateValidationEndPoint,
                    });
                    const validation =
                      response?.data?.formValidations[0] ?? null;

                    const end = `${endPointValidation}/${validation?.formFillMetadataValidationId}`;
                    await AxiosPatch({
                      endPoint: end,
                      payload: payloadValidation,
                    });
                  } catch (err) {}
                } else if (
                  formLayoutComponents[i]?.children[j]?.children[k]
                    ?.isValidationUpdate === false
                ) {
                  await AxiosPost({
                    endPoint: endPointValidation,
                    payload: payloadValidation,
                  });
                }
              }
            }

            // [ array end ]
            if (
              typeof formLayoutComponents[i]?.children?.[j]?.container?.id ===
              "string"
            ) {
              updatedPayload.type = "ARRAY_END";
              const id = ids?.find(
                (e: any) =>
                  e?.prevId ===
                  formLayoutComponents[i]?.children?.[j]?.container?.id
              )?.id;
              if (id) {
                updatedPayload.headerMetadataId = id;
              }
              try {
                let res = await AxiosPost({
                  endPoint,
                  payload: {
                    ...updatedPayload,
                    formOrder: roundOff(
                      formLayoutComponents[i]?.children?.[j]?.children[
                        formLayoutComponents[i]?.children?.[j]?.children
                          ?.length - 1
                      ]?.formOrder
                    ),
                    variableName: variableName(
                      formLayoutComponents[i]?.children[j]?.container?.name
                    ),
                  },
                });
                ids.push({
                  prevId: payload?.id,
                  id: res?.data?.id,
                });
              } catch (err) {}
            }

            // Array Ends
          }
          // For Standard Fields
          else {
            let {
              controlName,
              displayText,
              icon,
              id,
              itemType,
              containerId,
              ...payload
            }: any = formLayoutComponents[i]?.children[j];

            if (typeof formLayoutComponents[i]?.children[j]?.id === "string") {
              if (payload?.type === "FILE" || payload?.type === "IMAGE") {
                payload.placeholder = "";
              }
              payload.variableName = variableName(
                formLayoutComponents[i]?.children[j]?.name
              );
              try {
                let res = await AxiosPost({ endPoint, payload });
                ids.push({
                  prevId: formLayoutComponents[i]?.children[j]?.id,
                  id: res?.data?.id,
                });

                // if (
                //   selectedControl?.formOrder ===
                //   formLayoutComponents[i]?.children[j]?.formOrder
                // ) {
                //   selectControl(formLayoutComponents[i]?.children[j]);
                // }

                //Validation Logic
                const endPointValidation: string =
                  "v2/form-fill-metadata-validation";
                const payloadValidation = {
                  formFillMetadataValidationId: res?.data?.id,
                  formValidationId:
                    formLayoutComponents[i]?.children[j]?.formFillValidation
                      ?.formValidationId,
                };
                if (
                  !formLayoutComponents[i]?.children[j]?.isValidationUpdate &&
                  payloadValidation?.formValidationId
                ) {
                  await AxiosPost({
                    endPoint: endPointValidation,
                    payload: payloadValidation,
                  });
                }
              } catch (err) {
                defaultState("error");
              }
              //Validation Logic Ends
            } else if (formLayoutComponents[i]?.children[j]?.isUpdated) {
              let end = `${endPoint}/${formLayoutComponents[i]?.children[j]?.id}`;
              payload.variableName = variableName(
                formLayoutComponents[i]?.children[j]?.name
              );
              try {
                await AxiosPatch({ endPoint: end, payload });
              } catch (err) {
                defaultState("error");
              }
              // if (
              //   selectedControl?.formOrder ===
              //   formLayoutComponents[i]?.children[j]?.formOrder
              // ) {
              //   selectControl(formLayoutComponents[i]?.children[j]);
              // }

              //Validation Logic
              const endPointValidation: string =
                "v2/form-fill-metadata-validation";
              const payloadValidation = {
                formFillMetadataValidationId:
                  formLayoutComponents[i]?.children[j]?.id,
                formValidationId:
                  formLayoutComponents[i]?.children[j]?.formFillValidation
                    ?.formValidationId,
              };
              if (
                formLayoutComponents[i]?.children[j]?.isValidationUpdate ===
                true
              ) {
                try {
                  const selectedTemplateValidationEndPoint = `/v2/form-fill-metadatas/${formLayoutComponents[i]?.children[j]?.id}/validations`;

                  const response = await AxiosGet({
                    endPoint: selectedTemplateValidationEndPoint,
                  });
                  const validation = response?.data?.formValidations[0] ?? null;

                  const end = `${endPointValidation}/${validation?.formFillMetadataValidationId}`;
                  await AxiosPatch({
                    endPoint: end,
                    payload: payloadValidation,
                  });
                } catch (err) {}
              } else if (
                formLayoutComponents[i]?.children[j]?.isValidationUpdate ===
                false
              ) {
                await AxiosPost({
                  endPoint: endPointValidation,
                  payload: payloadValidation,
                });
              }
            }
          }
          // Standard Ends
        }
      }
      // Children Logic ends
    }

    //Rule Logic followed by param,
    const endPointRule = `v2/document-types/${sessionStorage.getItem(
      "docId"
    )}/form-fill-rule`;

    const postRuleAtSaveTime: any = [];

    for (let i = 0; i < ruleData?.length; i++) {
      //rule not saved ( New )

      if (typeof ruleData[i]?.id === "string") {
        // source id is not present
        if (!ruleData[i]?.sourceIds?.length) {
          if (typeof ruleData[i]?.formFillMetadataId === "number") {
            ruleData[i].sourceIds = [ruleData[i]?.formFillMetadataId];
          } else if (typeof ruleData[i]?.formFillMetadataId === "string") {
            const id = ids?.find(
              (e) => e?.prevId === ruleData[i]?.formFillMetadataId
            )?.id;
            ruleData[i].sourceIds = [id];
          }
        } else {
          for (let j = 0; j < ruleData[i]?.sourceIds?.length; j++) {
            if (typeof ruleData[i]?.sourceIds[j] === "string") {
              const id = ids?.find(
                (e) => e?.prevId === ruleData[i]?.sourceIds[j]
              )?.id;
              ruleData[i].sourceIds[j] = id;
            }

            // if id is array
            // delete Doc ( inside array , id cant be string ) & Normal Rule
            if (
              typeof ruleData[i]?.sourceIds[j] === "object" &&
              ruleData[i]?.sourceIds[j]?.length
            ) {
              for (let k = 0; k < ruleData[i]?.sourceIds[j]?.length; k++) {
                if (typeof ruleData[i]?.sourceIds[j][k] === "string") {
                  const id = ids?.find(
                    (e) => e?.prevId === ruleData[i]?.sourceIds[j][k]
                  )?.id;
                  ruleData[i].sourceIds[j][k] = id;
                }
              }

              // For Client Rule do not send -1
              if (ruleData[i]?.type === "CLIENT") {
                ruleData[i].sourceIds = [...ruleData[i]?.sourceIds[j]]?.filter(
                  (e) => e !== -1
                );
              } else {
                ruleData[i].sourceIds = [...ruleData[i]?.sourceIds[j]];
              }
            }
            // end
          }

          // For Client Rule do not send -1
          if (ruleData[i]?.type === "CLIENT") {
            ruleData[i].sourceIds = ruleData[i]?.sourceIds?.filter(
              (e: any) => e !== -1
            );
          }
        }

        for (let j = 0; j < ruleData[i]?.destinationIds?.length; j++) {
          if (typeof ruleData[i]?.destinationIds[j] === "string") {
            const id = ids?.find(
              (e) => e?.prevId === ruleData[i]?.destinationIds[j]
            )?.id;
            ruleData[i].destinationIds[j] = id;
          }

          // if id is array
          // delete Doc ( inside array , id cant be string ) & Normal Rule
          if (
            typeof ruleData[i]?.destinationIds[j] === "object" &&
            ruleData[i]?.destinationIds[j]?.length
          ) {
            for (let k = 0; k < ruleData[i]?.destinationIds[j]?.length; k++) {
              if (typeof ruleData[i]?.destinationIds[j][k] === "string") {
                const id = ids?.find(
                  (e) => e?.prevId === ruleData[i]?.destinationIds[j][k]
                )?.id;
                ruleData[i].destinationIds[j][k] = id;
              }
            }

            // For Client Rule do not send -1
            if (ruleData[i]?.type === "CLIENT") {
              ruleData[i].destinationIds = [
                ...ruleData[i]?.destinationIds[j],
              ]?.filter((e) => e !== -1);
            } else {
              ruleData[i].destinationIds = [...ruleData[i]?.destinationIds[j]];
            }
          }

          // end
        }

        // Special rule ( EDV )
        if (ruleData[i]?.param?.conditionList) {
          //Structure for Dropdown Types

          ruleData[i].param.conditionList[0].ddType = [
            ruleData[i]?.param?.conditionList?.[0]?.ddType?.[0]?.name,
          ];

          // End

          // Structure for Criteria
          const arr = [];
          for (
            let j = 1;
            j <= ruleData[i]?.param?.conditionList?.[1]?.criterias?.length;
            j++
          ) {
            if (
              typeof ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1][
                "a" + j
              ] === "number"
            ) {
              arr.push({
                ["a" + j]:
                  ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1][
                    "a" + j
                  ],
              });
            } else if (
              ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1]["a" + j]
                ?.length
            ) {
              const id = ids?.find(
                (e) =>
                  e?.prevId ===
                  ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1][
                    "a" + j
                  ]
              )?.id;
              arr.push({ ["a" + j]: id });
            }
          }
          ruleData[i].param.conditionList[1].criterias = arr;
          // End

          // Structure of Display Attributes
          for (
            let j = 0;
            j < ruleData[i]?.param?.conditionList?.[2]?.da?.length;
            j++
          ) {
            ruleData[i].param.conditionList[2].da[j] =
              ruleData[i].param.conditionList[2].da[j]?.id;
          }

          const dd = ruleData[i].param.conditionList[0];
          const cri = ruleData[i].param.conditionList[1];
          const da = ruleData[i].param.conditionList[2];

          const structuredParam = [
            { conditionList: [{ ...dd, ...cri, ...da }] },
          ];
          ruleData[i].param = structuredParam;
        }
        // End
        // Special Rule ( EDV ) ends

        // For Client Rule do not send -1
        if (ruleData[i]?.type === "CLIENT") {
          ruleData[i].destinationIds = ruleData[i]?.destinationIds?.filter(
            (e: any) => e !== -1
          );
        }

        // For Post Trigger Rule
        if (
          ruleData[i]?.postTriggerRuleIds &&
          ruleData[i]?.postTriggerRuleIds?.length
        ) {
          for (let j = 0; j < ruleData[i]?.postTriggerRuleIds?.length; j++) {
            if (typeof ruleData[i]?.postTriggerRuleIds[j] === "string") {
              const id = ids?.find(
                (e) => e?.prevId === ruleData[i]?.postTriggerRuleIds[j]
              )?.id;
              ruleData[i].postTriggerRuleIds[j] = id;
            }
          }
        }
        // End Post Trigger

        let prevId = ruleData[i]?.id;
        const { id, appliedId, ...payload } = ruleData[i];

        // If no value present inside an array, no need to send any data for CLIENT rule
        if (payload?.type === "CLIENT") {
          if (!payload?.sourceIds?.length) {
            delete payload.sourceIds;
          } else if (!payload?.destinationIds?.length) {
            delete payload.destinationIds;
          }
        }

        let updatedPayload: any = {};
        if (typeof payload?.formFillMetadataId === "string") {
          const id = ids?.find(
            (e) => e?.prevId === payload?.formFillMetadataId
          )?.id;
          updatedPayload = { ...payload, formFillMetadataId: id };
        } else {
          updatedPayload = { ...payload };
        }

        if (updatedPayload?.param) {
          // For FFD
          if (updatedPayload?.isDropdown) {
            updatedPayload.param = updatedPayload.param.value;
          } else {
            if (typeof updatedPayload.param === "object") {
              updatedPayload.param = JSON.stringify(updatedPayload.param);
            }
          }
        }

        try {
          const ruleCurrent = await AxiosPost({
            endPoint: endPointRule,
            payload: updatedPayload,
          });

          const obj = {
            ...updatedPayload,
            prevId: prevId,
            id: ruleCurrent?.data?.id,
            //...updatedPayload,
          };
          postRuleAtSaveTime.push(obj);
          if (ruleData[i]?.deleted) {
            const deleteEndPoint = endPointRule + "/" + ruleCurrent?.data?.id;
            await AxiosDelete({ endPoint: deleteEndPoint, payload: {} });
          }
        } catch (err) {
          defaultState("error");
        }
      } else if (ruleData[i]?.isRuleEdited) {
        //Saved Rule
        const putEndPoint = endPointRule + "/" + ruleData[i]?.id;

        if (typeof ruleData[i]?.formFillMetadataId === "string") {
          const id = ids?.find(
            (e) => e?.prevId === ruleData[i]?.formFillMetadataId
          )?.id;
          ruleData[i].sourceIds = [id];
        }

        for (let j = 0; j < ruleData[i]?.sourceIds?.length; j++) {
          if (typeof ruleData[i]?.sourceIds[j] === "string") {
            const id = ids?.find(
              (e) => e?.prevId === ruleData[i]?.sourceIds[j]
            )?.id;
            ruleData[i].sourceIds[j] = id;
          }

          // if id is array
          // delete Doc ( inside array , id cant be string ) & Normal Rule
          if (
            typeof ruleData[i]?.sourceIds[j] === "object" &&
            ruleData[i]?.sourceIds[j]?.length
          ) {
            for (let k = 0; k < ruleData[i]?.sourceIds[j]?.length; k++) {
              if (typeof ruleData[i]?.sourceIds[j][k] === "string") {
                const id = ids?.find(
                  (e) => e?.prevId === ruleData[i]?.sourceIds[j][k]
                )?.id;
                ruleData[i].sourceIds[j][k] = id;
              }
            }

            ruleData[i].sourceIds = [...ruleData[i]?.sourceIds[j]];
          }
          // end
        }

        for (let j = 0; j < ruleData[i]?.destinationIds?.length; j++) {
          if (typeof ruleData[i]?.destinationIds[j] === "string") {
            const id = ids?.find(
              (e) => e?.prevId === ruleData[i]?.destinationIds[j]
            )?.id;
            ruleData[i].destinationIds[j] = id;
          }

          // if id is array
          // delete Doc ( inside array , id cant be string ) & Normal Rule
          if (
            typeof ruleData[i]?.destinationIds[j] === "object" &&
            ruleData[i]?.destinationIds[j]?.length
          ) {
            for (let k = 0; k < ruleData[i]?.destinationIds[j]?.length; k++) {
              if (typeof ruleData[i]?.destinationIds[j][k] === "string") {
                const id = ids?.find(
                  (e) => e?.prevId === ruleData[i]?.destinationIds[j][k]
                )?.id;
                ruleData[i].destinationIds[j][k] = id;
              }
            }

            ruleData[i].destinationIds = [...ruleData[i]?.destinationIds[j]];
          }
          //end
        }

        // Special rule ( EDV )
        if (ruleData[i]?.param?.conditionList) {
          //Structure for Dropdown Types

          ruleData[i].param.conditionList[0].ddType = [
            ruleData[i]?.param?.conditionList?.[0]?.ddType?.[0]?.name,
          ];

          // End

          // Structure for Criteria
          const arr = [];
          for (
            let j = 1;
            j <= ruleData[i]?.param?.conditionList?.[1]?.criterias?.length;
            j++
          ) {
            if (
              typeof ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1][
                "a" + j
              ] === "number"
            ) {
              arr.push({
                ["a" + j]:
                  ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1][
                    "a" + j
                  ],
              });
            } else if (
              ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1]["a" + j]
                ?.length
            ) {
              const id = ids?.find(
                (e) =>
                  e?.prevId ===
                  ruleData[i]?.param?.conditionList?.[1]?.criterias[j - 1][
                    "a" + j
                  ]
              )?.id;
              arr.push({ ["a" + j]: id });
            }
          }
          ruleData[i].param.conditionList[1].criterias = arr;
          // End

          // Structure of Display Attributes
          for (
            let j = 0;
            j < ruleData[i]?.param?.conditionList?.[2]?.da?.length;
            j++
          ) {
            ruleData[i].param.conditionList[2].da[j] =
              ruleData[i].param.conditionList[2].da[j]?.id;
          }

          const dd = ruleData[i].param.conditionList[0];
          const cri = ruleData[i].param.conditionList[1];
          const da = ruleData[i].param.conditionList[2];

          const structuredParam = [
            { conditionList: [{ ...dd, ...cri, ...da }] },
          ];
          ruleData[i].param = structuredParam;
        }
        // End

        const { id, appliedId, ...payload } = ruleData[i];
        let updatedPayload: any = {};
        if (typeof payload?.formFillMetadataId === "string") {
          const id = ids?.find(
            (e) => e?.prevId === payload?.formFillMetadataId
          )?.id;
          updatedPayload = { ...payload, formFillMetadataId: id };
        } else {
          updatedPayload = { ...payload };
        }

        if (updatedPayload?.param) {
          // For FFD
          if (updatedPayload?.isDropdown) {
            updatedPayload.param = updatedPayload.param.value;
          } else {
            if (typeof updatedPayload.param === "object") {
              updatedPayload.param = JSON.stringify(updatedPayload.param);
            }
          }
        }

        try {
          await AxiosPatch({ endPoint: putEndPoint, payload: updatedPayload });
          if (ruleData[i]?.deleted) {
            await AxiosDelete({ endPoint: putEndPoint, payload: {} });
          }
        } catch (err) {
          defaultState("error");
        }
      }
    }

    // Post Trigger Rule PUT
    let wholeObj: any;
    for (let i = 0; i < postRule?.length; i++) {
      wholeObj = postRuleAtSaveTime?.find(
        (e: any) => e.prevId === postRule?.[i]?.ruleIdOnWhichPostRuleApplied
      );
      if (wholeObj) {
        postRule[i].ruleIdOnWhichPostRuleApplied = wholeObj?.id;
        postRule[i].obj = wholeObj;
      }

      for (let j = 0; j < postRule?.[i]?.ruleIdWhichToBeApplied?.length; j++) {
        const res = postRuleAtSaveTime?.find(
          (e: any) => e.prevId === postRule?.[i]?.ruleIdWhichToBeApplied[j]
        );
        if (res) {
          postRule[i].ruleIdWhichToBeApplied[j] = res?.id;
        }
      }
    }

    for (let i = 0; i < postRule?.length; i++) {
      const putEndPoint =
        endPointRule + "/" + postRule[i]?.ruleIdOnWhichPostRuleApplied;
      const { id, ...obj } = postRule[i]?.obj;
      const payload = {
        ...obj,
        postTriggerRuleIds: postRule[i]?.ruleIdWhichToBeApplied,
      };
      try {
        await AxiosPatch({ endPoint: putEndPoint, payload: payload });
      } catch (err) {
        defaultState("error");
      }
    }
    // End Post PUT

    defaultState("success", "Records are saved.");
  };

  const deleteArrayItems = (
    containerId: string,
    arrayId: string,
    event?: { stopPropagation: () => void | undefined }
    //type: string
  ) => {
    event?.stopPropagation();
    setIsSaveFormLoading(true);
    const newState = formLayoutComponents.slice();
    const formContainerId = newState.findIndex(
      (f) => f.container.id === containerId
    );
    const formContainer = { ...newState[formContainerId] };
    const newChildren = formContainer.children.slice();

    const arrayContainer = newChildren?.filter((e) => e?.container);
    let resObj: { container: { id: string | undefined } };

    const findArray = (arr: any, id: string) => {
      for (let i = 0; i < arr?.children.length; i++) {
        if (arr.children[i].id === id) {
          return arr;
        }
      }
    };

    for (let i = 0; i < arrayContainer?.length; i++) {
      resObj = findArray(arrayContainer[i], arrayId);
      if (resObj) {
        break;
      }
    }

    const index = newChildren?.findIndex(
      (e) => e?.container?.id === resObj?.container?.id
    );
    const finalIndex = newChildren[index]?.children?.findIndex(
      (e: { id: string }) => e?.id === arrayId
    );

    if (finalIndex > -1) {
      if (typeof newChildren[index]?.children[finalIndex]?.id === "number") {
        commonDeletRecord(newChildren[index]?.children[finalIndex]?.id);
      }
      newChildren[index]?.children?.splice(finalIndex, 1);
    }

    formContainer.children = newChildren;
    newState[formContainerId] = formContainer;
    setFormLayoutComponents(newState);
    setMainArrUpdate(() => mainArrayUpdate + 1);

    showModalStrip("success", "Record is deleted.", 5000);
    setIsSaveFormLoading(false);

    // setSelectedControl((prev) =>
    //   prev && prev.id === arrayId ? undefined : prev
    // );
  };

  // Master Template data GET
  const getTemplateData = async (props: getTypeId) => {
    const { docId } = props;

    const templateEndPoint = `/v2/document-types/${docId}/form-fill-metadatas/delete-flag/false?page=0&size=1000&sort=formOrder%2Casc&searchParam=`;

    try {
      const response = await AxiosGet({ endPoint: templateEndPoint });
      const data = response?.data?.content;
      const structuredData: any = [];
      let containerId = "";

      let arrayFlag = false;
      for (let i = 0; i < data?.length; i++) {
        if (data?.[i]?.type === "PANEL") {
          containerId = data?.[i]?.id;
          structuredData.push({ container: data?.[i], children: [] });
        } else if (structuredData?.length > 0) {
          if (
            data?.[i]?.type === "ARRAY_HDR" ||
            arrayFlag ||
            data?.[i]?.type === "ARRAY_END"
          ) {
            if (data?.[i]?.type === "ARRAY_HDR") {
              arrayFlag = true;
              structuredData?.[structuredData?.length - 1].children.push({
                container: { ...data?.[i], containerId, type: "ARRAY" },
                children: [],
              });
            } else if (data?.[i]?.type === "ARRAY_END") {
              arrayFlag = false;
              const childrenLen =
                structuredData?.[structuredData?.length - 1].children?.length -
                1;
              structuredData?.[structuredData?.length - 1].children?.[
                childrenLen
              ]?.children?.push({
                ...data[i],
                containerId,
              });
            } else if (arrayFlag) {
              const childrenLen =
                structuredData?.[structuredData?.length - 1].children?.length -
                1;
              structuredData?.[structuredData?.length - 1].children?.[
                childrenLen
              ]?.children?.push({
                ...data[i],
                containerId,
              });
            }
          } else {
            structuredData?.[structuredData?.length - 1].children.push({
              ...data[i],
              containerId,
            });
          }
        }
      }

      setFormLayoutComponents(structuredData);
      setMainArrUpdate(() => mainArrayUpdate + 1);
      setIsSaveFormLoading(false);
    } catch (err) {}
  };

  // Particular/Selected validation data GET
  const getSelectedTemplateValidationData = async (
    props: getSelectedTypeValidationId
  ) => {
    const { templateId } = props;
    const selectedTemplateValidationEndPoint = `/v2/form-fill-metadatas/${templateId}/validations`;
    try {
      const response = await AxiosGet({
        endPoint: selectedTemplateValidationEndPoint,
      });
      const validation = response?.data?.formValidations[0] ?? null;
      setIsValidationEdited(validation ? true : false);
      setSelectedValidation(undefined);
      setSelectedApiValidation(validation);
    } catch (err) {
      setSelectedApiValidation(null);
      throw err;
    }
  };

  // Master validation data GET
  const getValidationData = (id: string | null) => {
    const validationEndPoint = `/v2/companies/${id}/form-validations?size=1000`;
    AxiosGet({ endPoint: validationEndPoint })
      .then((response) => {
        const data = response?.data?.content;
        setValidationRawData(data);
      })
      .catch((err) => {});
  };

  // Particular/Selected rule data GET
  const handleRuleGet = async () => {
    setIsRuleLoading(true);
    const endPoint = `v2/document-types/${sessionStorage.getItem(
      "docId"
    )}/form-fill-meta/${
      selectedControl?.id
    }?page=0&size=15&sort=id%2Cdesc&searchParam=`;

    if (selectedControl?.isProcessed) {
      setIsRuleLoading(false);
      return;
    }

    const finalData: any = {
      ...selectedControl,
      isProcessed: true,
    };

    editControlProperties(finalData, "noDropdownUpdate");

    const deleteDocData = [
      "DELETE_DOCUMENT",
      "COPY_TO_DOCUMENT_STORAGE_ID",
      "MAKE_DOCUMENT_UPLOAD_MANDATORY",
      "MAKE_DOCUMENT_UPLOAD_MANDATORY",
      "UNDELETE_DOCUMENT",
    ];

    try {
      const data = await AxiosGet({ endPoint });
      let arr = [];
      for (let i = 0; i < data?.data?.content.length; i++) {
        let particularRuleJson: any;

        // Extracting names by ids
        if (
          data?.data?.content[i]?.actionType &&
          data?.data?.content[i]?.sourceType
        ) {
          particularRuleJson = ruleDropdownValue?.find(
            (e) =>
              e?.action === data?.data?.content[i]?.actionType &&
              e?.source === data?.data?.content[i]?.sourceType
          );
        } else {
          particularRuleJson = ruleDropdownValue?.find(
            (e) => e?.action === data?.data?.content[i]?.actionType
          );
        }

        // Source Name
        let sourceName = [];
        // To check if rule is of Doc type
        const deleteType = deleteDocData?.includes(
          data?.data?.content[i]?.actionType
        );

        let isSourceMultiple = false,
          isDestinationMultiple = false,
          sourceIndex = -1,
          destinationIndex = -1;

        for (let j = 0; j < data?.data?.content[i]?.sourceIds?.length; j++) {
          if (data?.data?.content[i]?.sourceIds[j] === -1) {
            sourceName.push(null);
          } else {
            let name;
            //For Doc type
            if (deleteType) {
              name = documentDropDownData?.find(
                (e: any) => e?.id === data?.data?.content[i]?.sourceIds[j]
              );
            } else {
              // Normal Rule
              name = ruleFieldDropdown?.find(
                (e) => e?.id === data?.data?.content[i]?.sourceIds[j]
              );

              // For Multiple Check (Multiple selected to show on Screen)
              if (
                !isSourceMultiple &&
                particularRuleJson?.sourceFields?.fields?.length
              ) {
                for (
                  let k = particularRuleJson?.sourceFields?.fields?.length - 1;
                  k >= 0;
                  k--
                )
                  if (particularRuleJson?.sourceFields?.fields[k]?.unlimited) {
                    isSourceMultiple = true;
                    sourceIndex = j;
                  }
              }
            }

            if (name) {
              sourceName.push(name);
            } else {
              sourceName.push(null);
            }
          }
        }

        // Destination Name
        let destinationName = [];
        for (
          let j = 0;
          j < data?.data?.content[i]?.destinationIds?.length;
          j++
        ) {
          if (data?.data?.content[i]?.destinationIds[j] === -1) {
            destinationName.push(null);
          } else {
            let name;
            //For Doc Type
            if (deleteType) {
              name = documentDropDownData?.find(
                (e: any) => e?.id === data?.data?.content[i]?.destinationIds[j]
              );
            } else {
              //For Normal Rule
              name = ruleFieldDropdown?.find(
                (e) => e?.id === data?.data?.content[i]?.destinationIds[j]
              );

              // For Multiple Check (Multiple selected to show on Screen)
              if (
                !isDestinationMultiple &&
                particularRuleJson?.destinationFields?.fields?.length
              ) {
                for (
                  let k =
                    particularRuleJson?.destinationFields?.fields?.length - 1;
                  k >= 0;
                  k--
                )
                  if (
                    particularRuleJson?.destinationFields?.fields[k]?.unlimited
                  ) {
                    isDestinationMultiple = true;
                    destinationIndex = j;
                  }
              }
            }

            if (name) {
              destinationName.push(name);
            } else {
              destinationName.push(null);
            }
          }
        }

        // Multiple Binding
        if (isSourceMultiple) {
          const combineArr = sourceName?.splice(
            sourceIndex,
            sourceName?.length
          );
          sourceName = [...sourceName, combineArr];
        }

        if (isDestinationMultiple) {
          const combineArr = destinationName?.splice(
            destinationIndex,
            destinationName?.length
          );
          destinationName = [...destinationName, combineArr];
        }
        // End

        // Converting Param data to display for EDV rule
        let param = data?.data?.content[i]?.param;
        const dropDownTypeVal: any = [],
          criteriaAarr: any = [],
          attributeUpdatedArr: any = [];
        if (data?.data?.content[i]?.param?.includes("conditionList")) {
          data.data.content[i].param = JSON.parse(
            data?.data?.content[i]?.param
          );

          if (data?.data?.content[i]?.param?.[0]?.conditionList) {
            // Structure Data to Show on the screen for DROPDOWN TYPES
            if (
              data?.data?.content[i]?.param?.[0]?.conditionList?.[0]?.ddType
            ) {
              const obj = dropdownTypeArr?.find(
                (e: any) =>
                  e?.name ===
                  data?.data?.content[i]?.param?.[0]?.conditionList?.[0]
                    ?.ddType?.[0]
              );

              if (obj) {
                dropDownTypeVal?.push(obj);
              }
            }

            // Structure Data to Show on the screen for CRITERIA
            if (
              data?.data?.content[i]?.param?.[0]?.conditionList?.[0]?.criterias
            ) {
              for (let p = 1; p <= 8; p++) {
                if (
                  data?.data?.content[
                    i
                  ]?.param?.[0]?.conditionList?.[0]?.criterias?.some(
                    (item: any) => item.hasOwnProperty("a" + p)
                  )
                ) {
                  const name = ruleFieldDropdown?.find(
                    (e: any) =>
                      e?.id ===
                      data?.data?.content[i]?.param?.[0]?.conditionList?.[0]
                        ?.criterias[p - 1][`a${p}`]
                  );
                  criteriaAarr.push({
                    [`a` + p]:
                      data?.data?.content[i]?.param?.[0]?.conditionList?.[0]
                        ?.criterias[p - 1][`a${p}`],
                    name: name,
                  });
                } else {
                  criteriaAarr.push({ [`a` + p]: "", name: null });
                }
              }
            }
            // End CRITERIA

            // Structure Data to Show on the screen for DISPLAY ATTRIBUTE
            if (data?.data?.content[i]?.param?.[0]?.conditionList?.[0]?.da) {
              for (
                let p = 0;
                p <
                data?.data?.content[i]?.param?.[0]?.conditionList?.[0]?.da
                  ?.length;
                p++
              ) {
                let item;
                if (
                  data?.data?.content[i]?.param?.[0]?.conditionList?.[0]?.da?.[
                    p
                  ].hasOwnProperty("id")
                ) {
                  item = attributeStaticArr?.find(
                    (e: any) =>
                      e?.id ===
                      data?.data?.content[i]?.param?.[0]?.conditionList?.[0]
                        ?.da?.[p]?.id
                  );
                } else {
                  item = attributeStaticArr?.find(
                    (e: any) =>
                      e?.id ===
                      data?.data?.content[i]?.param?.[0]?.conditionList?.[0]
                        ?.da?.[p]
                  );
                }

                if (item) {
                  attributeUpdatedArr.push({ id: item?.id, name: item?.name });
                }
              }
            }
            // End DISPLAY ATTRIBUTE

            param = {
              conditionList: [
                {
                  ddType: dropDownTypeVal,
                  // data?.data?.content[i]?.param?.conditionList?.[0]?.ddType,
                },
                { criterias: criteriaAarr },
                { da: attributeUpdatedArr },
              ],
            };
          }
        }
        // End

        arr.push({
          ...data?.data?.content[i],
          sourceName: sourceName,
          destinationName: destinationName,
          appliedId: selectedControl?.id,
          param: param,
        });
      }

      setRuleData((prev) => [...prev, ...arr]);
      setIsRuleLoading(false);
    } catch (err) {}
  };

  // Master Data GET
  const getAllTemplateData = async (id: string | null) => {
    const endPoint = `companies/${id}/templates`;
    try {
      const data = await AxiosGet({ endPoint });
      setAllTemplateData(data?.data);
    } catch (err) {}
  };

  // rule submit ( new / existing )
  const onRuleSubmit = async (type: string) => {
    setIsRuleLoading(true);
    const sourceIds = [],
      destinationIds = [];
    if (selectedRule?.sourceFields?.fields?.length) {
      for (let i = 0; i < selectedRule?.sourceFields?.fields?.length; i++) {
        sourceIds.push(-1);
      }
    }
    if (selectedRule?.destinationFields?.fields?.length) {
      for (
        let i = 0;
        i < selectedRule?.destinationFields?.fields?.length;
        i++
      ) {
        destinationIds.push(-1);
      }
    }

    if (type === "new") {
      const payload = {
        actionType: "",
        sourceType: "",
        type: "",
        sourceIds: [],
        sourceName: [],
        destinationIds: [],
        destinationName: [],
        conditionalValues: null,
        condition: null,
        conditionValueType: null,
        button: "",
        delimiter: null,
        param: null,
        relatedTemplateId: null,
        relatedDocTypeId: null,
        searchable: "false",
        formFillMetadataId: selectedControl?.id,
        executeOnFill: "true",
        executeOnRead: "false",
        postTriggerRuleIds: [],
        executeOnESign: "false",
        executePostESign: "false",
        runPostConditionFail: "false",
        onStatusFail: null,
        conditionalValueId: null,
        id: "",
        appliedId: "",
        deleted: false,
        isRuleEdited: false,
      };
      const data: any = {
        ...payload,
        appliedId: selectedControl?.id,
        id: generateID(),
      };

      setParticularRule(data);
      setRuleData((prev: any) => [...prev, data]);
      setIsRuleLoading(false);
    } else if (type === "dropdownRuleSelected") {
      const index = ruleData?.findIndex(
        (e: any) => e?.id === particularRule?.id
      );

      if (index > -1) {
        const updatedRule = ruleData.slice();
        updatedRule[index] = {
          ...updatedRule[index],
          actionType: selectedRule ? selectedRule?.action : "",
          sourceType: selectedRule ? selectedRule?.source : "",
          type: selectedRule ? selectedRule?.processingType : "",
          sourceIds: sourceIds,
          destinationIds: destinationIds,
          sourceName: [],
          destinationName: [],
          button: selectedRule?.button,
        };

        // Dirty Record Implementation starts

        // To find Panel Id
        let panelId: number | string | undefined,
          children: any = [];
        for (let i = 0; i < formLayoutComponents?.length; i++) {
          let record = formLayoutComponents[i]?.children?.find(
            (f: any) => f?.id === updatedRule[index]?.formFillMetadataId
          );
          if (record) {
            panelId = formLayoutComponents[i]?.container?.id;
            children = JSON.parse(
              JSON.stringify(formLayoutComponents[i]?.children)
            );
            break;
          }
        }

        //  To find Panel's Children Id
        const childrenId = children?.find(
          (e: any) => e?.id === selectedControl?.id
        )?.id;

        // Rule Id

        // Reverse the ruleData so that id of correct rule it will pick
        let reverseRuleData = JSON.parse(JSON.stringify(ruleData));
        reverseRuleData.reverse();

        const ruleId = reverseRuleData?.find(
          (e: any) => e?.formFillMetadataId === selectedControl?.id
        )?.id;

        // To Check if dropdown changes for record which lies in panel which is already present in dirty record
        const existingPanelId = dirtyRecord?.findIndex(
          (e: any) => e?.panelId === panelId
        );

        let existingChildrenId = -1;

        // To Check if dropdown changes for record(control filed) which is already present in dirty record
        if (existingPanelId !== undefined && existingPanelId > -1) {
          existingChildrenId = dirtyRecord[
            existingPanelId
          ]?.children?.findIndex((e: any) => e?.childrenId === childrenId);

          let updatedDirtyRecord = JSON.parse(JSON.stringify(dirtyRecord));

          // On Same Rule dropdown changes
          if (existingChildrenId !== undefined && existingChildrenId > -1) {
            const ruleIndex = updatedDirtyRecord[existingPanelId].children[
              existingChildrenId
            ].rule?.findIndex((e: any) => e?.id === particularRule?.id);

            if (ruleIndex !== undefined && ruleIndex > -1) {
              updatedDirtyRecord[existingPanelId].children[
                existingChildrenId
              ].rule[ruleIndex] = {
                id: ruleId,
                sourceIds: selectedRule?.sourceFields?.fields?.filter(
                  (e: any) => e?.mandatory
                ),
                destinationIds: selectedRule?.destinationFields?.fields?.filter(
                  (e: any) => e?.mandatory
                ),
              };
              // for same control field new record added
            } else {
              updatedDirtyRecord[existingPanelId].children[
                existingChildrenId
              ]?.rule?.push({
                id: ruleId,
                sourceIds: selectedRule?.sourceFields?.fields?.filter(
                  (e: any) => e?.mandatory
                ),
                destinationIds: selectedRule?.destinationFields?.fields?.filter(
                  (e: any) => e?.mandatory
                ),
              });
            }
            // Rule added for Same panel but for control field
          } else {
            updatedDirtyRecord[existingPanelId]?.children?.push({
              childrenId: childrenId,
              rule: [
                {
                  id: ruleId,
                  sourceIds: selectedRule?.sourceFields?.fields?.filter(
                    (e: any) => e?.mandatory
                  ),
                  destinationIds:
                    selectedRule?.destinationFields?.fields?.filter(
                      (e: any) => e?.mandatory
                    ),
                },
              ],
            });
          }

          setDirtyRecord(updatedDirtyRecord);

          // No dirty record is present
        } else {
          children = [
            {
              childrenId: childrenId,
              rule: [
                {
                  id: ruleId,
                  sourceIds: selectedRule?.sourceFields?.fields?.filter(
                    (e: any) => e?.mandatory
                  ),
                  destinationIds:
                    selectedRule?.destinationFields?.fields?.filter(
                      (e: any) => e?.mandatory
                    ),
                },
              ],
            },
          ];

          // Add dirty records
          setDirtyRecord((prev: any) => [
            ...prev,
            {
              panelId: panelId,
              children: children,
            },
          ]);
        }
        // End

        setParticularRule(updatedRule[index]);
        setRuleData(updatedRule);
        setIsRuleLoading(false);
      }
    }
  };

  // To get company Id
  const getCompanyDetails = async () => {
    const endPoint = "/user/who-am-i";
    try {
      const response = await AxiosGet({ endPoint });
      const data = response?.data?.companyId;
      setCompanyDetails(data);
    } catch (err) {}
    AxiosGet({ endPoint });
  };

  // Filter Rules
  const filterStatus = () => {
    if (isRuleActive === undefined) {
      setIsRuleActive(true);
    } else if (isRuleActive) {
      setIsRuleActive(false);
    } else if (isRuleActive === false) {
      setIsRuleActive(undefined);
    }
  };

  // Add new Panel
  const handleAdd = () => {
    setIsAddRule(true);
    setSelectedRule(null);
    onRuleSubmit("new");
  };

  // To Active / Inactive Rules
  const activeInactive = (event: any, status: boolean) => {
    event.stopPropagation();
    setRuleStatus(undefined);
    setTimeout(() => {
      setRuleStatus(status);
    }, 0);
  };

  // Rule Validation dropdown
  const standardUserDefinedValidation = (
    validationData: ValidationDataType[]
  ) => {
    for (let i = 0; i < validationData?.length; i++) {
      if (validationData[i]?.standardValidation) {
        validationData[i].name += "  (standard)";
      } else {
        validationData[i].name += "  (user defined)";
      }
    }
    return validationData;
  };

  // Validation Fetch
  const fetchOptions = async (type: string) => {
    //setLoading(true);
    try {
      if (type === "input" || isMoreRecord) {
        const companyId = window.sessionStorage.getItem("companyId");
        if (type === "input") {
          if (validationInput === "") {
            setValidationData(prevValue);
            return;
          }

          const endPoint = `v2/companies/${companyId}/form-validations?q=${validationInput}`;
          const response = await AxiosGet({ endPoint });
          const data = response?.data?.content;
          const updatedData: any = standardUserDefinedValidation(data);
          setPrevValue(validationData);
          setValidationData(updatedData);
        } else if (type === "scroll") {
          const endPoint = `v2/companies/${companyId}/form-validations?page=${page}`;
          const response = await AxiosGet({ endPoint });
          const data = response?.data?.content;

          if (data?.length < 20) {
            setIsMoreRecord(false);
          }

          const updatedData: any = standardUserDefinedValidation(data);

          setValidationData([...validationData, ...updatedData]);
          setPrevValue([...prevValue, ...updatedData]);
          setPage(page + 1);
        }
      }
      //setLoading(false);
    } catch (error) {}
  };
  // End

  //
  const deleteDocDropdownData = async () => {
    const templateId = window.sessionStorage.getItem("templateId");
    const endPoint = `v2/templates/${templateId}/document-types`;

    const res = await AxiosGet({ endPoint });
    const data = res?.data?.documentTypes;

    const filteredData = data?.filter((e: any) => !e?.formFillEnabled);
    setDocumentDropDownData(filteredData);
  };

  // Need to change when selected control changes
  const selectedRuleChangesDependency = async () => {
    setParamData(null);

    if (selectedControl) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 500);
      setAddValidation(false);
      setValidationFormData({
        name: "",
        regex: "",
        minLength: "",
        maxLenght: "",
      });
      setValidationType("");
      setSelectedValidation(undefined);
      setFromValidationSearch(false);

      const setValues = (type: string, val: any) => {
        setValidationType(type);
        setSelectedValidation(val);
      };

      if (typeof selectedControl?.id === "number") {
        try {
          await getSelectedTemplateValidationData({
            templateId: selectedControl?.id,
          });
          // if user select new value, but value already presented from api
          if (selectedControl?.formFillValidation) {
            setValues("select", selectedControl?.formFillValidation);
          }
        } catch (err) {
          // any error in api
          if (selectedControl?.formFillValidation) {
            setValues("select", selectedControl?.formFillValidation);
          } else {
            setValues("", null);
          }
        }

        setGridValue(selectedControl?.gridValue ?? 6);
        await handleRuleGet();
      } else if (selectedControl?.formFillValidation) {
        // selected api value but not saved yet
        setSelectedApiValidation(null);
        setValues("select", selectedControl?.formFillValidation);
      } else {
        // not selected ,not saved
        setSelectedApiValidation(null);
        setValues("", null);
      }

      if (selectedControl?.type !== "PANEL") {
        setContainerType(selectedControl?.containerId);
      }
    }

    setIsAddRule(false);
    setSelectedRule(null);
    setIsOtherRule(false);
    setExpanded(-1);
  };

  // Dropdown values changes according to panel
  const shuffleRuleDropdown = () => {
    const data = JSON.parse(JSON.stringify(ruleFieldDropdown));
    const startId = data?.findIndex(
      (e: any) => e?.containerId === containerType
    );
    let endId;
    for (let i = startId; i < data?.length; i++) {
      if (data[i]?.containerId !== containerType) {
        endId = i;
        break;
      }
    }

    if (!endId) {
      endId = data?.length;
    }

    if (startId > -1 && endId > -1) {
      const sliceData = data?.splice(startId, endId);
      setRuleFieldDropdown([...sliceData, ...data]);
    }
  };

  const setDropdownValues = () => {
    const dropdown: any = [];
    const ruleFieldDrop: any = [];
    let panelName;

    const commonPushFun = (obj: any, name: any) => {
      let item = JSON.parse(JSON.stringify(obj));
      item.name += ` (${name})`;
      ruleFieldDrop.push(item);
    };

    for (let i = 0; i < formLayoutComponents?.length; i++) {
      if (formLayoutComponents?.[i]?.container) {
        dropdown.push(formLayoutComponents?.[i]?.container);
        panelName = formLayoutComponents?.[i]?.container;
      }
      for (let j = 0; j < formLayoutComponents?.[i]?.children?.length; j++) {
        if (formLayoutComponents?.[i]?.children[j]?.container) {
          for (
            let k = 0;
            k < formLayoutComponents?.[i]?.children[j]?.children?.length;
            k++
          ) {
            if (
              formLayoutComponents?.[i]?.children[j]?.children[k]?.type !==
              "ARRAY_END"
            ) {
              commonPushFun(
                formLayoutComponents?.[i]?.children[j]?.children[k],
                panelName?.name
              );
            }
          }
        } else {
          commonPushFun(
            formLayoutComponents?.[i]?.children[j],
            panelName?.name
          );
        }
      }
    }
    setPanelDropdown(dropdown);
    setRuleFieldDropdown(ruleFieldDrop);
  };

  const leftBarFieldsValues = async () => {
    const endPoint = `v1/form-type-schemas?page=0&size=10000&sort=name,asc`;

    setSideBarLpoading(true);
    const res = await AxiosGet({ endPoint });
    const response = res?.data?.content;

    let updatedRes: any = [],
      advanceType: any = [];

    for (let i = 0; i < response?.length; i++) {
      let obj: any = response[i],
        meta: any;

      meta = {
        ...obj?.meta?.properties,
        type:
          response[i]?.group === "OBJ" || response[i]?.group === "ADV"
            ? response[i]?.group
            : response[i]?.name,
        itemType: FormItemTypes.CONTROL, // no need this is good
        dataType: FormTextDataTypes.NUMBER, // Need in form preview // will do
      };
      obj.meta.properties = meta;
      updatedRes?.push(obj);
      if (response[i]?.group === "ADV") {
        advanceType?.push(response[i]);
      }
    }

    setLeftBarFields(updatedRes);
    setAdvanceType(advanceType);
    setSideBarLpoading(false);
  };

  const ruleDropdownValues = async () => {
    const endPoint = `v1/form-rule-schemas?page=0&size=10000&sort=name,asc`;
    const res = await AxiosGet({ endPoint });
    setRuleDropdownValue(res?.data?.content);
    //setRuleDropdownValue(customDropdown); // For param dropdown testing
  };

  const getDropdownTypeArr = async () => {
    try {
      const res = await AxiosGet({
        endPoint: `v2/company/${window.sessionStorage.getItem(
          "companyId"
        )}/external-data-metadata?page=0&size=30&sort=id%2Cdesc&genericData=false&searchParam=`,
      });

      setDropdownTypeArr(res?.data);
    } catch (err) {}
  };

  // useEffect Section

  // Data required for delete type documents
  useEffect(() => {
    leftBarFieldsValues();
    ruleDropdownValues();
    deleteDocDropdownData();
  }, []);

  // Template Name ( Left side Top )
  useEffect(() => {
    if (allTemplateData.length) {
      const id: number = parseInt(
        window.sessionStorage.getItem("templateId") ?? ""
      );
      const name: string | undefined = allTemplateData?.find(
        (e) => e?.id === id
      )?.templateName;
      setTemplateName(name);
    }
  }, [allTemplateData]);

  // Need to change when selected control changes
  useEffect(() => {
    selectedRuleChangesDependency();
  }, [selectedControl]);

  // When we get company Id need to call some apis
  useEffect(() => {
    if (window.sessionStorage.getItem("companyId") !== null) {
      getTemplateData({ docId: window.sessionStorage.getItem("docId") });
      getAllTemplateData(window.sessionStorage.getItem("companyId"));
      fetchOptions("scroll");
      getValidationData(window.sessionStorage.getItem("companyId"));
      getDropdownTypeArr();
    }
  }, [window.sessionStorage.getItem("companyId")]);

  //
  useEffect(() => {
    if (formLayoutComponents.length) {
      setEditCaseDropdown(true);
      setDropdownValues();
    }
  }, [mainArrayUpdate, formLayoutComponents]);

  // Dropdown values changes according to panel
  useEffect(() => {
    if (containerType) {
      shuffleRuleDropdown();
    }
  }, [containerType]);

  //handle special case (edit)
  //Remove circular dependency using another state
  useEffect(() => {
    if (editCaseDropdown) {
      shuffleRuleDropdown();
      setEditCaseDropdown(false);
    }
  }, [ruleFieldDropdown?.length, editCaseDropdown]);
  //End

  // Property or Rule section
  useEffect(() => {
    if (!isPropertyRule) {
      setIsAddRule(false);
    }
  }, [isPropertyRule]);

  useEffect(() => {
    if (particularRule && ruleStatus !== undefined) {
      setIsRuleLoading(true);
      const index = ruleData?.findIndex(
        (e: any) => e?.id === particularRule?.id
      );

      if (index > -1) {
        const updatedRule = ruleData.slice();
        updatedRule[index] = {
          ...updatedRule[index],
          deleted: ruleStatus,
          isRuleEdited:
            typeof updatedRule[index]?.id === "number" ? true : false,
        };
        setRuleData(updatedRule);
        setIsRuleLoading(false);
      }
    }
  }, [ruleStatus]);

  // Validation Loop for Advanced Object
  useEffect(() => {
    if (validationObjArr.length) {
      for (let i = 0; i < validationObjArr?.length; i++) {
        const containerIndex = formLayoutComponents.findIndex(
          (e: any) => e?.container?.id === validationObjArr[i]?.containerId
        );

        if (containerIndex > -1) {
          const newFormData = formLayoutComponents;
          const controlIndex = newFormData[containerIndex]?.children?.findIndex(
            (e: any) => e?.id === validationObjArr[i]?.id
          );
          if (controlIndex > -1) {
            newFormData[containerIndex].children[controlIndex] = {
              ...newFormData[containerIndex].children[controlIndex],
              formFillValidation: validationObjArr[i]?.formFillValidation,
            };
          }
          setFormLayoutComponents(newFormData);
        }
      }
      setValidationObjArr([]);
    }
  }, [validationObjArr.length]);

  // Rule Loop for Advanced Object
  useEffect(() => {
    if (ruleObjectList.length) {
      //console.log(`ruleObjectList`, ruleObjectList);
      // console.log(`currentObj`, currentObj);
      //let firstServerRule: any = JSON.parse(JSON.stringify(particularRule));

      const ruleObj: any = [];
      let postRuleId: any = [];

      for (let i = 0; i < ruleObjectList?.length; i++) {
        const particularRule = ruleDropdownValue?.find(
          (e: any) => e?.name === ruleObjectList?.[i]?.rule
        );

        if (particularRule) {
          let newRuleObjectList = JSON.parse(JSON.stringify(ruleObjectList)),
            sourceName: any = [],
            destinationName: any = [],
            indexSource: any,
            breakFlagSource: boolean = false,
            indexDestination: any,
            breakFlagDestination: boolean = false;

          for (let j = 0; j < ruleObjectList?.[i]?.sourceIds?.length; j++) {
            for (let k = 0; k < currentObj?.length; k++) {
              if (ruleObjectList?.[i]?.sourceIds[j] === k) {
                // Part of Code for Multiple Selection
                if (particularRule?.sourceFields?.fields[j]?.unlimited) {
                  indexSource = j;
                  breakFlagSource = true;
                  break;
                }
                // End

                newRuleObjectList[i].sourceIds[j] = currentObj?.[k]?.id;
                sourceName.push(currentObj?.[k]);
              }
            }
            if (breakFlagSource) {
              break;
            }

            // currentObj?.map((item: any, index: number) => {
            //   if (ruleObjectList?.[i]?.sourceIds[j] === index) {
            //     if (particularRule?.sourceFields?.fields[j]?.unlimited) {
            //       index = j;
            //     }
            //     newRuleObjectList[i].sourceIds[j] = item?.id;
            //     sourceName.push(item);
            //   }
            // });
          }

          // For Multiple selection
          if (breakFlagSource) {
            const idArr = [],
              nameArr = [];
            for (
              let j = indexSource;
              j < ruleObjectList?.[i]?.sourceIds?.length;
              j++
            ) {
              for (let k = 0; k < currentObj?.length; k++) {
                if (ruleObjectList?.[i]?.sourceIds[j] === k) {
                  idArr.push(currentObj?.[k]?.id);
                  nameArr.push(currentObj?.[k]);
                }
              }
            }
            newRuleObjectList[i].sourceIds[indexSource] = idArr;
            sourceName.push(nameArr);
          }
          // End Multiple Selection

          for (
            let j = 0;
            j < ruleObjectList?.[i]?.destinationIds?.length;
            j++
          ) {
            for (let k = 0; k < currentObj?.length; k++) {
              if (ruleObjectList?.[i]?.destinationIds[j] === k) {
                // Part of Code for Multiple Selection
                if (particularRule?.destinationFields?.fields[j]?.unlimited) {
                  indexDestination = j;
                  breakFlagDestination = true;
                  break;
                }
                //End for Multiple Selection

                newRuleObjectList[i].destinationIds[j] = currentObj?.[k]?.id;
                destinationName.push(currentObj?.[k]);
              }
            }

            // currentObj?.map((item: any, index: number) => {
            //   if (ruleObjectList?.[i]?.destinationIds[j] === index) {
            //     newRuleObjectList[i].destinationIds[j] = item?.id;
            //     destinationName.push(item);
            //   }
            // });
            if (breakFlagDestination) {
              break;
            }
          }

          // For Multiple selection
          if (breakFlagDestination) {
            const idArr = [],
              nameArr = [];
            for (
              let j = indexDestination;
              j < ruleObjectList?.[i]?.destinationIds?.length;
              j++
            ) {
              for (let k = 0; k < currentObj?.length; k++) {
                if (ruleObjectList?.[i]?.destinationIds[j] === k) {
                  idArr.push(currentObj?.[k]?.id);
                  nameArr.push(currentObj?.[k]);
                }
              }
            }
            newRuleObjectList[i].destinationIds[indexDestination] = idArr;
            destinationName.push(nameArr);
          }
          // End Multiple Selection

          const obj = {
            ...newRuleObjectList?.[i],
            sourceName: sourceName,
            destinationName: destinationName,
          };

          let id = generateID();

          ruleObj.push({
            ...particularRule,
            appliedId: ruleObjectList[i]?.appliedId,
            actionType: particularRule?.action,
            sourceType: particularRule?.source,
            ...obj,
            prevId: ruleObjectList?.[i]?.id,
            id: id,
            searchable: false,
            formFillMetadataId: ruleObjectList[i]?.appliedId,
            executeOnFill: true,
            executeOnRead: false,
            executeOnESign: false,
            executePostESign: false,
            runPostConditionFail: false,
            type: particularRule?.processingType,
          });

          if (ruleObjectList?.[i]?.postTriggerRuleIds) {
            const ruleID: any[] = [];
            for (
              let j = 0;
              j < ruleObjectList?.[i]?.postTriggerRuleIds?.length;
              j++
            ) {
              // currentObj?.map((item: any, index: number) => {
              //   if (ruleObjectList?.[i]?.postTriggerRuleIds[j] === index) {
              //     newRuleObjectList[i].postTriggerRuleIds[j] = item?.id;
              //   }
              // });
              ruleID.push(ruleObjectList?.[i]?.postTriggerRuleIds[j]);
            }
            postRuleId.push({
              ruleIdOnWhichPostRuleApplied: id,
              ruleIdWhichToBeApplied: ruleID,
            });
          }
        }
      }

      for (let i = 0; i < postRuleId?.length; i++) {
        for (
          let j = 0;
          j < postRuleId[i]?.ruleIdWhichToBeApplied?.length;
          j++
        ) {
          const res = ruleObj?.find(
            (e: any) => e?.prevId === postRuleId[i]?.ruleIdWhichToBeApplied[j]
          );
          postRuleId[i].ruleIdWhichToBeApplied[j] = res?.id;
        }
      }

      setPostRule(postRuleId);

      setRuleData((prev: any) => [...prev, ...ruleObj]);
      setRuleObjectList([]);
      setCurrentObj([]);
    }
  }, [ruleObjectList, currentObj?.length]);

  console.log(`formData`, formLayoutComponents);
  //console.log(`ruleData`, ruleData);
  // console.log(`ruleObjectList`, ruleObjectList);
  //console.log(`PostRule`, postRule);
  //console.log(`dirtyRecord`, dirtyRecord);

  return {
    handleItemAdded,
    deleteContainer,
    deleteControl,
    selectControl,
    editContainerProperties,
    editControlProperties,
    moveControlFromSide,
    moveControl,
    publishForm,
    saveForm,
    selectedTemplate,
    formLayoutComponents,
    setFormLayoutComponents,
    selectedControl,
    deleteArrayItems,
    getTemplateData,
    getSelectedTemplateValidationData,
    getValidationData,
    validationData,
    selectedValidation,
    setSelectedValidation,
    isValidationEdited,
    selectedApiValidation,
    setSelectedApiValidation,
    ruleData,
    setRuleData,
    handleRuleGet,
    getAllTemplateData,
    allTemplateData,
    templateName,
    change,
    setChange,
    particularRule,
    setParticularRule,
    expanded,
    setExpanded,
    selectedItem,
    setSelectedItem,
    paramData,
    setParamData,
    ruleFieldDropdown,
    setRuleFieldDropdown,
    onRuleSubmit,
    selectedRule,
    setSelectedRule,
    saveFormLoading,
    setIsSaveFormLoading,
    getCompanyDetails,
    companyDetails,
    expandedPanel,
    setExpandedPanel,
    setValidationData,
    documentDropDownData,
    mainArrayUpdate,
    setIsCalled,
    gridValue,
    setGridValue,
    isCalled,
    isPropertyRule,
    setIsPropertyRule,
    addValidation,
    setAddValidation,
    filterStatus,
    isRuleActive,
    handleAdd,
    validationFormData,
    setValidationFormData,
    validationType,
    setValidationType,
    fromValidationSearch,
    setFromValidationSearch,
    validationInput,
    setValidationInput,
    setPage,
    prevValue,
    fetchOptions,
    isAddRule,
    setIsAddRule,
    activeInactive,
    isOtherRule,
    setIsOtherRule,
    ruleStatus,
    panelDropdown,
    inputRef,
    formNames,
    setFormName,
    isRuleLoading,
    dirtyRecord,
    setDirtyRecord,
    ruleDropdownValue,
    leftBarFields,
    sideBarLoading,
    dropdownTypeArr,
    setIsEdited,
  };
};

export default useFormBuilder;
