import { createSlice } from "@reduxjs/toolkit";

export const intialStateData = {
  tabChange:false,
  sidebarOpen: true, // Default state: sidebar is open
  extended: {
    extendedUserGroups: [],
    loading: false,
  },
  clientData: {
    clientDataValue: [],
  },
  hashData: {
    hashDataValue: [],
  },
  userForm: {
    userFormValue: [],
    loading: false,
  },
  user: {
    userValue: [],
    loading: false,
  },
  template: {
    templateValue: [],
    loading: false,
  },
  companyFeature: {
    companyFeatureValue: [],
    loading: false,
  },
  selectedTemplateIdWithoutTMPT: {
    TempId: '',
   },
  selectedTemplateIdWithTMPT: {
   TempId: '',
  },
  selectedTemplateName: {
    Name: '',
   },
   selectedDocumentTypeId:{
    docTypeId:""
   },
   selectedtransactionId:{
    transactionId:""
   },
  fill: {
    fillValue: [],
    loading: false,
  },
  allDocument: {
    allDocumentValue: [],
    loading: false,
  },
  fillByTxnurl:{
    fillTxnSuccess:false,
    fillByTxnurlValue:[],
    loading:false
  },
  templateInfo: {
    templateInfoValue: [],
    loading: false,
  },
  metaDataInfo:{
    metaDataInfoValue:[],
    loading:false,
  },
  fillMetaDataInfo:{
    fillMetaDataInfoValue:[],
    loading:false,
  },
  uploadFileInfo:{
    uploadFileInfoValue:[],
    loading:false,
  },
documentsInfo:{
  documentsInfoValue:[],
    loading:false,
  },
  uploadedDocumentInfo: {
    uploadedDocumentInfoValue: [],
    loading: false,
  },
  statusTransitionInfo: {
    statusTransitionInfoValue: [],
    loading: false,
  },
  deleteDocumentInfo:{
    deleteDocumentInfoValue:[],
    loading:false,
  },
  thumbnailInfo:{
    thumbnailInfoValue:[],
    loading:false
  },
  dropdownInfo:{
    dropdownInfoValue:[],
    loading:false
  },
  sendOtpInfo:{
    sendOtpValue:[],
    loading:false
  },
  verifyOtpInfo:{
    verifyOtpInfoValue:[],
    loading:false
  }
  ,
  templateDetailValues:{
    templateDetailInfoValues:[],
    loading:false
  },
  metaDataImage:{
    metaDataImageValue:[],
    loading:false
  },
  deleteExtendedFormGroup:{
    deleteExtendedFormGroupValue:[],
    loading:false,
  },
 imageForExtendedForm:{
  imageForExtendedFormValue:[],
  loading:false
 }
};
export const ManchSlice = createSlice({
  name: "manch",
  initialState: intialStateData,
  reducers: {
    getExtendedGroupsImage:(state,action)=>{
      console.log(action.payload, "getExtendedGroups");

      state.imageForExtendedForm = {
        ...state.imageForExtendedForm,
        loading: true,
      };
    },
    successExtendedGroupsImage:(state,action)=>{
      console.log(action.payload, "successExtendedGroups");
      state.imageForExtendedForm = {
        ...state.imageForExtendedForm,
        imageForExtendedFormValue: action.payload,
        loading: false,
      };
    },
    getExtendedGroups:(state,action)=>{
      console.log(action.payload, "getExtendedGroups");

      state.extended = {
        ...state.extended,
        loading: true,
      };
    },
    successExtendedGroups:(state,action)=>{
      console.log(action.payload, "successExtendedGroups");
      state.extended = {
        ...state.extended,
        extendedUserGroups: action.payload,
        loading: false,
      };
    },
    postExtendedGroups:(state,action)=>{
      console.log(action.payload, "getExtendedGroups");

      state.extended = {
        ...state.extended,
        loading: true,
      };
    },
    setTabChange: (state, action) => {
      console.log(action.payload, "tabchange");
      state.tabChange = action.payload; // Store the dispatched value in the state
    },
    
    setSidebarState: (state, action) => {
      if (typeof action.payload === "boolean") {
        state.sidebarOpen = action.payload; // Set to the provided value
      } else {
        state.sidebarOpen = !state.sidebarOpen; // Toggle the state
      }
    },
    verifyOtpAction: (state, action) => {
      state.verifyOtpInfo = {
        ...state.verifyOtpInfo,
        loading: true,
      };
    },
    successVerifyOtpAction: (state, action) => {
      console.log(action.payload, "date02");
    
      // Ensure verifyOtpInfo and verifyOtpInfoValue exist
      state.verifyOtpInfo = {
        ...state.verifyOtpInfo,
        verifyOtpInfoValue: Array.isArray(state.verifyOtpInfo?.verifyOtpInfoValue)
          ? [...state.verifyOtpInfo.verifyOtpInfoValue, action.payload]
          : [action.payload], // Initialize as array if undefined
        loading: false,
      };
    },
   sendOtpAction: (state, action) => {
      state.sendOtpInfo = {
        ...state.sendOtpInfo,
        loading: true,
      };
    },
    successOtpAction: (state, action) => {

      state.sendOtpInfo = {
        ...state.sendOtpInfo,
        sendOtpInfoValue: action.payload,
        loading: false,
      };
    },
    
    getDropdownAction: (state, action) => {
      state.dropdownInfo = {
        ...state.dropdownInfo,
        loading: true,
      };
    },
    successDropdownAction: (state, action) => {

      state.dropdownInfo = {
        ...state.dropdownInfo,
        dropdownInfoValue: action.payload,
        loading: false,
      };
    },
    
    setClientTypeData: (state, action) => {

      state.clientData = {
        ...state.clientData,
        clientDataValue: action.payload
    }
  }
    ,
    storeSelectedIdWithTMPT: (state, action) => {

      state.selectedTemplateIdWithTMPT = {
        ...state.selectedTemplateIdWithTMPT,
        TempId: action.payload
      };
    }, 
    storeDocumentIdWithoutTMPT: (state, action) => {

      state.selectedTemplateIdWithoutTMPT = {
        ...state.selectedTemplateIdWithoutTMPT,
        TempId: action.payload
      };
    }, 
    storeDocumentTypeId: (state, action) => {

      state.selectedDocumentTypeId = {
        ...state.selectedDocumentTypeId,
        docTypeId: action.payload
      };
    }, 
    storeTransactionId: (state, action) => {

      state.selectedtransactionId = {
        ...state.selectedtransactionId,
        transactionId: action.payload
      };
    }, 
    storeSelectedName: (state, action) => {

      state.selectedTemplateName = {
        ...state.selectedTemplateName,
        Name: action.payload
      };
    }, 
    getUserFormAction: (state, action) => {
      state.userForm = {
        ...state.userForm,
        loading: true,
      };
    },
    successUserFormAction: (state, action) => {

      state.userForm = {
        ...state.userForm,
        userFormValue: action.payload,
        loading: false,
      };
    },
    getUserDetailAction: (state, action) => {
      state.user = {
        ...state.user,
        loading: true,
      };
    },
    successUserDetailAction: (state, action) => {

      state.user = {
        ...state.user,
        userValue: action.payload,
        loading: false,
      };
    },
    getTemplate: (state, action) => {
      state.template = {
        ...state.template,
        loading: true,
      };
    },
    successTemplate: (state, action) => {

      state.template = {
        ...state.template,
        templateValue: action.payload,
        loading: false,
      };
    },
    getcompanyFeature: (state, action) => {
      state.companyFeature = {
        ...state.companyFeature,
        loading: true,
      };
    },
    successCompanyFeature: (state, action) => {

      state.companyFeature = {
        ...state.companyFeature,
        companyFeatureValue: action.payload.companyProductFeatures,
        loading: false,
      };
    },
    postFillAction: (state, action) => {
      state.fill = {
        ...state.fill,
        loading: true,
      };
    },
    successFillAction: (state, action) => {

      state.fill = {
        ...state.fill,
        fillValue: action.payload,
        loading: false,
      };
    },

    getAllDocument: (state, action) => {
      state.allDocument = {
        ...state.allDocument,
        loading: true,
      };
    },
    successAllDocument: (state, action) => {

      state.allDocument = {
        ...state.allDocument,
        allDocumentValue: action.payload,
        loading: false,
      };
    },
    putFillTxnAction: (state, action) => {
      state.fillByTxnurl = {
        ...state.fillByTxnurl,
        loading: true,
      };
    },
    successFillTxnAction: (state, action) => {
      console.log("success action payload", action.payload);

      state.fillByTxnurl = {
        ...state.fillByTxnurl,
        fillTxnSuccess:true,
        fillByTxnurlValue: action.payload,
        loading: false,
      };
    },
    getTemplateInfo: (state, action) => {
      state.templateInfo = {
        ...state.templateInfo,
        loading: true,
      };
    },
    successTemplateInfo: (state, action) => {

      state.templateInfo = {
        ...state.templateInfo,
        templateInfoValue: action.payload,
        loading: false,
      };
    },
    getMetaDataAction: (state, action) => {
      console.log("payload", action);
      state.metaDataInfo = {
        ...state.metaDataInfo,
        loading: true,
      };
    },
    successMetaDataAction: (state, action) => {

      state.metaDataInfo = {
        ...state.metaDataInfo,
        metaDataInfoValue: action.payload,
        loading: false,
      };
    },
    getFillInfo: (state, action) => {
      console.log("payload get payload info getfillinfo 0", action);
      state.fillMetaDataInfo = {
        ...state.fillMetaDataInfo,
        loading: true,
      };
    },
    successFillInfo: (state, action) => {
      console.log(action.payload, "successfillinfo");
   
      // Copy current hashDataValue
      let array = [...state.hashData.hashDataValue];
      console.log(array, "array");
   
      // Find the index of the object with the same metaDataId
      const existingIndex = array.findIndex(item => item.metaDataId === action.payload.metaDataId);
   
      if (existingIndex !== -1) {
         // If found, replace the existing object with the new data (action.payload)
         array[existingIndex] = action.payload;
      } else {
         // If not found, push the new data
         array.push(action.payload);
      }
   
      // Update state.hashData with the new array for hashDataValue
      state.hashData = {
         ...state.hashData,
         hashDataValue: array,  // Update with new array
      };
        // Append the new fillMetaDataInfoValue to the existing ones
        state.fillMetaDataInfo = {
          ...state.fillMetaDataInfo,
          fillMetaDataInfoValue: [
            // Filter out existing items with the same key as the new payload
            ...(state.fillMetaDataInfo.fillMetaDataInfoValue || []).filter(
              (item) => Object.keys(item)[0] !== Object.keys(action.payload.fillMetaDataInfoValue)[0]
            ),
            // Add the new payload
            action.payload.fillMetaDataInfoValue,
          ],
          loading: false,
        };
   },
    getFileUploadInfo: (state, action) => {
      // Log to debug what is inside the action payload
      console.log("File upload payload info", action);
    
      // Set loading to true when upload starts
      state.uploadFileInfo = {
        ...state.uploadFileInfo,
        loading: true,
      };
    },
    successFileUploadInfo: (state, action) => {

      state.uploadFileInfo = {
        ...state.uploadFileInfo,
        uploadFileInfoValue: action.payload,
        loading: false,
      };
    },
    postDocumentsInfo: (state, action) => {
      // Log to debug what is inside the action payload
      console.log("postDocumentsInfo", action);
    
      // Set loading to true when upload starts
      state.documentsInfo = {
        ...state.documentsInfo,
        loading: true,
      };
    },
    successDocumentsInfo: (state, action) => {

      state.documentsInfo = {
        ...state.documentsInfo,
        documentsInfoValue: action.payload,
        loading: false,
      };
    },
    getUploadedInfo: (state, action) => {
      console.log("postDocumentsInfo", action);
      state.uploadedDocumentInfo = {
        ...state.uploadedDocumentInfo,
        loading: true,
      };
    },
    successUploadedInfo: (state, action) => {
      console.log(action.payload,"slice url");
      state.uploadedDocumentInfo = {
        ...state.uploadedDocumentInfo,
        uploadedDocumentInfoValue: action.payload,  // This should be the blob URL
        loading: false,
      };
    },
    getStatusTrasition: (state, action) => {
      state.statusTransitionInfo = {
        ...state.statusTransitionInfo,
        loading: true,
      };
    },
    successStatusTrasition: (state, action) => {

      state.statusTransitionInfo = {
        ...state.statusTransitionInfo,
        statusTransitionInfoValue: action.payload,
        loading: false,
      };
    },
    deleteDocumentAction: (state, action) => {
      state.deleteDocumentInfo = {
        ...state.deleteDocumentInfo,
        loading: true,
      };
    },
    successDeleteAction: (state, action) => {

      state.deleteDocumentInfo = {
        ...state.deleteDocumentInfo,
        deleteDocumentInfoValue: action.payload,
        loading: false,
      };
    },
    getThumbnailAction: (state, action) => {
      state.thumbnailInfo = {
        ...state.thumbnailInfo,
        loading: true,
      };
    },
    successThumbnailAction: (state, action) => {
      const { documentTypeId, imageData } = action.payload;
    
      // Check if this documentTypeId already exists in the array
      const existingIndex = state.thumbnailInfo.thumbnailInfoValue.findIndex(
        (item) => item.documentTypeId === documentTypeId
      );
    
      if (existingIndex >= 0) {
        // If it exists, update the imageData for the same documentTypeId
        state.thumbnailInfo.thumbnailInfoValue[existingIndex].imageData = imageData;
      } else {
        // If it doesn't exist, add a new entry
        state.thumbnailInfo.thumbnailInfoValue.push({ documentTypeId, imageData });
      }
    
      state.thumbnailInfo.loading = false;
    },

    getMetDataImageAction: (state, action) => {
      state.metaDataImage = {
        ...state.metaDataImage,
        loading: true,
      };
    },
    successMetDataImageAction:(state,action)=>{
      console.log(action.payload,"action.payload imagemeta")
      state.metaDataImage = {
        ...state.metaDataImage,
        metaDataImageValue: action.payload,
        loading: false,
      };
    },
    getTemplateDetail: (state, action) => {
      console.log("runnning slice gor template",state,action)
      state.templateDetail = {
        ...state.templateDetail,
        loading: true,
      };
    },
    successTemplateDetail: (state, action) => {

      state.templateDetail = {
        ...state.templateDetail,
        templateDetailInfo: action.payload,
        loading: false,
      };
    },

    getTemplateDetailValues: (state, action) => {
      console.log("runnning slice gor template",state,action)
      state.templateDetailValues = {
        ...state.templateDetailValues,
        loading: true,
      };
    },
    successTemplateDetailValues: (state, action) => {

      state.templateDetailValues = {
        ...state.templateDetailValues,
        templateDetailInfoValues: action.payload,
        loading: false,
      };
    },
    deleteExtendedFormGroups:(state,action)=>{
      state.deleteExtendedFormGroup = {
        ...state.deleteExtendedFormGroup,
        loading: true,
      };
    },
    successDeleteExtendedFormGroup: (state, action) => {

      state.deleteExtendedFormGroup = {
        ...state.deleteExtendedFormGroup,
        deleteExtendedFormGroupValue: action.payload,
        loading: false,
      };
    },
    failureDeleteExtendedFormGroup: (state, action) => {
      state.deleteExtendedFormGroup = {
        ...state.deleteExtendedFormGroup,
        error: action.payload,
        loading: false,
      };
    },
    get_Latest_Extended_Groups: (state, action) => {
      state.loading = true;
    },
    get_Extended_Form_Fill: (state, action) => {
      state.loading = true;
    },
    success_Extended_Form_Fill: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  deleteExtendedFormGroups,
  successDeleteExtendedFormGroup,
  failureDeleteExtendedFormGroup,
  setTabChange,
  setSidebarState,
  getUserFormAction,
  successUserFormAction,
  getUserDetailAction,
  successUserDetailAction,
  getTemplate,
  successTemplate,
  getcompanyFeature,
  successCompanyFeature,
  postFillAction,
  successFillAction,
  storeSelectedIdWithTMPT,
  storeDocumentIdWithoutTMPT,
  storeSelectedName,
  storeDocumentTypeId,
  getAllDocument,
  successAllDocument,
  putFillTxnAction,
  successFillTxnAction,
  getTemplateInfo,
  successTemplateInfo,
  getMetaDataAction,
  successMetaDataAction,
  getFillInfo,
  successFillInfo,
  getFileUploadInfo,
  successFileUploadInfo,
  postDocumentsInfo,
  successDocumentsInfo,
  getUploadedInfo,
  successUploadedInfo,
  getStatusTrasition,
  successStatusTrasition,
  deleteDocumentAction,
  successDeleteAction,
  getThumbnailAction,
  successThumbnailAction,
  setClientTypeData,
  getDropdownAction,
  successDropdownAction,
  sendOtpAction,
  successOtpAction,
  verifyOtpAction,
  successVerifyOtpAction,
  getTemplateDetail,
  successTemplateDetail,
  getTemplateDetailValues,
  successTemplateDetailValues,
  storeTransactionId,
  getMetDataImageAction,
  successMetDataImageAction,
  formDataAction,
  getExtendedGroups,
  successExtendedGroups,
  postExtendedGroups,
  get_Latest_Extended_Groups,
  get_Extended_Form_Fill,
  success_Extended_Form_Fill,
  getExtendedGroupsImage,
  successExtendedGroupsImage
} = ManchSlice.actions;
