import React from "react";
import { common } from "./interface";

export const DateIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30.423 30.066"
      style={{ margin: "0 0 0.25rem 0" }}
    >
      <g data-name="Group 4162">
        <g data-name="Group 4072">
          <g data-name="Group 4071">
            <path
              data-name="Path 1713"
              d="M39.711 15.978h-2.24v-.916a.833.833 0 0 0-1.665 0v.916H22.994v-.916a.833.833 0 0 0-1.665 0v.916h-2.24a4.384 4.384 0 0 0-4.379 4.379V39.63a4.384 4.384 0 0 0 4.379 4.379h20.622a4.384 4.384 0 0 0 4.379-4.379V20.357a4.384 4.384 0 0 0-4.379-4.379zm-20.622 1.665h2.239v.916a.833.833 0 0 0 1.665 0v-.916H35.81v.916a.833.833 0 0 0 1.665 0v-.916h2.239a2.717 2.717 0 0 1 2.714 2.714v3.409H16.375v-3.409a2.72 2.72 0 0 1 2.714-2.714zm20.622 24.7H19.089a2.717 2.717 0 0 1-2.714-2.714V25.436h26.046V39.63a2.714 2.714 0 0 1-2.71 2.714z"
              transform="translate(-13.667 -13.943)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1714"
              d="M28.434 46.5H26.8a1.951 1.951 0 0 0-1.948 1.948v1.54a1.951 1.951 0 0 0 1.948 1.948h1.636a1.951 1.951 0 0 0 1.948-1.948v-1.54a1.953 1.953 0 0 0-1.95-1.948zm.279 3.488a.283.283 0 0 1-.283.283h-1.636a.283.283 0 0 1-.283-.283v-1.54a.283.283 0 0 1 .283-.283h1.636a.283.283 0 0 1 .283.283z"
              transform="translate(-19.586 -32.78)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1715"
              d="M46.934 46.5H45.3a1.951 1.951 0 0 0-1.948 1.948v1.54a1.951 1.951 0 0 0 1.948 1.948h1.636a1.951 1.951 0 0 0 1.948-1.948v-1.54a1.951 1.951 0 0 0-1.95-1.948zm.283 3.488a.283.283 0 0 1-.283.283H45.3a.283.283 0 0 1-.283-.283v-1.54a.283.283 0 0 1 .283-.283h1.636a.283.283 0 0 1 .283.283z"
              transform="translate(-30.385 -32.78)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1716"
              d="M65.444 46.5h-1.636a1.951 1.951 0 0 0-1.948 1.948v1.54a1.951 1.951 0 0 0 1.948 1.948h1.636a1.951 1.951 0 0 0 1.948-1.948v-1.54a1.951 1.951 0 0 0-1.948-1.948zm.283 3.488a.283.283 0 0 1-.283.283h-1.636a.283.283 0 0 1-.283-.283v-1.54a.283.283 0 0 1 .283-.283h1.636a.283.283 0 0 1 .283.283z"
              transform="translate(-41.19 -32.78)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1717"
              d="M28.434 63.89H26.8a1.951 1.951 0 0 0-1.948 1.948v1.54a1.951 1.951 0 0 0 1.948 1.948h1.636a1.951 1.951 0 0 0 1.948-1.948v-1.54a1.953 1.953 0 0 0-1.95-1.948zm.279 3.488a.283.283 0 0 1-.283.283h-1.636a.283.283 0 0 1-.283-.283v-1.54a.283.283 0 0 1 .283-.283h1.636a.283.283 0 0 1 .283.283z"
              transform="translate(-19.586 -42.931)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1718"
              d="M46.934 63.89H45.3a1.951 1.951 0 0 0-1.948 1.948v1.54a1.951 1.951 0 0 0 1.948 1.948h1.636a1.951 1.951 0 0 0 1.948-1.948v-1.54a1.951 1.951 0 0 0-1.95-1.948zm.283 3.488a.283.283 0 0 1-.283.283H45.3a.283.283 0 0 1-.283-.283v-1.54a.283.283 0 0 1 .283-.283h1.636a.283.283 0 0 1 .283.283z"
              transform="translate(-30.385 -42.931)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1719"
              d="M65.444 63.89h-1.636a1.951 1.951 0 0 0-1.948 1.948v1.54a1.951 1.951 0 0 0 1.948 1.948h1.636a1.951 1.951 0 0 0 1.948-1.948v-1.54a1.951 1.951 0 0 0-1.948-1.948zm.283 3.488a.283.283 0 0 1-.283.283h-1.636a.283.283 0 0 1-.283-.283v-1.54a.283.283 0 0 1 .283-.283h1.636a.283.283 0 0 1 .283.283z"
              transform="translate(-41.19 -42.931)"
              style={{ fill: color }}
            />
          </g>
        </g>
        <path
          data-name="Rectangle 871"
          style={{ fill: "none", opacity: "0.4" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
