import {
  deleteDocumentAction,
  deleteExtendedFormGroups,
  failureDeleteExtendedFormGroup,
  getAllDocument,
  getDropdownAction,
  getExtendedGroups,
  getExtendedGroupsImage,
  getFileUploadInfo,
  getFillInfo,
  getMetaDataAction,
  getStatusTrasition,
  getTemplate,
  getTemplateDetail,
  getTemplateDetailValues,
  getTemplateInfo,
  getThumbnailAction,
  getUploadedInfo,
  getUserDetailAction,
  getUserFormAction,
  get_Extended_Form_Fill,
  get_Latest_Extended_Groups,
  getcompanyFeature,
  postDocumentsInfo,
  postExtendedGroups,
  postFillAction,
  putFillTxnAction,
  sendOtpAction,
  successAllDocument,
  successCompanyFeature,
  successDeleteAction,
  successDeleteExtendedFormGroup,
  successDocumentsInfo,
  successDropdownAction,
  successExtendedGroups,
  successExtendedGroupsImage,
  successFileUploadInfo,
  successFillAction,
  successFillInfo,
  successFillTxnAction,
  successMetaDataAction,
  successOtpAction,
  successStatusTrasition,
  successTemplate,
  successTemplateDetail,
  successTemplateDetailValues,
  successTemplateInfo,
  successThumbnailAction,
  successUploadedInfo,
  successUserDetailAction,
  successUserFormAction,
  successVerifyOtpAction,
  success_Extended_Form_Fill,
  verifyOtpAction,
} from "../redux/slices/ManchSlices";
import { call, all, put, takeLatest } from "redux-saga/effects";
import * as Api from "../services/FormFillApi";
import CryptoJS from "crypto-js";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions

function* fetchUserForm(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchUserApiResults, payloadData);
    yield put(successUserFormAction(response));
  } catch (e) {
    console.log("catch");
  }
}
function* fetchUserDetails(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchUserDetail, payloadData);
    yield put(successUserDetailAction(response));
  } catch (e) {
    console.log("catch");
  }
}
function* fetchTemlateDetails(action) {
  try {
    let { companyId, id } = action.payload;
    const response = yield call(Api.fetchTemplate, companyId, id);
    yield put(successTemplate(response));
  } catch (e) {
    console.log("catch");
  }
}
function* fetchCompantFeatureDetails(action) {
  try {
    let { companyId, id } = action.payload;
    const response = yield call(Api.fetchCompanyFeature, companyId, id);
    yield put(successCompanyFeature(response));
  } catch (e) {
    console.log("catch");
  }
}
function* postFillDetails(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postFill, payloadData);
    yield put(successFillAction(response));
  } catch (e) {
    console.log("catch");
  }
}
function* fetchAllDocumentDetails(action) {
  try {
    let payloadData = action.payload;
    console.log(payloadData, "payloaddata vv");
    const response = yield call(Api.fetchAllDocuments, payloadData);
    yield put(successAllDocument(response));
    console.log("response vv", response);
  } catch (e) {
    console.log("catch");
  }
}
function* putFillTxnDetails(action) {
  try {
    const { transactionId, dataOfPut } = action.payload; // Destructure payload
    console.log(transactionId, "extractedValue in saga");
    console.log(dataOfPut, "dataOfPut in saga");

    const response = yield call(Api.putFillTxn, transactionId, dataOfPut); // Pass both values to API
    console.log(response, "response in saga");

    yield put(successFillTxnAction(response));
  } catch (e) {
    console.log("catch in saga", e);
  }
}
function* fetchTemplateInfoDetails(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchTemplateInfo, payloadData);
    yield put(successTemplateInfo(response));
  } catch (e) {
    console.log("catch");
  }
}
function* fetchMetaDataDetails(action) {
  try {
    const { trxcId, docTypeId } = action.payload;
    const response = yield call(Api.fetchmetaData, trxcId, docTypeId);
    console.log(response, "responsedata of meta");
    yield put(successMetaDataAction(response));

    for (const item of response) {
      if (item.dataValue) {
        yield put(
          successFillInfo({
            ...item,
            fillMetaDataInfoValue: item,
          })
        );
      }
    }
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* postfillMetaData(action) {
  try {
    const { trxcId, docTypeId, dataValue, metaDataId, valueHash } =
      action.payload;
    const encryptData = (data) => {
      const secret = process.env.REACT_APP_SECRET_KEY;
      const encryptedData = CryptoJS.AES.encrypt(data, secret);
      return encryptedData.toString(); // Convert to string
    };
    const encrypt = encryptData(dataValue);
    console.log("dataValue", dataValue);
    console.log(valueHash, "api saga value hash");
    const response = yield call(
      Api.fillmetaData,
      trxcId,
      docTypeId,
      encrypt,
      metaDataId,
      valueHash
    );
    console.log(typeof response.status, "001");
    console.log("here is the response", typeof response.data);
    if (response.status === 200) {
      // yield put(successFillInfo(action.payload));
      yield put(
        successFillInfo({
          ...action.payload,
          fillMetaDataInfoValue: response.data,
        })
      );
    }
  } catch (e) {
    console.log("catch");
  }
}
function* postFileUploadData(action) {
  try {
    const { trxcId, docTypeId, dataValue } = action.payload;

    // Log to ensure FormData is populated correctly
    console.log([...dataValue.entries()], "FormData in saga");

    // Make the API call to upload the file
    const response = yield call(
      Api.uploadFileData,
      trxcId,
      docTypeId,
      dataValue
    );

    // Dispatch success action
    yield put(successFileUploadInfo(response));
  } catch (e) {
    console.log("catch", e);
  }
}
function* postDocuments(action) {
  try {
    const { trxcId, formData } = action.payload; // Destructure payload
    const response = yield call(Api.documentsUpload, trxcId, formData); // Pass both transactionId and form data
    yield put(successDocumentsInfo(response)); // Dispatch success action
    const responseOfGetAll = yield put(getAllDocument(trxcId));
    console.log("responseOfgetall", responseOfGetAll);
  } catch (e) {
    console.log("Error during document upload", e); // Error handling
  }
}
function* fetchUploadedDocuments(action) {
  try {
    const { trxcId, documentId } = action.payload;
    const response = yield call(Api.fetchUploadedFiles, trxcId, documentId);

    const arrayBuffer = yield call(response.arrayBuffer());
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url);

    yield put(successUploadedInfo(url));
  } catch (e) {
    console.error("Error fetching the document", e);
  }
}
//status transition
function* fetchStatusTransition(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getStatusTransition, payloadData);
    yield put(successStatusTrasition(response));
    console.log("response status transition", response);
    const statusTransition = response.statusTransitions?.[0];
    const currentStatus = statusTransition?.currentStatus;
    const nextStatus = statusTransition?.nextStatus;
    const id = statusTransition?.id;
    console.log(currentStatus, "zxzx");
    console.log(nextStatus, "zxzx");
    console.log(id, "zxzx");

    if (currentStatus && nextStatus && id) {
      yield call(Api.trasitStatus, payloadData, currentStatus, nextStatus, id);
    } else {
      console.log("send api");
      const newStatus = "SAVED";
      yield call(Api.send, payloadData, newStatus);
    }
  } catch (e) {
    console.error("Status transition error:", e);
  }
}
function* deleteDocById(action) {
  try {
    const { trxcId, docTypeId } = action.payload;
    console.log(trxcId, "trxcid delete");
    console.log(docTypeId, "docTypeId delete");

    const response = yield call(Api.deleteDoc, trxcId, docTypeId);
    console.log("delete doc api is callid ");
    const responseOfGetAll = yield put(getAllDocument(trxcId));
    console.log("get all document api is callid ");
    console.log(responseOfGetAll, "response of get all");
    // yield put(successAllDocument(responseOfGetAll));
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* fetchDropdownDetails(action) {
  try {
    const { tld, dtld, ddType, fType, pv, criteria, ce, da, dl } =
      action.payload;

    // Call the API with the parameters
    const response = yield call(
      Api.getDropDown,
      tld,
      dtld,
      ddType,
      fType,
      pv,
      criteria,
      ce,
      da,
      dl
    );

    // Dispatch success action
    yield put(successDropdownAction(response));
  } catch (e) {
    console.error("Error fetching dropdown details", e);
  }
}
function* postSendOtp(action) {
  try {
    const { data, trxcId, docTypeId } = action.payload;
    const response = yield call(Api.send_otp, data, trxcId, docTypeId);

    // Dispatch success action
    yield put(successOtpAction(response));
  } catch (e) {
    console.log("catch", e);
  }
}
function* postVerifyOtp(action) {
  try {
    const { data, trxcId, docTypeId } = action.payload;
    const response = yield call(Api.verify_otp, data, trxcId, docTypeId);
    const id = data.id;
    const responseFromApi = response.data.result;

    yield put(successVerifyOtpAction({ responseFromApi, id }));
  } catch (e) {
    console.log("catch", e);
  }
}

function* fetchTemplateDataDetail(action) {
  try {
    console.log(action, "saga1");
    const { templateId } = action.payload;
    console.log(templateId, "saga2");
    const response = yield call(Api.getTemplateData, templateId);
    yield put(successTemplateDetail(response));
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* getTemplatevalus(action) {
  try {
    console.log(action, "saga1");
    const { transactionId } = action.payload;
    console.log(transactionId, "saga2");
    const response = yield call(Api.getTheValuesOfTemplate, transactionId);
    yield put(successTemplateDetailValues(response?.payLoad));
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* getExtendedGroupsData(action) {
  try {
    console.log(action, "asdfg101");
    const formFillId = action.payload;
    console.log(formFillId, "asdfg");

    const response = yield call(Api.extendedFormGroupValue, formFillId);
    console.log(response, "extended response");

    if (response?.content?.length === 0) {
      console.log(response?.content?.length, "length001");
      yield put(postExtendedGroups(formFillId));
      // yield put(getExtendedGroups(formFillId ));
    } else {
      yield put(successExtendedGroups(response));
    }
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* postExtendedGroupsData(action) {
  console.log(action, "asdfg");

  try {
    console.log(action, "asdfg");

    const formFillId = action.payload;
    console.log(formFillId, "asdfg");

    const response = yield call(Api.postExtendedFormGroupValue, formFillId);
    console.log(response, "extended response");
    yield put(getExtendedGroups(formFillId));
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* deleteExtendedByGroupId(action) {
  try {
    const { headerId, rowId } = action.payload;
    console.log(headerId, "headerId delete");
    console.log(rowId, "rowId delete");

    yield call(Api.deleteExtendedUserGroup, headerId, rowId);
    console.log("delete doc API is called");

    // Dispatch success action
    yield put(successDeleteExtendedFormGroup({ headerId, rowId }));

    // Fetch updated data after deletion
    const formFillId = headerId;
    yield put(get_Latest_Extended_Groups(formFillId));
    console.log("get all document API is called");
  } catch (e) {
    console.error("Error deleting metadata", e);
    yield put(failureDeleteExtendedFormGroup(e.message));
  }
}
function* getLatestExtendedGroupsData(action) {
  try {
    console.log(action, "asdfg101");
    const formFillId = action.payload;

    const response = yield call(Api.extendedFormGroupValue, formFillId);
    console.log(response, "extended response");

    yield put(successExtendedGroups(response));
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* putExtendedFill(action) {
  try {
    const { headerId, extendedFillDataId, value } = action.payload; // Fix typo
    const response = yield call(
      Api.putExtended,
      headerId,
      extendedFillDataId,
      { value } // Wrap value inside an object
    );
    
    yield put(success_Extended_Form_Fill(response.data)); // Ensure response.data is used
  } catch (e) {
    console.error("Error fetching metadata", e);
  }
}
function* postExtendUpload(action){
  try {
    const { headerId,
      extendedFillDataId, dataValue } = action.payload;

    // Log to ensure FormData is populated correctly
    console.log([...dataValue.entries()], "FormData in saga");

    // Make the API call to upload the file
    const response = yield call(
      Api.uploadFileDataExtend,
      headerId,
      extendedFillDataId,
      dataValue
    );

    // Dispatch success action
    yield put(successExtendedGroupsImage(response));
  } catch (e) {
    console.log("catch", e);
  }
}

function* ManchSaga() {
  yield all([
    takeLatest(getUserFormAction.type, fetchUserForm),
    takeLatest(getUserDetailAction.type, fetchUserDetails),
    takeLatest(getTemplate.type, fetchTemlateDetails),
    takeLatest(getcompanyFeature.type, fetchCompantFeatureDetails),
    takeLatest(postFillAction.type, postFillDetails),
    takeLatest(getAllDocument.type, fetchAllDocumentDetails),
    takeLatest(putFillTxnAction.type, putFillTxnDetails),
    takeLatest(getTemplateInfo.type, fetchTemplateInfoDetails),
    takeLatest(getMetaDataAction.type, fetchMetaDataDetails),
    takeLatest(getFillInfo.type, postfillMetaData),
    takeLatest(getFileUploadInfo.type, postFileUploadData),
    takeLatest(postDocumentsInfo.type, postDocuments),
    takeLatest(getUploadedInfo.type, fetchUploadedDocuments),
    takeLatest(getStatusTrasition.type, fetchStatusTransition),
    takeLatest(deleteDocumentAction.type, deleteDocById),
    takeLatest(getDropdownAction.type, fetchDropdownDetails),
    takeLatest(sendOtpAction.type, postSendOtp),
    takeLatest(verifyOtpAction.type, postVerifyOtp),
    takeLatest(getTemplateDetail.type, fetchTemplateDataDetail),
    takeLatest(getTemplateDetailValues.type, getTemplatevalus),
    takeLatest(getExtendedGroups.type, getExtendedGroupsData),
    takeLatest(postExtendedGroups.type, postExtendedGroupsData),
    takeLatest(deleteExtendedFormGroups.type, deleteExtendedByGroupId),
    takeLatest(get_Latest_Extended_Groups.type, getLatestExtendedGroupsData),
    takeLatest(get_Extended_Form_Fill.type, putExtendedFill),
    takeLatest(getExtendedGroupsImage.type,postExtendUpload)
  ]);
}

export default ManchSaga;
