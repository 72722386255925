import * as React from "react"
const MobileDone = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    data-name="Group 807"
    {...props}
  >
    <g
      data-name="Rectangle 284"
      style={{
        fill: "#3f5fff",
        stroke: "#3f5fff",
        strokeWidth: "1.4px",
      }}
    >
      <rect
        width={44}
        height={44}
        stroke="none"
        rx={22}
        style={{
          stroke: "none",
        }}
      />
      <rect
        width={42.6}
        height={42.6}
        x={0.7}
        y={0.7}
        rx={21.3}
        style={{
          fill: "none",
        }}
      />
    </g>
    <path
      d="m219.121 168-10.692 10.693-6.071-6.073-1.7 1.7 7.769 7.769 12.39-12.388z"
      data-name="Path 286"
      style={{
        fill: "#fff",
        fillRule: "evenodd",
      }}
      transform="translate(-188.66 -153)"
    />
  </svg>
)
export default MobileDone