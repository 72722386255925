import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Submit from "../../../../assets/icons/FormFillIcons/Submit";
import Form from "../../../../assets/icons/FormFillIcons/Form";
import ActiveForm from "../../../../assets/icons/FormFillIcons/ActiveForm";
import Flag from "../../../../assets/icons/FormFillIcons/Flag";
import { Box } from "@mui/material";
import ActiveDocuments from "../../../../assets/icons/FormFillIcons/ActiveDocuments";
import "./DocumentsStepper.scss";
// Custom Connector Styles
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:'#3f5fff',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

// Custom Step Icon Styles
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "transparent",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "transparent",
    color: "#fff",
  }),
  ...(ownerState.completed && {
    backgroundColor: 'transparent',
    color: "#fff",
  }),
}));

// Custom Step Icon Component
const CustomStepIcon = ({
  active,
  completed,
  icon,
  firstDocumentAvailable,
}) => {
  const icons = firstDocumentAvailable
    ? {
        1: <ActiveForm />,
        2: <ActiveDocuments />,
        3: <Flag />,
      }
    : {
        1: <Form />,
        2: <Submit />,
        3: <Flag />,
      };

  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {icons[icon]}
    </CustomStepIconRoot>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired,
  firstDocumentAvailable: PropTypes.bool.isRequired,
};

const UploadDocuments = () => {
  // Fetching data from Redux
  const documents = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents || []
  );

  const firstDocumentName = documents?.[0]?.name || "Loading...";
  const firstDocumentAvailable = firstDocumentName !== "Loading...";
  const steps = [
    { id: 1, label: firstDocumentName },
    { id: 2, label: "Document List" },
    { id: 3, label: "Submission" },
  ];

  // Adjust steps if there's only one document
  const adjustedSteps =
    documents.length === 1
      ? [
          { id: 1, label: firstDocumentName },
          { id: 2, label: "Submission" },
        ]
      : steps;
  console.log(documents, "documents");
  const marginStyle = {
    marginLeft: documents?.some((doc) => doc.formFillEnabled === false)
      ? "-57px"
      : "-150px",
  };
  return (
    <Box className="documentStepperClass">
       <Box className="oneMoreClass" style={marginStyle}>
      <Stack spacing={4} >
        <Stepper
          alternativeLabel
          activeStep={1} // Set active step dynamically
          connector={<ColorlibConnector />}
        >
          {adjustedSteps.map((step) => (
            <Step key={step.id}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    icon={step.id}
                    firstDocumentAvailable={firstDocumentAvailable}
                  />
                )}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
        </Box>
    </Box>
  );
};

export default UploadDocuments;
