import React from "react";
import { common } from "./interface";

export const TextIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4159">
        <g data-name="Group 4104">
          <path
            data-name="Rectangle 873"
            style={{ fill: "none" }}
            d="M0 0h30v30H0z"
          />
          <g data-name="Group 4076">
            <g data-name="Group 4074">
              <path
                data-name="Path 1723"
                d="M37.051 66.455a.318.318 0 0 1-.322.314H21.256a.318.318 0 0 1-.322-.314V63H19v3.455a2.228 2.228 0 0 0 2.256 2.2h15.473a2.228 2.228 0 0 0 2.256-2.2V63h-1.934z"
                transform="translate(-14 -43.668)"
                style={{ fill: color }}
              />
            </g>
            <g data-name="Group 4077">
              <path
                data-name="Path 1722"
                d="M41.96 23.4v-3.458a.959.959 0 0 0-.976-.942H27.976a.959.959 0 0 0-.976.942V23.4h1.951v-2.516H33.5v10.679h-2.6v1.884h7.155v-1.884h-2.6V20.884h4.553V23.4z"
                transform="translate(-19.487 -14)"
                style={{ fill: color }}
              />
              <path
                data-name="Rectangle 873"
                transform="translate(5.226 5.226)"
                style={{ fill: "none" }}
                d="M0 0h20.319v20.319H0z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
