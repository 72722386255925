export const attributeStaticArr = [
  { id: "a1", name: "Attribute 1" },
  { id: "a2", name: "Attribute 2" },
  { id: "a3", name: "Attribute 3" },
  { id: "a4", name: "Attribute 4" },
  { id: "a5", name: "Attribute 5" },
  { id: "a6", name: "Attribute 6" },
  { id: "a7", name: "Attribute 7" },
  { id: "a8", name: "Attribute 8" },
];

export const criteriaArr = [
  { a1: "", name: null },
  { a2: "", name: null },
  { a3: "", name: null },
  { a4: "", name: null },
  { a5: "", name: null },
  { a6: "", name: null },
  { a7: "", name: null },
  { a8: "", name: null },
];

export const ffdIdArr = [
  { comingKey: "companyId", toBeReplacedKey: "companyId" },
  { comingKey: "templateId", toBeReplacedKey: "templateId" },
  { comingKey: "docTypeId", toBeReplacedKey: "docId" },
];
