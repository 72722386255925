import { conditionDropdown, conditionValueTypeDropdown } from "./Condition";
import { attributeStaticArr, criteriaArr, ffdIdArr } from "./FixedArr";

export {
  conditionDropdown,
  conditionValueTypeDropdown,
  attributeStaticArr,
  criteriaArr,
  ffdIdArr,
};
