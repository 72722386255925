import React, { useEffect, useState } from "react";
import "./FilledTransaction.scss";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import { Box, Typography, TextField, Button, Select } from "@mui/material";
import {
  getcompanyFeature,
  getTemplateDetail,
  storeDocumentIdWithoutTMPT,
  storeSelectedIdWithTMPT,
  storeSelectedName,
  getTemplate,
  postFillAction,
  getTemplateDetailValues,
  getAllDocument,
  putFillTxnAction,
  getTemplateInfo,
  storeTransactionId,
  setSidebarState
} from "../../../redux/slices/ManchSlices";
const FilledTransaction = () => {
  const { transactionId, templateId } = useParams(); // Get params
  const [otpType, setOtpType] = React.useState([]);
  const templateValues = useSelector(
    (state) => state?.ManchSlice?.templateDetailValues?.templateDetailInfoValues
  );
  const [companyFeature, setCompanyFeature] = useState([]);
  const [form, setForm] = useState({
    title: templateValues?.offerDetails?.title || "",
    message: templateValues?.offerDetails?.message || "",
    name: templateValues?.signerDetails?.name || "",
    mobileNo: templateValues?.signerDetails?.mobileNumber || "",
    email: templateValues?.signerDetails?.email || "",
    organization: templateValues?.signerDetails?.companyName || "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [selectedIndex, setSelectedIndex] = useState(""); // Tracks selected index
  const [isTemplateLoaded, setTemplateLoaded] = useState(false);
  const handleChange = (event) => {
    setOtpType(event.target.value);
  };
  const encryptData = (data) => {
    const secret = "dev.manchtech.com"; // Secret key
    const encryptedData = CryptoJS.AES.encrypt(data, secret);
    return encryptedData.toString(); // Convert to string
  };
  useEffect(() => {
    if (templateValues !== null) {
      // Update form state
      setForm({
        title: templateValues?.offerDetails?.title || "",
        message: templateValues?.offerDetails?.message || "",
        name: templateValues?.signerDetails?.name || "",
        mobileNo: templateValues?.signerDetails?.mobileNumber || "",
        email: templateValues?.signerDetails?.email || "",
        organization: templateValues?.signerDetails?.companyName || "",
      });

      // Transform authModes
      const transformedFeatures = [
        templateValues?.authModes?.defaultAuthMode || {},
        ...(templateValues?.authModes?.authModes || []),
      ];

      setCompanyFeature(transformedFeatures);
      console.log(transformedFeatures, "transform");
      const defaultFeatureName = templateValues?.authModes?.defaultAuthMode?.featureName || "";
      setOtpType(defaultFeatureName);
    }
  }, [templateValues]);
  // Selectors
  const sidebarOpen = useSelector(
    (state) => state?.ManchSlice?.sidebarOpen
  );
  const TEMPID = useSelector(
    (e) => e?.ManchSlice?.selectedTemplateIdWithTMPT?.TempId
  );
  const TemplateIdWithoutTMPT = useSelector((e) => e?.ManchSlice?.selectedTemplateIdWithoutTMPT?.TempId);
  const templateDetail = useSelector(
    (state) => state?.ManchSlice?.templateDetail?.templateDetailInfo
  );
  const selectedTemplate = useSelector(
    (e) => e?.ManchSlice?.selectedTemplateName?.Name
  );
  const companyId = useSelector(
    (state) => state?.ManchSlice?.user?.userValue?.companyId
  );
  const txnURL = useSelector(
    (e) => e?.ManchSlice?.fill?.fillValue?.data?.txnURL
  );
  const allDocumentsSuccess = useSelector(
    (state) => state.ManchSlice?.allDocument?.allDocumentValue?.transactionId
  );
  const fillTxnSuccess = useSelector(
    (state) => state?.ManchSlice?.fillByTxnurl?.fillTxnSuccess
  );
  console.log(companyFeature, "cm");
  const uiCustomizations = useSelector(
    (e) => e?.ManchSlice?.template?.templateValue?.uiCustomizations
  );
  const firstPartyEmail = useSelector(
    (e) => e?.ManchSlice?.user?.userValue?.email
  );
  // Logs
  console.log(companyId, "companyId");
  console.log(templateDetail, "template detail");
  console.log(templateId, "templateId");
useEffect(()=>{
dispatch(storeTransactionId(transactionId))
},[])
  // Effect 1: Fetch template details when `templateId` changes
  useEffect(() => {
    if (templateId && companyId) {
      console.log("Fetching template details...");
      dispatch(getTemplateDetail({ templateId }));

      // Reset the loaded flag on templateId change
      setTemplateLoaded(false);
    }
  }, [templateId, companyId, dispatch]);

  // Effect 2: Dispatch actions when `companyId` and `selectedIndex` are available
  useEffect(() => {
    if (companyId && selectedIndex) {
      console.log("Dispatching actions for template and features...");
      dispatch(getTemplate({ companyId, id: selectedIndex }));
      dispatch(getcompanyFeature({ companyId, id: selectedIndex }));
    }
  }, [companyId, selectedIndex, dispatch]); // Runs only when both values are set

  // Effect 3: Handle click event when template details are available
  useEffect(() => {
    if (
      templateDetail?.name &&
      templateDetail?.key &&
      templateId &&
      !isTemplateLoaded
    ) {
      console.log("Template loaded. Triggering handleListItemClick...");
      handleListItemClick(templateId, templateDetail.name, templateDetail.key);
      setTemplateLoaded(true); // Mark as loaded to prevent repeat calls
    }
  }, [templateDetail, templateId, isTemplateLoaded]);

  // Function: Handle list item click
  const handleListItemClick = (id, name, key, dockTypeId) => {
    console.log(id, key, name, dockTypeId, "123");
    setSelectedIndex(id); // Update selected index

    // Dispatch basic info immediately
    dispatch(storeDocumentIdWithoutTMPT(id));
    dispatch(storeSelectedIdWithTMPT(key));
    dispatch(storeSelectedName(name));
    // dispatch(storeDocumentTypeId(dockTypeId))
  };

  useEffect(() => {
    if (transactionId) {
      dispatch(getTemplateDetailValues({ transactionId }));
    }
  }, []);
  const handleProceed = () => {
  // useEffect(() => {
    if (transactionId) {
      dispatch(getAllDocument(transactionId));
      console.log(firstPartyEmail, "firstPartyEmail");
      console.log(form.name, "name");
      console.log(form.mobileNo, "mobileNo");
      console.log(form.email, "form.email");
      console.log(form.organization, "form.organization");

      // Encrypting firstParty and secondParty data
      const encryptedFirstPartyEmail = encryptData(firstPartyEmail);
      const encryptedSecondPartyName = encryptData(form.name);
      const encryptedSecondPartyMobile = encryptData(form.mobileNo);
      const encryptedSecondPartyEmail = encryptData(form.email);
      const encryptedSecondPartyOrganization = encryptData(form.organization);

      // const dataOfPut = {
      //   firstParty: {
      //     email: encryptedFirstPartyEmail,
      //   },
      //   secondParty: {
      //     name: encryptedSecondPartyName,
      //     mobile: encryptedSecondPartyMobile,
      //     email: encryptedSecondPartyEmail,
      //     organization: encryptedSecondPartyOrganization,
      //   },
      //   title: form.title,
      //   message: form.message,
      //   lockEsignMethodSelection: false,
      //   authModeType: otpType,
      //   // dataHash: null,
      // };
      const dataOfPut = {
        firstParty: {
          email: firstPartyEmail,
        },
        secondParty: {
          name: form.name ? form.name : "name",
          mobile: form.mobileNo ? form.mobileNo : "mobile",
          email: form.email ? form.email : "email",
          organization: form.organization,
        },
        title: form.title ? form.title : "title",
        message: form.message ? form.message : "Please Review It",
        lockEsignMethodSelection: false,
        authModeType: otpType ? otpType : "ESIGN_METHOD_OTP",
        dataHash: null,
      };
      console.log("data of put transaction", dataOfPut);
      dispatch(putFillTxnAction({ transactionId, dataOfPut }));
      
    }
  // }, [txnURL]);
  }
  useEffect(() => {
    if (Number(allDocumentsSuccess) === Number(transactionId)) {
      console.log(transactionId, "ext001");
      dispatch(getTemplateInfo(transactionId));
      console.log("asdf01");
      navigate(`/onboardingRequest/template/${TemplateIdWithoutTMPT}/transaction/${transactionId}`); // Use absolute path
      console.log("asdf02");
    }
  }, [transactionId, allDocumentsSuccess, dispatch, navigate]);

 
  useEffect(()=>{
    dispatch(setSidebarState(false))
  },[])
  return (
    <div
      className="filledTransaction"
      style={{
        paddingLeft: sidebarOpen ? "80px" : "0px",
        height: "100%",
        backgroundColor: "#f4f8fb",
        overflowY: "hidden",
      }}
    >
      <Box className="background">
        <Box className="insideForm">
          <Box className="content">
            <Box className="menuItem">
              <Typography
                variant="formHeader"
                sx={{ textTransform: "capitalize" }}
              >
                {" "}
                {selectedTemplate}
              </Typography>
            </Box>
            <Box className="titleAndMessage">
              <Box>
                {" "}
                {uiCustomizations && uiCustomizations[0]?.displayInUi ? (
                  <TextField
                    id="standard-basic"
                    label={uiCustomizations[0]?.displayName
                      ?.toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    variant="standard"
                    required={true}
                    value={form.title}
                    sx={{ width: "31.25vw" }}
                    // error={!!errors.title}
                    helperText={errors.title}
                    onChange={(e) =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        title: e.target.value,
                      }))
                    }
                    InputLabelProps={{
                      sx: {
                        "& .MuiFormLabel-asterisk": {
                          color: "red",
                        },
                      },
                    }}
                  />
                ) : null}
              </Box>
              {uiCustomizations && uiCustomizations[1]?.displayInUi ? (
                <TextField
                  id="standard-basic"
                  label={uiCustomizations[1]?.displayName
                    ?.toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  variant="standard"
                  required={true}
                  value={form.message}
                  sx={{ width: "31.25vw" }}
                  // error={!!errors.message}
                  helperText={errors.message}
                  onChange={(e) =>
                    setForm((prevForm) => ({
                      ...prevForm,
                      message: e.target.value,
                    }))
                  }
                  InputLabelProps={{
                    sx: {
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                />
              ) : null}
            </Box>
            <Box mt={5}>
              {uiCustomizations && uiCustomizations[2]?.displayInUi ? (
                <Typography variant="formSecondary">
                  {uiCustomizations[2]?.displayName
                    ?.toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Typography>
              ) : null}
            </Box>
            <Box className="titleAndMessage">
              {uiCustomizations && uiCustomizations[3]?.displayInUi ? (
                <TextField
                  id="standard-basic"
                  label={uiCustomizations[3]?.displayName
                    ?.toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  variant="standard"
                  required={true}
                  value={form.name}
                  sx={{ width: "31.25vw" }}
                  // error={!!errors.name}
                  helperText={errors.name}
                  onChange={(e) =>
                    setForm((prevForm) => ({
                      ...prevForm,
                      name: e.target.value,
                    }))
                  }
                  InputLabelProps={{
                    sx: {
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                />
              ) : null}
              {uiCustomizations && uiCustomizations[4]?.displayInUi ? (
                <TextField
                  id="standard-basic"
                  label={uiCustomizations[4]?.displayName
                    ?.toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  variant="standard"
                  required={true}
                  value={form.mobileNo}
                  sx={{ width: "31.25vw" }}
                  // error={!!errors.mobileNo}
                  helperText={errors.mobileNo}
                  onChange={(e) =>
                    setForm((prevForm) => ({
                      ...prevForm,
                      mobileNo: e.target.value,
                    }))
                  }
                  InputLabelProps={{
                    sx: {
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                />
              ) : null}
            </Box>
            <Box className="titleAndMessage">
              {uiCustomizations && uiCustomizations[5]?.displayInUi ? (
                <TextField
                  id="standard-basic"
                  label={uiCustomizations[5]?.displayName
                    ?.toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  variant="standard"
                  required={true}
                  value={form.email}
                  sx={{ width: "31.25vw" }}
                  // error={!!errors.email}
                  helperText={errors.email}
                  onChange={(e) =>
                    setForm((prevForm) => ({
                      ...prevForm,
                      email: e.target.value,
                    }))
                  }
                  InputLabelProps={{
                    sx: {
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    },
                  }}
                />
              ) : null}
              {uiCustomizations && uiCustomizations[6]?.displayInUi ? (
                <TextField
                  id="standard-basic"
                  label={uiCustomizations[6]?.displayName
                    ?.toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  variant="standard"
                  required={false}
                  value={form.organization}
                  sx={{ width: "31.25vw" }}
                  onChange={(e) =>
                    setForm((prevForm) => ({
                      ...prevForm,
                      organization: e.target.value,
                    }))
                  }
                />
              ) : null}
            </Box>
            {uiCustomizations && uiCustomizations[7]?.displayInUi ? (
              <FormControl
                variant="standard"
                sx={{ mt: "30px", minWidth: 120,width: "31.25vw" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  {uiCustomizations[7]?.displayName
                    ?.toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={templateValues?.authModes?.defaultAuthMode?.featureName }
                  onChange={handleChange}
                  label="Otp"
                  required={true}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {companyFeature.map((element) => {
                    return (
                      <MenuItem value={element.featureName} key={element.displayName}>
                        {element.displayName
                          ?.toLowerCase()
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* {errors.authModeType && <Typography color="error">{errors.authModeType}</Typography>} */}
              </FormControl>
            ) : null}
            <Box className="btnBox">
              <Button variant="dark" onClick={handleProceed}>
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default FilledTransaction;
