import React, { FunctionComponent } from "react";
import logo from "../assets/logo/logo.png";

interface NavbarProps {
  window?: () => Window;
}

const mainPath = window.location.pathname;

const existingPath = mainPath?.includes("form-builder")
  ? "formFillFieldSetupTab"
  : "statusTransactionTabNew";

const domainUrl = process.env.REACT_APP_MAIN_URL;
const diy1Url = domainUrl
  ? domainUrl + `admin.html?action=${existingPath}`
  : "";

export const reDirectToMainSite = () => {
  window.location.href = diy1Url;
};

const Navbar: FunctionComponent<NavbarProps> = () => {
  return (
    <header
      id="header"
      className="header-sticky sticky-active"
      data-fullwidth="true"
    >
      <img
        src={logo}
        alt=""
        className="main-logo"
        style={{
          width: "10rem",
          height: "3rem",
          margin: "0 0 0 3rem",
          cursor: "pointer",
        }}
        onClick={reDirectToMainSite}
      />
    </header>
  );
};

export default Navbar;
