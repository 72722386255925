import React from "react";
import { common } from "./interface";

export const RuleIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="Group_4282" data-name="Group 4282" transform="translate(0.08)">
        <rect
          id="Rectangle_582"
          data-name="Rectangle 582"
          width="20"
          height="20"
          transform="translate(-0.08)"
          fill="none"
        />
        <path
          id="Union_19"
          data-name="Union 19"
          d="M9.559,0H0V16.686H13.671V4.11Zm.316,1.774,2.039,2.037H9.875ZM12.633,15.65H1.038V1.036h7.8V4.847h3.795Z"
          transform="translate(3 2)"
          fill="#4f4f4f"
        />
      </g>
    </svg>
  );
};
