import React from "react";
import { common } from "./interface";

export const TimeIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4161">
        <g data-name="Group 4161">
          <path
            data-name="Path 1720"
            d="M13.025 0a13.025 13.025 0 1 0 13.026 13.025A13.04 13.04 0 0 0 13.025 0zm0 25.009a11.983 11.983 0 1 1 11.984-11.984 12 12 0 0 1-11.984 11.984z"
            transform="translate(2 2)"
            style={{ fill: color, stroke: color }}
          />
          <path
            data-name="Path 1721"
            d="M17.135 5.719a.521.521 0 0 0-.521.521v9.144l-7.672 2.438a.521.521 0 0 0 .316.993l8.035-2.554c.008 0 .013-.009.021-.011a.5.5 0 0 0 .1-.054.513.513 0 0 0 .076-.052.479.479 0 0 0 .062-.079.486.486 0 0 0 .054-.081.514.514 0 0 0 .027-.09.492.492 0 0 0 .022-.107V6.24a.521.521 0 0 0-.52-.521z"
            transform="translate(-2.109 -.739)"
            style={{ fill: color, stroke: color }}
          />
        </g>
        <path
          data-name="Rectangle 895"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
