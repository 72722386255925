import React from "react";
import { common } from "./interface";

export const FileUploadIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4164">
        <g data-name="Group 4085">
          <path
            data-name="Path 1737"
            d="M48.372 24.036H37.16a3.5 3.5 0 0 0-3.491 3.491v19.611a3.5 3.5 0 0 0 3.491 3.491h14.293a3.5 3.5 0 0 0 3.49-3.491V31.381zm.46 3.008 3.42 3.823h-3.42zm2.622 21.921H37.16a1.83 1.83 0 0 1-1.828-1.827V27.527A1.83 1.83 0 0 1 37.16 25.7h10.008v6.832h6.112v14.606a1.829 1.829 0 0 1-1.827 1.828z"
            transform="translate(-29.125 -22.521)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1738"
            d="m57.261 64.361 1.264 1.081 1.932-2.26v6.8h1.663v-6.8l1.932 2.26 1.264-1.081-4.028-4.712z"
            transform="translate(-46.177 -48.261)"
            style={{ fill: color }}
          />
        </g>
        <path
          data-name="Rectangle 878"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
