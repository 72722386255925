import React, { Dispatch, useRef, useState } from "react";
import { FormLayoutComponentChildrenType } from "../../../types/FormTemplateTypes";
import { renderItem } from "./Render";
import { ResizeDirection } from "re-resizable";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import { FormItemTypes } from "../../../utils/formBuilderUtils";
import { DialogBox } from "../../Modal";

const selectedColor = "var(--primary)";
const nonSelectedColor = "rgba(0,0,0,0.1)";

type DispatchObject = {
  value: boolean;
  arrayId?: string;
};

export type typeResizeCallback = (
  event: any,
  direction: ResizeDirection,
  refToElement: any
) => void | undefined;

type typeResizeStopCallback = (
  component: FormLayoutComponentChildrenType
) => void | undefined;

interface ControlViewComponentProps {
  item: any;
  deleteControl: (itemId: string, containerId: string) => void;
  deleteArrayItems?: (
    containerId: string,
    id: string,
    event?: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  containerId: string;
  selectControl: (item: FormLayoutComponentChildrenType) => void;
  selectedControl: any;
  index: number;
  moveControl: (
    item: FormLayoutComponentChildrenType,
    dragIndex: number,
    hoverIndex: number,
    containerId: string
  ) => void;
  isOverMainContainer?: DispatchObject;
  setIsOverMainContainer?: Dispatch<DispatchObject>;
  ResizeCallback?: typeResizeCallback;
  ResizeStopCallback?: typeResizeStopCallback;
}

function ControlViewComponent(props: ControlViewComponentProps) {
  const {
    item,
    deleteControl,
    containerId,
    selectControl,
    selectedControl,
    setIsOverMainContainer,
    deleteArrayItems,
    ResizeCallback,
    ResizeStopCallback,
    index,
    moveControl,
  } = props;

  const filterItem = item?.container ?? item;

  let wrapperStyle = {
    border: "1px solid " + nonSelectedColor,
    borderRadius: "9px",
    backgroundColor: "white",
    boxShadow: "none",
    height: "100%",
    width: "100%",
    padding: 0,
  };

  // Check if its the same type and id to change color.
  if (
    selectedControl &&
    filterItem.id === selectedControl.id &&
    filterItem.type === selectedControl.type
  ) {
    wrapperStyle.border = "1px solid " + selectedColor;
  }

  const [modalOpen, setModalOpen] = useState(false);

  const handleDeleteControl: React.MouseEventHandler<HTMLSpanElement> = (
    event
  ) => {
    setModalOpen(true);
    if (event.stopPropagation) event.stopPropagation();
  };

  // Drag & Sort Code for functionality

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    FormLayoutComponentChildrenType,
    void,
    { handlerId: Identifier | null }
  >({
    accept: FormItemTypes.CONTROL,
    collect(monitor: any) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: FormLayoutComponentChildrenType, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveControl(item, dragIndex as number, hoverIndex, containerId);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: FormItemTypes.CONTROL,
    item: () => {
      return { ...item, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div className="mt-3" ref={ref}>
      {renderItem(
        item,
        setIsOverMainContainer,
        selectControl,
        selectedControl,
        filterItem,
        handleDeleteControl,
        deleteArrayItems,
        ResizeCallback,
        ResizeStopCallback
      )}
      <DialogBox
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        deleteFun={deleteControl}
        filterItem={filterItem.id}
        containerId={containerId}
        type="field"
      />
    </div>
  );
}

export default ControlViewComponent;
