import React from "react";
import {
  ButtonIcon,
  CheckIcon,
  DateIcon,
  DropdownIcon,
  FileUploadIcon,
  ImageIcon,
  MultiDropdown,
  MultiFileUploadIcon,
  NumberIcon,
  OtpIcon,
  RadioIcon,
  TextAreaIcon,
  TextIcon,
  TimeIcon,
  MapIcon,
  ArrayIcon,
} from "../../../../assets/icons";
import AirplayOutlinedIcon from "@mui/icons-material/AirplayOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";

const commonStyle = {
  size: 30,
  color: "#c1c1c1",
};

export const iconArr = [
  { ele: <TextIcon {...commonStyle} />, type: "TEXT" },
  { ele: <TextAreaIcon {...commonStyle} />, type: "TEXTAREA" },
  { ele: <NumberIcon {...commonStyle} />, type: "NUMBER" },
  { ele: <TimeIcon {...commonStyle} />, type: "TIME" },
  { ele: <DateIcon {...commonStyle} />, type: "DATE" },
  {
    ele: <RadioIcon {...commonStyle} />,
    type: "EXTERNAL_DROP_DOWN_RADIOBUTTON",
  },
  { ele: <CheckIcon {...commonStyle} />, type: "STATIC_CHECK_BOX" },
  { ele: <DropdownIcon {...commonStyle} />, type: "EXTERNAL_DROP_DOWN_VALUE" },
  {
    ele: <MultiDropdown {...commonStyle} />,
    type: "MULTISELECT_EXTERNAL_DROPDOWN",
  },
  { ele: <FileUploadIcon {...commonStyle} />, type: "FILE" },
  {
    ele: <MultiFileUploadIcon {...commonStyle} />,
    type: "MultiFileUploadIcon",
  },
  { ele: <ImageIcon {...commonStyle} />, type: "IMAGE" },
  { ele: <ButtonIcon {...commonStyle} />, type: "EXECUTE_BUTTON" },

  { ele: <OtpIcon {...commonStyle} />, type: "OTP" },
  {
    ele: <AirplayOutlinedIcon sx={{ color: commonStyle.color }} />,
    type: "OBJ",
  },
  {
    ele: <LabelOutlinedIcon sx={{ color: commonStyle.color, fontSize: 30 }} />,
    type: "LABEL",
  },
  {
    ele: (
      <FileOpenOutlinedIcon sx={{ color: commonStyle.color, fontSize: 25 }} />
    ),
    type: "ADV",
  },
  {
    ele: <LabelOutlinedIcon sx={{ color: commonStyle.color, fontSize: 30 }} />,
    type: "LABEL",
  },
  {
    ele: <MapIcon {...commonStyle} />,
    type: "DYNAMIC_LOCATION",
  },
  {
    ele: <ArrayIcon {...commonStyle} />,
    type: "ARRAY",
  },
];
