import React, { Component } from "react";
import StepperFormPreview from "./form-preview/StepperFormPreview";
import { Drawer } from "@mui/material";
import { FormLayoutComponentsType } from "../../../types/FormTemplateTypes";

interface FormPreviewProps {
  screenType: string;
  showPreview: boolean;
  closePreviewDrawer: () => void;
  formLayoutComponents: FormLayoutComponentsType[];
  selectedTemplate: any;
  saveForm: any;
  templateName: string | undefined;
}

interface FormPreviewStates {
  screenType: string;
}

class FormPreview extends Component<FormPreviewProps, FormPreviewStates> {
  closePreviewDrawer: any;
  constructor(props: FormPreviewProps) {
    super(props);
    this.state = {
      screenType: this.props.screenType || "mobile",
    };
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  handleCloseClick() {
    this.props.closePreviewDrawer();
  }

  render() {
    const {
      showPreview,
      formLayoutComponents,
      selectedTemplate,
      saveForm,
      closePreviewDrawer,
      templateName,
    } = this.props;

    return (
      <>
        <Drawer open={showPreview} anchor="right">
          <div
            style={{
              width: "40vw",
              backgroundColor: "#f8f9fa",
              height: "100vh",
            }}
          >
            <div
              className="d-flex align-items-center pl-4"
              style={{
                backgroundColor: "rgb(232, 240, 253)",
                height: "5rem",
              }}
            >
              <i
                className="fas fa-chevron-right"
                style={{
                  marginRight: "20px",
                  cursor: "pointer",
                  fontSize: "1.25rem",
                }}
                onClick={() => this.handleCloseClick()}
              ></i>
              <h4
                style={{
                  color: "rgb(49, 123, 240)",
                  fontSize: "19px",
                  fontWeight: "500",
                }}
              >
                Preview
              </h4>
            </div>
            <div className="container">
              <div className="p-5">
                <StepperFormPreview
                  screenType={this.state.screenType}
                  formLayoutComponents={formLayoutComponents}
                  //selectedTemplate={selectedTemplate}
                  saveForm={saveForm}
                  closePreviewDrawer={closePreviewDrawer}
                  templateName={templateName}
                />
              </div>
            </div>
          </div>
        </Drawer>
      </>
    );
  }
}

export default FormPreview;
