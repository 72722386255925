import React from "react";
import { DeleteIcon } from "../../assets/icons";
import { Box } from "@mui/material";

const ObjectLabelComponent = (props: any) => {
  const {
    component,
    isSelected,
    handleDeleteControl,
    isArrayItem = false,
  } = props;

  return (
    <div
      style={{
        borderBottom: isArrayItem
          ? "none"
          : isSelected
          ? "1px solid #318BE3"
          : "none",
        width: "100%",
      }}
      className="w-100 d-flex align-items-center justify-content-between"
    >
      <div
        style={{
          width: "100%",
          margin: isSelected ? "0 1rem 0 0" : "0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="w-100">{component}</div>
      </div>
      {isSelected && !isArrayItem && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div onClick={handleDeleteControl}>
            <DeleteIcon />
          </div>
        </Box>
      )}
    </div>
  );
};

export default ObjectLabelComponent;
