import * as React from "react"
const DeleteIconMobile = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    <g
      data-name="Ellipse 60"
      style={{
        fill: "#fff",
        stroke: "#3f5fff",
      }}
    >
      <circle
        cx={25}
        cy={25}
        r={25}
        stroke="none"
        style={{
          stroke: "none",
        }}
      />
      <circle
        cx={25}
        cy={25}
        r={24.5}
        style={{
          fill: "none",
        }}
      />
    </g>
    <path
      d="M214.874 177.63H197.29v18.87h17.584zm-1.935 16.936h-13.715v-15h13.716z"
      data-name="Path 410"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-181.308 -158.152)"
    />
    <path
      d="M156.35 59.64h22.3v-1.934h-6.861V54.6h-8.576v3.108h-6.863zm8.8-3.108h4.707v1.174h-4.707z"
      data-name="Path 411"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-142.725 -42.203)"
    />
    <path
      d="M277.2 263.26h1.935v8.8H277.2z"
      data-name="Path 412"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-256.617 -238.852)"
    />
    <path
      d="M389.2 263.26h1.935v8.8H389.2z"
      data-name="Path 413"
      style={{
        fill: "#3f5fff",
      }}
      transform="translate(-362.169 -238.852)"
    />
  </svg>
)
export default DeleteIconMobile