import React,{useState} from "react";
import "./DocumentsSections.scss";
import { Box, Typography, Button } from "@mui/material";
import CircleOutline from "../../../../assets/icons/FormFillIcons/CircleOutline";
import Done from "../../../../assets/icons/FormFillIcons/Done";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";
import { getStatusTrasition } from "../../../../redux/slices/ManchSlices";

const DocumentsSections = () => {

  const dispatch = useDispatch();
  const trxcId = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.transactionId
  );
  const currentStatus = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.transactionId
  );
  const selectDocuments = (state) => {
    return state?.ManchSlice?.allDocument?.allDocumentValue?.documents || [];
  };
  
  const selectFilteredDocuments = createSelector(
    [selectDocuments],
    (documents) => Array.isArray(documents) ? documents.filter(doc => !doc.formFillEnabled) : [] // Ensure documents is an array
  );
  const filteredDocuments = useSelector(selectFilteredDocuments);
  // if (filteredDocuments.length === 0) {
  //   dispatch(getStatusTrasition(trxcId))
  // }
  const handleSubmit = () => {
    // Check if any document meets the criteria
    const hasMandatoryAndUploaded = filteredDocuments.some(
      (doc) => doc.mandatory === true && doc.uploaded === true
    );
  
    // Dispatch if the condition is met
    if (hasMandatoryAndUploaded) {
 
      dispatch(getStatusTrasition(trxcId));
    }
  };
  console.log(filteredDocuments,"fitereddocs in docsec")
  return (
    <Box className="documentSectionClass">
      <Box className="textSection">
        <Typography variant="sectionTitle">Sections</Typography>
      </Box>
      <Box className="tabsClass">
        {filteredDocuments &&
          filteredDocuments.map((element, index) => (
            <Box key={index} className="stepsArray">
              <Box pl={2}>
              {element?.uploaded?<Done/>:  <CircleOutline/>}
              </Box>
              <Box pl={1}>
                <Typography variant="templateInfo">{element.name}</Typography>
              </Box>
            </Box>
          ))}
      </Box>
      <Box className="btnSubmit">
        <Button variant="sectionSubmit" disabled={!filteredDocuments.some(
      (doc) => doc.mandatory === true && doc.uploaded === true)} onClick={handleSubmit}>Submit</Button>
      </Box>
    </Box>
  );
};

export default DocumentsSections;
