import React, { useEffect, useRef } from "react";

const InactivityTracker = (props: any) => {
  const { setIsScreenInactive } = props;
  const timeoutRef = useRef<any>(null);

  // Reset inactivity timer
  const resetTimer = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsScreenInactive(true);
      //alert("You've been inactive for too long!");
      // Perform actions like logout
    }, 600000); // 10 mins of inactivity
  };

  useEffect(() => {
    // Reset timer on user activity
    const handleActivity = () => {
      setIsScreenInactive(false);
      resetTimer();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);

    resetTimer(); // Start the timer on mount

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return <></>;
};

export default InactivityTracker;
