import * as React from "react"
const DeleteIconRed = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <path
      d="M0 0h30v30H0z"
      data-name="Path 941"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M134.282 66.463h12.437l.925-14.226h1.743v-1.823h-4.756v-2.869h-8.261v2.867h-4.756v1.823h1.732zm10.73-1.823h-9.024l-.807-12.405h10.637zm-6.82-15.267h4.617v1.044h-4.616z"
      data-name="Path 845"
      style={{
        fill: "#ef002c",
      }}
      transform="translate(-125.614 -41.545)"
    />
  </svg>
)
export default DeleteIconRed
