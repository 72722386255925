import React, { useContext } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropContainerComponent from "../DropContainerComponent";
import { FormItemTypes } from "../../../../utils/formBuilderUtils";
import { FormBuilderContext } from "../../FormBuilder";
import { Box } from "@mui/material";

const ItemType = "ITEM";

const DraggableItem = ({ item, index, moveItem }: any) => {
  const value: any = useContext(FormBuilderContext);
  const {
    selectedControl,
    deleteContainer,
    deleteControl,
    selectControl,
    moveControl,
    isOverMainContainer,
    setIsOverMainContainer,
    deleteArrayItems,
    gridValue,
    setGridValue,
    editControlProperties,
    isCalled,
    setIsCalled,
    expandedPanel,
    setExpandedPanel,
    dirtyRecord,
    particularRule,
    ruleData,
  } = value;

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: any) => {
      if (draggedItem?.index !== index) {
        moveItem(draggedItem?.index, index);
        draggedItem.index = index; // Update the dragged item's index
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        width: "100%",
      }}
    >
      <DropContainerComponent
        key={item.container.id}
        index={index}
        layout={item.container}
        childrenComponents={item.children}
        selectedControl={selectedControl}
        deleteContainer={deleteContainer}
        deleteControl={deleteControl}
        selectControl={selectControl}
        accept={FormItemTypes.CONTROL}
        moveControl={moveControl}
        isOverMainContainer={isOverMainContainer}
        setIsOverMainContainer={setIsOverMainContainer}
        deleteArrayItems={deleteArrayItems}
        gridValue={gridValue}
        setGridValue={setGridValue}
        editControlProperties={editControlProperties}
        isCalled={isCalled}
        setIsCalled={setIsCalled}
        expandedPanel={expandedPanel}
        setExpandedPanel={setExpandedPanel}
        dirtyRecord={dirtyRecord}
        particularRule={particularRule}
        ruleData={ruleData}
      />
    </div>
  );
};

const DraggableList = () => {
  const value: any = useContext(FormBuilderContext);
  const {
    handleItemAdded,
    formLayoutComponents,
    setFormLayoutComponents,
    setIsEdited,
  } = value;

  const moveItem = (fromIndex: any, toIndex: any) => {
    const updatedItems = [...formLayoutComponents];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);

    setIsEdited(true);
    let formOrder = 0;
    for (let i = 0; i < updatedItems?.length; i++) {
      updatedItems[i].container.formOrder = ++formOrder;
      updatedItems[i].container.isUpdated = true;
      for (let j = 0; j < updatedItems[i]?.children?.length; j++) {
        updatedItems[i].children[j].formOrder = ++formOrder;
        updatedItems[i].children[j].isUpdated = true;
      }
    }

    setFormLayoutComponents(updatedItems);
  };

  return (
    <Box
      className="p-10 mt-3"
      sx={{
        overflowY: "auto",
        height: "calc(100vh - 150px)",
        width: "100%",
      }}
    >
      {formLayoutComponents?.map((item: any, index: any) => (
        <DraggableItem
          key={index}
          index={index}
          item={item}
          moveItem={moveItem}
        />
      ))}
      <Box sx={{ width: "100%" }}>
        <DropContainerComponent
          accept={FormItemTypes.CONTAINER}
          name={"Parent Component"}
          handleItemAdded={handleItemAdded}
        />
      </Box>
    </Box>
  );
};

const MoveablePannel = () => (
  <DndProvider backend={HTML5Backend}>
    <DraggableList />
  </DndProvider>
);

export default MoveablePannel;
