import * as React from "react"
const Eyemobile = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    <g
      data-name="Path 847"
      style={{
        fill: "none",
      }}
    >
      <path
        d="M25 0A25 25 0 1 1 0 25 25 25 0 0 1 25 0z"
        style={{
          stroke: "none",
        }}
      />
      <path
        d="M25 1A23.921 23.921 0 0 0 8.03 8.03 23.921 23.921 0 0 0 1 25a23.921 23.921 0 0 0 7.03 16.97A23.921 23.921 0 0 0 25 49a23.921 23.921 0 0 0 16.97-7.03A23.921 23.921 0 0 0 49 25a23.921 23.921 0 0 0-7.03-16.97A23.921 23.921 0 0 0 25 1m0-1c13.807 0 25 11.193 25 25S38.807 50 25 50 0 38.807 0 25 11.193 0 25 0z"
        style={{
          fill: "#3f5fff",
          stroke: "none",
        }}
      />
    </g>
    <path
      d="M254.184 177.89a6.308 6.308 0 1 0 4.449 1.848 6.3 6.3 0 0 0-4.449-1.848zm0 10.975a4.684 4.684 0 1 1 3.306-1.372 4.679 4.679 0 0 1-3.306 1.372z"
      data-name="Path 800"
      style={{
        fill: "#3f5fff",
        stroke: "#3f5fff",
        strokeWidth: ".4px",
      }}
      transform="translate(-229.415 -159.394)"
    />
    <path
      d="M138.373 121.375a.433.433 0 0 0 0-.086v-.038a.26.26 0 0 1 0-.07c-1.576-5.887-7.818-10.149-14.93-10.149s-13.349 4.263-14.886 10.144a.263.263 0 0 1 0 .07v.038a.429.429 0 0 0 0 .086v.151a.431.431 0 0 0 0 .086v.038a.265.265 0 0 1 0 .07c1.538 5.892 7.781 10.154 14.886 10.154s13.354-4.263 14.886-10.144a.263.263 0 0 1 0-.07v-.043a.429.429 0 0 0 0-.086v-.076c0-.027.043-.049.043-.076zm-14.93 8.876c-6.329 0-11.87-3.69-13.279-8.8 1.408-5.11 6.95-8.8 13.279-8.8s11.87 3.69 13.279 8.8c-1.408 5.11-6.949 8.8-13.279 8.8z"
      data-name="Path 801"
      style={{
        fill: "#3f5fff",
        stroke: "#3f5fff",
        strokeWidth: ".4px",
      }}
      transform="translate(-98.675 -96.659)"
    />
  </svg>
)
export default Eyemobile
