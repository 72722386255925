import React from "react";
import { common } from "./interface";

export const FileIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 40"
    >
      <g id="File_icon" transform="translate(-240 -148)">
        <rect
          id="Rectangle_490"
          data-name="Rectangle 490"
          width="40"
          height="40"
          transform="translate(240 148)"
          fill="none"
          opacity="0.2"
        />
        <g id="noun-files-4766629" transform="translate(83.67 106.336)">
          <path
            id="Path_690"
            data-name="Path 690"
            d="M184.075,130.353V111.145h-3.842a.768.768,0,0,1-.768-.768v-3.842h-14.6v23.819ZM181,107.621l1.987,1.987H181Zm3.842,24.269H164.1a.768.768,0,0,1-.768-.768V105.766A.768.768,0,0,1,164.1,105h16.135a.769.769,0,0,1,.543.225l4.61,4.61a.769.769,0,0,1,.225.543v20.745a.768.768,0,0,1-.768.768Z"
            transform="translate(0 -54.492)"
            fill={color}
            fill-rule="evenodd"
          />
          <path
            id="Path_691"
            data-name="Path 691"
            d="M220.618,73.556h-3a.768.768,0,1,0,0,1.537h3.769a.768.768,0,0,0,.768-.768V54.347a.77.77,0,0,0-.225-.543l-6.915-6.915a.769.769,0,0,0-.544-.225H199.1a.768.768,0,0,0-.768.768v3.073a.768.768,0,1,0,1.537,0V48.2h14.28l6.465,6.465Z"
            transform="translate(-32.701)"
            fill={color}
            fill-rule="evenodd"
          />
          <path
            id="Path_692"
            data-name="Path 692"
            d="M229.349,304.1a.768.768,0,0,1-.768.768h-6.147a.768.768,0,0,1,0-1.537h6.147A.768.768,0,0,1,229.349,304.1Z"
            transform="translate(-54.494 -239.764)"
            fill={color}
            fill-rule="evenodd"
          />
          <path
            id="Path_693"
            data-name="Path 693"
            d="M222.434,242.55h9.989a.768.768,0,0,1,0,1.537h-9.989a.768.768,0,0,1,0-1.537Zm0-9.22h7.684a.768.768,0,0,1,0,1.537h-7.684a.768.768,0,0,1,0-1.537Z"
            transform="translate(-54.494 -174.373)"
            fill={color}
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
