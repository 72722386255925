import React, { useEffect } from "react";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import BackdropCircularProgressComponent from "./components/BackdropCircularProgressComponent";
import ModalStrip from "./components/ModalStrip";
import { useNavigate } from "react-router-dom";
import FormBuilderPage from "./pages/FormBuilderPage";
import Navbar from "./components/Navbar";
import { Routes, Route, useLocation } from "react-router-dom";
import { AxiosGet } from "./api";

function App() {
  const pathLoc = window.location.pathname.slice(10);

  const templateLast = pathLoc.indexOf("/");
  const templateId = pathLoc.slice(0, templateLast);

  const documentFirst = pathLoc.indexOf("d");
  const doc = pathLoc.slice(documentFirst + 9);
  const docLast = doc.indexOf("/");
  const documentId = doc.slice(0, docLast);

  const myParam = useLocation().search;
  const token = new URLSearchParams(myParam).get("token");
  const mainUrl = new URLSearchParams(myParam).get("redirectUrl");

  window.sessionStorage.setItem("templateId", templateId);
  window.sessionStorage.setItem("docId", documentId);

  const path = `template/${templateId}/document/${documentId}/form-builder`;

  const navigate = useNavigate();

  const getCompanyDetails = async () => {
    const endPoint = "/user/who-am-i";
    try {
      const response = await AxiosGet({ endPoint });
      const data = response?.data?.companyId;
      window.sessionStorage.setItem("companyId", data);
    } catch (err) {}
    AxiosGet({ endPoint });
  };

  useEffect(() => {
    if (token !== null) {
      window.sessionStorage.setItem("token", token);
    }
  }, [token]);

  useEffect(() => {
    if (mainUrl !== null) {
      window.sessionStorage.setItem("mainUrl", mainUrl);
    }
  }, [mainUrl]);

  useEffect(() => {
    getCompanyDetails();
    setTimeout(() => {
      navigate(path);
    }, 2000);
  }, []);

  return (
    <>
      <Provider store={store}>
        <BackdropCircularProgressComponent />
        <ModalStrip />
        <Navbar />
        <Routes>
          <Route path={path} element={<FormBuilderPage />} />
        </Routes>
      </Provider>
    </>
  );
}

export default App;
