export const groupType = [
  "RADIO_BUTTON",
  "CHECK_BOX",
  "TEXT",
  "DROPDOWN",
  "DROPDOWN_VALUE",
  "DROPDOWN_MULTIPLE",
  "EVALUATION",
  "BUTTON",
  "LABEL",
  "IMAGE_VIEW",
  "IMAGE_DISPLAY",
  "IMAGE",
  "PANEL",
  "FILE",
  "CONTENT_VALUE_INFO",
  "EXECUTE_BUTTON",
];
