import * as React from "react"
const Deleteicon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <path
      d="M0 0h30v30H0z"
      data-name="Rectangle 510"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M212.483 177.63H197.29v16.305h15.193zm-1.672 14.634h-11.85V179.3h11.851z"
      data-name="Path 410"
      style={{
        fill: "#4f4f4f",
        stroke: "#4f4f4f",
        strokeWidth: ".4px",
      }}
      transform="translate(-190.253 -167.51)"
    />
    <path
      d="M156.35 58.955h19.267v-1.672h-5.928V54.6h-7.41v2.685h-5.929zm7.6-2.685h4.067v1.014h-4.067z"
      data-name="Path 411"
      style={{
        fill: "#4f4f4f",
        stroke: "#4f4f4f",
        strokeWidth: ".4px",
      }}
      transform="translate(-151.35 -50.598)"
    />
    <path
      d="M277.2 263.26h1.672v7.6H277.2z"
      data-name="Path 412"
      style={{
        fill: "#4f4f4f",
        stroke: "#4f4f4f",
        strokeWidth: ".4px",
      }}
      transform="translate(-266.188 -248.88)"
    />
    <path
      d="M389.2 263.26h1.672v7.6H389.2z"
      data-name="Path 413"
      style={{
        fill: "#4f4f4f",
        stroke: "#4f4f4f",
        strokeWidth: ".4px",
      }}
      transform="translate(-372.617 -248.88)"
    />
  </svg>
)
export default Deleteicon