import React from "react";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";

export const DialogBox = (props: any) => {
  const {
    modalOpen,
    setModalOpen,
    deleteFun,
    layout,
    type,
    filterItem,
    containerId,
    event,
  } = props;
  return (
    <Dialog
      open={modalOpen}
      keepMounted
      onClose={() => setModalOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="pb-0 m-0" style={{ fontSize: "1.5rem" }}>
        {type === "alert"
          ? `Session Expired! Re-Login to continue`
          : `Are you sure you want to delete ${
              type === "panel" ? "panel" : "field"
            }?`}
      </DialogTitle>
      <DialogActions>
        {type !== "alert" && (
          <Button
            onClick={() => setModalOpen(false)}
            style={{ fontSize: "1rem" }}
          >
            No
          </Button>
        )}
        <Button
          onClick={() => {
            setModalOpen(false);
            if (type === "panel") {
              deleteFun?.(layout?.id as string);
            } else if (type === "field") {
              deleteFun(filterItem, containerId);
            } else if (type === "arrayItem") {
              deleteFun(containerId, filterItem, event);
            } else if (type === "alert") {
              deleteFun();
            }
          }}
          style={{ fontSize: "1rem" }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
