import React from "react";
import { common } from "./interface";

export const TextAreaIcon = (props: common) => {
  const { size, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30.728 30"
    >
      <g data-name="Group 4160">
        <path
          data-name="Path 1743"
          d="M0 165.534v-12.522h30.728v12.522zm29.214-10.889H1.513v9.255h27.7v-9.256zM5.5 156.093H3.174v.649h.771v5.058h-.771v.649H5.5v-.649h-.771v-5.061H5.5v-.649z"
          transform="translate(0 -144.012)"
          style={{ fill: color }}
        />
        <path
          data-name="Rectangle 890"
          style={{ fill: "none", opacity: ".3" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
