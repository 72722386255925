import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./index.css";
import { ThemeProvider } from "@mui/material";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactFlowProvider } from "@xyflow/react";
import { appTheme } from "./AppTheme";

import 'dayjs/locale/en-gb'; // Import the locale you want
import dayjs from 'dayjs';
// Set the locale globally if needed
dayjs.locale('en-gb');
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <ReactFlowProvider>
        <BrowserRouter>
          <ThemeProvider theme={appTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <App />
        </LocalizationProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ReactFlowProvider>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
