import React from "react";
import { common } from "./interface";

export const OtpIcon = (props: common) => {
  const { size = 30, color = "#c1c1c1" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g
        id="Group_4165"
        data-name="Group 4165"
        transform="translate(16192 5243)"
      >
        <rect
          id="Rectangle_878"
          data-name="Rectangle 878"
          width="30"
          height="30"
          transform="translate(-16192 -5243)"
          fill="none"
        />
        <path
          id="Path_1820"
          data-name="Path 1820"
          d="M33.223,15.387h-.446V9.149a.445.445,0,0,0-.446-.446.445.445,0,0,1-.446-.446.445.445,0,0,0-.446-.446H17.182a.445.445,0,0,0-.446.446.445.445,0,0,1-.446.446.445.445,0,0,0-.446.446v6.238H15.4a1.336,1.336,0,0,0-1.337,1.337v6.238A1.336,1.336,0,0,0,15.4,24.3h.446v6.238a.445.445,0,0,0,.446.446.445.445,0,0,1,.446.446.445.445,0,0,0,.446.446H31.44a.445.445,0,0,0,.446-.446.445.445,0,0,1,.446-.446.445.445,0,0,0,.446-.446V24.3h.446a1.336,1.336,0,0,0,1.337-1.337V16.724a1.336,1.336,0,0,0-1.337-1.337ZM16.736,9.515a1.309,1.309,0,0,0,.811-.811H31.075a1.309,1.309,0,0,0,.811.811v5.873H16.736Zm15.15,20.657a1.309,1.309,0,0,0-.811.811H17.547a1.309,1.309,0,0,0-.811-.811V24.3h15.15Zm1.782-7.209a.445.445,0,0,1-.446.446H15.4a.445.445,0,0,1-.446-.446V16.724a.445.445,0,0,1,.446-.446H33.223a.445.445,0,0,1,.446.446ZM19.855,17.17h-.891a1.336,1.336,0,0,0-1.337,1.337V21.18a1.336,1.336,0,0,0,1.337,1.337h.891a1.336,1.336,0,0,0,1.337-1.337V18.506A1.336,1.336,0,0,0,19.855,17.17Zm.446,4.01a.445.445,0,0,1-.446.446h-.891a.445.445,0,0,1-.446-.446V18.506a.445.445,0,0,1,.446-.446h.891a.445.445,0,0,1,.446.446Zm6.238-3.565a.445.445,0,0,1-.446.446H24.757v4.01a.446.446,0,1,1-.891,0v-4.01H22.529a.446.446,0,1,1,0-.891h3.565a.445.445,0,0,1,.446.446Zm2.674-.446H27.876a.445.445,0,0,0-.446.446v4.456a.446.446,0,0,0,.891,0V20.734h.891a1.782,1.782,0,0,0,0-3.565Zm0,2.674h-.891V18.061h.891a.891.891,0,1,1,0,1.782Z"
          transform="translate(-16201 -5247.813)"
          fill={color}
          stroke={color}
          stroke-width="0.4"
        />
      </g>
    </svg>
  );
};
