import React, { useEffect, useState } from "react";
import "./Sections.scss";
import { Box, Typography, Button, Modal, Drawer } from "@mui/material";
import CircleOutline from "../../../../assets/icons/FormFillIcons/CircleOutline";
import { getStatusTrasition } from "../../../../redux/slices/ManchSlices";
import { useDispatch, useSelector } from "react-redux";
import CongratulationPopup from "../../Global/congratulationPopup/CongratulationPopup";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../Inner/useWindowSize";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Done from "../../../../assets/icons/FormFillIcons/Done";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Sections = ({
  onSectionChange,
  activeSection,
  onButtonClick,
  hasErrors,
  formStatus,
}) => {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [panelData, setPanelData] = useState([]);
  const [congratulationModal, setCongratulationModal] = useState(true);
  const X_AUTH = window.sessionStorage.getItem("token");
  const trxcId = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.transactionId
  );
  const successStatusTransition = useSelector(
    (state) =>
      state?.ManchSlice?.statusTransitionInfo?.statusTransitionInfoValue
        ?.statusTransitions
  );
  const tabChange = useSelector((state) => state?.ManchSlice?.tabChange);
  const metaData = useSelector(
    (state) => state?.ManchSlice?.metaDataInfo?.metaDataInfoValue
  );
  const documents = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents || []
  );
  const handleCloseCongratulation = () => setCongratulationModal(true);
  const handleClick = () => {
    if (documents?.some((doc) => doc.formFillEnabled === false)) {
      onButtonClick("clicked");
    } else {
      dispatch(getStatusTrasition(trxcId));
    }
  };
  useEffect(() => {
    const Data = metaData.filter((item) => item.type === "PANEL");
    setPanelData(Data);
  }, [metaData]);

  useEffect(() => {
    if (successStatusTransition) {
      const timer = setTimeout(() => {
        navigate(`/onboardingRequest?token=${X_AUTH}`, { replace: true });
        console.log("it is coming from section");
        window.location.reload();
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [successStatusTransition, navigate]);

  const isMobile = width <= 500;

  return (
    <>
      {isMobile ? (
        <Box sx={{ width: "100%" }} className="StepperForSection">
          <Stepper activeStep={activeSection} alternativeLabel>
            {panelData?.map((element, index) => (
              <Step key={index}>
                <StepLabel>{element.tagName}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      ) : (
        <Box className="sectionClass">
          <Box className="textSection">
            <Typography variant="sectionTitle">Sections</Typography>
          </Box>
          <Box className="tabsClass">
            {panelData &&
              panelData.map((element, index) => (
                <Box
                  key={index}
                  className="stepsArray"
                  sx={{
                    backgroundColor:
                      activeSection === index ? "#e6ebf1" : "transparent",
                    cursor: hasErrors ? "not-allowed" : "pointer", // Disable pointer cursor if errors exist
                  }}
                  onClick={() => {
                    // Allow navigation if clicked tab is previous, or if it's forward and there are no errors
                    if (index < activeSection || (!hasErrors && tabChange)) {
                      onSectionChange(index);
                    }
                  }}
                >
                  <Box pl={2}>
                  {index < activeSection ? <Done /> : <CircleOutline />}
                  </Box>
                  <Box pl={1}>
                    <Typography
                      variant="templateInfo"
                      sx={{
                        color: activeSection === index ? "#3f5fff" : "#4f4f4f",
                      }}
                    >
                      {element.tagName}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
          <Box className="btnSubmit">
            {hasErrors || formStatus !== "filled" ? (
              <Button
                variant="sectionSubmitDisable"
                disabled={hasErrors || formStatus !== "filled"}
              >
                Submit
              </Button>
            ) : (
              <Button variant="sectionSubmit" onClick={handleClick}>
                Submit
              </Button>
            )}
          </Box>
          <Modal
            open={successStatusTransition && congratulationModal}
            onClose={handleCloseCongratulation}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CongratulationPopup />
            </Box>
          </Modal>
        </Box>
      )}
      {isMobile && (
        <Drawer
          anchor="bottom"
          open={successStatusTransition && congratulationModal}
          onClose={handleCloseCongratulation}
        >
          <Box sx={{ height: 425, p: 4 }} role="presentation">
            <CongratulationPopup />
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default Sections;
