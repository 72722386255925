import React, { Dispatch, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Button,
} from "@mui/material";
import { useDrop } from "react-dnd";
import {
  FormControlNames,
  FormItemTypes,
} from "../../../utils/formBuilderUtils";
import {
  FormLayoutComponentChildrenType,
  FormLayoutCoponentChildrenItemsType,
} from "../../../types/FormTemplateTypes";
import { Resizable, ResizeDirection } from "re-resizable";
import { enableProps } from "./DropContainerComponent";
import { DeleteIcon } from "../../../assets/icons";
import DisplayComponent from "../../displayComponents";
import ObjectLabelComponent from "../../displayComponents/ObjectLabelComponent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  TimeIcon,
  DateIcon,
  FileUploadIcon,
  ImageIcon,
} from "../../../assets/icons";

import MapImage from "../../../assets/images/map.png";
import { DialogBox } from "../../Modal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type DispatchObject = {
  value: boolean;
  arrayId?: string;
};

export type ResizeCallback = (
  event: any,
  direction: ResizeDirection,
  refToElement: any
) => void | undefined;

type ResizeStopCallback = (
  component: FormLayoutComponentChildrenType
) => void | undefined;

const wrapperStyle = {
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: "9px",
  marginBottom: "20px",
  backgroundColor: "white",
  boxShadow: "none",
  cursor: "pointer",
};

export const renderItem = (
  item: FormLayoutComponentChildrenType,
  setIsOverMainContainer: Dispatch<DispatchObject> | undefined,
  selectControl: (item: FormLayoutComponentChildrenType) => void,
  selectedControl: { id: any; type: any } | undefined,
  filterItem: any,
  handleDeleteControl: any,
  deleteArrayItems?: any,
  ResizeCallback?: ResizeCallback,
  ResizeStopCallback?: ResizeStopCallback
) => {
  const textArr = ["TEXT", "URL", "EMAIL"];

  const TextComponent = (props: any) => {
    const { item, selectedControl, filterItem, handleDeleteControl } = props;
    return (
      <DisplayComponent
        component={
          <p
            style={{
              margin: "0",
              padding: "0 0 0.35rem 0",
            }}
          >
            {item.name?.length > 11
              ? item.name?.slice(0, 11) + (item.mandatory ? " *" : "") + "..."
              : item.name + (item.mandatory ? " *" : "")}
          </p>
        }
        isSelected={
          selectedControl &&
          filterItem.id === selectedControl.id &&
          filterItem.type === selectedControl.type
            ? true
            : false
        }
        handleDeleteControl={handleDeleteControl}
        item={item}
        selectedControl={selectedControl}
        isArrayItem={item?.isArrayItem ? true : false}
      />
    );
  };

  switch (item?.container?.type ? item?.container?.type : item?.type) {
    case textArr?.includes(item?.type) ? item?.type : null:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <TextComponent
            item={item}
            selectedControl={selectedControl}
            filterItem={filterItem}
            handleDeleteControl={handleDeleteControl}
          />
        </div>
      );

    case "LABEL":
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <ObjectLabelComponent
            component={
              <p
                style={{
                  margin: "0",
                  padding: "0 0 0.35rem 0",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {item.name?.length > 30
                  ? item.name?.slice(0, 30) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")}
              </p>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.INPUTNUMBERFIELD:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                {item.name?.length > 11
                  ? item.name?.slice(0, 11) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")}
              </p>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.INPUTMULTILINE:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                {item.name?.length > 11
                  ? item.name?.slice(0, 11) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")}
              </p>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.LABEL:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                {item.name?.length > 11
                  ? item.name?.slice(0, 11) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")}
              </p>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.CHECKBOX:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ margin: "0 1rem 0 0", padding: "0" }}
                    checked={false}
                  />
                }
                label={
                  item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")
                }
                style={{ margin: "0.2rem 0 0 0", padding: "0 0 0.35rem 0" }}
              />
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.SELECTDROPDOWN:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <div className="d-flex justify-content-between align-items-center">
                <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                  {item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")}
                </p>
                <KeyboardArrowDownIcon />
              </div>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.MULTIPLESELECTDROPDOWN:
      const valueArr: string[] = [];
      item?.items?.map((each: FormLayoutCoponentChildrenItemsType) => {
        valueArr.push(each.value);
      });
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <div className="d-flex align-items-center justify-content-between">
                <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                  {item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")}
                </p>
                <KeyboardArrowDownIcon />
              </div>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.BUTTON:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  variant="outlined"
                  disabled
                  style={{ height: "1.75rem", margin: "0 0 0.35rem 0" }}
                >
                  {item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")}
                </Button>
                {/* <KeyboardArrowDownIcon /> */}
              </div>
              // <Button
              //   variant="outlined"
              //   disabled
              //   fullWidth
              //   style={{ height: "3.75rem" }}
              // >
              //   {item?.placeholder}
              // </Button>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.DATEFIELD:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <div className="d-flex align-items-center justify-content-between">
                <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                  {item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")}
                </p>
                <DateIcon size={20} color="#4f4f4f" />
              </div>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.TIMEFIELD:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <div className="d-flex align-items-center justify-content-between">
                <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                  {item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")}
                </p>
                <TimeIcon size={20} color="#4f4f4f" />
              </div>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.FILEUPLOAD:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <div className="d-flex align-items-center justify-content-between">
                <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                  {item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")}
                </p>
                <FileUploadIcon size={20} color="#4f4f4f" />
              </div>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.IMAGEUPLOAD:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <div className="d-flex align-items-center justify-content-between">
                <p style={{ margin: "0", padding: "0 0 0.35rem 0" }}>
                  {item.name?.length > 11
                    ? item.name?.slice(0, 11) +
                      (item.mandatory ? " *" : "") +
                      "..."
                    : item.name + (item.mandatory ? " *" : "")}
                </p>
                <ImageIcon size={20} color="#4f4f4f" />
              </div>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.CHECKLIST:
      return (
        <div className="d-flex justify-content-between align-items-center">
          <FormGroup>
            {item.items?.map((i, ind) => (
              <FormControlLabel
                key={i.value}
                control={<Checkbox />}
                label={i.label}
                style={{ marginLeft: "0px" }}
                disabled
              />
            ))}
          </FormGroup>
        </div>
      );

    case FormControlNames.OTP:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <p
                style={{
                  margin: "0",
                  padding: "0 0 0.35rem 0",
                }}
              >
                {item.name?.length > 11
                  ? item.name?.slice(0, 11) +
                    (item.mandatory ? " *" : "") +
                    "..."
                  : item.name + (item.mandatory ? " *" : "")}
              </p>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.MAP:
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            selectControl(filterItem);
          }}
          style={{ cursor: "pointer" }}
        >
          <DisplayComponent
            component={
              <img
                src={MapImage}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  margin: "0 0 0.5rem 0",
                }}
              />
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArrayItem={item?.isArrayItem ? true : false}
            item={item}
          />
        </div>
      );

    case FormControlNames.ARRAY:
      const [{ canDrop, isOver }, drop] = useDrop({
        accept: FormItemTypes.CONTROL,
        canDrop: (item: any) => {
          return item.type !== "ARRAY";
        },
        drop: () => {},
        collect: (monitor: any) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      });

      const isActive = canDrop && isOver;

      useEffect(() => {
        try {
          if (isActive) {
            setIsOverMainContainer?.({
              value: false,
              arrayId: item?.container?.id,
            });
          } else {
            setIsOverMainContainer?.({
              value: true,
              arrayId: item?.container?.id,
            });
          }
        } catch (error) {
          console.error("Error in setState:", error);
        }
      }, [isActive]);

      const [modalOpen, setModalOpen] = useState<boolean>(false);
      const [deleteArrayItemInfo, setDeleteArrayItemInfo] = useState<any>({
        itemId: null,
        containerId: null,
        event: null,
      });

      return (
        <>
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => {
              selectControl(filterItem);
            }}
            style={{ cursor: "pointer" }}
          >
            <ObjectLabelComponent
              component={
                <p
                  style={{
                    margin: "0",
                    padding: "0 0 0.35rem 0",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {item?.container?.name && item?.container?.name?.length > 30
                    ? item?.container?.name?.slice(0, 30) +
                      (item?.container?.mandatory ? " *" : "") +
                      "..."
                    : item?.container?.name +
                      (item?.container?.mandatory ? " *" : "")}
                </p>
              }
              // handleDeleteControl={handleDeleteControl}
              isArrayItem={item?.isArrayItem ? true : false}
              // isSelected={
              //   selectedControl &&
              //   filterItem.id === selectedControl.id &&
              //   filterItem.type === selectedControl.type
              //     ? true
              //     : false
              // }
              item={item}
            />
          </div>

          <DisplayComponent
            component={
              <div
                ref={drop}
                className={
                  !item?.children?.length ||
                  (item?.children[0]?.type === "ARRAY_END" &&
                    item?.children?.length === 1)
                    ? "d-flex flex-column justify-content-center align-items-center"
                    : "d-flex m-0 flex-wrap pl-1"
                }
                style={{
                  minHeight:
                    !item?.children?.length ||
                    (item?.children[0]?.type === "ARRAY_END" &&
                      item?.children?.length === 1)
                      ? "20vh"
                      : "100%",
                  alignItems:
                    !item?.children?.length ||
                    (item?.children[0]?.type === "ARRAY_END" &&
                      item?.children?.length === 1)
                      ? "center"
                      : "",
                  justifyContent:
                    !item?.children?.length ||
                    (item?.children[0]?.type === "ARRAY_END" &&
                      item?.children?.length === 1)
                      ? "center"
                      : "",
                  boxShadow: "none",
                  backgroundColor: isActive
                    ? "#e8f0fe"
                    : "rgba(0, 0, 0, 0.025)",
                  borderRadius: "9px",
                  paddingBottom: "1rem",
                }}
              >
                {!item?.children?.length ||
                (item?.children[0]?.type === "ARRAY_END" &&
                  item?.children?.length === 1) ? (
                  <p>+ Drop items here</p>
                ) : (
                  item?.children
                    ?.filter((e: any) => e?.type !== "ARRAY_END")
                    ?.map((item: FormLayoutComponentChildrenType) => (
                      <Resizable
                        defaultSize={{
                          width: item?.type === "LABEL" ? "97%" : "47%",
                          height: "auto",
                        }}
                        minWidth="45%"
                        maxWidth="97%"
                        enable={{
                          ...enableProps,
                          right:
                            item?.id === selectedControl?.id ? true : false,
                        }}
                        style={{ margin: "1rem 0.5rem", padding: 0 }}
                        onResize={ResizeCallback}
                        onResizeStop={() => ResizeStopCallback?.(item)}
                      >
                        <div
                          className="d-flex"
                          onClick={() => selectControl?.(item)}
                          style={{
                            //...wrapperStyle,
                            borderBottom: `1px solid ${
                              item?.id === selectedControl?.id
                                ? "rgb(49, 139, 227)"
                                : item?.type !== "LABEL"
                                ? "rgba(0,0,0,0.1)"
                                : "transparent"
                            }`,
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="mt-3"
                            key={item?.id}
                            style={{ width: "100%" }}
                          >
                            {renderItem(
                              { ...item, isArrayItem: true },
                              setIsOverMainContainer,
                              selectControl,
                              selectedControl,
                              filterItem,
                              handleDeleteControl,
                              deleteArrayItems,
                              ResizeCallback,
                              ResizeStopCallback
                            )}
                          </div>
                          {item?.id === selectedControl?.id && (
                            <div className="control-actions d-flex justify-content-between align-items-center">
                              <span
                                onClick={(event) => {
                                  setDeleteArrayItemInfo({
                                    containerId: item?.containerId,
                                    itemId: item?.id,
                                    event: event,
                                  });
                                  setModalOpen(true);
                                }}
                                style={{ margin: "0 0 -1rem 0" }}
                              >
                                <DeleteIcon />
                              </span>
                              <ArrowForwardIosIcon
                                sx={{
                                  color: "rgb(79, 79, 79)",
                                  fontSize: "1rem",
                                  margin: "0 -0.5rem -1rem 0",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Resizable>
                    ))
                )}
              </div>
            }
            isSelected={
              selectedControl &&
              filterItem.id === selectedControl.id &&
              filterItem.type === selectedControl.type
                ? true
                : false
            }
            handleDeleteControl={handleDeleteControl}
            isArray={true}
            item={item}
          />
          {deleteArrayItemInfo?.itemId &&
            deleteArrayItemInfo?.containerId &&
            deleteArrayItemInfo?.event && (
              <DialogBox
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                deleteFun={deleteArrayItems}
                filterItem={deleteArrayItemInfo?.itemId}
                containerId={deleteArrayItemInfo?.containerId}
                event={deleteArrayItemInfo?.event}
                type="arrayItem"
              />
            )}
        </>
      );
  }
};
