import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import axios from "axios";
import DeleteIconRed from "../../../../assets/icons/FormFillIcons/DeleteIconRed";
import VerifiedGreen from "../../../../assets/icons/FormFillIcons/VerifiedGreen";
import {
  Box,
  TextField,
  Button,
  Chip,
  Modal,
  FormHelperText,
} from "@mui/material";
import useWindowSize from "../../Global/Inner/useWindowSize";
import "./SectionsFormView.scss";
import { useSelector } from "react-redux";
import Backbtn from "../../../../assets/icons/FormFillIcons/Back";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { IconButton, Divider } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import FileUpload from "../../../../assets/icons/FormFillIcons/FileUpload";
import ImgUpload from "../../../../assets/icons/FormFillIcons/ImgUpload";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  getDropdownAction,
  getFileUploadInfo,
  getFillInfo,
  sendOtpAction,
  verifyOtpAction,
  successMetDataImageAction,
  setTabChange,
  getExtendedGroups,
  deleteExtendedFormGroups,
  postExtendedGroups,
  get_Extended_Form_Fill,
  getStatusTrasition,
  getExtendedGroupsImage,
} from "../../../../redux/slices/ManchSlices";
import CloseIcon from "@mui/icons-material/Close";
import Cropper from "react-cropper"; // Assuming you're using react-cropper
import "cropperjs/dist/cropper.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { CloseOutlined } from "@mui/icons-material";
import ActiveDocuments from "../../../../assets/icons/FormFillIcons/ActiveDocuments";
import Form from "../../../../assets/icons/FormFillIcons/Form";
import Done from "../../../../assets/icons/FormFillIcons/MobileDone";
import Flag from "../../../../assets/icons/FormFillIcons/Flag";
import { styled } from "@mui/material/styles";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Kolkata");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VerticalConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.vertical}`]: {
    marginLeft: 25,
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 3,
    minHeight: 30,
    backgroundColor: theme.palette.grey[400],
  },
}));
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "transparent",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "transparent",
    color: "#fff",
  }),
  ...(ownerState.completed && {
    backgroundColor: "transparent",
    color: "#393e46",
  }),
}));
const CustomStepIcon = ({
  active,
  completed,
  icon,
  firstDocumentAvailable,
}) => {
  const icons = firstDocumentAvailable
    ? {
        1: <Done />,
        2: <ActiveDocuments />,
        3: <Flag />,
      }
    : {
        1: <Form />,
        2: <Submit />,
        3: <Flag />,
      };

  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {icons[icon]}
    </CustomStepIconRoot>
  );
};
export default function SectionsFormView({
  activeSection,
  onSectionChange,
  onErrorStateChange,
  updateFormStatus,
  onButtonClick,
}) {
  sessionStorage.setItem("user_type", "FIRST_PARTY");
  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();
  const isMobile = width <= 500;
  const [finalValidation, setFinalValidation] = useState(false);
  const [mandatoryData, setMandatoryData] = useState({});
  const [visibleOtpFields, setVisibleOtpFields] = useState({});
  const [processedData, setProcessedData] = useState([]);
  const [dropdownSelected, setDropdownSelected] = useState("");
  const [personName, setPersonName] = useState([]);
  const [fileError, setFileError] = useState("");
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState({ open: false }); // Modal open/close state
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null); // For previewing the image
  const [cropData, setCropData] = useState([]); // For storing cropped image data
  const cropperRef = useRef(null); // Ref for the cropper component
  const [extractedTable, setExtractedTable] = useState([]);
  const dispatch = useDispatch();
  const activeStep = 1;
  let domainUrl = process.env.REACT_APP_MAIN_URL;
  const fetchedIds = useRef(new Set());
  const fileUploadData = useSelector(
    (state) => state?.ManchSlice?.uploadFileInfo.uploadFileInfoValue.data
  );
  const dataOfFill = useSelector(
    (state) => state?.ManchSlice?.fillMetaDataInfo.fillMetaDataInfoValue
  );
  const names = useSelector(
    (state) =>
      state?.ManchSlice?.dropdownInfo?.dropdownInfoValue?.dropDownValues
  );
  const successStatusTransition = useSelector(
    (state) =>
      state?.ManchSlice?.statusTransitionInfo?.statusTransitionInfoValue
        ?.statusTransitions
  );
  const trxcId = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.transactionId
  );
  const extendedUserGroups = useSelector(
    (state) => state?.ManchSlice?.extended?.extendedUserGroups
  );

  const docTypeId = useSelector(
    (state) =>
      state?.ManchSlice?.allDocument?.allDocumentValue?.documents?.[0]
        ?.documentTypeId
  );
  const metaDataImage = useSelector(
    (state) => state?.ManchSlice?.metaDataImage
  );
  // const docTypeId = useSelector(
  //   (state) => state?.ManchSlice?.selectedDocumentTypeId?.docTypeId
  // );
  const templateId = useSelector(
    (state) => state?.ManchSlice?.selectedTemplateIdWithoutTMPT?.TempId
  );
  const verifiedOtp = useSelector(
    (state) => state?.ManchSlice?.verifyOtpInfo?.verifyOtpInfoValue
  );
  const valueHashArray = useSelector(
    (state) => state?.ManchSlice?.hashData?.hashDataValue
  );
  const documents = useSelector(
    (state) => state?.ManchSlice?.allDocument?.allDocumentValue?.documents || []
  );
  const firstDocumentName = documents?.[0]?.name || "Loading...";
  const firstDocumentAvailable = firstDocumentName !== "Loading...";
  const steps = [
    { id: 1, label: firstDocumentName },
    { id: 2, label: "Document List" },
    { id: 3, label: "Submission" },
  ];
  const adjustedSteps =
    documents.length === 1
      ? [
          { id: 1, label: firstDocumentName },
          { id: 2, label: "Submission" },
        ]
      : steps;
  const startBtnHandler = () => {
    console.log("startbtnhandler");
    if (documents?.some((doc) => doc.formFillEnabled === false)) {
      onButtonClick("clicked");
    } else {
      dispatch(getStatusTrasition(trxcId));
    }
    setOpen(false);
  };
  const secret = process.env.REACT_APP_SECRET_KEY;
  // console.log(secret, "secret");
  const getHashValue = (metaDataId) => {
    console.log(metaDataId, "gettingmeta");
    // Find the object with the matching metaDataId in the valueHashArray
    const foundItem = valueHashArray?.find(
      (item) => item.metaDataId === metaDataId
    );
    console.log(foundItem, "found");
    if (foundItem) {
      // Get the previous data value from the found object
      let previousValue = foundItem.dataValue;
      console.log(previousValue, "previousvalue");

      // If previousValue is not a string, convert it to a string
      if (typeof previousValue !== "string") {
        previousValue = previousValue?.toString();
      }

      // If previousValue is an empty string, return null
      if (previousValue === "") {
        return null;
      } else {
        // Apply SHA256 hashing to the previousValue
        let utf16Sha256 = CryptoJS.SHA256(previousValue);
        utf16Sha256 = utf16Sha256?.toString(CryptoJS.enc.Hex);
        const encryptedPrevVal = CryptoJS.AES.encrypt(utf16Sha256, secret);
        return encryptedPrevVal?.toString();
      }
    }

    // Return null if no matching metaDataId is found
    return null;
  };
  const staticDropdownValue = [
    {
      dropdownId: 449707,
      dropdownValue: "No value found",
      description: "",
      message: "",
      displayOrder: 1,
      defaultValue: false,
    },
  ];
  const handleChange = useCallback((fieldId, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldId]: value,
    }));
  }, []);
  const checkData = (data) => {
    const [key, value] = Object.entries(data)[0]; // Extract the key-value pair from data

    if (formData[key] !== value) {
      setFormData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
    // return formData[key] === value ? null : data; // Compare with formData
  };
  const handleBlur = (
    metaDataId,
    value,
    isMandatory,
    formValidations,
    item
  ) => {
    let errorMessage = "";

    // Check for mandatory field
    if (
      isMandatory &&
      (!value || (Array.isArray(value) && value.length === 0))
    ) {
      errorMessage = "This field is required";
    }

    // Check for regex validation
    if (formValidations && formValidations[0]?.regex && value) {
      const regex = new RegExp(formValidations[0].regex);
      if (!regex.test(value)) {
        errorMessage = formValidations[0].description || "Invalid format";
      }
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (errorMessage) {
        newErrors[metaDataId] = errorMessage; // Set error if there is one
      } else {
        delete newErrors[metaDataId]; // Remove field if no error
      }
      return newErrors;
    });

    // Return true if no error message, meaning validation passed
    return !errorMessage;
  };

  const handleDelete = (valueToDelete) => {
    setPersonName((prev) => prev.filter((name) => name !== valueToDelete));
  };

  const metaData = useSelector(
    (state) => state?.ManchSlice?.metaDataInfo?.metaDataInfoValue
  );
  // console.log(metaData, "metaData");
  // Memoize finalResult to prevent unnecessary recalculations
  const finalResult = useMemo(() => {
    const result = [];
    let currentGroup = null;
    metaData?.forEach((item) => {
      if (item.type === "PANEL") {
        currentGroup = { ...item, formData: [] };
        result.push(currentGroup);
      } else if (currentGroup) {
        currentGroup.formData.push(item);
      }
    });
    return result;
  }, [metaData]);

  useEffect(() => {
    if (finalResult.length > 0) {
      const initialData = {};
      finalResult.forEach((item) => {
        initialData[item.metaDataId] =
          item.value || (item.type === "STATIC_CHECK_BOX" ? null : "");
      });
      setFormData(initialData);
      // dispatch(formDataAction(initialData))
    }
  }, [metaData, finalResult]);

  useEffect(() => {
    // Iterate over the dataOfFill array
    dataOfFill.forEach((entry) => {
      const key = Object.keys(entry)[0]; // Get the key of the current object
      if (!key) return; // Skip if key is undefined
      const ruleResultsArray = entry[key]; // Access the array under the key

      // Safely access the first item in the ruleResultsArray
      if (!Array.isArray(ruleResultsArray)) return; // Ensure it's an array
      const firstRule = ruleResultsArray[0];
      const validationStatus = firstRule?.validationStatus;
      const formFillMetadataId = firstRule?.formFillMetadataId;
      const errorMessage = firstRule?.message;

      console.log(validationStatus, "valid");

      if (validationStatus === "SUCCESS") {
        // Remove the error for the current formFillMetadataId (if it exists)
        setErrors((prevErrors) => {
          const { [formFillMetadataId]: _, ...remainingErrors } = prevErrors; // Exclude the error key
          return remainingErrors;
        });

        // Map form values for each entry in updatedValues
        const formValues = firstRule?.updatedValues?.reduce((acc, item) => {
          acc[item.formFillMetadataId] = item.value;
          return acc;
        }, {});

        if (formValues) {
          // Update form data state
          // dispatch(formDataAction({formValues}))
          setFormData((prevForm) => ({
            ...prevForm,
            ...formValues,
          }));
        }
      }

      if (validationStatus === "FAIL") {
        if (formFillMetadataId && errorMessage) {
          // Add or update the error for the current formFillMetadataId
          setErrors((prevErrors) => ({
            ...prevErrors,
            [formFillMetadataId]: errorMessage,
          }));
        }
      }
    });
  }, [dataOfFill]);

  useEffect(() => {
    // Safely check for ruleResults and access the array
    const ruleResultsArray = fileUploadData?.ruleResults
      ? Object.values(fileUploadData.ruleResults)[0]
      : [];
    const firstRule = ruleResultsArray && ruleResultsArray[0];

    const validationStatus = firstRule?.validationStatus;
    const formFillMetadataId = firstRule?.formFillMetadataId;

    if (validationStatus === "SUCCESS") {
      // Remove the error for the current formFillMetadataId (if it exists)
      setErrors((prevErrors) => {
        const { [formFillMetadataId]: _, ...remainingErrors } = prevErrors; // Exclude the error key
        return remainingErrors;
      });

      // Map form values for each entry in updatedValues
      const formValues = firstRule?.updatedValues?.reduce((acc, item) => {
        acc[item.formFillMetadataId] = item.value;
        return acc;
      }, {});

      if (formValues) {
        // Update form data state
        // dispatch(formDataAction(formValues))
        setFormData((prevForm) => ({
          ...prevForm,
          ...formValues,
        }));
      }
    }

    if (validationStatus === "FAIL") {
      const errorMessage = firstRule?.message;

      if (formFillMetadataId && errorMessage) {
        // Add or update the error for the current formFillMetadataId
        setErrors((prevErrors) => ({
          ...prevErrors,
          [formFillMetadataId]: errorMessage,
        }));
      }
    }
  }, [fileUploadData]);

  const onDropdownOpen = (item) => {
    const requiredItem = item?.formRules?.filter(
      (rule) => rule.actionType === "EXT_DROP_DOWN"
    );
    console.log(requiredItem, "req");
    dispatch(
      getDropdownAction({
        tld: templateId,
        dtld: docTypeId,
        ddType: requiredItem && requiredItem[0]?.param,
        fType: "EXT_DROP_DOWN",
        pv: "",
        criteria: JSON.stringify({ criterias: {} }),
        ce: "",
        da: "",
      })
    );
  };
  // Validate and update the form status
  useEffect(() => {
    let allMandatoryFieldsFilled = true; // Default assumption

    // Iterate through each group and its fields in finalResult
    finalResult.forEach((group) => {
      group.formData.forEach((field) => {
        // Check if field is mandatory
        if (field.mandatory) {
          const value = formData[field.metaDataId]; // Get the value from formData

          // If value is missing or empty, mark the form as incomplete
          if (value?.length === 0) {
            allMandatoryFieldsFilled = false;
          }
        }
      });
    });

    // Update form status based on whether all mandatory fields are filled
    updateFormStatus(allMandatoryFieldsFilled ? "filled" : "notFilled");
  }, [formData]);
  useEffect(() => {
    if (processedData && formData) {
      // Extract mandatoryData from processedData
      const mandatoryData = processedData.reduce((acc, item) => {
        if (item.mandatory) {
          acc[item.metaDataId] = item.dataValue || ""; // Default to an empty string if dataValue is null
        }
        return acc;
      }, {});

      // Validate formData against mandatoryData
      const isValid = Object.keys(mandatoryData).every((key) => {
        return formData[key] && formData[key] !== "";
      });

      // Check if all keys in mandatoryData have values
      const newVariable = Object.values(mandatoryData).every(
        (value) => value && value.trim() !== ""
      );

      // Combine both validations
      const result = isValid || newVariable;
      setFinalValidation(result); // Update state with the final validation result

      dispatch(setTabChange(result));
    }
  }, [processedData, formData]);
  useEffect(() => {
    // Notify parent about error state whenever `errors` changes
    onErrorStateChange && onErrorStateChange(Object.keys(errors).length > 0);
  }, [errors, onErrorStateChange]);

  const ruleData1 = (condition) => {
    const result1 = [];
    condition?.forEach((condition) => {
      const result = [];
      condition
        .split(";") // Split the data into individual rules
        .filter(Boolean) // Remove any empty strings
        .forEach((rule) => {
          const match = rule.match(
            /(\w+)\(vo\("([^"]+)"\)([!=]+)"([^"]+)",\s*"([^"]+)"\)/
          );
          if (match) {
            const [, targetValue, source, operator, sorceValue, target] = match;
            result.push({
              source,
              target,
              targetValue,
              sorceValue: operator === "==" ? sorceValue : "No",
            });
          }
        });

      const rulesData =
        result &&
        result
          ?.filter((rule) => {
            const sourceField = processedData?.find(
              (field) => field.variableName === rule.source
            );
            return (
              // sourceField && sourceField.dataValue === rule.sorceValue
              sourceField && dropdownSelected === rule.sorceValue
            );
          })
          ?.map((rule) => {
            const targetField = processedData?.find(
              (field) => field.variableName === rule.target
            );
            result1.push({
              targetValue: rule.targetValue,
              target: rule.target,
              metaDataId: targetField ? targetField.metaDataId : null,
            });
          });
    });
    return result1;
  };
  const processFormRules = (item, currentPanelName) => {
    const currentPanelData = finalResult?.filter(
      (currentItem) => currentItem.tagName === currentPanelName
    );
    let currentFormData = currentPanelData[0]?.formData || [];
    // setData(currentFormData);
    const Rule = currentFormData.filter((data) => data.formRules);

    let modifiedItem = { ...item };

    const clientRules = Rule.flatMap(
      (item) => item.formRules?.filter((rule) => rule.type === "CLIENT") || []
    );
    if (clientRules) {
      clientRules.forEach((rule) => {
        switch (rule.actionType) {
          case "CONVERT_TO":
            if (
              rule.sourceType === "UPPER_CASE" &&
              rule.sourceIds?.includes(item.metaDataId)
            ) {
              console.log(
                rule.sourceType === "UPPER_CASE" &&
                  rule.sourceIds?.includes(item.metaDataId),
                "upp"
              );
              modifiedItem.convertToUpper = true; // Add a flag to process this later
            }
            break;
          case "MAKE_DISABLED":
            if (rule.destinationIds?.includes(item.metaDataId)) {
              const dataValue = item.dataValue;
              const condition = rule.condition;
              const conditionalValues = rule.conditionalValues;
              if (
                condition === "NOT_IN" &&
                !conditionalValues.includes(dataValue)
              ) {
                modifiedItem.disabled = true;
              }
            }
            break;

          case "MAKE_MANDATORY":
            if (
              rule.conditionalValues?.includes(dropdownSelected) &&
              rule.destinationIds?.includes(item.metaDataId)
            ) {
              modifiedItem.mandatory = true;
            }
            break;
          case "MAKE_NON_MANDATORY":
            if (
              rule.conditionalValues?.includes(dropdownSelected) &&
              rule.destinationIds?.includes(item.metaDataId)
            ) {
              modifiedItem.mandatory = false;
            }
            break;
          case "MAKE_VISIBLE":
            if (
              rule.conditionalValues?.includes(dropdownSelected) &&
              rule.destinationIds?.includes(item.metaDataId)
            ) {
              modifiedItem.visible = true;
            }
            break;

          case "MAKE_INVISIBLE":
            if (
              rule.conditionalValues?.includes(dropdownSelected) &&
              rule.destinationIds?.includes(item.metaDataId)
            ) {
              modifiedItem.visible = false;
            }
            break;
          case "SEND_OTP":
            if (rule.sourceIds?.includes(item.metaDataId)) {
              modifiedItem.showSendOtpButton = true; // Mark to render SEND OTP button
              modifiedItem.otpDestinationIds = rule?.destinationIds[0]; // Store destination ID for OTP field
              modifiedItem.showOtpField = false;
            }
            break;
          case "EXECUTE":
            if (rule && rule.conditionalValues) {
              const ruleDataValue = ruleData1(rule?.conditionalValues);

              // Process the data
              ruleDataValue?.forEach((item) => {
                switch (item.targetValue) {
                  case "mvi": // Make visible
                    if (modifiedItem.metaDataId === item.metaDataId) {
                      modifiedItem.visible = true;
                    }
                    break;
                  case "minvi": // Make invisible
                    if (modifiedItem.metaDataId === item.metaDataId) {
                      modifiedItem.visible = false;
                    }
                    // setFieldVisibility(!isConditionMet);
                    break;
                  case "mm": // Make mandatory
                    if (modifiedItem.metaDataId === item.metaDataId) {
                      modifiedItem.mandatory = true;
                    }
                    break;
                  case "mnm": // Make non mandatory
                    if (modifiedItem.metaDataId === item.metaDataId) {
                      modifiedItem.mandatory = false;
                    }
                    break;
                  case "cf": // Clear value
                    if (modifiedItem.metaDataId === item.metaDataId) {
                      checkData({ [item.metaDataId]: null });
                    }
                    break;
                  default:
                    console.log(`No matching operator for ${item.targetValue}`);
                    break;
                }
              });
            }
            break;
          case "SESSION_BASED_MAKE_INVISIBLE":
            if (sessionStorage.getItem("user_type") == rule.param) {
              if (rule.conditionalValues[1] == "createdForCheck") {
                if (
                  rule.conditionalValues?.includes(dropdownSelected) &&
                  rule.destinationIds?.includes(item.metaDataId)
                ) {
                  modifiedItem.visible = false;
                }
              }
            }
            break;
          case "SESSION_BASED_MAKE_VISIBLE":
            if (sessionStorage.getItem("user_type") == rule.param) {
              if (rule.conditionalValues[1] == "createdForCheck") {
                if (
                  rule.conditionalValues?.includes(dropdownSelected) &&
                  rule.destinationIds?.includes(item.metaDataId)
                ) {
                  modifiedItem.visible = true;
                }
              }
            }
            break;
          case "SESSION_BASED_MAKE_ENABLED":
            if (sessionStorage.getItem("user_type") == rule.param) {
              if (rule.conditionalValues[1] == "createdForCheck") {
                if (rule.destinationIds?.includes(item.metaDataId)) {
                  const dataValue = item.dataValue;
                  const condition = rule.condition;
                  const conditionalValues = rule.conditionalValues;
                  if (
                    condition === "NOT_IN" &&
                    !conditionalValues.includes(dataValue)
                  ) {
                    modifiedItem.disabled = false;
                  }
                }
              }
            }
            break;
          case "SESSION_BASED_MAKE_DISABLED":
            if (sessionStorage.getItem("user_type") == rule.param) {
              if (rule.conditionalValues[1] == "createdForCheck") {
                if (rule.destinationIds?.includes(item.metaDataId)) {
                  const dataValue = item.dataValue;
                  const condition = rule.condition;
                  const conditionalValues = rule.conditionalValues;
                  if (
                    condition === "NOT_IN" &&
                    !conditionalValues.includes(dataValue)
                  ) {
                    modifiedItem.disabled = true;
                  }
                }
              }
            }
            break;
          case "SESSION_BASED_MAKE_MANDATORY":
            if (sessionStorage.getItem("user_type") == rule.param) {
              if (rule.conditionalValues[1] == "createdForCheck") {
                if (
                  rule.conditionalValues?.includes(dropdownSelected) &&
                  rule.destinationIds?.includes(item.metaDataId)
                ) {
                  modifiedItem.mandatory = true;
                }
              }
            }
            break;
          case "SESSION_BASED_MAKE_NON_MANDATORY":
            if (sessionStorage.getItem("user_type") == rule.param) {
              if (rule.conditionalValues[1] == "createdForCheck") {
                if (
                  rule.conditionalValues?.includes(dropdownSelected) &&
                  rule.destinationIds?.includes(item.metaDataId)
                ) {
                  modifiedItem.mandatory = false;
                }
              }
            }
            break;
          // Add other cases as needed
          default:
            break;
        }
      });
    }
    // console.log(modifiedItem, "modified");
    return modifiedItem;
  };
  // Effect to process form data when dependencies change
  useEffect(() => {
    const currentPanel = finalResult?.[activeSection];
    if (currentPanel?.formData) {
      const updatedData = currentPanel.formData.map((item) =>
        processFormRules(item, currentPanel.tagName)
      );
      setProcessedData(updatedData);
    }
  }, [dropdownSelected, activeSection, finalResult]);
  useEffect(() => {
    if (metaData) {
      // Initialize a new object to store formData
      const updatedFormData = {};

      // Iterate over each item in the metaData array
      metaData.forEach(async (item) => {
        const { metaDataId, dataValue, htmlContent, formFillDataId } = item; // Destructure relevant properties

        // Check the specific condition
        if (htmlContent === "OCR_VIEW" && dataValue) {
          try {
            // Replace `trxId` with the actual value from your context or props
            const response = await axios.get(
              `${domainUrl}/app/transactions/${trxcId}/form-fill-data/${formFillDataId}/object-content`,
              {
                headers: {
                  "X-Authorization": process.env.REACT_APP_AUTH, // Replace with your actual token
                },
                responseType: "arraybuffer", // To receive binary data as arrayBuffer
              }
            );

            // Convert array buffer to base64
            const base64String = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );

            // Construct the image data URL
            const imageData = `data:image/jpeg;base64,${base64String}`;

            // Update the specific formData entry with the fetched image data
            updatedFormData[metaDataId] = imageData;

            // Optionally, dispatch the success action
            dispatch(
              successMetDataImageAction({
                metaDataId: metaDataId,
                imageData: imageData,
              })
            );
          } catch (error) {
            console.error("Error fetching the thumbnail:", error);
          }
        } else {
          // Use dataValue or fallback to an empty string
          updatedFormData[metaDataId] = dataValue || "";
        }
      });

      // Update the formData state with the populated object
      setFormData(updatedFormData);
      // dispatch(formDataAction(updatedFormData))
    }
  }, [metaData, dispatch, trxcId]);

  const renderFormElement = useCallback(
    (item, currentPanel, formData, onChange, onBlur, fromDataGrid) => {
      const processedItem = processFormRules(item, currentPanel.tagName);
      const isOtpVerified = (metaDataId) => {
        return verifiedOtp.some(
          (otp) => otp.id === metaDataId && otp.responseFromApi === "SUCCESS"
        );
      };
      switch (processedItem.type) {
        case "EXECUTE_BUTTON":
          return (
            processedItem?.visible !== false && (
              <Button variant="sectionForm">{processedItem.tagName}</Button>
            )
          );
        case "LABEL":
          return (
            processedItem?.visible !== false && (
              <Box sx={{ marginTop: "30px" }}>
                <Typography variant="formLabel" noWrap>
                  {processedItem.tagName}
                </Typography>
              </Box>
            )
          );
        case "TEXT":
          return (
            processedItem?.visible !== false && (
              <Box className="textCase">
                <TextField
                  label={item.tagName}
                  InputLabelProps={{
                    style: fromDataGrid
                      ? { visibility: "hidden" }
                      : { visibility: "visible" },
                  }}
                  variant="standard"
                  className="smallField"
                  value={
                    typeof formData === "string"
                      ? formData
                      : formData[processedItem.metaDataId] || ""
                  }
                  onChange={(e) => {
                    let newValue = e.target.value;

                    // Convert to uppercase if needed
                    if (processedItem.convertToUpper) {
                      newValue = newValue.toUpperCase();
                    }

                    // Call the appropriate onChange function
                    if (processedItem?.extendedFillDataId) {
                      onChange(newValue);
                    } else {
                      handleChange(processedItem.metaDataId, newValue);
                    }
                  }}
                  onBlur={(e) => {
                    // Only run handleBlur if no button exists in formRules
                    if (
                      !processedItem.formRules ||
                      !processedItem.formRules.some(
                        (rule) => rule.type === "SERVER" && rule.button
                      )
                    ) {
                      const newValue = e.target.value;

                      // Exit early if no value is entered
                      if (!newValue) {
                        return;
                      }

                      // Call handleBlur and store its return value
                      const isValid = handleBlur(
                        processedItem.metaDataId,
                        newValue,
                        processedItem.mandatory,
                        processedItem.formValidations,
                        processedItem
                      );

                      // Stop if validation fails
                      if (!isValid) {
                        console.log("Validation failed, no dispatch triggered");
                        return;
                      }

                      // Check if value matches existing entry in valueHashArray
                      const existingEntry = valueHashArray?.find(
                        (entry) => entry.metaDataId === processedItem.metaDataId
                      );

                      // Skip dispatch if value hasn't changed
                      if (
                        existingEntry &&
                        existingEntry.dataValue === newValue
                      ) {
                        return;
                      }
                      if (processedItem?.extendedFillDataId) {
                        onBlur();
                      }
                      // If values differ, dispatch the action
                      else {
                        const hashValue = getHashValue(
                          processedItem.metaDataId
                        );
                        console.log(hashValue, "hashValue for text field");
                        if (!processedItem.showSendOtpButton) {
                          dispatch(
                            getFillInfo({
                              dataValue: newValue, // Encrypt the value
                              metaDataId: processedItem.metaDataId,
                              valueHash: hashValue,
                              trxcId,
                              docTypeId,
                            })
                          );
                        }
                      }
                    }
                  }}
                  required={processedItem.mandatory}
                  disabled={processedItem.disabled || false}
                  error={!!errors[processedItem.metaDataId]}
                  helperText={errors[processedItem.metaDataId] || ""}
                />
                <Box className="textCaseBox">
                  {processedItem.showSendOtpButton ? (
                    <Button
                      // className="textCaseBtn"

                      variant="text"
                      color="primary"
                      onClick={() => {
                        // Retrieve the updated formData before validation and dispatch
                        const newValue =
                          formData[processedItem.metaDataId] || "";

                        // Exit early if no value entered
                        if (!newValue) {
                          console.log(
                            "No value entered, onClick action skipped"
                          );
                          return;
                        }

                        // Run handleBlur validation on button click
                        const isValid = handleBlur(
                          processedItem.metaDataId,
                          newValue,
                          processedItem.mandatory,
                          processedItem.formValidations,
                          processedItem
                        );

                        // Stop if validation fails
                        if (!isValid) {
                          console.log(
                            "Validation failed, no dispatch triggered"
                          );
                          return;
                        }
                        const encryptedData = CryptoJS.AES.encrypt(
                          newValue,
                          secret
                        );
                        const data = {
                          fieldType:
                            processedItem.tagName === "Email"
                              ? "FORM_EMAIL_OTP"
                              : "FORM_MOBILE_OTP",
                          value: encryptedData.toString(),
                          primaryFieldId: processedItem.metaDataId,
                          otpFieldId: processedItem.otpDestinationIds,
                        };

                        dispatch(
                          sendOtpAction({
                            data,
                            trxcId,
                            docTypeId,
                          })
                        );
                        // Update visibility for the OTP field
                        setVisibleOtpFields((prev) => ({
                          ...prev,
                          [processedItem.otpDestinationIds]: true,
                        }));
                      }}
                    >
                      {/* Check if the OTP is already verified */}
                      {verifiedOtp.some(
                        (otp) =>
                          otp.id === processedItem.otpDestinationIds &&
                          otp.responseFromApi === "SUCCESS"
                      ) ? (
                        <Box className="textCaseIcon">
                          <VerifiedGreen />
                        </Box>
                      ) : (
                        <Box className="textCaseBtn">Send OTP</Box>
                      )}
                    </Button>
                  ) : null}
                </Box>
                <Box className="textCaseBox">
                  {/* Conditionally render the button if button key exists in formRules */}
                  {processedItem.formRules &&
                    processedItem.formRules.some(
                      (rule) => rule.type === "SERVER" && rule.button
                    ) && (
                      <Button
                        // className="textCaseBtn"
                        variant="text"
                        color="primary"
                        onClick={() => {
                          // Retrieve the updated formData before validation and dispatch
                          const newValue =
                            formData[processedItem.metaDataId] || "";

                          // Exit early if no value entered
                          if (!newValue) {
                            console.log(
                              "No value entered, onClick action skipped"
                            );
                            return;
                          }

                          // Run handleBlur validation on button click
                          const isValid = handleBlur(
                            processedItem.metaDataId,
                            newValue,
                            processedItem.mandatory,
                            processedItem.formValidations,
                            processedItem
                          );

                          // Stop if validation fails
                          if (!isValid) {
                            console.log(
                              "Validation failed, no dispatch triggered"
                            );
                            return;
                          }

                          // Check if the new value matches existing dataValue in valueHashArray
                          const existingEntry = valueHashArray?.find(
                            (entry) =>
                              entry.metaDataId === processedItem.metaDataId
                          );

                          if (
                            existingEntry &&
                            existingEntry.dataValue === newValue
                          ) {
                            console.log(
                              "Value is the same, no dispatch triggered"
                            );
                            return;
                          }

                          // If values differ, dispatch the action
                          const hashValue = getHashValue(
                            processedItem.metaDataId
                          );
                          console.log(hashValue, "hashValue for text field");

                          dispatch(
                            getFillInfo({
                              dataValue: newValue,
                              metaDataId: processedItem.metaDataId,
                              valueHash: hashValue,
                              trxcId,
                              docTypeId,
                            })
                          );
                        }}
                      >
                        {processedItem?.validationStatus === "SUCCESS" ||
                        (Array.isArray(dataOfFill) &&
                          dataOfFill.length > 0 &&
                          dataOfFill.some((entry) => {
                            const key = Object.keys(entry)[0]; // Ensure 'entry' is an object with at least one key
                            if (!key) return false; // Skip this iteration if no keys exist
                            const ruleResults = entry[key];
                            return (
                              Array.isArray(ruleResults) &&
                              ruleResults.some(
                                (result) =>
                                  result.formFillMetadataId ===
                                    processedItem.metaDataId &&
                                  result.validationStatus === "SUCCESS"
                              )
                            );
                          })) ? (
                          <Box className="textCaseIcon">
                            <VerifiedGreen />
                          </Box>
                        ) : (
                          <Box className="textCaseBtn">Verify</Box>
                        )}
                      </Button>
                    )}
                </Box>
              </Box>
            )
          );
        case "OTP":
          // Check if the OTP field should be visible
          const isVerified = isOtpVerified(item.metaDataId);
          if (!visibleOtpFields[item.metaDataId] || isVerified) {
            return null; // Do not render if OTP is not visible or already verified
          }

          return (
            <Box className="textCase">
              <TextField
                label={processedItem?.extendedFillDataId ? "" : item.tagName}
                variant="standard"
                className="smallField"
                value={formData[processedItem.metaDataId] || ""}
                onChange={(e) => {
                  let newValue = e.target.value;

                  if (processedItem.convertToUpper) {
                    newValue = newValue.toUpperCase();
                  }

                  handleChange(processedItem.metaDataId, newValue);
                }}
              />
              <Box className="textCaseBox">
                <Button
                  // className="textCaseBtn"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    const newValue = formData[processedItem.metaDataId] || "";

                    if (!newValue) {
                      console.log("No value entered, onClick action skipped");
                      return;
                    }

                    const isValid = handleBlur(
                      processedItem.metaDataId,
                      newValue,
                      processedItem.mandatory,
                      processedItem.formValidations,
                      processedItem
                    );

                    if (!isValid) {
                      console.log("Validation failed, no dispatch triggered");
                      return;
                    }

                    const encryptedData = CryptoJS.AES.encrypt(
                      newValue,
                      secret
                    );
                    const data = {
                      value: encryptedData.toString(),
                      id: processedItem.metaDataId,
                    };

                    dispatch(
                      verifyOtpAction({
                        data,
                        trxcId,
                        docTypeId,
                      })
                    );
                  }}
                >
                  <Box className="textCaseBtn">Verify OTP</Box>
                </Button>
              </Box>
            </Box>
          );

        case "NUMBER":
          return (
            processedItem?.visible !== false && (
              <TextField
                sx={{
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                label={processedItem?.extendedFillDataId ? "" : item.tagName}
                InputLabelProps={{
                  style: fromDataGrid
                    ? { visibility: "hidden" }
                    : { visibility: "visible" },
                }}
                type="number"
                variant="standard"
                className="smallField"
                value={formData[item.metaDataId] || ""} // Add this line to control the value
                onBlur={(e) => {
                  handleBlur(
                    item.metaDataId,
                    e.target.value,
                    item.mandatory,
                    item.formValidations,
                    item
                  );
                  if (!errors[item.metaDataId]) {
                    const hashValue = getHashValue(item.metaDataId);
                    dispatch(
                      getFillInfo({
                        dataValue: e.target.value,
                        metaDataId: item.metaDataId,
                        valueHash: hashValue,
                        trxcId,
                        docTypeId,
                      })
                    );
                  }
                }}
                disabled={processedItem.disabled || false}
                required={processedItem.mandatory}
                error={!!errors[item.metaDataId]}
                helperText={errors[item.metaDataId] || ""}
                onChange={(e) => handleChange(item.metaDataId, e.target.value)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  step: "any",
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
              />
            )
          );
        case "DATE":
          return (
            processedItem?.visible !== false && (
              <Box className="textCase">
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  label={fromDataGrid ? "" : item.tagName}
                  slotProps={{
                    textField: {
                      InputLabelProps: {
                        style: fromDataGrid
                          ? { visibility: "hidden" }
                          : { visibility: "visible" },
                      },
                    },
                  }}
                  variant="standard"
                  required={processedItem.mandatory}
                  disabled={processedItem.disabled || false}
                  sx={{
                    margin: "30px 0px 0px 0px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0px",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12.5px 14px",
                    },
                  }}
                  value={
                    typeof formData === "string"
                      ? dayjs(formData, "DD/MM/YYYY").isValid()
                        ? dayjs(formData, "DD/MM/YYYY").tz("Asia/Kolkata")
                        : null
                      : dayjs(formData[item.metaDataId], "DD/MM/YYYY").isValid()
                      ? dayjs(formData[item.metaDataId], "DD/MM/YYYY").tz(
                          "Asia/Kolkata"
                        )
                      : null
                  }
                  onChange={(date) => {
                    // Convert to IST before formatting
                    const istDate = date
                      ? dayjs(date).tz("Asia/Kolkata")
                      : null;
                    const formattedDate = istDate
                      ? istDate.format("DD/MM/YYYY")
                      : null;

                    if (processedItem?.extendedFillDataId) {
                      onChange(formattedDate);
                    } else {
                      handleChange(item.metaDataId, formattedDate);
                    }
                  }}
                  onClose={() => {
                    const value = formData[item.metaDataId];
                    handleBlur(item.metaDataId, value, item.mandatory);
                    if (!errors[item.metaDataId]) {
                      if (processedItem?.extendedFillDataId) {
                        onBlur();
                      } else {
                        const hashValue = getHashValue(item.metaDataId);

                        dispatch(
                          getFillInfo({
                            dataValue: value, // Encrypt the formatted value
                            metaDataId: item.metaDataId,
                            valueHash: hashValue,
                            trxcId,
                            docTypeId,
                          })
                        );
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors[item.metaDataId]}
                      helperText={errors[item.metaDataId] || ""}
                    />
                  )}
                />
              </Box>
            )
          );

        case "FILE":
          return (
            processedItem?.visible !== false && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "8px",
                  position: "relative",
                  marginTop: fromDataGrid ? "0px" : "30px",
                  width: "90%",
                }}
              >
                {console.log(
                  metaDataImage?.metaDataImageValue?.imageData,
                  "checking file"
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // Aligns upload icon to the right
                    width: "100%",
                  }}
                >
                  <Typography variant="imgPlaceholder" color="textPrimary">
                    {fromDataGrid ? "" : item.tagName}{" "}
                    {processedItem.mandatory && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </Typography>

                  <label
                    htmlFor={`file-upload-${item.metaDataId}`}
                    style={{ marginBottom: "0px" }}
                  >
                    <IconButton
                      color="primary"
                      component="span"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "40px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                      }}
                    >
                      <FileUpload />
                    </IconButton>
                  </label>
                </Box>

                {/* Access metaDataImage from Redux */}
                <Box
                  sx={{
                    // marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {metaDataImage?.metaDataImageValue?.metaDataId ===
                    item.metaDataId &&
                  metaDataImage?.metaDataImageValue?.imageData ? (
                    // Display the image if available in the Redux slice
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center", // Optional: Align vertically in center
                        height: 200, // Box height if needed
                        paddingBottom: "15px",
                        position: "relative",
                      }}
                    >
                      {" "}
                      <img
                        src={metaDataImage.metaDataImageValue.imageData}
                        alt="Uploaded"
                        style={{
                          height: "200px",
                          width: isMobile ? "100%" : "auto", // Maintain aspect ratio
                          objectFit: "contain", // Ensures image fits well
                        }}
                      />
                      <IconButton
                        // onClick={onDelete}
                        sx={{ position: "absolute", right: 0, bottom: 0 }}
                      >
                        <DeleteIconRed />
                      </IconButton>
                    </Box>
                  ) : (
                    <input
                      accept="application/pdf,image/*" // Accept both PDF and image files
                      style={{ display: "none" }}
                      id={`file-upload-${item.metaDataId}`}
                      type="file"
                      required={processedItem.mandatory}
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          const reader = new FileReader();

                          reader.onloadend = () => {
                            const base64data = reader.result.split(",")[1]; // Get Base64 data
                            const fileType = selectedFile.type;
                            let blobUrl;

                            // Clear previous errors for this metaDataId
                            setErrors((prevErrors) => {
                              const {
                                [item.metaDataId]: _,
                                ...remainingErrors
                              } = prevErrors;
                              return remainingErrors;
                            });

                            // Handle PDF
                            if (fileType === "application/pdf") {
                              const blob = new Blob([selectedFile], {
                                type: "application/pdf",
                              });
                              blobUrl = URL.createObjectURL(blob);

                              handleChange(item.metaDataId, {
                                base64: base64data,
                                blob: blob,
                                url: blobUrl,
                                name: selectedFile.name,
                              });

                              const isValid = handleBlur(
                                item.metaDataId,
                                selectedFile,
                                item.mandatory
                              );
                              if (isValid) {
                                const formUploadData = new FormData();
                                formUploadData.append(
                                  "fileName",
                                  selectedFile.name
                                );
                                formUploadData.append(
                                  "mediaType",
                                  selectedFile.type
                                );
                                formUploadData.append("data", blob); // Append the actual file
                                formUploadData.append(
                                  "formFillMetadataId",
                                  item.metaDataId
                                );

                                dispatch(
                                  getFileUploadInfo({
                                    dataValue: formUploadData,
                                    metaDataId: item.metaDataId,
                                    valueHash: item.dataValue,
                                    trxcId,
                                    docTypeId,
                                  })
                                );
                              }
                            }

                            // Handle Images
                            else if (fileType.startsWith("image/")) {
                              const blob = new Blob([selectedFile], {
                                type: fileType,
                              });
                              blobUrl = URL.createObjectURL(blob);

                              handleChange(item.metaDataId, {
                                base64: base64data,
                                blob: blob,
                                url: blobUrl,
                                name: selectedFile.name,
                              });

                              const isValid = handleBlur(
                                item.metaDataId,
                                selectedFile,
                                item.mandatory
                              );
                              if (isValid) {
                                const formUploadData = new FormData();
                                formUploadData.append(
                                  "fileName",
                                  selectedFile.name
                                );
                                formUploadData.append(
                                  "mediaType",
                                  selectedFile.type
                                );
                                formUploadData.append("data", blob); // Append the actual file
                                formUploadData.append(
                                  "formFillMetadataId",
                                  item.metaDataId
                                );

                                dispatch(
                                  getFileUploadInfo({
                                    dataValue: formUploadData,
                                    metaDataId: item.metaDataId,
                                    valueHash: item.dataValue,
                                    trxcId,
                                    docTypeId,
                                  })
                                );
                              }
                            } else {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                [item.metaDataId]:
                                  "Please select a valid PDF or image file.",
                              }));
                            }
                          };

                          reader.readAsDataURL(selectedFile); // Convert file to Base64
                        }
                      }}
                    />
                  )}
                  {/* <label htmlFor={`file-upload-${item.metaDataId}`}>
                  <IconButton color="primary" component="span">
                    <img src={fileUpload} alt="..." />
                  </IconButton>
                </label> */}
                </Box>

                {/* Display file details if a file is selected */}
                {formData[item.metaDataId]?.name && (
                  <Box mt={2} sx={{ textAlign: "center" }}>
                    {formData[item.metaDataId].name.endsWith(".pdf") ? (
                      <>
                        <PictureAsPdfIcon fontSize="large" color="error" />

                        <Typography variant="body2">
                          {formData[item.metaDataId].name} -{" "}
                          <a
                            href={formData[item.metaDataId].url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View PDF
                          </a>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center", // Optional: Align vertically in center
                            height: 200, // Box height if needed
                            paddingBottom: "15px",
                            position: "relative",
                          }}
                        >
                          <img
                            src={formData[item.metaDataId].url}
                            alt={formData[item.metaDataId].name}
                            style={{
                              height: "200px",
                              width: isMobile ? "100%" : "auto", // Maintain aspect ratio
                              objectFit: "contain", // Ensures image fits well
                            }}
                          />
                          <IconButton
                            // onClick={onDelete}
                            sx={{ position: "absolute", right: 0, bottom: 0 }}
                          >
                            <DeleteIconRed />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </Box>
                )}

                <Divider sx={{ width: "100%", borderWidth: "1px" }} />

                <Typography variant="helperText" color="textSecondary">
                  (Supported Formats: PDF, Images)
                </Typography>

                {/* Display validation errors */}
                {errors[item.metaDataId] && (
                  <Typography color="error" variant="body2">
                    {errors[item.metaDataId]}
                  </Typography>
                )}
              </Box>
            )
          );

        case "EXTERNAL_DROP_DOWN_VALUE":
          return (
            processedItem?.visible !== false && (
              <FormControl
                sx={{ marginTop: "30px", width: "90%" }}
                variant="standard"
                error={!!errors[processedItem.metaDataId]} // Add error handling for form control
                required={processedItem.mandatory} // Add required if the field is mandatory
              >
                <InputLabel
                  id={`dropdown-label-${processedItem.metaDataId}`}
                  sx={{
                    visibility: fromDataGrid ? "hidden" : "visible", // Apply visibility logic
                  }}
                >
                  {fromDataGrid ? "" : item.tagName}
                </InputLabel>
                <Select
                  labelId={`dropdown-label-${processedItem.metaDataId}`}
                  id={`dropdown-${processedItem.metaDataId}`}
                  value={
                    typeof formData === "string"
                      ? formData
                      : formData[processedItem.metaDataId] || ""
                  } // Use formData for value
                  onOpen={() => onDropdownOpen(processedItem)}
                  onChange={(e) => {
                    if (processedItem?.extendedFillDataId) {
                      onChange(e.target.value);
                      onBlur();
                    } else {
                      handleChange(processedItem.metaDataId, e.target.value); // Handle value change
                      setDropdownSelected(e.target.value);
                      // Handle validation on blur
                      handleBlur(
                        processedItem.metaDataId,
                        e.target.value,
                        processedItem.mandatory,
                        processedItem.formValidations
                      );

                      // Dispatch API call after validation, if no errors
                      if (!errors[processedItem.metaDataId]) {
                        const hashValue = getHashValue(
                          processedItem.metaDataId
                        );

                        dispatch(
                          getFillInfo({
                            dataValue: e.target.value,
                            metaDataId: processedItem.metaDataId,
                            valueHash: hashValue,
                            trxcId,
                            docTypeId,
                          })
                        );
                      }
                    }
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {names
                    ? names?.map((dropdownItem) => (
                        <MenuItem
                          value={dropdownItem.dropdownValue}
                          key={dropdownItem.dropdownId}
                          onClick={() => {
                            console.log("Dropdown item clicked:");
                          }}
                        >
                          {dropdownItem.dropdownValue}
                        </MenuItem>
                      ))
                    : staticDropdownValue.map((dropdownItem) => (
                        <MenuItem
                          value={dropdownItem.dropdownValue}
                          key={dropdownItem.dropdownId}
                          disabled
                        >
                          {dropdownItem.dropdownValue}
                        </MenuItem>
                      ))}
                </Select>

                {/* Display validation helper text */}
                <FormHelperText>{errors[item.metaDataId] || ""}</FormHelperText>
              </FormControl>
            )
          );

        // Inside renderFormElement function
        case "STATIC_CHECK_BOX":
          return (
            processedItem?.visible !== false && (
              <FormControlLabel
                sx={{ mt: "30px" }}
                required={item.mandatory}
                control={
                  <Checkbox
                    checked={formData[item.metaDataId] === "X"} // Make the checkbox controlled by formData
                    onChange={(e) => {
                      const value = e.target.checked ? "X" : null;
                      handleChange(item.metaDataId, value); // Update the formData state
                      handleBlur(item.metaDataId, value, item.mandatory); // Handle any blur logic

                      if (!errors[item.metaDataId]) {
                        const hashValue = getHashValue(item.metaDataId);

                        dispatch(
                          getFillInfo({
                            dataValue: value,
                            metaDataId: item.metaDataId,
                            valueHash: hashValue,
                            trxcId,
                            docTypeId,
                          })
                        );
                      }
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      visibility: fromDataGrid ? "hidden" : "visible",
                    }}
                  >
                    {fromDataGrid ? "" : item.tagName}
                  </span>
                }
              />
            )
          );

        case "IMAGE": {
          const handlePhotoUpload = (id, event) => {
            const file = event.target.files[0]; // Get the first selected file
            if (file) {
              // Check if the file is an image
              if (file.type.startsWith("image/")) {
                setFileError(""); // Clear any previous errors
                const reader = new FileReader();
                reader.onload = () => {
                  setProfilePhotoPreview(reader.result); // Set the preview
                  setIsModalOpen({ open: true, id: id }); // Open the cropper modal
                };
                reader.readAsDataURL(file); // Read the image as base64 data URL
              } else {
                // Set error message if file is not an image
                setFileError(
                  "Only image formats (e.g., .jpg, .png) are supported."
                );
              }
            }
          };
          const getCropData = (item) => {
            if (cropperRef.current) {
              const croppedImageDataURL = cropperRef.current.cropper
                .getCroppedCanvas()
                .toDataURL();
              //   setCropData(croppedImageDataURL); // Store cropped image data
              setCropData((prevCropData) => {
                // Check if an object with the same id already exists
                const index = prevCropData.findIndex(
                  (item) => item.id === isModalOpen.id
                );

                if (index !== -1) {
                  // If found, replace the object with the new one
                  return prevCropData.map((item) =>
                    item.id === isModalOpen.id
                      ? { id: isModalOpen.id, imageData: croppedImageDataURL }
                      : item
                  );
                } else {
                  // If not found, add the new object
                  return [
                    ...prevCropData,
                    { id: isModalOpen.id, imageData: croppedImageDataURL },
                  ];
                }
              });
              const fileName = `cropped_${item.metaDataId}.png`; // Example: setting a name for cropped image
              const fileBlob = dataURLtoBlob(croppedImageDataURL); // Convert base64 to blob
              if (isModalOpen.id.extendedFillDataId) {
                const formUploadData = new FormData();
                formUploadData.append("fileName", fileName);
                formUploadData.append("mediaType", "image/png");
                formUploadData.append("data", fileBlob);

                dispatch(
                  getExtendedGroupsImage({
                    dataValue: formUploadData,
                    headerId:
                      extendedUserGroups?.content[0]?.data[0]?.headerFillDataId,
                    extendedFillDataId: isModalOpen.id.extendedFillDataId,
                  })
                );
              } else {
                const formUploadData = new FormData();
                formUploadData.append("fileName", fileName);
                formUploadData.append("mediaType", "image/png");
                formUploadData.append("data", fileBlob);
                formUploadData.append("formFillMetadataId", item.metaDataId);

                dispatch(
                  getFileUploadInfo({
                    dataValue: formUploadData,
                    metaDataId: item.metaDataId,
                    valueHash: item.dataValue,
                    trxcId,
                    docTypeId,
                  })
                );
              }
              setIsModalOpen({ open: false }); // Close the modal
            }
          };
          const dataURLtoBlob = (dataurl) => {
            const arr = dataurl.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
          };

          const displayImage = (metaData) => {
            const filteredimageData = cropData.find(
              (item) => item.id.metaDataId === metaData
            );
            return filteredimageData?.imageData
              ? filteredimageData?.imageData
              : null;
          };
          return (
            processedItem?.visible !== false && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  borderRadius: "8px",
                  position: "relative",
                  marginTop: fromDataGrid ? "0px" : "30px",
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="imgPlaceholder" color="textPrimary">
                    {fromDataGrid ? "" : item.tagName}{" "}
                    {/* {processedItem.metaDataId} */}
                    {processedItem.mandatory && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </Typography>

                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id={`image-upload-${item.metaDataId}`}
                    type="file"
                    onChange={(e) => handlePhotoUpload(processedItem, e)}
                    required={processedItem.mandatory}
                  />
                  <label
                    htmlFor={`image-upload-${item.metaDataId}`}
                    style={{ marginBottom: "0px" }}
                  >
                    <IconButton color="primary" component="span">
                      <ImgUpload />
                    </IconButton>
                  </label>
                </Box>

                {/* Display cropped image or original preview */}
                {displayImage(processedItem.metaDataId) && (
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      alignItems: "center", // Optional: Align vertically in center
                      height: 200, // Box height if needed
                      paddingBottom: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={displayImage(processedItem.metaDataId)}
                      alt="Cropped"
                      style={{
                        height: "200px",
                        width: "auto", // Maintain aspect ratio
                        objectFit: "contain", // Ensures image fits well
                      }}
                    />
                    <IconButton
                      // onClick={onDelete}
                      sx={{ position: "absolute", right: 0, bottom: 0 }}
                    >
                      <DeleteIconRed />
                    </IconButton>
                  </Box>
                )}

                <Divider sx={{ width: "100%", borderWidth: "1px" }} />

                <Typography variant="helperText" color="textSecondary">
                  (Supported Formats: ‘png’, ‘jpg’, ‘jpeg’)
                </Typography>

                {fileError && (
                  <Typography
                    color="error"
                    variant="body2"
                    sx={{ marginTop: "4px" }}
                  >
                    {fileError}
                  </Typography>
                )}
                {/* Cropper Modal */}
                <Modal
                  open={isModalOpen.open}
                  onClose={() => setIsModalOpen({ open: false })}
                  aria-labelledby="crop-modal-title"
                  aria-describedby="crop-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      id="crop-modal-title"
                      variant="h6"
                      component="h2"
                      mb={2}
                    >
                      Crop the Image
                    </Typography>
                    <Cropper
                      ref={cropperRef}
                      style={{ height: 200, width: "100%" }}
                      aspectRatio={1} // Adjust aspect ratio as needed
                      src={profilePhotoPreview}
                      viewMode={1}
                      guides={true}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                    />
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => getCropData(processedItem)}
                      >
                        Crop
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setIsModalOpen({ open: false })}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                {/* Display validation errors */}
                {errors[item.metaDataId] && (
                  <Typography color="error" variant="body2">
                    {errors[item.metaDataId]}
                  </Typography>
                )}
              </Box>
            )
          );
        }
        case "MULTISELECT_EXTERNAL_DROPDOWN":
          return (
            processedItem?.visible !== false && (
              <FormControl
                variant="standard"
                sx={{
                  marginTop: "30px",
                  width: "90%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12.5px 14px",
                  },
                }}
                error={!!errors[item.metaDataId]} // Add error handling for form control
                required={item.mandatory} // Add required if the field is mandatory
              >
                <InputLabel
                  id={`multi-select-label-${item.metaDataId}`}
                  sx={{
                    visibility: fromDataGrid ? "hidden" : "visible", // Apply visibility logic
                  }}
                >
                  {fromDataGrid ? "" : item.tagName}
                </InputLabel>
                <Select
                  labelId={`multi-select-label-${item.metaDataId}`}
                  id={`multi-select-${item.metaDataId}`}
                  multiple
                  value={formData[item.metaDataId] || []} // Bind value from formData
                  onChange={(e) => {
                    handleChange(item.metaDataId, e.target.value); // Handle multiselect change
                    // Handle validation on blur
                    handleBlur(
                      item.metaDataId,
                      e.target.value,
                      item.mandatory,
                      item.formValidations
                    );

                    // Dispatch API call after validation, if no errors
                    if (!errors[item.metaDataId]) {
                      const hashValue = getHashValue(item.metaDataId);

                      dispatch(
                        getFillInfo({
                          dataValue: e.target.value.join(","), // Convert array to comma-separated string
                          metaDataId: item.metaDataId,
                          valueHash: hashValue,
                          trxcId,
                          docTypeId,
                        })
                      );
                    }
                  }}
                  input={<OutlinedInput label={item.tagName} />}
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          onDelete={() => handleDelete(value, item.metaDataId)} // Handle deletion of selected items
                          deleteIcon={<CloseIcon />}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {names
                    ? names?.map((name) => (
                        <MenuItem
                          key={name.dropdownId}
                          value={name.dropdownValue}
                        >
                          <Checkbox
                            checked={
                              formData[item.metaDataId]?.indexOf(
                                name.dropdownValue
                              ) > -1
                            }
                          />
                          <ListItemText primary={name.dropdownValue} />
                        </MenuItem>
                      ))
                    : staticDropdownValue?.map((dropdownItem) => {
                        <MenuItem
                          value={dropdownItem.dropdownValue}
                          key={dropdownItem.dropdownId}
                          disabled
                        >
                          {dropdownItem.dropdownValue}
                        </MenuItem>;
                      })}
                </Select>

                {/* Display validation helper text */}
                <FormHelperText>{errors[item.metaDataId] || ""}</FormHelperText>
              </FormControl>
            )
          );

        case "TIME":
          return (
            processedItem?.visible !== false && (
              <MobileTimePicker
                label={fromDataGrid ? "" : item.tagName}
                disabled={processedItem.disabled || false}
                variant="standard"
                orientation="portrait"
                inputFormat="hh:mm a" // Changed from "HH:mm" to "hh:mm a" for AM/PM
                ampm={true}
                sx={{
                  margin: "30px 0px 0px 0px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12.5px 14px",
                  },
                }}
                slotProps={{
                  textField: {
                    InputLabelProps: {
                      style: {
                        visibility: fromDataGrid ? "hidden" : "visible",
                      },
                    },
                  },
                }}
                value={
                  typeof formData === "string"
                    ? dayjs(formData, "hh:mm a").isValid() // Match input format
                      ? dayjs(formData, "hh:mm a").tz("Asia/Kolkata")
                      : null
                    : dayjs(formData[item.metaDataId], "hh:mm a").isValid()
                    ? dayjs(formData[item.metaDataId], "hh:mm a").tz(
                        "Asia/Kolkata"
                      )
                    : null
                }
                onChange={(date) => {
                  const istTime = date?.tz("Asia/Kolkata");
                  const formattedTime = istTime
                    ? istTime.format("HH:mm a")
                    : null;

                  processedItem?.extendedFillDataId
                    ? onChange(formattedTime)
                    : handleChange(item.metaDataId, formattedTime);
                }}
                onClose={() => {
                  const value = formData[item.metaDataId];
                  handleBlur(
                    item.metaDataId,
                    value,
                    item.mandatory,
                    item.formValidations
                  );
                  if (!errors[item.metaDataId]) {
                    if (processedItem?.extendedFillDataId) {
                      onBlur();
                    } else {
                      const hashValue = getHashValue(item.metaDataId);
                      dispatch(
                        getFillInfo({
                          dataValue: value,
                          metaDataId: item.metaDataId,
                          valueHash: hashValue,
                          trxcId,
                          docTypeId,
                        })
                      );
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={!!errors[item.metaDataId]}
                    helperText={errors[item.metaDataId] || ""}
                    required={item.mandatory}
                    sx={{
                      "& .MuiInputBase-input": {
                        minWidth: "120px", // Adjust as needed
                      },
                    }}
                  />
                )}
              />
            )
          );

        case "TEXTAREA":
          return (
            processedItem?.visible !== false && (
              <TextField
                label={item.tagName}
                InputLabelProps={{
                  style: fromDataGrid
                    ? { visibility: "hidden" }
                    : { visibility: "visible" },
                }}
                variant="standard"
                className="smallField"
                multiline
                maxRows={4}
                value={formData[item.metaDataId] || ""}
                onChange={(e) => handleChange(item.metaDataId, e.target.value)}
                onBlur={(e) => {
                  handleBlur(item.metaDataId, e.target.value, item.mandatory);
                  if (!errors[item.metaDataId]) {
                    const hashValue = getHashValue(item.metaDataId);

                    dispatch(
                      getFillInfo({
                        dataValue: e.target.value,
                        metaDataId: item.metaDataId,
                        valueHash: hashValue,
                        trxcId,
                        docTypeId,
                      })
                    );
                  }
                }}
                disabled={processedItem.disabled || false}
                required={item.mandatory}
                error={!!errors[item.metaDataId]}
                helperText={errors[item.metaDataId] || ""}
              />
            )
          );
        // Add more cases for other types as needed
        default:
          return null;
      }
    },
    [processFormRules]
  );
  useEffect(() => {
    console.log(finalResult[activeSection],"finalResult[activeSection]")
    const currentSection = finalResult[activeSection];
    
    if (!currentSection?.formData) {
      setExtractedTable(null);
      return;
    }
  
    const formData = currentSection.formData;
    let startIdx = -1;
    let endIdx = -1;
  
    // Find ARRAY_HDR index
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].type === "ARRAY_HDR") {
        startIdx = i;
        break;
      }
    }
  
    if (startIdx === -1) {
      setExtractedTable(null);
      return;
    }
  
    // Find corresponding ARRAY_END index
    for (let i = startIdx + 1; i < formData.length; i++) {
      if (formData[i].type === "ARRAY_END") {
        endIdx = i;
        break;
      }
    }
  
    if (endIdx === -1) {
      setExtractedTable(null);
      return;
    }
  
    // Extract items between ARRAY_HDR and ARRAY_END
    const extractedItems = formData.slice(startIdx + 1, endIdx);
    setExtractedTable(extractedItems);
  }, [activeSection, finalResult]);
  const onSubmitHandler = () => {
    setOpen(true);
  };
  function isFirstFormDataTable(finalResult) {
    return (
      finalResult.length > 0 &&
      finalResult[0].formData &&
      finalResult[0].formData.length > 0 &&
      finalResult[0].formData[0].tagName === "Table"
    );
  }
  return (
    <Box className="SectionFormViewClass">
      {finalResult &&
        finalResult?.map((element, index) => (
          <TabPanel
            value={activeSection}
            index={index}
            key={element.metaDataId}
          >
            <Box>
              <Box className="btnAndHeader">
                <Button
                  onClick={() => {
                    if (activeSection > 0) {
                      onSectionChange(activeSection - 1);
                    }
                  }}
                  className="theButton"
                >
                  <Backbtn />
                </Button>
                <Typography variant="formLabel">{element.tagName}</Typography>
              </Box>

              {extractedTable?.length > 0 &&
              isFirstFormDataTable(finalResult) ? (
                <DataGridRenderer
                  extractedTable={extractedTable}
                  extendedUserGroups={extendedUserGroups}
                  renderFormElement={renderFormElement}
                  finalResult={finalResult[activeSection]}
                />
              ) : null}
              <Grid
                container
                sx={{
                  mb: 2,
                  ml: isMobile ? "20px" : "16px",
                  width: isMobile ? "90%" : "100%",
                }}
              >
                {Array.isArray(element?.formData) &&
                  (() => {
                    let skip = false;
                    return element.formData
                      .filter((item) => {
                        if (item.type === "ARRAY_HDR") {
                          if (!fetchedIds.current.has(item.formFillDataId)) {
                            fetchedIds.current.add(item.formFillDataId);
                            dispatch(getExtendedGroups(item.formFillDataId));
                          }
                          skip = true;
                          return false;
                        }
                        if (item.type === "ARRAY_END") {
                          skip = false;
                          return false;
                        }
                        return !skip;
                      })
                      .map((item) => (
                        <Grid
                          item
                          xs={12}
                          sm={item.gridValue}
                          key={item.metaDataId}
                        >
                          {renderFormElement(
                            item,
                            finalResult[activeSection],
                            formData
                          )}
                        </Grid>
                      ));
                  })()}
              </Grid>
            </Box>
          </TabPanel>
        ))}

      {extractedTable?.length > 0 && !isFirstFormDataTable(finalResult) ? (
        <DataGridRenderer
          extractedTable={extractedTable}
          extendedUserGroups={extendedUserGroups}
          renderFormElement={renderFormElement}
          finalResult={finalResult[activeSection]}
      
        />
      ) : null}
      {activeSection === finalResult.length - 1 ? null : (
        <Box className="btnSection" mr={isMobile ? 0 : 4} pb={2}>
          <Button
            variant={
              isMobile
                ? !finalValidation
                  ? "mobileDisableButton"
                  : "mobiledark"
                : !finalValidation
                ? "disableButton"
                : "templateBlue"
            }
            disabled={!finalValidation}
            onClick={() => onSectionChange(activeSection + 1)}
          >
            Continue
          </Button>
        </Box>
      )}
      {activeSection === finalResult.length - 1 && isMobile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant={isMobile ? "mobiledark" : "templateBlue"}
            onClick={onSubmitHandler}
          >
            {documents?.some((doc) => doc.formFillEnabled === false)
              ? "Continue"
              : "Submit"}
          </Button>
        </Box>
      ) : null}
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: 241, // Adjust height as needed
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "100%", p: 2 }}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<VerticalConnector />}
            >
              {adjustedSteps.map((step, index) => (
                <Step key={step.id}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <CustomStepIcon
                        {...props}
                        icon={step.id}
                        firstDocumentAvailable={firstDocumentAvailable}
                      />
                    )}
                  >
                    <Typography
                      sx={{
                        color: index === activeStep ? "#393e46" : "#898d90",
                        fontWeight: index === activeStep ? "bold" : "normal",
                      }}
                    >
                      {step.label}
                    </Typography>
                    <Box>
                      {index === 0 && (
                        <Button
                          // onClick={() => setOpen(false)}
                          onClick={startBtnHandler}
                          sx={{
                            position: "absolute",
                            right: "-4px",
                            top: documents?.some(
                              (doc) => doc.formFillEnabled === false
                            )
                              ? "130px"
                              : "178px",
                            textTransform: "none",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Start
                        </Button>
                      )}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

import { Drawer } from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";
const DataGridRenderer = ({
  extractedTable,
  extendedUserGroups,
  renderFormElement,
  finalResult,

}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [editedValues, setEditedValues] = useState({});

  console.log(extractedTable, "extractedTable");
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const isMobile = width <= 500;
  // Handle editing in DataGrid
  const handleEditChange = (newValue, extendedFillDataId) => {
    const updatedValues = editedValues.map((item) => {
      // Create a copy of the current item to avoid mutation
      const updatedItem = { ...item };

      // Iterate over each key in the item (excluding 'id')
      Object.keys(updatedItem).forEach((key) => {
        if (key === "id") return; // Skip the 'id' field

        const field = updatedItem[key];
        if (field.extendedFillDataId === extendedFillDataId) {
          // Update the value if extendedFillDataId matches
          updatedItem[key] = {
            ...field,
            value: newValue,
          };
        }
      });

      return updatedItem;
    });

    setEditedValues(updatedValues);
  };

  // Handle onBlur for DataGrid
  const handleBlur = (value, extendedFillDataId) => {
    const secret = process.env.REACT_APP_SECRET_KEY;
    const encryptedData = CryptoJS.AES.encrypt(value, secret).toString(); // Assuming secret data encryption

    console.log(encryptedData, "encryptedData");
    if (value) {
      dispatch(
        get_Extended_Form_Fill({
          extendedFillDataId: extendedFillDataId,
          value: encryptedData,
          headerId: extendedUserGroups?.content[0]?.data[0]?.headerFillDataId,
        })
      );
    }
  };

  // Handle editing in Drawer
  const handleDrawerEditChange = (newValue, extendedFillDataId) => {
    console.log("handledrawer edit is value", newValue);
    console.log("handledrawer edit is selectedRow", selectedRow);
    console.log("handledrawer edit is extendedFillDataId", extendedFillDataId);

    const row = { ...selectedRow };
    Object.keys(row).forEach((key) => {
      // Skip keys that are not data items (like the "id" key)
      if (key === "id") return;

      const item = row[key];
      // Ensure the item exists and has an extendedFillDataId property
      if (item && item.extendedFillDataId === extendedFillDataId) {
        item.value = newValue;
      }
    });
    setSelectedRow(row);
  };

  // Handle onBlur for Drawer

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(params.row.id);
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={(e) => {
              console.log(params, "dsfndndsjndsjcnjcnsc");
              e.stopPropagation();
              handleEdit(params.row);
            }}
          >
            <BorderColorOutlinedIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
    ...(extractedTable?.map((metadata) => ({
      field: metadata.metaDataId.toString(),
      headerName: metadata.tagName,
      width: 250,
      renderCell: (params) => {
        const fieldId = metadata.metaDataId.toString();
        const rowItem = params.row[fieldId] || {};
        const value = rowItem.value || "";

        return renderFormElement(
          {
            ...metadata,
            value,
            extendedFillDataId: rowItem.extendedFillDataId,
          },
          finalResult,
          value,
          (newValue) => handleEditChange(newValue, rowItem.extendedFillDataId),
          () => handleBlur(value, rowItem.extendedFillDataId),
          true
        );
      },
    })) || []),
  ];
  // Example delete handler (you need to implement this)
  const handleDelete = (rowId) => {
    // Update your state to remove the row with this ID
    const headerId = extendedUserGroups?.content[0]?.data[0]?.headerFillDataId;
    dispatch(deleteExtendedFormGroups({ headerId, rowId }));
  };
  const memoizedRows = useMemo(() => {
    return (
      extendedUserGroups?.content?.map((group) => {
        const row = { id: group.groupId };
        group.data?.forEach((dataItem) => {
          const fieldId = dataItem.formFillMetadataId.toString();
          row[fieldId] = {
            value: dataItem.value,
            extendedFillDataId: dataItem.extendedFillDataId,
          };
        });
        return row;
      }) ?? []
    );
  }, [extendedUserGroups]); // Only recompute when extendedUserGroups changes

  // Update state only if needed
  useEffect(() => {
    if (JSON.stringify(memoizedRows) !== JSON.stringify(editedValues)) {
      setEditedValues(memoizedRows);
    }
  }, [memoizedRows]);
  const handleAdd = () => {
    const headerId = extendedUserGroups?.content[0]?.data[0]?.headerFillDataId;
    dispatch(postExtendedGroups(headerId));
  };

  const handleEdit = (rowData) => {
    console.log(rowData, "rowdata");
    setSelectedRow(rowData);
    setIsDrawerOpen(true);
  };
  const renderDrawerContent = () => {
    if (!selectedRow) return null;

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <Box sx={{ p: 3, background: "#e8f0fe" }}>
            <Typography variant="h6" gutterBottom>
              {/* Edit Entry - ID: {selectedRow.id} */}
            
              {finalResult?.formData[0]?.tagName}
            </Typography>
          </Box>

          <Grid container spacing={2} sx={{ p: 3 }}>
            {extractedTable?.map((metadata) => {
              const fieldId = metadata.metaDataId.toString();
              const rowItem = selectedRow[fieldId] || {};
              const value = rowItem.value || "";

              return (
                <Grid item xs={12} sm={6} key={fieldId}>
                  {renderFormElement(
                    {
                      ...metadata,
                      value,
                      extendedFillDataId: rowItem.extendedFillDataId,
                    },
                    finalResult,
                    value,
                    (newValue) =>
                      handleDrawerEditChange(
                        newValue,
                        rowItem.extendedFillDataId
                      ),
                    () => handleBlur(value, rowItem.extendedFillDataId)
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-end",
            padding: "16px 16px 16px 0px",
            background:
              "linear-gradient(to bottom, #f0f0f0 -185%, #f0f0f0 58%, #fff 144%)",
          }}
        >
          <Button
            style={{
              width: "164px",
              height: "54px",
              marginLeft: "20px",
              padding: "18px 63px 17px",
              border: "1px solid #4f4f4f",
              backgroundColor: "transparent",
              color: "#4f4f4f",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            variant="outlined"
            onClick={() => setIsDrawerOpen(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "164px",
              height: "54px",
              marginLeft: "20px",
              padding: "18px 63px 17px",
              border: "1px solid #318be3",
              backgroundColor: "#0000FF",
              color: "#fff",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            variant="contained"
            onClick={() => {
              // Save all changes
              setIsDrawerOpen(false);
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ width: "90%", ml: isMobile ? "20px" : "18px", mb: 2, mt: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
          {finalResult?.formData[0]?.tagName}
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={handleAdd}
            sx={{ fontWeight: "600", fontSize: "16px" }}
          >
            Add
          </Button>
        </Box>
      </Box>
      <DataGrid
        rows={editedValues}
        columns={columns}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        getRowHeight={() => 80}
        pagination={false}
        scrollbarSize={30}
        sx={{
          // Height configurations
          height: Math.min(editedValues.length * 80 + 56, 600), // Dynamic height (80px per row + header)
          maxHeight: 450,
          minHeight: 200, // Prevents collapsing

          // Virtual Scroller styles
          "& .MuiDataGrid-virtualScroller": {
            overflow: "auto", // Ensures scrollbar appears
            minHeight: "200px !important",

            // Force scrollbar visibility
            "&::-webkit-scrollbar": {
              display: "block !important",
              width: "8px",
            },
          },

          // Scrollbar Content Width
          ".MuiDataGrid-scrollbarContent": {
            width: "2320px !important",
          },

          // Full-width scrollbar track
          ".MuiDataGrid-scrollbar--horizontal": {
            height: "8px !important",
            backgroundColor: "transparent !important",
          },

          // Horizontal scrollbar thumb styling
          "& ::-webkit-scrollbar-thumb:horizontal": {
            width: "200px !important",
            minWidth: "200px !important",
            background: `
        linear-gradient(
          to right,
          #bdbdbd 0%,
          #bdbdbd calc(100% - 200px),
          transparent calc(100% - 200px),
          transparent 100%
        ) !important
      `,
            backgroundClip: "content-box !important",
            border: "2px solid transparent",
          },

          // Vertical scrollbar styling
          "& ::-webkit-scrollbar:vertical": {
            width: "4px !important",
          },
          "& ::-webkit-scrollbar-thumb:vertical": {
            height: "200px !important",
            minHeight: "200px !important",
            background: `
        linear-gradient(
          to bottom,
          #bdbdbd 0%,
          #bdbdbd calc(100% - 200px),
          transparent calc(100% - 200px),
          transparent 100%
        ) !important
      `,
            backgroundClip: "content-box !important",
            border: "2px solid transparent",
            borderRadius: "4px",
          },

          // Firefox scrollbar support
          scrollbarColor: "#bdbdbd transparent !important",

          // Remove white space background
          "& .MuiDataGrid-virtualScrollerContent": {
            backgroundColor: "transparent", // Match your background color
          },

          // Remove row and column borders
          "& .MuiDataGrid-cell, & .MuiDataGrid-row": {
            borderColor: "transparent !important",
            border: "none !important",
            background: "transparent !important",
          },
        }}
      />
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "40%",
            minWidth: 400,
          },
        }}
      >
        {renderDrawerContent()}
      </Drawer>
    </Box>
  );
};
