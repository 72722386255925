import React, {
  FunctionComponent,
  useEffect,
  useState,
  createContext,
} from "react";
import {
  Button,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  Skeleton,
  Box,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropContainerComponent from "./subcomponents/DropContainerComponent";
import EditPropertiesComponent from "./subcomponents/EditPropertiesComponent";
import EditRuleComponent from "./subcomponents/EditRuleComponent";
import {
  isMobile as libIsMobile,
  isTablet as libIsTablet,
} from "react-device-detect";
import LeftSidebar from "./LeftSidebar";
import useFormBuilder from "./hooks/useFormBuilder";
import useFormPreview from "./hooks/useFormPreview";
import { Publish, RemoveRedEye } from "@mui/icons-material";
import { FormItemTypes } from "../../utils/formBuilderUtils";
import FormPreview from "./subcomponents/FormPreview";
import { TemplateType } from "../../types/FormTemplateTypes";
import { FormContainerList } from "../../utils/formBuilderUtils";
import { FileIcon } from "../../assets/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ViewRuleCard from "./subcomponents/ViewRuleCard";
import MoveablePannel from "./subcomponents/moveablePannel/MoveablePannel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

let isMobile: boolean;
if (process.env.NODE_ENV === "localhost") {
  isMobile = window.innerWidth < 1024;
} else {
  isMobile = libIsMobile || libIsTablet || window.innerWidth < 1024;
}

interface FormBuilderProps {
  template: TemplateType;
}

interface StyleProps {
  fontSize: string;
  fontWeight: number;
  color: string;
  textTransform:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "initial"
    | "inherit";
}

const useStyles = makeStyles()(() => ({
  textField: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  sidebarHeight: {
    height: "calc(100vh - 11rem);",
    //overflowY: "auto",
    width: "100%",
    padding: "0 0 1rem 0",
  },
}));

type DispatchObject = {
  value: boolean;
  arrayId?: string;
};

const backgroundColor = "#fcfcfc";

const commonStyle: StyleProps = {
  fontSize: "12px",
  fontWeight: 500,
  color: "#318BE3",
  textTransform: "capitalize",
};

export const { color, ...rest } = commonStyle;

export const enable = {
  ...commonStyle,
  fontSize: "16px",
  fontWeight: 500,
  cursor: "pointer",
};

export const disable = { ...enable, color: "grey" };

const marginPadding = {
  margin: 0,
  padding: 0,
  backgroundColor: backgroundColor,
};

const initialPanelFields = {
  id: "",
  name: "Go To Panel",
  type: "PANEL",
  mandatory: false,
  editable: false,
  fontSize: 12,
  fontStyle: "Courier",
  exportable: false,
  visible: true,
  pdfFill: false,
  exportToBulkTemplate: false,
  formOrder: "",
  deleted: false,
  formFillDataEnable: false,
  standardField: false,
};

const domainUrl = process.env.REACT_APP_MAIN_URL;
const mainUrl = window.sessionStorage.getItem("mainUrl");
const diy1Url = domainUrl ? domainUrl + mainUrl : "";

const reDirectToMainSite = () => {
  window.location.href = diy1Url;
};

export const FormBuilderContext = createContext({});

const FormBuilder: FunctionComponent<FormBuilderProps> = (props) => {
  const {
    handleItemAdded,
    saveForm,
    deleteContainer,
    deleteControl,
    editContainerProperties,
    editControlProperties,
    moveControl,
    moveControlFromSide,
    selectControl,
    selectedTemplate,
    formLayoutComponents,
    setFormLayoutComponents,
    selectedControl,
    deleteArrayItems,
    getTemplateData,
    getSelectedTemplateValidationData,
    validationData,
    selectedValidation,
    setSelectedValidation,
    isValidationEdited,
    selectedApiValidation,
    setSelectedApiValidation,
    ruleData,
    setRuleData,
    handleRuleGet,
    getAllTemplateData,
    templateName,
    change,
    setChange,
    particularRule,
    setParticularRule,
    expanded,
    setExpanded,
    selectedItem,
    setSelectedItem,
    paramData,
    setParamData,
    ruleFieldDropdown,
    setRuleFieldDropdown,
    onRuleSubmit,
    selectedRule,
    setSelectedRule,
    saveFormLoading,
    setIsSaveFormLoading,
    getCompanyDetails,
    companyDetails,
    expandedPanel,
    setExpandedPanel,
    setValidationData,
    getValidationData,
    documentDropDownData,
    mainArrayUpdate,
    setIsCalled,
    gridValue,
    setGridValue,
    isCalled,
    isPropertyRule,
    setIsPropertyRule,
    addValidation,
    setAddValidation,
    filterStatus,
    isRuleActive,
    handleAdd,
    validationFormData,
    setValidationFormData,
    validationType,
    setValidationType,
    fromValidationSearch,
    setFromValidationSearch,
    validationInput,
    setValidationInput,
    setPage,
    prevValue,
    fetchOptions,
    isAddRule,
    setIsAddRule,
    activeInactive,
    isOtherRule,
    setIsOtherRule,
    ruleStatus,
    panelDropdown,
    inputRef,
    formNames,
    setFormName,
    isRuleLoading,
    dirtyRecord,
    setDirtyRecord,
    ruleDropdownValue,
    leftBarFields,
    sideBarLoading,
    dropdownTypeArr,
    setIsEdited,
    ruleDataList,
    postTriggerRule,
    setPostTriggerRule,
    smartSuggestion,
    onSmartSuggestion,
    stringToDropdown,
    setStringToDropdown,
    fromDropdown,
    setFromDropdown,
    dropDownTypeApiOrField,
    setDropDownTypeApiOrField,
    handleRuleChange,
    convertStringToDropDownJSONform,
    completeRuleScreen,
    setCompleteRuleScreen,
    viewRuleCardScreen,
    setViewRuleCardScreen,
  } = useFormBuilder({ template: props.template });

  const { showPreview, openPreviewDrawer, closePreviewDrawer } =
    useFormPreview();
  const { classes } = useStyles();

  const [isOverMainContainer, setIsOverMainContainer] =
    useState<DispatchObject>({
      value: true,
      arrayId: "",
    });

  // State of Scrolling From Selection to Destination
  const [hideToFixScrolling, setHideToFixScrolling] = useState<boolean>(true); // Scrolling Fix
  const [panelSelected, setPanelSelected] = useState(initialPanelFields);
  // End

  // Panel Expand logic
  const handleChange = (index: number, item: any, type: string) => {
    if (type !== "status") {
      setExpanded(expanded === index ? -1 : index);
      setSelectedItem({ ...item, fromStatus: false });
    } else {
      setSelectedItem({ ...item, fromStatus: true });
    }
  };

  // Auto Scroll
  const scrollFunction = (_: any, value: any) => {
    setPanelSelected(value);
    setTimeout(() => {
      setExpandedPanel([value?.id]);
      selectControl(value);
    }, 1250);

    const element = document.getElementById(value?.id);

    element?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
    setHideToFixScrolling(false);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      }),
        setHideToFixScrolling(true);
    }, 1000);
  };

  useEffect(() => {
    setIsSaveFormLoading(true);
  }, []);

  const fromBuilderProps = {
    handleItemAdded,
    saveForm,
    deleteContainer,
    deleteControl,
    editContainerProperties,
    editControlProperties,
    moveControl,
    moveControlFromSide,
    selectControl,
    selectedTemplate,
    formLayoutComponents,
    setFormLayoutComponents,
    selectedControl,
    deleteArrayItems,
    getTemplateData,
    getSelectedTemplateValidationData,
    validationData,
    selectedValidation,
    setSelectedValidation,
    isValidationEdited,
    selectedApiValidation,
    setSelectedApiValidation,
    ruleData,
    setRuleData,
    handleRuleGet,
    getAllTemplateData,
    templateName,
    change,
    setChange,
    particularRule,
    setParticularRule,
    expanded,
    setExpanded,
    selectedItem,
    setSelectedItem,
    paramData,
    setParamData,
    ruleFieldDropdown,
    setRuleFieldDropdown,
    onRuleSubmit,
    selectedRule,
    setSelectedRule,
    saveFormLoading,
    setIsSaveFormLoading,
    getCompanyDetails,
    companyDetails,
    expandedPanel,
    setExpandedPanel,
    setValidationData,
    getValidationData,
    documentDropDownData,
    mainArrayUpdate,
    setIsCalled,
    gridValue,
    setGridValue,
    isCalled,
    isPropertyRule,
    setIsPropertyRule,
    addValidation,
    setAddValidation,
    filterStatus,
    isRuleActive,
    handleAdd,
    validationFormData,
    setValidationFormData,
    validationType,
    setValidationType,
    fromValidationSearch,
    setFromValidationSearch,
    validationInput,
    setValidationInput,
    setPage,
    prevValue,
    fetchOptions,
    isAddRule,
    setIsAddRule,
    activeInactive,
    isOtherRule,
    setIsOtherRule,
    ruleStatus,
    panelDropdown,
    inputRef,
    formNames,
    setFormName,
    isRuleLoading,
    dirtyRecord,
    setDirtyRecord,
    ruleDropdownValue,
    leftBarFields,
    dropdownTypeArr,
    isOverMainContainer,
    setIsOverMainContainer,
    setIsEdited,
    ruleDataList,
    postTriggerRule,
    setPostTriggerRule,
    smartSuggestion,
    onSmartSuggestion,
    stringToDropdown,
    setStringToDropdown,
    fromDropdown,
    setFromDropdown,
    dropDownTypeApiOrField,
    setDropDownTypeApiOrField,
    handleRuleChange,
    convertStringToDropDownJSONform,
    completeRuleScreen,
    setCompleteRuleScreen,
    viewRuleCardScreen,
    setViewRuleCardScreen,
  };

  return (
    <>
      {!isMobile ? (
        <FormBuilderContext.Provider value={fromBuilderProps}>
          <DndProvider backend={HTML5Backend}>
            <div className="wrapper">
              <div className="row" style={{ padding: "0", margin: 0 }}>
                <div className="col-lg-3" style={marginPadding}>
                  <Button
                    className="w-100 p-4 mb-3"
                    style={{ backgroundColor: "#e8f0fd" }}
                    onClick={() => {
                      if (handleItemAdded) {
                        handleItemAdded({
                          ...FormContainerList[0],
                          controlName: "",
                        });
                      }
                    }}
                  >
                    <span style={{ marginRight: "9px" }}>
                      <i className="fa fa-plus"></i>
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#318BE3",
                      }}
                    >
                      Add panel
                    </span>
                  </Button>

                  <div className={classes.sidebarHeight + " sidebar"}>
                    {/* draggable items */}

                    <LeftSidebar
                      handleItemAdded={handleItemAdded}
                      formLayoutComponents={formLayoutComponents}
                      isOverMainContainer={isOverMainContainer}
                      setIsCalled={setIsCalled}
                      sideBarLoading={sideBarLoading}
                    />
                  </div>
                </div>

                <div className="col-lg-6 p-0">
                  <div className="container p-20 p-t-10 h-100">
                    <div className="row m-0">
                      <div className="w-100 d-flex justify-content-between align-items-between">
                        <div className="d-flex justify-content-between align-items-center">
                          <FileIcon size={30} color="#4f4f4f" />
                          {saveFormLoading ? (
                            <Skeleton
                              variant="rectangular"
                              width={140}
                              height={40}
                              style={{
                                borderRadius: "6px",
                                margin: "0 0 0 1rem",
                              }}
                            />
                          ) : (
                            <>
                              {templateName && templateName.length > 13 ? (
                                <Tooltip
                                  title={templateName}
                                  placement="top-start"
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: "#FFF",
                                        color: "#4f4f4f",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                                        fontSize: "12px",
                                      },
                                    },
                                  }}
                                >
                                  <h4
                                    className="text-capitalize d-flex align-items-center"
                                    style={{
                                      color: "#4f4f4f",
                                      margin: "4px 0 0 1rem",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {templateName?.slice(0, 13) + "..."}
                                  </h4>
                                </Tooltip>
                              ) : (
                                <h4
                                  className="text-capitalize d-flex align-items-center"
                                  style={{
                                    color: "#4f4f4f",
                                    margin: "4px 0 0 1rem",
                                    fontSize: "14px",
                                  }}
                                >
                                  {templateName ?? ""}
                                </h4>
                              )}
                            </>
                          )}
                        </div>
                        <div className="action-buttons d-flex align-items-center">
                          {saveFormLoading ? (
                            <Skeleton
                              variant="rectangular"
                              width={140}
                              height={40}
                              style={{
                                borderRadius: "6px",
                                margin: "0 0 0 1rem",
                              }}
                            />
                          ) : (
                            <Autocomplete
                              options={panelDropdown || []}
                              getOptionLabel={(option) => option?.name}
                              onChange={scrollFunction}
                              size="small"
                              style={{ width: "12rem" }}
                              className="mr-2"
                              popupIcon={<KeyboardArrowDownIcon />}
                              value={panelSelected}
                              disableClearable
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Go To Panel"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}

                          <Button
                            onClick={reDirectToMainSite}
                            className="mx-2"
                            style={commonStyle}
                          >
                            Cancel
                          </Button>
                          <div className="border-right h-50" />
                          <Button
                            onClick={saveForm}
                            className="mx-1"
                            style={commonStyle}
                          >
                            Save
                          </Button>
                          <Button
                            className="ml-2"
                            variant="outlined"
                            onClick={() => openPreviewDrawer()}
                            endIcon={<RemoveRedEye />}
                            style={{
                              ...commonStyle,
                              height: "2.5rem",
                              border: "1px solid #318BE3",
                            }}
                          >
                            Preview
                          </Button>
                        </div>
                      </div>
                    </div>

                    {/* Droppable Zone */}
                    {saveFormLoading ? (
                      <div className="row">
                        <div style={{ height: "3rem" }} />
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={70}
                          style={{
                            borderRadius: "12px",
                            margin: "1rem",
                          }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={70}
                          style={{
                            borderRadius: "12px",
                            margin: "1rem",
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <MoveablePannel />
                        {/* <div
                          className="p-20 mt-3"
                          style={{
                            overflowY: "auto",
                            height: "calc(100vh - 150px)",
                            //position: "relative",
                            width: "100%",
                          }}
                        >
                          <div className="row">
                            {formLayoutComponents
                              // ?.filter((e) => e.container?.deleted === false)
                              ?.map((layout, ind) => {
                                return (
                                  <DropContainerComponent
                                    key={layout.container.id}
                                    index={ind}
                                    layout={layout.container}
                                    selectedControl={selectedControl}
                                    childrenComponents={layout.children}
                                    deleteContainer={deleteContainer}
                                    deleteControl={deleteControl}
                                    selectControl={selectControl}
                                    accept={FormItemTypes.CONTROL}
                                    moveControl={moveControl}
                                    isOverMainContainer={isOverMainContainer}
                                    setIsOverMainContainer={
                                      setIsOverMainContainer
                                    }
                                    deleteArrayItems={deleteArrayItems}
                                    gridValue={gridValue}
                                    setGridValue={setGridValue}
                                    editControlProperties={
                                      editControlProperties
                                    }
                                    isCalled={isCalled}
                                    setIsCalled={setIsCalled}
                                    expandedPanel={expandedPanel}
                                    setExpandedPanel={setExpandedPanel}
                                    dirtyRecord={dirtyRecord}
                                    particularRule={particularRule}
                                    ruleData={ruleData}
                                  />
                                );
                              })}
                          </div>
                          <div className="row">
                            <DropContainerComponent
                              accept={FormItemTypes.CONTAINER}
                              name={"Parent Component"}
                              handleItemAdded={handleItemAdded}
                            />
                          </div>
                        </div> */}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-3" style={{ ...marginPadding }}>
                  <div
                    className="w-100 mb-3 pt-3 d-flex justify-content-around align-items-center"
                    style={{
                      backgroundColor: "#e8f0fd",
                      height: "5rem",
                    }}
                  >
                    <p
                      style={isPropertyRule ? enable : disable}
                      onClick={() => setIsPropertyRule(true)}
                    >
                      Property
                    </p>
                    <p
                      style={
                        isPropertyRule
                          ? { ...disable, marginRight: "2rem" }
                          : { ...enable, marginRight: "2rem" }
                      }
                      onClick={() => setIsPropertyRule(false)}
                    >
                      Rule
                    </p>
                  </div>
                  {!selectedControl ? (
                    <div
                      role="alert"
                      className="m-t-30 alert alert-dismissible pb-3"
                      style={{ backgroundColor: "#318BE3" }}
                    >
                      <h4>Note!</h4>
                      {isPropertyRule
                        ? "You need to select panel/field to edit properties"
                        : "You need to select a control to edit rules"}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-between pr-2">
                      {isPropertyRule ? (
                        <h5
                          className="my-2"
                          style={{ fontSize: "16px", margin: "0 0 0 2rem" }}
                        >
                          {`Edit ${
                            selectedControl.itemType === FormItemTypes.CONTAINER
                              ? "Container"
                              : "Field"
                          } Properties`}
                        </h5>
                      ) : selectedControl?.name?.length <= 8 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 0 0 2rem",
                          }}
                        >
                          {completeRuleScreen && !viewRuleCardScreen && (
                            <Box
                              onClick={() => {
                                setIsPropertyRule(true);
                                setTimeout(() => {
                                  setIsPropertyRule(false);
                                }, 0);
                              }}
                            >
                              <ArrowBackIcon sx={{ cursor: "pointer" }} />
                            </Box>
                          )}

                          <h5
                            className="my-2"
                            style={{
                              fontSize: "16px",
                              margin:
                                completeRuleScreen && !viewRuleCardScreen
                                  ? "0 0 0 1rem"
                                  : 0,
                            }}
                          >
                            {selectedControl?.name}
                          </h5>
                        </Box>
                      ) : (
                        <Tooltip
                          title={selectedControl?.name}
                          placement="top-start"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#FFF",
                                color: "#4f4f4f",
                                boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                                fontSize: "12px",
                              },
                            },
                          }}
                        >
                          <h5
                            className="my-2"
                            style={{ fontSize: "16px", margin: "0 0 0 2rem" }}
                          >
                            {selectedControl?.name?.slice(0, 8) + "..."}
                          </h5>
                        </Tooltip>
                      )}

                      {!isPropertyRule && (
                        <div
                          className="d-flex align-items-center justify-content-between"
                          //style={{ width: "18.5rem" }}
                        >
                          <Button
                            variant="outlined"
                            onClick={filterStatus}
                            style={{ textTransform: "capitalize" }}
                          >
                            {isRuleActive === undefined
                              ? "All"
                              : isRuleActive
                              ? "active"
                              : "Inactive"}
                          </Button>
                          <Button
                            className="mx-2"
                            color="primary"
                            disableElevation
                            variant="contained"
                            style={{
                              ...rest,
                              backgroundColor:
                                selectedControl?.type === "PANEL"
                                  ? "#E5E4E2"
                                  : "#318BE3",
                            }}
                            onClick={handleAdd}
                            disabled={
                              selectedControl?.type === "PANEL" ? true : false
                            }
                          >
                            Add Rule
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {(selectedControl ||
                    (!selectedControl && hideToFixScrolling)) && (
                    <div
                      className={classes.sidebarHeight + " sidebar"}
                      style={{
                        paddingTop: "1rem",
                        overflowY: "auto",
                        height: "calc(100vh - 14rem)",
                      }}
                    >
                      <div className="container">
                        {isPropertyRule ? (
                          <EditPropertiesComponent
                            selectedControl={selectedControl}
                            selectControl={selectControl}
                            formLayoutComponents={formLayoutComponents}
                            moveControlFromSide={moveControlFromSide}
                            editContainerProperties={editContainerProperties}
                            editControlProperties={editControlProperties}
                            gridValue={gridValue}
                            isCalled={isCalled}
                            validationData={validationData ?? []}
                            selectedValidation={selectedValidation}
                            setSelectedValidation={setSelectedValidation}
                            isValidationEdited={isValidationEdited}
                            selectedApiValidation={selectedApiValidation}
                            setSelectedApiValidation={setSelectedApiValidation}
                            setValidationData={setValidationData}
                            getSelectedTemplateValidationData={
                              getSelectedTemplateValidationData
                            }
                            addValidation={addValidation}
                            setAddValidation={setAddValidation}
                            validationFormData={validationFormData}
                            setValidationFormData={setValidationFormData}
                            validationType={validationType}
                            setValidationType={setValidationType}
                            fromValidationSearch={fromValidationSearch}
                            setFromValidationSearch={setFromValidationSearch}
                            // setFetchFun={setFetchFun}
                            validationInput={validationInput}
                            setValidationInput={setValidationInput}
                            setPage={setPage}
                            prevValue={prevValue}
                            fetchOptions={fetchOptions}
                            inputRef={inputRef}
                          />
                        ) : (
                          <div>
                            {/* {isRuleLoading ? (
                              <div className="row">
                                <Skeleton
                                  variant="rectangular"
                                  width={"100%"}
                                  height={50}
                                  style={{
                                    borderRadius: "12px",
                                    margin: "1rem",
                                  }}
                                />
                              </div>
                            ) : (
                              selectedControl &&
                              !isAddRule &&
                              ruleData
                                ?.filter(
                                  (e) => e?.appliedId === selectedControl?.id
                                )
                                ?.map((item, index) => (
                                  <ViewRuleCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    selectedRule={selectedRule}
                                    setSelectedRule={setSelectedRule}
                                    ruleFieldDropdown={ruleFieldDropdown}
                                    isOtherRule={isOtherRule}
                                    setIsOtherRule={setIsOtherRule}
                                    isPropertyRule={isPropertyRule}
                                    handleRuleGet={handleRuleGet}
                                    selectedControl={selectedControl}
                                    setIsAddRule={setIsAddRule}
                                    // ruleMasterData={ruleMasterData}
                                    setChange={setChange}
                                    selectControl={selectControl}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    handleChange={handleChange}
                                    // onClick={() => setSelectedItem(item)}
                                    selectedItem={selectedItem}
                                    editControlProperties={
                                      editControlProperties
                                    }
                                    setParticularRule={setParticularRule}
                                    change={change}
                                    ruleData={ruleData}
                                    setRuleData={setRuleData}
                                    onRuleSubmit={onRuleSubmit}
                                    paramData={paramData}
                                    setParamData={setParamData}
                                    activeInactive={activeInactive}
                                    ruleStatus={ruleStatus}
                                    particularRule={particularRule}
                                    isRuleActive={isRuleActive}
                                    documentDropDownData={documentDropDownData}
                                    formNames={formNames}
                                    setFormName={setFormName}
                                    dirtyRecord={dirtyRecord}
                                    setDirtyRecord={setDirtyRecord}
                                  />
                                ))
                            )} */}

                            {selectedControl &&
                              !isAddRule &&
                              ruleData
                                ?.filter(
                                  (e) => e?.appliedId === selectedControl?.id
                                )
                                ?.map((item, index) => (
                                  <ViewRuleCard
                                    key={index}
                                    index={index}
                                    item={item}
                                    selectedRule={selectedRule}
                                    setSelectedRule={setSelectedRule}
                                    ruleFieldDropdown={ruleFieldDropdown}
                                    isOtherRule={isOtherRule}
                                    setIsOtherRule={setIsOtherRule}
                                    isPropertyRule={isPropertyRule}
                                    handleRuleGet={handleRuleGet}
                                    selectedControl={selectedControl}
                                    setIsAddRule={setIsAddRule}
                                    // ruleMasterData={ruleMasterData}
                                    setChange={setChange}
                                    selectControl={selectControl}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    handleChange={handleChange}
                                    // onClick={() => setSelectedItem(item)}
                                    selectedItem={selectedItem}
                                    editControlProperties={
                                      editControlProperties
                                    }
                                    setParticularRule={setParticularRule}
                                    change={change}
                                    ruleData={ruleData}
                                    setRuleData={setRuleData}
                                    onRuleSubmit={onRuleSubmit}
                                    paramData={paramData}
                                    setParamData={setParamData}
                                    activeInactive={activeInactive}
                                    ruleStatus={ruleStatus}
                                    particularRule={particularRule}
                                    isRuleActive={isRuleActive}
                                    documentDropDownData={documentDropDownData}
                                    formNames={formNames}
                                    setFormName={setFormName}
                                    dirtyRecord={dirtyRecord}
                                    setDirtyRecord={setDirtyRecord}
                                  />
                                ))}

                            {selectedControl && isAddRule && (
                              <div style={{ margin: "0 1rem" }}>
                                <EditRuleComponent
                                  selectedControl={selectedControl}
                                  setIsAddRule={setIsAddRule}
                                  selectedRule={selectedRule}
                                  setSelectedRule={setSelectedRule}
                                  ruleFieldDropdown={ruleFieldDropdown}
                                  isOtherRule={isOtherRule}
                                  setIsOtherRule={setIsOtherRule}
                                  isPropertyRule={isPropertyRule}
                                  handleRuleGet={handleRuleGet}
                                  setChange={setChange}
                                  selectControl={selectControl}
                                  editControlProperties={editControlProperties}
                                  change={change}
                                  ruleData={ruleData}
                                  setRuleData={setRuleData}
                                  onRuleSubmit={onRuleSubmit}
                                  paramData={paramData}
                                  setParamData={setParamData}
                                  activeInactive={activeInactive}
                                  particularRule={particularRule}
                                  setParticularRule={setParticularRule}
                                  documentDropDownData={documentDropDownData}
                                  formNames={formNames}
                                  setFormName={setFormName}
                                  dirtyRecord={dirtyRecord}
                                  setDirtyRecord={setDirtyRecord}
                                  type={""}
                                  firstName={""}
                                  lastName={""}
                                  expanded={0}
                                  setExpanded={function (value: number): void {
                                    throw new Error(
                                      "Function not implemented."
                                    );
                                  }}
                                  selectedItem={undefined}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Preview Drawer */}
            <FormPreview
              screenType="mobile"
              showPreview={showPreview}
              formLayoutComponents={formLayoutComponents}
              closePreviewDrawer={closePreviewDrawer}
              selectedTemplate={selectedTemplate}
              saveForm={saveForm}
              templateName={templateName}
            />
          </DndProvider>
        </FormBuilderContext.Provider>
      ) : (
        <>
          <div className="wrapper mt-5">
            <p className="text-center">
              Form Builder is only supported on desktop devices for now. Please
              switch to a desktop device.
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default FormBuilder;
