import * as API from "../utility/ApiHelper";
// import CryptoJS from "crypto-js";

// const encryptData = () => {
//   const secret = process.env.REACT_APP_SECRET_KEY; // Secret key
//   console.log(secret, "secret");
//   const encryptedData = CryptoJS.AES.encrypt("ENCRYPTED",secret,); // Assuming secret data encryption
//   return encryptedData.toString(); // Convert to string
// };

const companyId = window.sessionStorage.getItem("companyId");
export const fetchUserApiResults = () => {
  return API.get(
    `/app/v2/companies/${companyId}/templates?size=1000&sort=id%2Cdesc&fillEnabled=true`
  );
};
export const fetchUserDetail = () => {
  return API.get("/app/user/who-am-i");
};
export const fetchTemplate = (id) => {
  const companyId = window.sessionStorage.getItem("companyId");

  return API.get(
    `/app/companies/${companyId}/ui-customizations?templateId=${id}&section=create_ob&userType=first_party`
  );
};
export const fetchCompanyFeature = (id) => {
  const companyId = window.sessionStorage.getItem("companyId");
  
  return API.get(
    `/app/companies/${companyId}/company-product-features?templateId=${id}&parentFeatureName=ESIGN_METHOD`
  );
};
export const postFill = (data) => {
  return API.post(`/app/document/fill`, data);
};
export const fetchAllDocuments = (no) => {
  return API.get(
    `/app/transactions/${no}/documents?partyType=FIRST_PARTY,SECOND_PARTY`
  );
};
export const putFillTxn = (transactionId, dataOfPut) => {
  console.log(transactionId, "transactionId");
  console.log(dataOfPut, "data1");

  return API.put(`/app/document/fill/${transactionId}`, dataOfPut);
};
export const fetchTemplateInfo = (id) => {
  return API.get(`/app/document/fill/${id}`);
};
export const fetchmetaData = (transactionId, documentTypeId) => {
  const apiConfig = {
    headers: {
      "X-Auth2": encryptData(), // Override X-Auth2 if needed
    },
  };
  return API.get(
    `/app/document/fill/${transactionId}/${documentTypeId}/metadata`,
    null,
    apiConfig
  );
};
export const fillmetaData = (
  transactionId,
  documentTypeId,
  dataValue,
  metaDataId,
  valueHash
) => {
  const apiConfig = {
    headers: {
      "X-Auth2":"U2FsdGVkX19nY8HmrSkk0IcXvdqE9MN5RVl4vGCOzOk=", // Override X-Auth2 with encrypted data
    },
  };

  return API.post(
    `/app/document/fill/${transactionId}/${documentTypeId}/filldata`,
    [{ dataValue, metaDataId, valueHash }],
    // apiConfig
  );
};

// Function call template api
export const getTemplates = (id) => {
  
  return API.get(
    `app/companies/${id}/templates`
  );
};


// Function to call status tansition api
export const getStatusTransitions = (id) => {
  return API.get(
    `app/v2/templates/${id}/status-transitions?page=0&size=15&sort=id%2Cdesc&templateId=${id}`
  );
};


export const postStatusTransitions = (data) => {
  console.log("postStatusTransitionsdata",data)
  return API.post(`app/v2/templates/${data.id}/status-transitions`, data.data);
};


export const putStatusTransitions = (data) => {
  console.log("api Data",data)
  return API.put(`app/v2/templates/${data.id}/status-transitions/${data.STid}`, data.item);
};


export const getparticipants= (id) => {
  console.log("ankit0001",id)
  return API.get(
    `app/v2/templates/${id}/participants?page=0&size=1000&sort=id%2Casc`
  );
};

export const getDocumentType= (id) => {
  return API.get(
    `app/v2/templates/${id}/document-types?page=0&size=1000&sort=id%2Casc`
  );
};

export const getDocumentTypeFormFill= (id) => {
  return API.get(
    `app/v2/document-types/${id}/form-fill-metadatas/delete-flag/false?page=0&size=1000&sort=id%2Casc&searchParam=`
  );
};

export const getGenericGroup= (id) => {
  const companyId = window.sessionStorage.getItem("companyId");

  return API.get(
    `app/genericGrp/generic-group/company/${companyId}?page=0&size=1000&sort=id%2Casc`
  );
};

export const getUserType= (key) => {
  const companyId = window.sessionStorage.getItem("companyId");

  return API.get(
    `app/v2/companies/${companyId}/users?&q=${key}&sort=id%2Cdesc&role=FIRST_PARTY&inclInactive=true&excludeMe=false`
  );
};

export const postParticipant = (data) => {
  console.log("data0101aakak111111",data)
  const participantData=data.flow
  const transformedData = JSON.parse(JSON.stringify(participantData, (key, value) => {
    return value === "" ? null : value;
  }));
  return API.post(`app/v2/templates/${data.id}/participants`, transformedData);
};

export const postCondition= (data) => {
  const companyId = window.sessionStorage.getItem("companyId");
  return API.post(`app/v2/companies/${companyId}/conditions`, data);
};

export const getCondition= (id) => {
  const companyId = window.sessionStorage.getItem("companyId");
  return API.get(`app/v2/companies/${companyId}/conditions/${id}?page=0&size=1000&sort=id%2Casc&searchParam=`);
};

export const getStatusTemplates= (id) => {
  const companyId = window.sessionStorage.getItem("companyId");
  return API.get(`app/v2/templates/${id}/status-templates?page=0&size=15&sort=id%2Cdesc`);
};

export const postStatusTemplates= (data) => {
  console.log("data0101aakak",data)
  return API.post(`app/v2/templates/${data.templateId}/status-templates`,data.statusTempData);
};
export const putStatusTemplates= (data) => {
  return API.put(`app/v2/templates/${data.templateId}/status-templates/${data.id}`,data.statusTempData);
};

export const getStatusTemplatesWithNodes= (id) => {
  return API.get(`app/v2/templates/${id}`)
};

export const patchStatusTemplate= (data) => {
  const payload={
    "workFlowDataNodeData": JSON.stringify(data)
  }
  return API.patch(`app/v2/templates/${data.tempId}`,payload);
};

export const putParticipants= (data) => {
  return API.put(
    `app/v2/templates/${data.tempId}/participants/${data.participantId}`,data.putData
  );
};