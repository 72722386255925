import React, {
  FunctionComponent,
  Dispatch,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  dirtObjType,
  FormLayoutComponentChildrenType,
  FormLayoutComponentContainerType,
} from "../../../types/FormTemplateTypes";
import { useDrop } from "react-dnd";
import {
  FormContainerList,
  FormItemTypes,
} from "../../../utils/formBuilderUtils";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Tooltip,
} from "@mui/material";
import ControlViewComponent from "./ControlViewComponent";
import "./styles.scss";
import { Resizable, ResizeDirection } from "re-resizable";
import { DeleteIcon } from "../../../assets/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { DialogBox } from "../../Modal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type DispatchObject = {
  value: boolean;
  arrayId?: string;
};

export type ResizeCallback = (
  event: any,
  direction: ResizeDirection,
  refToElement: any
) => void | undefined;

interface DropContainerComponentProps {
  accept: string;
  name?: string;
  index?: number;
  handleItemAdded?: (
    item: FormLayoutComponentChildrenType | FormLayoutComponentContainerType,
    containerId?: string
  ) => void;
  layout?: FormLayoutComponentChildrenType | FormLayoutComponentContainerType;
  selectedControl?:
    | null
    | FormLayoutComponentChildrenType
    | FormLayoutComponentContainerType;
  childrenComponents?: FormLayoutComponentChildrenType[];
  deleteContainer?: (containerId: string) => void;
  deleteControl?: (controlId: string, containerId: string) => void;
  selectControl?: (
    layout:
      | FormLayoutComponentChildrenType
      | FormLayoutComponentContainerType
      | undefined
  ) => void;
  moveControl?: (
    item: FormLayoutComponentChildrenType,
    dragIndex: number,
    hoverIndex: number,
    containerId: string
  ) => void;
  isOverMainContainer?: DispatchObject;
  setIsOverMainContainer?: Dispatch<DispatchObject>;
  deleteArrayItems?: (
    containerId: string,
    id: string,
    event?: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  gridValue?: number | undefined;
  setGridValue?: Dispatch<number | undefined>;
  editControlProperties?: (updatedItem: any, type: string) => void;
  isCalled?: boolean;
  setIsCalled?: Dispatch<boolean>;
  expandedPanel?: any;
  setExpandedPanel?: Dispatch<undefined | string[] | number[]>;
  dirtyRecord?: dirtObjType[];
  particularRule?: any;
  ruleData?: any;
}

export const enableProps = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

const gridValueReturn = (value: number) => {
  if (value >= 20 && value < 30) {
    return 4;
  } else if (value >= 30 && value < 40) {
    return 5;
  } else if (value >= 40 && value < 50) {
    return 6;
  } else if (value >= 50 && value < 60) {
    return 7;
  } else if (value >= 60 && value < 70) {
    return 8;
  } else if (value >= 70 && value < 80) {
    return 9;
  } else if (value >= 80 && value < 90) {
    return 10;
  } else if (value >= 90 && value < 96) {
    return 12;
  } else if (value >= 96) {
    return 12;
  }
};

const DropContainerComponent: FunctionComponent<DropContainerComponentProps> = (
  props
) => {
  const {
    accept,
    layout,
    childrenComponents,
    deleteContainer,
    deleteControl,
    selectControl,
    selectedControl,
    handleItemAdded,
    moveControl,
    isOverMainContainer,
    setIsOverMainContainer,
    deleteArrayItems,
    gridValue,
    setGridValue,
    editControlProperties,
    setIsCalled,
    expandedPanel,
    setExpandedPanel,
    dirtyRecord,
  } = props;

  const [elementWidth, setElementWidth] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: accept,
    drop: () => layout,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [modalOpen, setModalOpen] = useState(false);

  const isActive = canDrop && isOver && isOverMainContainer?.value;
  let backgroundColor =
    accept && accept === FormItemTypes.CONTROL ? "rgba(0,0,0,0)" : "#f0f0f0";
  let borderColor = "rgba(0,0,0,0.1)";
  const borderBase = "1.5px solid";
  let border;
  if (isActive) {
    backgroundColor = "rgba(232, 240, 254, 0.5)";
  } else if (canDrop && isOverMainContainer?.value) {
    backgroundColor = "transparent";
  }

  if (accept === FormItemTypes.CONTROL) {
    border = borderBase + " " + borderColor;
  }

  // Change border Color
  if (
    selectedControl &&
    selectedControl.itemType === layout?.itemType &&
    selectedControl.id === layout.id
  ) {
    borderColor = "#318BE3";
    border = borderBase + " " + borderColor;
  }

  const deleteContainerFunction = (event: {
    stopPropagation: () => void | undefined;
  }) => {
    setModalOpen(true);
  };

  const ResizeCallback: ResizeCallback = (a, b, target) => {
    setElementWidth(target.offsetWidth);
    setIsCalled?.(false);
  };

  const ResizeStopCallback = (component: FormLayoutComponentChildrenType) => {
    setIsCalled?.(true);
    const item = component?.container
      ? { ...component?.container, gridValue: gridValue }
      : { ...component, gridValue: gridValue };
    editControlProperties?.(item, "");
    selectControl?.(undefined);
    selectControl?.(item);
  };

  const refContainer = useRef<HTMLDivElement>(null);

  const handleChange = (panel: any) => (_event: any, isExpanded: boolean) => {
    if (isExpanded) {
      //setExpandedPanel?.([...expandedPanel, panel]);
      setExpandedPanel?.([panel]); // Only single accordian will open
    } else {
      setExpandedPanel?.(
        expandedPanel?.filter((item: string) => item !== panel)
      );
    }
  };

  useEffect(() => {
    if (refContainer.current) {
      setContainerWidth(refContainer?.current?.offsetWidth);
    }
  }, [window.screen.width]);

  useEffect(() => {
    if (elementWidth && containerWidth) {
      const percent = (elementWidth / containerWidth) * 100;
      const value = parseInt(Math.round(percent).toFixed(0));

      const grid = gridValueReturn(value);

      setGridValue?.(grid);
    }
  }, [elementWidth, containerWidth]);

  // To check dirty
  let dirty = false;
  let obj = dirtyRecord?.find((e: any) => e?.panelId === layout?.id);

  if (obj) {
    for (let i = 0; i < obj?.children?.length; i++) {
      for (let j = 0; j < obj?.children[i]?.rule?.length; j++) {
        if (
          obj?.children[i]?.rule[j]?.sourceIds?.length > 0 ||
          obj?.children[i]?.rule[j]?.destinationIds?.length > 0
        ) {
          dirty = true;
        }
      }
    }
  }

  return (
    <div
      className="col-12 container-drop"
      ref={drop}
      style={{
        backgroundColor,
        borderRadius: "9px",
        border,
        padding: "8px",
      }}
      id={layout?.id}
    >
      {accept === FormItemTypes.CONTAINER && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "90px" }}
        >
          <Button
            onClick={() => {
              if (handleItemAdded) {
                handleItemAdded({
                  ...FormContainerList[0],
                  controlName: "",
                });
              }
            }}
          >
            <span style={{ marginRight: "9px" }}>
              <i className="fa fa-plus"></i>
            </span>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#318BE3",
              }}
            >
              Add a panel here
            </span>
          </Button>
        </div>
      )}

      {accept === FormItemTypes.CONTROL && (
        <Accordion
          style={{ boxShadow: "none", background: "transparent" }}
          expanded={expandedPanel?.includes(layout?.id)}
          onChange={handleChange(layout?.id)}
          ref={refContainer}
        >
          <AccordionSummary
            expandIcon={
              expandedPanel?.includes(layout?.id) ? (
                <RemoveIcon style={{ color: "#4f4f4f", fontSize: "1.8rem" }} />
              ) : (
                <AddOutlinedIcon
                  style={{ color: "#4f4f4f", fontSize: "1.8rem" }}
                />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ width: "100%" }}
          >
            <div
              onClick={() => {
                selectControl?.(layout);
              }}
              className="w-100 container-header d-flex justify-content-between align-items-center"
              style={{
                cursor: "pointer",
                margin: 0,
                padding: 0,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <h2
                  style={{
                    margin: "0 0 -2.5px 0",
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  {(layout as FormLayoutComponentContainerType)?.name}
                </h2>
                {layout?.id &&
                  // dirtyRecord?.some((e: any) => e?.panelId === layout?.id)
                  dirty && (
                    <Tooltip
                      title={`Some Mandatory fields of Rules for this panel are missing`}
                      placement="top-start"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#FFF",
                            color: "red",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                            fontSize: "12px",
                            border: "1px solid red",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          width: "1.25rem",
                          margin: "0 0 0 0.5rem",
                          cursor: "pointer",
                          color: "red",
                        }}
                      />
                    </Tooltip>
                  )}
              </Box>

              <div
                className="control-actions d-flex justify-content-between align-items-center"
                style={{ margin: "0 0 0 0" }}
              >
                <span
                  style={{ cursor: "grab" }}
                  className="d-flex justify-content-between align-items-center h-100%"
                >
                  <i className="fa fa-ellipsis-v"></i>
                  <i className="fa fa-ellipsis-v"></i>
                </span>
                <span
                  onClick={(e) => {
                    // Prevent accordion from expanding/collapsing when delete icon is clicked // event bubbling
                    e.stopPropagation();
                    deleteContainerFunction(e);
                  }}
                  style={{ zIndex: "999", position: "relative" }}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className="d-flex m-0"
              style={{
                minHeight: !childrenComponents?.length ? "20vh" : "100%",
                alignItems: !childrenComponents?.length ? "center" : "",
                justifyContent: !childrenComponents?.length ? "center" : "",
                flexWrap: "wrap",
                boxShadow: "none",
                borderTop: "1px solid rgba(0,0,0,0.1)",
              }}
            >
              {childrenComponents?.length === 0 ? (
                <>
                  <div>
                    <span style={{ marginRight: "9px" }}>
                      <i className="fa fa-plus"></i>
                    </span>
                    <span>Drop Field</span>
                  </div>
                </>
              ) : (
                <>
                  {childrenComponents
                    //?.filter((e) => e?.deleted === false)
                    ?.map((component, ind) => {
                      return (
                        <Resizable
                          style={{ margin: "2rem 1rem 0 1rem", padding: 0 }}
                          defaultSize={{
                            width:
                              component?.container ||
                              component?.gridValue === 12
                                ? "97%"
                                : component?.gridValue === 6
                                ? "44%"
                                : component?.gridValue === 4
                                ? "28%"
                                : "44%",
                            height: "auto",
                          }}
                          minWidth="28%"
                          maxWidth="97%"
                          minHeight="100%"
                          onResize={ResizeCallback}
                          onResizeStop={() => ResizeStopCallback(component)}
                          enable={{
                            ...enableProps,
                            right:
                              (selectedControl?.type === "ARRAY" &&
                                component?.container?.id ===
                                  selectedControl?.id) ||
                              (selectedControl?.type !== "ARRAY" &&
                                component?.id === selectedControl?.id)
                                ? true
                                : false,
                          }}
                        >
                          <ControlViewComponent
                            key={component.id}
                            item={component}
                            deleteControl={(controlId, containerId) => {
                              if (deleteControl) {
                                deleteControl(controlId, containerId);
                              }
                            }}
                            selectControl={(layout) => {
                              selectControl?.(layout);
                            }}
                            selectedControl={selectedControl}
                            containerId={layout?.id as string}
                            index={ind}
                            moveControl={(
                              item,
                              dragIndex,
                              hoverIndex,
                              containerId
                            ) => {
                              if (moveControl) {
                                moveControl(
                                  item,
                                  dragIndex,
                                  hoverIndex,
                                  containerId
                                );
                              }
                            }}
                            isOverMainContainer={isOverMainContainer}
                            setIsOverMainContainer={setIsOverMainContainer}
                            deleteArrayItems={deleteArrayItems}
                            ResizeCallback={ResizeCallback}
                            ResizeStopCallback={ResizeStopCallback}
                          />
                        </Resizable>
                      );
                    })}
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <DialogBox
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        deleteFun={deleteContainer}
        layout={layout}
        type="panel"
      />
    </div>
  );
};

export default DropContainerComponent;
