import React from "react";
import "./UploadDocuments.scss";
import TemplateName from "../../../components/FormFill/Reuseable/TemplateName/TemplateName";
import { Box } from "@mui/material";
import DocumentsStepper from "../../../components/FormFill/Global/DocumentsStepper/DocumentsStepper";
import DocumentsSections from "../../../components/FormFill/Global/DocumentsSections/DocumentsSections";
import DocumentList from "../../../components/FormFill/Reuseable/DocumentList/DocumentList";
import { useSelector } from "react-redux";
import useWindowSize from "../../../components/FormFill/Global/Inner/useWindowSize";
const UploadDocuments = () => {
  const [width] = useWindowSize();
  const isMobile = width <= 500;
  const sidebarOpen = useSelector((state) => state?.ManchSlice?.sidebarOpen);
  const lastSegment = window.location.pathname.split("/").pop();
  const isUploadDocuments = lastSegment === "uploadDocuments";
  return (
    <Box
      className="UploadDocuments"
      // style={{
      //   paddingLeft: sidebarOpen ? "80px" : "0px",
      //   height: "100%",
      //   backgroundColor: "#f4f8fb",
      //   overflowY: "scroll",
  //    }}
    >
      <Box className={isUploadDocuments && isMobile ? "modified-layout" : "layout"}>
        <Box className="background">
          <Box className={`block-a ${isUploadDocuments && isMobile ? "modified-block-a" : ""}`}>
            <TemplateName />
          </Box>
          <Box className="main-content">
            <Box className="left-col">
              <Box className={`block-b ${isUploadDocuments && isMobile ? "modified-block-b" : ""}`}>
                <DocumentsStepper />
              </Box>
              <Box className="block-d">
                <DocumentList />
              </Box>
            </Box>
              <Box className="right-col">
                        <Box className={`block-c ${isUploadDocuments && isMobile ? "modified-block-c" : ""}`}>
              <DocumentsSections />
                </Box>
                       </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadDocuments;
