import React from "react";
import { common } from "./interface";

export const MapIcon = (props: common) => {
  const { size, color = "#c1c1c1" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="Group 4285">
        <g data-name="Layer 2">
          <path
            data-name="Path 1817"
            d="M9 29h14a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2z"
            style={{ fill: color }}
            transform="translate(-1 -1)"
          />
          <path
            data-name="Path 1818"
            d="M15.35 24.76a1 1 0 0 0 1.3 0C17 24.5 24 18.38 24 11a8 8 0 1 0-16 0c0 7.38 7 13.5 7.35 13.76zM16 5a6 6 0 0 1 6 6c0 5.23-4.32 10-6 11.63-1.68-1.63-6-6.4-6-11.63a6 6 0 0 1 6-6z"
            style={{ fill: color }}
            transform="translate(-1 -1)"
          />
          <path
            data-name="Path 1819"
            d="M19 11a3 3 0 1 0-3 3 3 3 0 0 0 3-3zm-4 0a1 1 0 1 1 1 1 1 1 0 0 1-1-1z"
            style={{ fill: color }}
            transform="translate(-1 -1)"
          />
        </g>
        <path
          data-name="Rectangle 1095"
          style={{ fill: "none" }}
          d="M0 0h30v30H0z"
        />
      </g>
    </svg>
  );
};
