import * as React from "react"
const Form = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} {...props}>
    <g data-name="Group 4276">
      <g
        data-name="Path 970"
        style={{
          fill: "#fff",
        }}
      >
        <path
          d="M22 43a20.931 20.931 0 0 1-14.85-6.15A20.931 20.931 0 0 1 1 22 20.931 20.931 0 0 1 7.15 7.15 20.931 20.931 0 0 1 22 1a20.931 20.931 0 0 1 14.85 6.15A20.931 20.931 0 0 1 43 22a20.931 20.931 0 0 1-6.15 14.85A20.931 20.931 0 0 1 22 43z"
          style={{
            stroke: "none",
          }}
        />
        <path
          d="M22 2A19.936 19.936 0 0 0 7.858 7.858 19.936 19.936 0 0 0 2 22a19.936 19.936 0 0 0 5.858 14.142A19.936 19.936 0 0 0 22 42a19.936 19.936 0 0 0 14.142-5.858A19.936 19.936 0 0 0 42 22a19.936 19.936 0 0 0-5.858-14.142A19.936 19.936 0 0 0 22 2m0-2c12.15 0 22 9.85 22 22s-9.85 22-22 22S0 34.15 0 22 9.85 0 22 0z"
          style={{
            fill: "#c1c1c1",
            stroke: "none",
          }}
        />
      </g>
      <g data-name="Group 806">
        <path
          d="M0 0h30v29H0z"
          data-name="Rectangle 292"
          style={{
            fill: "none",
          }}
          transform="translate(7 7)"
        />
        <path
          d="M179.571 124.848V109.81h-3.008a.6.6 0 0 1-.6-.6v-3.01h-11.43v18.647zm-2.406-17.8 1.555 1.555h-1.555zm3.008 19h-16.241a.6.6 0 0 1-.6-.6V105.6a.6.6 0 0 1 .6-.6h12.632a.6.6 0 0 1 .425.176l3.609 3.609a.6.6 0 0 1 .176.425v16.24a.6.6 0 0 1-.6.6z"
          data-name="Path 690"
          style={{
            fill: "#c1c1c1",
            fillRule: "evenodd",
          }}
          transform="translate(-150.928 -92.896)"
        />
        <path
          d="M215.78 67.717h-2.349a.6.6 0 1 0 0 1.2h2.95a.6.6 0 0 0 .6-.6V52.679a.6.6 0 0 0-.176-.425l-5.414-5.414a.6.6 0 0 0-.426-.176h-12.03a.6.6 0 0 0-.6.6v2.406a.6.6 0 0 0 1.2 0v-1.8h11.18l5.061 5.061z"
          data-name="Path 691"
          style={{
            fill: "#c1c1c1",
            fillRule: "evenodd",
          }}
          transform="translate(-184.129 -37.57)"
        />
        <path
          d="M227.681 303.934a.6.6 0 0 1-.6.6h-4.812a.6.6 0 0 1 0-1.2h4.812a.6.6 0 0 1 .6.6z"
          data-name="Path 692"
          style={{
            fill: "#c1c1c1",
            fillRule: "evenodd",
          }}
          transform="translate(-206.256 -281.004)"
        />
        <path
          d="M222.267 240.548h7.82a.6.6 0 1 1 0 1.2h-7.82a.6.6 0 0 1 0-1.2zm0-7.218h6.015a.6.6 0 0 1 0 1.2h-6.015a.6.6 0 0 1 0-1.2z"
          data-name="Path 693"
          style={{
            fill: "#c1c1c1",
            fillRule: "evenodd",
          }}
          transform="translate(-206.256 -214.612)"
        />
      </g>
    </g>
  </svg>
)
export default Form