import * as React from "react"
const Flag = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} {...props}>
    <g data-name="Group 3712">
      <g
        data-name="Rectangle 284"
        style={{
          fill: "#fff",
          stroke: "#bfbfbf",
          strokeWidth: "1.6px",
        }}
      >
        <rect
          width={44}
          height={44}
          stroke="none"
          rx={22}
          style={{
            stroke: "none",
          }}
        />
        <rect
          width={42.4}
          height={42.4}
          x={0.8}
          y={0.8}
          rx={21.2}
          style={{
            fill: "none",
          }}
        />
      </g>
      <path
        d="M150.14 39.2v21.572h1.505v-8.027h16.4l-3.228-6.773 3.228-6.773zm15.52 12.016h-14.015V40.7h14.015l-2.511 5.268z"
        data-name="Path 372"
        style={{
          fill: "#c1c1c1",
        }}
        transform="translate(-137.118 -27.805)"
      />
    </g>
  </svg>
)
export default Flag